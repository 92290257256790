import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Inject, ChangeDetectorRef, Input, AfterViewInit } from '@angular/core';
import { Client } from '../models/client.model';
import { APP_CONFIG } from '../app.config';
import { StorageRevampService } from '../services/storage-revamp.service';
import { ImageLibraryService } from '../shared/imagelibrary.service';
import { Auth } from '../models/auth.model';
import { AuthService } from '../services/auth.service';
import { Subject } from 'rxjs';
import { ThirdPartyService } from '../services/thirdparty.service';
import { FormControl } from '@angular/forms';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import * as copy from 'copy-to-clipboard';

import { RoleHelper } from '../helpers/role-helper';
declare var $: any;
declare var swal: any;
declare var introJs: any;
declare var _bugHerd: any;

@Component({
  selector: 'app-image-library-revamp',
  templateUrl: './image-library-revamp.component.html',
  styleUrls: ['./image-library-revamp.component.scss']
})
export class ImageLibraryRevampComponent implements OnInit, OnDestroy, AfterViewInit {
  $destroy: Subject<boolean> = new Subject();

  loading = false;

  breadcrumbs = [];

  root = true;
  folderType = 'user';
  folders = [];
  renameFolderId: any;
  foldersCurrentPage = 1;
  foldersLastPage = 1;
  createFolder = false;

  imagesCurrentPage = 1;
  imagesLastPage = 1;
  images = [];
  imagesLoadMore = false;

  selectedImage = {};
  selectedImageType = '';

  files: any[] = [];
  filesUploadComplete = false;
  fileRemoved: any;
  uploadedFiles = 0;
  folderId = '';

  isFileMove = false;
  moveFile: any;
  moveFileFolders = [];
  moveFileFolderRoot = false;
  moveFileFolderType = 'user';
  moveFileFolderId = '';
  folderBreadcrumbs = [];

  isStockPage = false;
  stockSearchText: FormControl;
  unsplashCurrentPage = 1;
  unsplashImages = [];
  selectedUnsplashImage: any;

  is_builder = false;
  is_admin = false;

  scrollContainer = '';
  scrollFromRoot = false;

  authUser: Auth;
  client: Client;
  

  term = '';
  searchTimeoutFunction = null;

  @ViewChild('itemFolder', { static: true }) itemFolder: ElementRef;
  @ViewChild('newFolder', { static: true }) newFolder: ElementRef;
  @ViewChild('displayUpload', { static: true }) displayUpload: ElementRef;
  @ViewChild('moveFileModal', { static: true }) moveFileModal: ElementRef;
  @Input() page: string;
  @Input() libraryType: string;

  constructor(
    private StorageRevampService: StorageRevampService,
    public authService: AuthService,
    private imageService: ImageLibraryService,
    private ref: ChangeDetectorRef,
    private thirdPartyService: ThirdPartyService,
    private mScrollbarService: MalihuScrollbarService,
    @Inject(APP_CONFIG) public appConfig
  ) {
    this.stockSearchText = new FormControl('real estate');
  }

  
  ngOnInit() {
    if (this.page === 'admin') {
      this.is_admin = true;
      this.scrollContainer = '.image-library-content-wrapper';
      this.scrollFromRoot = true;
    }

    if (this.imageService.is_builder) {
      this.is_builder = true;
      this.authUser = this.authService.auth;
      this.client = this.authUser.client;
      this.scrollContainer = '.image-library-content-wrapper';
      this.scrollFromRoot = true;
    }

    if (!this.imageService.is_builder && !this.imageService.is_admin) {
      this.authUser = this.authService.auth;
      this.client = this.authUser.client;
      this.folders = [];
      this.getFoldersandImages('user', '');
    }

    this.imageService.onBuilderOpen
    .takeUntil(this.$destroy)
    .subscribe(
      (open: boolean) => {
        if (open) {
          this.openFolder('user', '');
        }
      }
    );

    this.imageService.adminChanged
    .takeUntil(this.$destroy)
    .subscribe(
      (client: any) => {
        if (typeof client !== 'undefined') {
          this.is_admin = true;
          this.client = client.ID;
          this.root = true;
          this.openFolder('user', '');
        }
      }
    );

    this.StorageRevampService.onGetFolders
    .takeUntil(this.$destroy)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined') {
          this.loading = false;
          if (!this.isFileMove) {
            this.folders = [...this.folders, ...response.data];
          }
          this.moveFileFolders = [...this.moveFileFolders, ...response.data];

          this.foldersLastPage = response.last_page;
          this.getAdditionalFolders();

          this.ref.detectChanges();
        }
      }
    );

    this.StorageRevampService.onGetImages
    .takeUntil(this.$destroy)
    .subscribe(
      (response: any) => {
        const $this = this;
        this.loading = false;
        if (typeof response.data !== 'undefined') {

          if (this.imagesLoadMore) {
            this.images = [...this.images, ...response.data];
          } else {
            this.images = response.data;
          }

          this.imagesLastPage = response.last_page;
          this.ref.detectChanges();
        }
      }
    );

    this.StorageRevampService.storageOperation
    .takeUntil(this.$destroy)
    .subscribe(
      (response: any) => {
        this.uploadedFiles++;
        let paramsFolder = {};
        let paramsImage = {};

        paramsFolder = { type: this.folderType, library_type: this.libraryType };
        paramsImage = { type: this.folderType, library_type: this.libraryType };

        if (this.folderId) {
          paramsFolder = { type: this.folderType, library_type: this.libraryType, parent_files_folder_id: this.folderId };
          paramsImage = { type: this.folderType, library_type: this.libraryType, files_folder_id: this.folderId };
        }

        if ( typeof response !== 'undefined' && response.status === 'success' ) {
          if (!this.filesUploadComplete) {
            this.filesUploadComplete = true;
          }

          if (this.isFileMove) {
            this.StorageRevampService.httpGetImages(paramsImage);
            this.onCancelMoveFile();
          } else {
            this.clearFolders();
            this.StorageRevampService.httpGetFolders(paramsFolder);
          }

          if (typeof response.fileIndex !== 'undefined') {
            this.files[response.fileIndex].completed = true;
            this.files[response.fileIndex].error = '';
          }
        } else {
          if (typeof response.fileIndex !== 'undefined') {
            this.files[response.fileIndex].completed = false;
            this.files[response.fileIndex].error = response;
          }

          if (this.isFileMove) {
            let errorMessage = 'Internal server error!';
            if (response.message) {
              errorMessage = response.message;
            }
            swal(
              'Error',
              errorMessage,
              'error'
            ).catch(swal.noop);
            this.loading = false;
            this.onCancelMoveFile();
          }

          if (this.createFolder) {
            let errorMessage = 'Internal server error!';
            if (response.message) {
              errorMessage = response.message;
            }
            swal(
              'Error',
              errorMessage,
              'error'
            ).catch(swal.noop);

            this.loading = false;
            this.createFolder = false;
            this.clearFolders();
            this.StorageRevampService.httpGetFolders(paramsFolder);
          }
        }

        if (this.files.length === this.uploadedFiles && this.filesUploadComplete) {
          this.StorageRevampService.httpGetImages(paramsImage);
        }

        if (this.fileRemoved !== 'undefined' && this.fileRemoved !== undefined) {
          if ( typeof response !== 'undefined' && response.status === 'success' ) {
            swal(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            ).catch(swal.noop);

            this.images.splice(this.fileRemoved, 1);
            this.fileRemoved = undefined;
          } else {
            let errorMessage = 'Internal server error!';
            if (response.message) {
              errorMessage = response.message;
            }
            swal(
              'Error',
              errorMessage,
              'error'
            ).catch(swal.noop);
            this.loading = false;
          }
        }
      }
    );

    this.stockSearchText.valueChanges
    .takeUntil(this.$destroy)
    .debounceTime(500)
    .subscribe(searchText => {
      if (typeof searchText !== 'undefined') {
        this.unsplashImages = [];
        this.unsplashCurrentPage = 1;

        this.thirdPartyService.httpUnsplashSearch({
          page: this.unsplashCurrentPage,
          query: searchText,
          perPage: 50
        });

        this.loading = true;
      }
    });

    this.thirdPartyService.onUnsplashSearch
    .takeUntil(this.$destroy)
    .subscribe(
      (response: any) => {
        this.loading = false;
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.unsplashImages = [ ...this.unsplashImages, ...response.data];
        }
        if (typeof response !== 'undefined' && response.status === 'error') {
          this.unsplashImages = [];
        }
        this.ref.detectChanges();
      }
    );
  }

  openFolder(type, folder: any) {
    this.folderType = type;
    this.isStockPage = false;
    this.unsplashImages = [];
    this.selectedImage = {};
    this.selectedImageType = '';
    this.root = type !== 'user' || folder && Object.keys(folder).length !== 0 ? false : true;
    if (type) {
      this.getFoldersandImages(type, folder);
    }
  }

  openMoveFileFolder(type, folder: any) {
    this.moveFileFolderType = type;
    this.isStockPage = false;
    this.unsplashImages = [];
    this.selectedImage = {};
    this.selectedImageType = '';
    this.moveFileFolderRoot = type !== 'user' || folder && Object.keys(folder).length !== 0 ? false : true;
    if (type) {
      this.getMoveFileFolders(type, folder);
    }
  }

  getMoveFileFolders(type, folder: any) {
    let paramsFolder = {};
    this.loading = true;
    this.ref.detectChanges();
    this.foldersCurrentPage = 1;
    this.moveFileFolderId = '';
    this.moveFileFolders = [];
    this.foldersCurrentPage = 1;

    paramsFolder = { type: type };

    if (folder && Object.keys(folder).length !== 0) {
      paramsFolder = { type: type, parent_files_folder_id: folder.uuid };

      this.moveFileFolderId = folder.uuid;

      const index = this.folderBreadcrumbs.findIndex(x => x.folderId === folder.uuid);
      if (index === -1) {
        this.folderBreadcrumbs.push({name: folder.name, folder: folder, folderId: folder.uuid, type: type});
      } else {
        const filteredBreadcrumbs = this.folderBreadcrumbs.filter(function(value, i, arr){
            return i <= index;
        });
        this.folderBreadcrumbs = filteredBreadcrumbs;
      }
    } else {
      this.folderBreadcrumbs = [{name: `my ${this.libraryType}s`, folder: {}, folderId: 0, type: type}];
      switch (type) {
        case 'group':
          this.folderBreadcrumbs.push({name: `group ${this.libraryType}s`, folder: {}, folderId: 1, type: type});
          break;
        case 'client':
          this.folderBreadcrumbs.push({name: `office ${this.libraryType}s`, folder: {}, folderId: 2, type: type});
          break;
        case 'admin':
          this.folderBreadcrumbs.push({name: `admin ${this.libraryType}s`, folder: {}, folderId: 3, type: type});
          break;
        case 'user':
          break;
        default:
          break;
      }
    }

    this.StorageRevampService.httpGetFolders(paramsFolder);
  }

  getFoldersandImages(type, folder: any) {
    let paramsFolder = {};
    let paramsImage = {};
    this.loading = true;
    this.ref.detectChanges();
    this.images = [];
    this.imagesCurrentPage = 1;
    this.folderId = '';
    this.folders = [];
    this.foldersCurrentPage = 1;

    paramsFolder = { type: type, library_type: this.libraryType };
    paramsImage = { type: type, library_type: this.libraryType };

    if (folder && Object.keys(folder).length !== 0) {
      paramsFolder = { type: type, library_type: this.libraryType, parent_files_folder_id: folder.uuid };
      paramsImage = { type: type, library_type: this.libraryType, files_folder_id: folder.uuid };

      this.folderId = folder.uuid;

      const index = this.breadcrumbs.findIndex(x => x.folderId === folder.uuid);
      if (index === -1) {
        this.breadcrumbs.push({name: folder.name, folder: folder, folderId: folder.uuid, type: type});
      } else {
        const filteredBreadcrumbs = this.breadcrumbs.filter(function(value, i, arr){
            return i <= index;
        });
        this.breadcrumbs = filteredBreadcrumbs;
      }
    } else {
      this.breadcrumbs = [{name: `my ${this.libraryType}s`, folder: {}, folderId: 0, type: type}];
      switch (type) {
        case 'group':
          this.breadcrumbs.push({name: `group ${this.libraryType}s`, folder: {}, folderId: 1, type: type});
          break;
        case 'client':
          this.breadcrumbs.push({name: `office ${this.libraryType}s`, folder: {}, folderId: 2, type: type});
          break;
        case 'admin':
          this.breadcrumbs.push({name: `admin ${this.libraryType}s`, folder: {}, folderId: 3, type: type});
          break;
        case 'stock':
          this.breadcrumbs.push({name: 'stock images', folder: {}, folderId: 4, type: type});
          this.isStockPage = true;
          this.loading = false;
          this.folders = [];

          this.unsplashCurrentPage = 1;
          this.unsplashImages = [];

          this.thirdPartyService.httpUnsplashSearch({
            page: this.unsplashCurrentPage,
            query: this.stockSearchText.value,
            perPage: 50
          });
          this.loading = true;
          break;
        case 'user':
          break;
        default:
          break;
      }
    }

    if (type !== 'stock') {
      this.StorageRevampService.httpGetFolders(paramsFolder);
      this.StorageRevampService.httpGetImages(paramsImage);
    }
  }

  addFolder() {
    this.renameFolderId = '';
    $(this.newFolder.nativeElement).modal('show');
    this.itemFolder.nativeElement.value = '';
  }

  getAdditionalFolders() {
    if (this.foldersCurrentPage < this.foldersLastPage) {
      this.foldersCurrentPage += 1;

      let paramsFolder = {};
      const type = this.isFileMove ? this.moveFileFolderType : this.folderType;

      paramsFolder = { type: type, page: this.foldersCurrentPage };
      if (this.folderId) {
        paramsFolder = { type: type, files_folder_id: this.folderId, page: this.foldersCurrentPage };
      }

      this.StorageRevampService.httpGetFolders(paramsFolder);
      this.loading = true;
    }
  }

  renameFolder(folder: any) {
    this.renameFolderId = folder.uuid;
    $(this.newFolder.nativeElement).modal('show');
    this.itemFolder.nativeElement.value = '';
    this.itemFolder.nativeElement.value = folder.name;
  }

  closeModal(type) {
    switch (type) {
      case 'folder':
        $(this.newFolder.nativeElement).modal('hide');
        break;
      case 'image':
        $(this.displayUpload.nativeElement).modal('hide');
        break;
      default:
        break;
    }
  }

  onRemoveFolder(folder: any) {
    const $this = this;
    const folderId = folder.uuid;
    swal({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then(function () {
      swal(
        'Deleted!',
        `folder '${folder.name}' has been deleted.`,
        'success'
      );
      $this.clearFolders();
      $this.StorageRevampService.httpDeleteFolder(folderId);
    }).catch(swal.noop);
  }

  onCreateFolder() {
    const values = [];
    this.createFolder = true;
    if (this.itemFolder.nativeElement.value.trim() === '') {
      swal(
        'Error',
        'Invalid Folder Name',
        'error'
      ).catch(swal.noop);
    }

    if (this.itemFolder.nativeElement.value.trim() !== '') {
      this.loading = true;

      const _folder_name = this.itemFolder.nativeElement.value;

      values['name'] = _folder_name.split(' ').join('-');
      values['type'] = this.folderType;
      values['parent_files_folder_id'] = this.folderId;
      values['library_type'] = this.libraryType;

      this.clearFolders();

      if (this.renameFolderId) {
        this.StorageRevampService.httpPutFolder(this.renameFolderId, values);
      } else {
        this.StorageRevampService.httpPostFolder(values);
      }

      setTimeout(
        () => {
          $('#newFolder').modal('hide');
          this.itemFolder.nativeElement.value = '';
        }, 200
      );
    }
  }

  clearFolders() {
    this.folders = [];
    this.foldersCurrentPage = 1;
    this.foldersLastPage = 1;
  }

  addFile(type = '') {
    this.files = [];
    this.uploadedFiles = 0;
    this.filesUploadComplete = false;
    $(this.displayUpload.nativeElement).modal('show');
  }

  fileBrowseHandler(event) {
    const files = event.target.files;
    this.uploadFilesList(files);
    event.target.value = '';
  }

  onFileDropped($event) {
    this.uploadFilesList($event);
  }

  onOverwriteFile(index: number) {
    const file = this.files[index];
          file.completed = false;
          file.error = '';

    const values = [];
    values['file_index'] = index;
    values['file'] = file;
    values['type'] = this.folderType;
    values['files_folder_id'] = this.folderId;
    values['library_type'] = this.libraryType;
    values['overwrite'] = 1;

    this.StorageRevampService.httpPostImage(values);
  }

  uploadFilesList(files: Array<any>) {
    this.imagesLoadMore = false;
    this.imagesCurrentPage = 1;
    let i = this.files.length;
    for (const item of files) {
      if (this.checkSupportedFileType(item.type)) {
        item.completed = false;
        item.error = '';

        const values = [];
        values['file_index'] = i;
        values['file'] = item;
        values['type'] = this.folderType;
        values['library_type'] = this.libraryType;
        values['files_folder_id'] = this.folderId;

        this.StorageRevampService.httpPostImage(values);
      } else {
        item.error = {status: 'FILE_TYPE_NOT_VALID', message: 'File type is not valid. please upload an image file only.'};
      }
      this.files.push(item);
      i++;
    }
  }

  showFile(item) {
    if (this.checkImageFileType(item.mimetype)) {
      const text = `<strong>File name:</strong> ${item.name }`;
      swal({
        html: text,
        imageUrl: this.getLink(item),
        customClass: 'showImage',
        imageWidth: 100,
        imageHeight: 100,
        imageAlt: item.name,
        confirmButtonText: '<i class="icon feather icon-x"></i>',
        confirmButtonColor: '#ededed',
        animation: true
      }).catch(swal.noop);
    } else {
      window.open(this.getLink(item), '_blank').focus();
    }
  }

  getFileExtension(item) {
    return item.split('.').pop();
  }

  downloadImage(item) {
    const imageLink = this.getLink(item);
    const link = document.createElement('a');
    link.href = imageLink;
    link.download = item.name;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  copyLink(item) {
    copy(this.getLink(item));
  }

  checkFileOwner(item) {
    const path = String(item.path).split('/');
    // tslint:disable-next-line: radix
    var id = parseInt(path[2]);
    if(item.path.includes(`resources/${this.libraryType}`)) {
      id = parseInt(path[3]);
    }
    switch (this.folderType) {
      case 'group':
        if (this.authUser.client.group.ID !== id) {
          return false;
        }
        break;
      case 'admin':
      case 'client':
        if (this.authUser.client.ID !== id) {
          return false;
        }
        break;
      default:
        if (this.authUser.user.ID !== id) {
          return false;
        }
        break;
    }

    return true;
  }

  onDelete(index: number, item) {
    const $this = this;
    const fileId = item.uuid;
    const path = String(item.path).split('/');

    if ( !this.authUser.is_master && !this.checkFileOwner(item)) {
      swal(
        'Error deleting',
        'You don\'t have enough permission to delete this ' + item.mimetype,
        'error'
      ).catch(swal.noop);
      return false;
    }

    swal({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(function () {
        $this.loading = true;
        $this.fileRemoved = index;
        $this.StorageRevampService.httpDeleteImage(fileId, { type: $this.folderType });
      }).catch(swal.noop);
  }

  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  onDeleteFile(index: number) {
    const file = this.files[index];
    if (file.error) {
      this.files.splice(index, 1);
    } else {
      this.files.splice(index, 1);
    }
  }

  viewUnsplash(link: string) {
    window.open(link, '_blank');
  }

  onGoBack() {
    const last = Array.from(
      document.querySelectorAll('.main-breadcrumb .breadcrumb-item:not(.active)')
    ).pop();
    last.querySelector('a').click();
  }

  onGoBackMoveFileFolder() {
    const last = Array.from(
      document.querySelectorAll('#moveFile .breadcrumb-item:not(.active)')
    ).pop();
    if (last) {
      last.querySelector('a').click();
    }
  }

  checkFileExist(error) {
    if (error && error.status === 'FILE_EXISTS') {
      return true;
    }
    return false;
  }

  checkImageFileType(type) {
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'image/svg', 'image/svg+xml'];
    if (validImageTypes.includes(type)) {
      return true;
    }
    return false;
  }

  checkSupportedFileType(type) {
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'image/svg', 'image/svg+xml'];
    // tslint:disable-next-line: max-line-length
    if (validImageTypes.includes(type) || this.is_admin || this.folderType === 'admin' || this.libraryType === 'file') {
      return true;
    }
    return false;
  }

  getErrorMessage(error) {
    let errorMessage = '';
    switch (error.status) {
      case 'FILE_EXISTS':
        errorMessage = 'File already exists.';
      break;
      default:
        errorMessage = error.message;
        break;
    }
    return errorMessage;
  }

  getLink(item: any, isThumbnail = false) {

    const path = (isThumbnail && item.thumbnail) ? item.thumbnail :  item.path;

    if (this.checkImageFileType(item.mimetype)) {
      const itemPath = path.replace('resources/', '');
      return this.appConfig.CACHED_S3_BUCKET_URL + itemPath;
    }

    return this.appConfig.NEW_S3_URL + path;
  }

  onScroll() {
    this.unsplashCurrentPage += 1;

    this.thirdPartyService.httpUnsplashSearch({
      page: this.unsplashCurrentPage,
      query: this.stockSearchText.value,
      perPage: 50
    });

    this.loading = true;
  }

  onScrollImage() {
    this.imagesLoadMore = true;
    if (this.imagesCurrentPage < this.imagesLastPage) {
      this.imagesCurrentPage += 1;

      let paramsImage = {};

      paramsImage = { type: this.folderType, library_type: this.libraryType, page: this.imagesCurrentPage, search_term: this.term };
      if (this.folderId) {
        // tslint:disable-next-line: max-line-length
        paramsImage = { type: this.folderType, library_type: this.libraryType, files_folder_id: this.folderId, page: this.imagesCurrentPage, search_term: this.term };
      }

      this.StorageRevampService.httpGetImages(paramsImage);
      this.loading = true;
    }
  }

  onImageClick(event, image, type) {
    if (this.is_builder === true) {
      if ($(event.target).hasClass('card-image')) {
        $('.image-list .card').removeClass('is-selected');
        $(event.target).parents('.card').addClass('is-selected');

        this.selectedImage = image;
        this.selectedImageType = type;
      }
    }
  }

  onInsert(image) {
    const $this = this;

    const size = Number((image.size / 1000000).toFixed(2));

    if ( size >= 1 ) {
      swal({
        title: 'Warning',
        // tslint:disable-next-line: max-line-length
        text: 'Please be aware you are trying to insert a large image and can cause problems when inserting into emails or result in large PDF\'s',
        type: 'warning',
      }).then(function () {
        $this.imageService.onInsert(image);
      });
    } else {
      $this.imageService.onInsert(image);
    }
  }

  onUnsplashInsert(links: any) {
    this.imageService.insertUnsplash(links);
  }

  onMoveFile(image) {
    this.isFileMove = true;
    this.moveFileFolderRoot = true;
    this.moveFile = image;
    this.openMoveFileFolder('user', '');
    $(this.moveFileModal.nativeElement).modal('show');
  }

  onCancelMoveFile() {
    this.isFileMove = false;
    this.moveFileFolderType = 'user';
    this.moveFile = {};
    this.moveFileFolderId = '';
    $(this.moveFileModal.nativeElement).modal('hide');
  }

  onMoveFileSubmit() {
    if (this.moveFile) {
      this.loading = true;
      const data = {
        folder_id: this.moveFileFolderId,
        type: this.moveFileFolderType
      };
      this.StorageRevampService.httpMoveImage(this.moveFile.uuid, data);
    }
  }

  onSearch(data) {
    this.term = data;
    
    if(this.searchTimeoutFunction) {
      clearInterval(this.searchTimeoutFunction);
    }

    this.searchTimeoutFunction = setTimeout(() => {      
      this.imagesCurrentPage = 1;
      this.images = [];
      
      let paramsImage = {};

      paramsImage = { type: this.folderType, library_type: this.libraryType, page: this.imagesCurrentPage, search_term: this.term };
      if (this.folderId) {
        // tslint:disable-next-line: max-line-length
        paramsImage = { type: this.folderType, library_type: this.libraryType, files_folder_id: this.folderId, page: this.imagesCurrentPage, search_term: this.term };
      }
      
      this.loading = true;
      this.StorageRevampService.httpGetImages(paramsImage);
    }, 1000);
  }

  ngAfterViewInit() {
    const $this = this;
    this.displayUpload.nativeElement.addEventListener('shown.bs.modal', function () {
       $this.mScrollbarService.initScrollbar('.filesContents', { axis: 'y', theme: 'minimal-dark', scrollInertia: 600 });
    });

    this.displayUpload.nativeElement.addEventListener('hidden.bs.modal', function () {
      $this.mScrollbarService.destroy('.filesContents');
    });

    this.moveFileModal.nativeElement.addEventListener('shown.bs.modal', function () {
      $this.mScrollbarService.initScrollbar('#moveFile .folderList', { axis: 'y', theme: 'minimal-dark', scrollInertia: 600 });
   });

    this.moveFileModal.nativeElement.addEventListener('hidden.bs.modal', function () {
      $this.isFileMove = false;
      $this.moveFile = {};
      $this.mScrollbarService.destroy('#moveFile .folderList');
   });
  }

  ngOnDestroy() {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
    this.mScrollbarService.destroy('.filesContents');
  }

  isAdmin(user) {
    return RoleHelper.isAdmin(user);
  }

  isGroupAdmin(user) {
    return RoleHelper.isGroupAdmin(user);
  }
  isLoginAs(user) {
    return RoleHelper.isLoginAs(user);
  }

  /* Always show upload button for users. Additional checks are for users who are group_admin or admin */
  showButton() {
      let show = false;
      switch (this.folderType) {
          case 'group':
              show = this.isGroupAdmin(this.authUser);
              break;
          case 'client':
              show = this.isAdmin(this.authUser) || this.isGroupAdmin(this.authUser);
              break;
          case 'admin':
              show = this.isLoginAs(this.authUser);
              break;
          case 'user':
              show = true;
              break;
      }
      return show;
  }
}
