<div class="height-100">
  <div #sidebarBox class="height-100">
    <div id="sidebar-box" class="height-100">
      <div class="header unfolded">
        <a routerLink="/templates" (click)="setTemplatePage()">
          <ng-container *ngIf="authUser.brandid !== '6' && !frontService.authService.isUk() && frontService.appConfig.SKIN === 'designly' && (authUser?.client?.group?.enable_pro_ui || authUser?.client?.enable_pro_ui || authUser?.is_master)">
            <span *ngIf="!isPro; else smallSidebarBanner" class="badge badge-light">lite</span>
            <ng-template #smallSidebarBanner>
              <span class="badge badge-pro">pro</span>
            </ng-template>
          </ng-container>
          <img src="/assets/img/{{ selectedLogo }}" class="logo" [class.idashboard-logo]="frontService.appConfig.SKIN === 'idashboard'">
        </a>
      </div>
      <div class="header folded">
        <a routerLink="/templates" (click)="setTemplatePage()">
          <ng-container *ngIf="authUser.brandid !== '6' && !frontService.authService.isUk() && frontService.appConfig.SKIN === 'designly' && (authUser?.client?.group?.enable_pro_ui || authUser?.client?.enable_pro_ui || authUser?.is_master)">
            <span *ngIf="!isPro; else sidebarBanner" class="badge badge-dark">lite</span>
            <ng-template #sidebarBanner>
              <span class="badge badge-pro">pro</span>
            </ng-template>
          </ng-container>
          <img *ngIf="authUser.brandid !== '6' && (frontService.appConfig.SKIN === 'designly' || frontService.appConfig.SKIN === 'idashboard')" src="/assets/img/designly-logo.svg" class="logo">
          <img *ngIf="frontService.appConfig.SKIN === 'signifi'" src="/assets/img/signify_design_logo.svg" class="logo signifi-logo">
          <img *ngIf="authUser.brandid === '6'" src="/assets/img/rh_designplus_icon.svg" class="logo">
          <img *ngIf="frontService.appConfig.SKIN === 'raywhite'" src="/assets/img/rw-logo.svg" class="logo">
          <img *ngIf="frontService.appConfig.SKIN === 'prd'" src="/assets/img/PRD.REALESTATE_Logo_White_SVG.svg" class="logo prd-logo">
          <img *ngIf="frontService.appConfig.SKIN === 'professionals'" src="/assets/img/professionals_logo_folded.svg" class="logo professionals-logo">
        </a>
      </div>
      <div class="heading">
        <img class="user-photo" [src]="user.photo" onerror="this.src='assets/img/agent-default.jpg'">
        <div class="user-name">{{ user.name }}</div>
        <div class="user-client">{{ authUser.office }}<ng-container *ngIf="isMultipleOffice">
            <br><a href="#" data-bs-target="#switchOffice" data-bs-toggle="modal">Switch Office <i
                class="icon feather icon-repeat"></i></a>
          </ng-container>
          <ng-container *ngIf="isDomainMultipleOffice">
            <br><a href="#" (click)="getAgencies()" data-bs-target="#domainMultiOffice" data-bs-toggle="modal">Switch Office
              <i class="icon feather icon-repeat"></i></a>
          </ng-container>
        </div>
      </div>
      <div class="menu">
        <ng-container *ngIf="authUser.provider === 'mydesktop'">
          <ul>
            <li class="intro-side-template">
              <a routerLinkActive="active" routerLink="/templates" (click)="setTemplatePage()">
                <i class="icon feather icon-file"></i><span class="hidden-sm hidden-xs">Templates</span>
              </a>
            </li>
            <li class="intro-side-template">
              <a routerLinkActive="active" routerLink="/templates/folders">
                <i class="icon feather icon-folder"></i><span class="hidden-sm hidden-xs">Folders</span>
              </a>
            </li>
            <!-- <li class="intro-side-shared">
              <a routerLinkActive="active"
                routerLink="{{ authUser.is_admin ? '/shared-templates/groups' : '/shared-templates/office' }}">
                <i class="icon feather icon-share-2"></i><span class="hidden-sm hidden-xs">Shared Templates</span>
              </a>
            </li>
            <li class="intro-side-archive">
              <a routerLinkActive="active" routerLink="/archive-templates">
                <i class="icon feather icon-folder"></i><span class="hidden-sm hidden-xs">Archive Templates</span>
              </a>
            </li> -->
            <li class="intro-side-analytics">
              <a routerLinkActive="active" routerLink="/analytics">
                <i class="icon feather icon-activity"></i><span class="hidden-sm hidden-xs">Analytics</span>
              </a>
            </li>
            <li class="intro-side-marketplace">
              <a routerLinkActive="active" routerLink="/content/marketplace">
                <i class="icon feather icon-file-text"></i><span class="hidden-sm hidden-xs">The Real Estate Voice</span>
              </a>
            </li>
          </ul>
          <ul class="help-settings">
            <li class="intro-side-setting">
              <a routerLinkActive="active" routerLink="/settings{{ defaultRoute }}">
                <i class="icon feather icon-settings"></i><span class="hidden-sm hidden-xs">Settings</span>
              </a>
            </li>
          </ul>
          <div class="help-panel margin-top-30 intro-side-support">
            <h4 class="darkblue-color margin-bottom-5">Need help?</h4>
            <p class="darkblue-color margin-bottom-20">Visit the help portal for videos, faq and more</p>
            <a routerLink="/help" class="btn btn-primary btn-1">
              <i class="icon feather icon-help-circle"></i><span class="hidden-sm hidden-xs">Support Portal</span>
            </a>
          </div>
        </ng-container>
        <ng-container *ngIf="authUser.provider !== 'mydesktop'">
          <ul>
                  
            <li>
              <a routerLinkActive="active" routerLink="/mydesigns">
                <i class="icon feather icon-file"></i><span class="hidden-sm hidden-xs">My Designs</span>
              </a>
            </li>
            <li class="intro-side-template">
              <a routerLinkActive="active" routerLink="/templates" [routerLinkActiveOptions]="{exact: true}" (click)="setTemplatePage()">
                <i class="icon feather icon-file"></i><span class="hidden-sm hidden-xs">Templates</span>
              </a>
            </li>  
            <!-- <li *ngIf="isUserPro">
              <a routerLinkActive="active" routerLink="/myprodesigns">
                <i class="icon feather icon-file"></i><span class="hidden-sm hidden-xs">My Pro Designs</span>
              </a>
            </li> -->
            <!--<li class="intro-side-template">
              <a routerLinkActive="active" routerLink="/templates/folders">
                <i class="icon feather icon-folder"></i><span class="hidden-sm hidden-xs">Folders</span>
              </a>
            </li>-->
            <li *ngIf="frontService.appConfig.SKIN !== 'signifi' && authUser?.provider !== 'idashboard' && authUser?.brandid !== '6' && authUser?.brandid !== '4' && authUser.provider !== 'designly'" class="intro-side-template">
              <a routerLinkActive="active" routerLink="/campaigns">
                <i class="icon feather icon-layers"></i><span class="hidden-sm hidden-xs">Campaigns</span>
              </a>
            </li>

            <li *ngIf="this.frontService.authService.getApp() === 'MyDesign3'" class="intro-side-analytics">
              <a routerLinkActive="active" routerLink="/analytics">
                <i class="icon feather icon-activity"></i><span class="hidden-sm hidden-xs">Analytics</span>
              </a>
            </li>
            <li *ngIf="authUser?.provider !== 'idashboard'" class="intro-side-marketplace">
              <a routerLinkActive="active" routerLink="/content/marketplace">
                <i class="icon feather icon-file-text"></i><span class="hidden-sm hidden-xs">The Real Estate Voice</span>
              </a>
            </li>
            <ng-container *ngIf="!frontService.authService.isUk() && frontService.appConfig.SKIN !== 'signifi' && (authUser?.client?.group?.enable_pro_ui || authUser?.client?.enable_pro_ui || authUser?.is_master)">
              <ng-container *ngIf="!authUser?.client?.group?.hide_webbooks || authUser?.is_master">
                <li class="intro-side-webbooks">
                  <a routerLinkActive="active" routerLink="/webbooks">
                    <i class="icon feather icon-book-open"></i><span *ngIf="frontService.appConfig.SKIN !== 'raywhite'" class="hidden-sm hidden-xs">Webbooks</span>  
                    <span *ngIf="frontService.appConfig.SKIN === 'raywhite'" class="hidden-sm hidden-xs">Pre-List Kit</span>  
                    <em *ngIf="!isPro" class="badge-pro ms-1">
                      <svg id="Group_2511" data-name="Group 2511" xmlns="http://www.w3.org/2000/svg" width="34.41" height="13.39" viewBox="0 0 34.41 13.39">
                        <rect id="Rectangle_1208" data-name="Rectangle 1208" width="34.41" height="13.39" rx="1.905" fill="#38a9e0"/>
                        <g id="Group_2506" data-name="Group 2506" transform="translate(14.735 3.233)">
                          <path id="Path_1025" data-name="Path 1025" d="M460.719,209.082v-6.5h2.164a2.523,2.523,0,0,1,1.73.567,1.983,1.983,0,0,1,0,2.946,2.562,2.562,0,0,1-1.73.554h-1.038v2.433Zm1.126-3.409h1.038a1.377,1.377,0,0,0,.906-.278.95.95,0,0,0,.333-.769.981.981,0,0,0-.332-.783,1.342,1.342,0,0,0-.907-.285h-1.038Z" transform="translate(-460.719 -202.482)" fill="#fff"/>
                          <path id="Path_1026" data-name="Path 1026" d="M464.2,209.082v-6.5h2.012a2.533,2.533,0,0,1,1.719.559,1.934,1.934,0,0,1,.315,2.531,1.952,1.952,0,0,1-.93.691l1.564,2.719H467.6l-1.458-2.543h-.829v2.543Zm1.117-3.47h.9a1.378,1.378,0,0,0,.9-.269.912.912,0,0,0,.329-.751.934.934,0,0,0-.327-.761,1.359,1.359,0,0,0-.9-.272h-.9Z" transform="translate(-458.868 -202.482)" fill="#fff"/>
                          <path id="Path_1027" data-name="Path 1027" d="M467.518,205.847a3.267,3.267,0,0,1,.935-2.392,3.234,3.234,0,0,1,2.4-.953,3.173,3.173,0,0,1,2.385.953,3.274,3.274,0,0,1,.926,2.392,3.332,3.332,0,0,1-.926,2.413,3.173,3.173,0,0,1-2.385.952,3.234,3.234,0,0,1-2.4-.952A3.324,3.324,0,0,1,467.518,205.847Zm1.089,0a2.435,2.435,0,0,0,.624,1.718,2.285,2.285,0,0,0,3.248,0,2.434,2.434,0,0,0,.618-1.718,2.38,2.38,0,0,0-.618-1.7,2.3,2.3,0,0,0-3.248,0A2.381,2.381,0,0,0,468.607,205.847Z" transform="translate(-457.055 -202.502)" fill="#fff"/>
                        </g>
                        <g id="Group_2510" data-name="Group 2510" transform="translate(3.643 2.273)">
                          <rect id="Rectangle_1209" data-name="Rectangle 1209" width="6.999" height="1" rx="0.5" transform="translate(0.274 7.38)" fill="#fac656"/>
                          <path id="Path_1028" data-name="Path 1028" d="M18.525,20.317A.285.285,0,0,1,18.1,20.2l-.946-2.029L16.6,17l-.547,1.174L15.111,20.2a.285.285,0,0,1-.429.112l-1.561-1.149a.289.289,0,0,0-.454.28l.619,4.345a.29.29,0,0,0,.285.251h6.065a.29.29,0,0,0,.285-.251l.619-4.345a.289.289,0,0,0-.454-.28Z" transform="translate(-12.664 -17.002)" fill="#fac656"/>
                        </g>
                      </svg>                  
                    </em>
                  </a>
                </li>
              </ng-container>
              <ng-container *ngIf="(authUser?.user.enable_proposals === 1) ||  (!authUser?.client?.group?.hide_proposals) || (authUser?.is_master)">
                <li class="intro-side-proposals">
                  <a routerLinkActive="active" routerLink="/proposals">
                    <i class="icon feather icon-edit-1"></i><span *ngIf="frontService.appConfig.SKIN !== 'raywhite'" class="hidden-sm hidden-xs">Proposals</span>
                    <span *ngIf="frontService.appConfig.SKIN === 'raywhite'" class="hidden-sm hidden-xs">Listing Presentation</span>
                    <em *ngIf="!isPro" class="badge-pro ms-1">
                      <svg id="Group_2511" data-name="Group 2511" xmlns="http://www.w3.org/2000/svg" width="35.41" height="13.39" viewBox="0 0 35.41 13.39">
                        <rect id="Rectangle_1208" data-name="Rectangle 1208" width="35.41" height="13.39" rx="1.905" fill="#38a9e0"/>
                        <g id="Group_2506" data-name="Group 2506" transform="translate(14.735 3.233)">
                          <path id="Path_1025" data-name="Path 1025" d="M460.719,209.082v-6.5h2.164a2.523,2.523,0,0,1,1.73.567,1.983,1.983,0,0,1,0,2.946,2.562,2.562,0,0,1-1.73.554h-1.038v2.433Zm1.126-3.409h1.038a1.377,1.377,0,0,0,.906-.278.95.95,0,0,0,.333-.769.981.981,0,0,0-.332-.783,1.342,1.342,0,0,0-.907-.285h-1.038Z" transform="translate(-460.719 -202.482)" fill="#fff"/>
                          <path id="Path_1026" data-name="Path 1026" d="M464.2,209.082v-6.5h2.012a2.533,2.533,0,0,1,1.719.559,1.934,1.934,0,0,1,.315,2.531,1.952,1.952,0,0,1-.93.691l1.564,2.719H467.6l-1.458-2.543h-.829v2.543Zm1.117-3.47h.9a1.378,1.378,0,0,0,.9-.269.912.912,0,0,0,.329-.751.934.934,0,0,0-.327-.761,1.359,1.359,0,0,0-.9-.272h-.9Z" transform="translate(-458.868 -202.482)" fill="#fff"/>
                          <path id="Path_1027" data-name="Path 1027" d="M467.518,205.847a3.267,3.267,0,0,1,.935-2.392,3.234,3.234,0,0,1,2.4-.953,3.173,3.173,0,0,1,2.385.953,3.274,3.274,0,0,1,.926,2.392,3.332,3.332,0,0,1-.926,2.413,3.173,3.173,0,0,1-2.385.952,3.234,3.234,0,0,1-2.4-.952A3.324,3.324,0,0,1,467.518,205.847Zm1.089,0a2.435,2.435,0,0,0,.624,1.718,2.285,2.285,0,0,0,3.248,0,2.434,2.434,0,0,0,.618-1.718,2.38,2.38,0,0,0-.618-1.7,2.3,2.3,0,0,0-3.248,0A2.381,2.381,0,0,0,468.607,205.847Z" transform="translate(-457.055 -202.502)" fill="#fff"/>
                        </g>
                        <g id="Group_2510" data-name="Group 2510" transform="translate(3.643 2.273)">
                          <rect id="Rectangle_1209" data-name="Rectangle 1209" width="6.999" height="1" rx="0.5" transform="translate(0.274 7.38)" fill="#fac656"/>
                          <path id="Path_1028" data-name="Path 1028" d="M18.525,20.317A.285.285,0,0,1,18.1,20.2l-.946-2.029L16.6,17l-.547,1.174L15.111,20.2a.285.285,0,0,1-.429.112l-1.561-1.149a.289.289,0,0,0-.454.28l.619,4.345a.29.29,0,0,0,.285.251h6.065a.29.29,0,0,0,.285-.251l.619-4.345a.289.289,0,0,0-.454-.28Z" transform="translate(-12.664 -17.002)" fill="#fac656"/>
                        </g>
                      </svg>                      
                    </em>
                  </a>
                </li>
              </ng-container>
              <ng-container *ngIf="!authUser?.client?.group?.hide_owner_reports || authUser?.is_master">
                <li class="intro-side-proposals">
                  <a routerLinkActive="active" routerLink="/owner-reports">
                    <i class="icon feather icon-pie-chart"></i><span class="hidden-sm hidden-xs">Owner Reports</span>
                    <em *ngIf="!isPro" class="badge-pro ms-1">
                      <svg id="Group_2511" data-name="Group 2511" xmlns="http://www.w3.org/2000/svg" width="35.41" height="13.39" viewBox="0 0 35.41 13.39">
                        <rect id="Rectangle_1208" data-name="Rectangle 1208" width="35.41" height="13.39" rx="1.905" fill="#38a9e0"/>
                        <g id="Group_2506" data-name="Group 2506" transform="translate(14.735 3.233)">
                          <path id="Path_1025" data-name="Path 1025" d="M460.719,209.082v-6.5h2.164a2.523,2.523,0,0,1,1.73.567,1.983,1.983,0,0,1,0,2.946,2.562,2.562,0,0,1-1.73.554h-1.038v2.433Zm1.126-3.409h1.038a1.377,1.377,0,0,0,.906-.278.95.95,0,0,0,.333-.769.981.981,0,0,0-.332-.783,1.342,1.342,0,0,0-.907-.285h-1.038Z" transform="translate(-460.719 -202.482)" fill="#fff"/>
                          <path id="Path_1026" data-name="Path 1026" d="M464.2,209.082v-6.5h2.012a2.533,2.533,0,0,1,1.719.559,1.934,1.934,0,0,1,.315,2.531,1.952,1.952,0,0,1-.93.691l1.564,2.719H467.6l-1.458-2.543h-.829v2.543Zm1.117-3.47h.9a1.378,1.378,0,0,0,.9-.269.912.912,0,0,0,.329-.751.934.934,0,0,0-.327-.761,1.359,1.359,0,0,0-.9-.272h-.9Z" transform="translate(-458.868 -202.482)" fill="#fff"/>
                          <path id="Path_1027" data-name="Path 1027" d="M467.518,205.847a3.267,3.267,0,0,1,.935-2.392,3.234,3.234,0,0,1,2.4-.953,3.173,3.173,0,0,1,2.385.953,3.274,3.274,0,0,1,.926,2.392,3.332,3.332,0,0,1-.926,2.413,3.173,3.173,0,0,1-2.385.952,3.234,3.234,0,0,1-2.4-.952A3.324,3.324,0,0,1,467.518,205.847Zm1.089,0a2.435,2.435,0,0,0,.624,1.718,2.285,2.285,0,0,0,3.248,0,2.434,2.434,0,0,0,.618-1.718,2.38,2.38,0,0,0-.618-1.7,2.3,2.3,0,0,0-3.248,0A2.381,2.381,0,0,0,468.607,205.847Z" transform="translate(-457.055 -202.502)" fill="#fff"/>
                        </g>
                        <g id="Group_2510" data-name="Group 2510" transform="translate(3.643 2.273)">
                          <rect id="Rectangle_1209" data-name="Rectangle 1209" width="6.999" height="1" rx="0.5" transform="translate(0.274 7.38)" fill="#fac656"/>
                          <path id="Path_1028" data-name="Path 1028" d="M18.525,20.317A.285.285,0,0,1,18.1,20.2l-.946-2.029L16.6,17l-.547,1.174L15.111,20.2a.285.285,0,0,1-.429.112l-1.561-1.149a.289.289,0,0,0-.454.28l.619,4.345a.29.29,0,0,0,.285.251h6.065a.29.29,0,0,0,.285-.251l.619-4.345a.289.289,0,0,0-.454-.28Z" transform="translate(-12.664 -17.002)" fill="#fac656"/>
                        </g>
                      </svg>                      
                    </em>
                  </a>
                </li>
              </ng-container>
            </ng-container>
          </ul>
          <div class="help-panel margin-top-30"></div>
          <ul class="help-settings">
            <li *ngIf="authUser?.is_master && authUser?.is_group_admin">
              <a routerLinkActive="active" routerLink="/corporate">
                <i class="icon feather icon-cpu"></i><span class="hidden-sm hidden-xs">Corporate Access</span>
              </a>
            </li>
            <li class="intro-side-brand-assets">
              <a routerLinkActive="active" routerLink="/brand-assets">
                <i class="icon feather icon-tag"></i><span class="hidden-sm hidden-xs">Brand Assets</span>
              </a>
            </li>
            <li class="intro-side-setting">
              <a routerLinkActive="active" routerLink="/settings">
                <i class="icon feather icon-settings"></i><span class="hidden-sm hidden-xs">Settings</span>
              </a>
            </li>
            <li class="intro-side-support">
              <a routerLinkActive="active" routerLink="/help">
                <i class="icon feather icon-help-circle"></i><span class="hidden-sm hidden-xs">Help Portal</span>
              </a>
            </li>
            <li class="intro-side-support" *ngIf="frontService.appConfig.SKIN === 'professionals'">
              <a routerLinkActive="active" routerLink="/custom-request">
                <i class="icon feather icon-mail"></i><span class="hidden-sm hidden-xs">Custom Request</span>
              </a>
            </li>
            <li *ngIf="authUser?.provider === 'designly' || authUser?.client?.crm_access_enabled" class="intro-side-power">
              <a href="javascript:void(0)" (click)="onCrmLogin()">
                <i class="icon feather icon-arrow-left"></i><span class="hidden-sm hidden-xs">CRM</span>
              </a>
            </li>
            <li class="intro-side-power">
              <a href="javascript:void(0)" (click)="onLogout()">
                <i class="icon feather icon-power"></i><span class="hidden-sm hidden-xs">Logout</span>
              </a>
            </li>
          </ul>
        </ng-container>
      </div>
      <div *ngIf="(authUser?.client?.group?.enable_pro_ui || authUser?.client?.enable_pro_ui || authUser?.is_master) && !frontService.authService.isUk() && !authUser?.isUserPro && !authUser?.isClientPro && (!authUser?.client?.group?.enable_designly_pro && !authUser?.user.enable_designly_pro)" class="pro-cta">
        <p>LEVEL UP to PRO if you would like to unlock great features!</p>
        <a href="javascript:void(0);" (click)="onLevelUpToPro()" class="btn"><svg xmlns="http://www.w3.org/2000/svg" width="12.786" height="13.707" viewBox="0 0 12.786 13.707">
          <g id="Group_2513" data-name="Group 2513" transform="translate(-13.226 -18.625)">
            <rect id="Rectangle_1209" data-name="Rectangle 1209" width="11.149" height="1.858" rx="0.5" transform="translate(13.736 30.474)" fill="#fac656"/>
            <path id="Path_1028" data-name="Path 1028" d="M22.174,22.284a.467.467,0,0,1-.7-.179l-1.535-3.233L19.057,17l-.887,1.87-1.535,3.233a.467.467,0,0,1-.7.179l-2.533-1.83a.468.468,0,0,0-.737.446l1,6.924a.467.467,0,0,0,.463.4h9.842a.467.467,0,0,0,.463-.4l1-6.924a.468.468,0,0,0-.737-.446Z" transform="translate(0.562 1.623)" fill="#fac656"/>
          </g>
        </svg> Get <span>Designly</span> Pro</a>
      </div>
      <!-- <div class="footer" *ngIf="authUser.provider !== 'designly'">
        <ng-container *ngIf="this.frontService.authService.getApp() === 'MyDesign3'">
          <span>Powered by</span>
          <img src="/assets/img/mydesktop-logo.svg" class="logo">
        </ng-container>
        <ng-container *ngIf="this.frontService.authService.getApp() === 'Designly' && !frontService.authService.isUk()">
          <span>Powered by</span>
          <img *ngIf="authUser.provider === 'vaultre' && frontService.appConfig.SKIN === 'designly'"
            src="/assets/img/partners/vaultrewhite.png" class="logo">
          <img *ngIf="authUser.provider === 'domain' && frontService.appConfig.SKIN === 'designly'"
            src="/assets/img/partners/domain-logo.svg" class="logo">
          <img
            *ngIf="frontService.appConfig.SKIN === 'raywhite' || frontService.appConfig.SKIN === 'signifi' || frontService.appConfig.SKIN === 'prd' || frontService.appConfig.SKIN === 'professionals'"
            src="/assets/img/designly-logo-inverted.svg" class="logo">
        </ng-container>
        <ng-container *ngIf="frontService.authService.isUk()">
          <span>Powered by</span>
          <img *ngIf="authUser.provider === 'vaultre'" src="/assets/img/partners/vaulteawhite.png" class="logo vaultea">
        </ng-container>
        <ng-container *ngIf="frontService.appConfig.SKIN === 'idashboard'">
          <img src="/assets/img/idashboard-white.png" class="logo idashboard">
        </ng-container>
      </div> -->
    </div>
  </div>
  <div #sidebarSubBox class="menu-sub-box" [class.hidden]="menus && menus.length <= 0">
    <div id="sidebar-sub-box" class="height-100">
      <div id="malihusb" class="height-100">
        <ul class="menu-sub-list">
          <li *ngFor="let menu of menus">
            <a [ngClass]="{ 'no-link': menu.subs && menu.url === '' }">{{ menu.name }}</a>
            <ul *ngIf="menu.subs">
              <li *ngFor="let sub of menu.subs">
                <a id="menu-{{ slugLink(sub.url) }}" routerLinkActive="active" [routerLink]="[sub.url]">{{ sub.name }}</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>