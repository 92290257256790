import { Inject, Injectable } from "@angular/core";
import { APP_CONFIG } from "../app.config";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";

@Injectable()
export class TemplateElementSettingService {
    elementChanged = new Subject<any>();
    postElement = new Subject<any>();
    constructor(
        @Inject(APP_CONFIG) private appConfig,
        private httpClient: HttpClient
    ) {}

    storeElementSetting(data: any) {
        return this.httpClient.post(this.appConfig.NEW_API_ENDPOINT + '/settings/template/element/store', data);
    }

    getElementSetting(element_id: number) {
        return this.httpClient.get(this.appConfig.NEW_API_ENDPOINT + `/settings/template/element/get/${element_id}`).subscribe(
            (response: any) => {
                this.elementChanged.next(response);
            }
        );
    }
}