import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminComponent } from './admin.component';
import { ClientsComponent } from './clients/clients.component';
import { TemplatesComponent } from './templates/templates.component';
import { HelpComponent } from './help/help.component';
import { ClientEditComponent } from './clients/client-edit/client-edit.component';
import { ClientDashboardComponent } from './clients/client-dashboard/client-dashboard.component';
import { ElementsComponent } from './templates/elements/elements.component';
import { ElementEditComponent } from './templates/elements/element-edit/element-edit.component';
import { GroupsComponent } from './groups/groups.component';
import { GroupEditComponent } from './groups/group-edit/group-edit.component';
import { GroupDashboardComponent } from './groups/group-dashboard/group-dashboard.component';
import { SettingAdminComponent } from './setting-admin/setting-admin.component';
import { MastersComponent } from './templates/masters/masters.component';
import { MasterEditComponent } from './templates/masters/master-edit/master-edit.component';
import { ElementHtmlComponent } from './templates/elements/element-html/element-html.component';
import { StylesComponent } from './styles/styles.component';
import { StyleDashboardComponent } from './styles/style-dashboard/style-dashboard.component';
import { StyleEditComponent } from './styles/style-edit/style-edit.component';
import { ClientGalleryComponent } from './clients/client-gallery/client-gallery.component';
import { SupportComponent } from './help/support/support.component';
import { TrainingVideosEditComponent } from './help/training-videos/training-videos-edit/training-videos-edit.component';
import { TrainingVideosDashboardComponent } from './help/training-videos/training-videos-dashboard/training-videos-dashboard.component';
import { IconsDashboardComponent } from './icons/icons-dashboard/icons-dashboard.component';
import { IconsEditComponent } from './icons/icons-edit/icons-edit.component';
import { AuthAdminGuard } from '../services/auth-admin-guard.service';
import { AnalyticTemplateUsageComponent } from './analytics/analytic-template-usage/analytic-template-usage.component';

import { ChangelogDashboardComponent } from './help/changelog-dashboard/changelog-dashboard.component';
import { ChangelogEditComponent } from './help/changelog-edit/changelog-edit.component';
import { SubgroupsComponent } from './subgroups/subgroups.component';
import { SubgroupsDashboardComponent } from './subgroups/subgroups-dashboard/subgroups-dashboard.component';
import { SubgroupsEditComponent } from './subgroups/subgroups-edit/subgroups-edit.component';
import { WebsitesComponent } from './websites/websites.component';
import { WebsiteDashboardComponent } from './websites/website-dashboard/website-dashboard.component';
import { WebsiteEditComponent } from './websites/website-edit/website-edit.component';
import { WebsiteLogsComponent } from './websites/website-logs/website-logs.component';
import { ClientUsersComponent } from './clients/client-users/client-users.component';
import { UsersDashboardComponent } from './clients/client-users/users-dashboard/users-dashboard.component';
import { UsersEditComponent } from './clients/client-users/users-edit/users-edit.component';
import { TemplateBlocksComponent } from './templates/template-blocks/template-blocks.component';
import { TemplateBlocksEditComponent } from './templates/template-blocks/template-blocks-edit/template-blocks-edit.component';
import { GroupsVendorReportsComponent } from './groups/groups-vendor-reports/groups-vendor-reports.component';
import { TrainingVideosVideosDashboardComponent } from './help/training-videos/training-videos-videos/training-videos-videos-dashboard/training-videos-videos-dashboard.component';
import { TrainingVideosVideosEditComponent } from './help/training-videos/training-videos-videos/training-videos-videos-edit/training-videos-videos-edit.component';
import { AdminProsComponent } from './admin-pros/admin-pros.component';
import { AdminProsBlocksDashboardComponent } from './admin-pros/admin-pros-blocks/admin-pros-blocks-dashboard/admin-pros-blocks-dashboard.component';
import { AdminProsBlocksEditComponent } from './admin-pros/admin-pros-blocks/admin-pros-blocks-edit/admin-pros-blocks-edit.component';
import { AdminProsElementsDashboardComponent } from './admin-pros/admin-pros-elements/admin-pros-elements-dashboard/admin-pros-elements-dashboard.component';
import { AdminProsElementsEditComponent } from './admin-pros/admin-pros-elements/admin-pros-elements-edit/admin-pros-elements-edit.component';
import { AdminProsThemesDashboardComponent } from './admin-pros/admin-pros-themes/admin-pros-themes-dashboard/admin-pros-themes-dashboard.component';
import { AdminProsThemesEditComponent } from './admin-pros/admin-pros-themes/admin-pros-themes-edit/admin-pros-themes-edit.component';
import { AdminProsBuilderTypesComponent } from './admin-pros/admin-pros-builder-types/admin-pros-builder-types.component';
import { AdminProsBuilderTypesDashboardComponent } from './admin-pros/admin-pros-builder-types/admin-pros-builder-types-dashboard/admin-pros-builder-types-dashboard.component';
import { AdminProsBuilderTypesEditComponent } from './admin-pros/admin-pros-builder-types/admin-pros-builder-types-edit/admin-pros-builder-types-edit.component';
import { AdminProsBuilderMergeFieldsComponent } from './admin-pros/admin-pros-builder-merge-fields/admin-pros-builder-merge-fields.component';
import { AdminProsBuilderMergeFieldsDashboardComponent } from './admin-pros/admin-pros-builder-merge-fields/admin-pros-builder-merge-fields-dashboard/admin-pros-builder-merge-fields-dashboard.component';
import { AdminProsBuilderMergeFieldsEditComponent } from './admin-pros/admin-pros-builder-merge-fields/admin-pros-builder-merge-fields-edit/admin-pros-builder-merge-fields-edit.component';
import { GroupsubgroupsComponent } from './groupsubgroups/groupsubgroups.component';
import { GroupsubgroupsDashboardComponent } from './groupsubgroups/groupsubgroups-dashboard/groupsubgroups-dashboard.component';
import { GlobalTemplateComponent } from './templates/masters/global-template/global-template.component';
import { ElementSettingComponent } from './templates/elements/element-setting/element-setting.component';

const adminRoutes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthAdminGuard],
    children: [
      { path: '', redirectTo: 'clients', pathMatch: 'full' },
      {
        path: 'clients',
        component: ClientsComponent,
        children: [
          { path: '', component: ClientDashboardComponent },
          { path: 'new', component: ClientEditComponent },
          { path: 'new/:section', component: ClientEditComponent },
          { path: ':id/edit', component: ClientEditComponent },
          { path: ':id/edit/:section', component: ClientEditComponent },
          { path: ':id/gallery', component: ClientGalleryComponent },
          { path: ':id/gallery/:type', component: ClientGalleryComponent },
          { path: ':id/gallery/:type/:gid', component: ClientGalleryComponent },
          {
            path: ':id/users',
            component: ClientUsersComponent,
            children: [{ path: '', component: UsersDashboardComponent }],
          },
          { path: ':id/users/new', component: UsersEditComponent },
          { path: ':id/users/:uid/edit', component: UsersEditComponent },
        ],
      },
      {
        path: 'pro-analytics',
        loadChildren: () => import('./pro-analytics/pro-analytics.module').then((m) => m.ProAnalyticsModule),
      },
      {
        path: 'templates',
        component: TemplatesComponent,
        children: [
          { path: '', component: ElementsComponent },
          { path: 'elements', component: ElementsComponent },
          { path: 'elements/:type', component: ElementsComponent },
          { path: 'elements/:type/new', component: ElementEditComponent },
          { path: 'elements/:type/new/:block', component: ElementEditComponent },
          { path: 'elements/:type/:id/edit/:dupe', component: ElementEditComponent },
          { path: 'elements/:type/:id/edit', component: ElementEditComponent },
          { path: 'elements/:type/:id/html', component: ElementHtmlComponent },
          { path: 'elements/:type/:id/setting', component: ElementSettingComponent },
          { path: 'masters', component: MastersComponent },
          { path: 'masters/:type', component: MastersComponent },
          { path: 'masters/:type/new', component: MasterEditComponent },
          { path: 'masters/:type/:id/edit/:dupe', component: MasterEditComponent },
          { path: 'masters/:type/:id/edit', component: MasterEditComponent },
          { path: 'blocks/:type', component: TemplateBlocksComponent },
          { path: 'blocks/:type/new', component: TemplateBlocksEditComponent },
          { path: 'blocks/:type/:id/edit', component: TemplateBlocksEditComponent },
          { path: 'masters/:id/global_settings', component: GlobalTemplateComponent },
        ],
      },
      {
        path: 'groups',
        component: GroupsComponent,
        children: [
          { path: '', component: GroupDashboardComponent },
          { path: 'new', component: GroupEditComponent },
          { path: ':id/edit', component: GroupEditComponent },
          { path: ':id/vendorreports', component: GroupsVendorReportsComponent },
        ],
      },
      {
        path: 'subgroups',
        component: SubgroupsComponent,
        children: [
          { path: '', component: SubgroupsDashboardComponent },
          { path: 'new', component: SubgroupsEditComponent },
          { path: ':id/edit', component: SubgroupsEditComponent },
        ],
      },
      {
        path: 'groupsubgroups',
        component: GroupsubgroupsComponent,
        children: [{ path: '', component: GroupsubgroupsDashboardComponent }],
      },
      {
        path: 'icons',
        children: [
          { path: '', component: IconsDashboardComponent },
          { path: 'new', component: IconsEditComponent },
          { path: ':id/edit', component: IconsEditComponent },
        ],
      },
      {
        path: 'help',
        component: HelpComponent,
        children: [
          {
            path: 'training-videos',
            children: [
              { path: '', component: TrainingVideosDashboardComponent },
              { path: 'new', component: TrainingVideosEditComponent },
              { path: ':id/edit', component: TrainingVideosEditComponent },
              { path: ':id/videos', component: TrainingVideosVideosDashboardComponent },
              { path: ':id/videos/new', component: TrainingVideosVideosEditComponent },
              { path: ':id/videos/:vid/edit', component: TrainingVideosVideosEditComponent },
            ],
          },
          { path: 'support', component: SupportComponent },
          {
            path: 'changelogs',
            children: [
              { path: '', component: ChangelogDashboardComponent },
              { path: 'new', component: ChangelogEditComponent },
              { path: ':id/edit', component: ChangelogEditComponent },
            ],
          },
        ],
      },
      { path: 'analytics', component: AnalyticTemplateUsageComponent },
      {
        path: 'styles',
        component: StylesComponent,
        children: [
          { path: '', component: StyleDashboardComponent },
          { path: 'new', component: StyleEditComponent },
          { path: ':id/edit/:dupe', component: StyleEditComponent },
          { path: ':id/edit', component: StyleEditComponent },
        ],
      },
      { path: 'settings', component: SettingAdminComponent },
      {
        path: 'websites',
        component: WebsitesComponent,
        children: [
          { path: '', redirectTo: 'agency-website/dashboard', pathMatch: 'full' },
          { path: ':type/dashboard', component: WebsiteDashboardComponent },
          { path: ':type/new', component: WebsiteEditComponent },
          { path: ':id/edit', component: WebsiteEditComponent },
          { path: ':type/logs', component: WebsiteLogsComponent },
        ],
      },
      {
        path: 'pros',
        component: AdminProsComponent,
        children: [
          { path: 'blocks/:product', component: AdminProsBlocksDashboardComponent },
          { path: 'blocks/:product/new', component: AdminProsBlocksEditComponent },
          { path: 'blocks/:product/:id/edit', component: AdminProsBlocksEditComponent },
          { path: 'blocks/:product/:id/edit/:duplicate', component: AdminProsBlocksEditComponent },
          { path: 'elements/:product', component: AdminProsElementsDashboardComponent },
          { path: 'elements/:product/new', component: AdminProsElementsEditComponent },
          { path: 'elements/:product/:id/edit', component: AdminProsElementsEditComponent },
          { path: 'elements/:product/:id/edit/:duplicate', component: AdminProsElementsEditComponent },
          { path: 'themes/:product', component: AdminProsThemesDashboardComponent },
          { path: 'themes/:product/new', component: AdminProsThemesEditComponent },
          { path: 'themes/:product/:id/edit', component: AdminProsThemesEditComponent },
          { path: 'themes/:product/:id/edit/:duplicate', component: AdminProsThemesEditComponent },
          {
            path: 'builder/types',
            component: AdminProsBuilderTypesComponent,
            children: [
              { path: '', component: AdminProsBuilderTypesDashboardComponent },
              { path: 'new', component: AdminProsBuilderTypesEditComponent },
              { path: ':id/edit', component: AdminProsBuilderTypesEditComponent },
            ],
          },
          {
            path: 'builder/mergefields',
            component: AdminProsBuilderMergeFieldsComponent,
            children: [
              { path: '', component: AdminProsBuilderMergeFieldsDashboardComponent },
              { path: 'new', component: AdminProsBuilderMergeFieldsEditComponent },
              { path: ':id/edit', component: AdminProsBuilderMergeFieldsEditComponent },
            ],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(adminRoutes)],
  exports: [RouterModule],
})
export class AdminRouteModule {}
