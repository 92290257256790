import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'ordinalDate'
})
export class OrdinalDatePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}

  transform(value: any, ...args: any[]): string {
    const date = new Date(value);
    const day = date.getDate();
    const suffix = this.getOrdinalSuffix(day);
    const monthAndYear = this.datePipe.transform(value, 'MMMM d, yyyy');

    const formattedDate = monthAndYear.replace(/(\d+)/, `$1${suffix}`);
    return formattedDate;
  }

  private getOrdinalSuffix(day: number): string {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1:  return "st";
      case 2:  return "nd";
      case 3:  return "rd";
      default: return "th";
    }
  }
}
