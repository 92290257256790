import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Master } from '../../../../models/master.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Router, ActivatedRoute } from '@angular/router';
import { DepartmentService } from '../../../../services/department.service';
import { GroupService } from '../../../../services/group.service';
import { MediaAttributeService } from '../../../../services/media-attribute.service';
import { MasterService } from '../../../../services/master.service';

import { Group } from '../../../../models/group.model';
import { MediaAttribute } from '../../../../models/media-attribute.model';
import { Department } from '../../../../models/department.model';
import { Subject } from 'rxjs/Subject';
import { ClientService } from '../../../../services/client.service';
import { Client } from '../../../../models/client.model';

import { SubgroupService } from '../../../../services/subgroup.service';
import { Subgroup } from '../../../../models/subgroup.model';
import swal from 'sweetalert2';

@Component({
  selector: 'app-master-edit',
  templateUrl: './master-edit.component.html',
  styleUrls: ['./master-edit.component.scss']
})
export class MasterEditComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = false;
  id: number;
  editMode = false;
  backUrl = '../';
  category: string;
  category_id: number;
  duplicateMode = false;
  categoryHolder: any;

  categories = {
    'enewsletter': 1,
    'buyer-match': 2,
    'quick-enquiry': 3,
    'brochure': 4,
    'property-guide': 5,
    'social-media': 6,
    'email-signature': 7,
    'dl-card': 8,
    'sign-board': 9,
    'property-match': 10,
    'match-listings': 11,
    'buyer-match-vault': 12,
    'property-alerts': 13,
    'pricefinder': 14
  };

  master: Master;
  masterForm: FormGroup;

  groups: Group[];
  mediaAttributes: MediaAttribute[];
  departments: Department[];
  clients: Client[];
  subgroups: Subgroup[];

  master_title = '';

  @ViewChild('fileIcon', { static: true }) fileIcon: ElementRef;
  @ViewChild('thumbnailImage', { static: true }) thumbnailImage: ElementRef;

  constructor(
    private masterService: MasterService,
    private groupService: GroupService,
    private mediaAttributeService: MediaAttributeService,
    private departmentService: DepartmentService,
    private clientService: ClientService,
    private route: ActivatedRoute,
    private router: Router,
    private subgroupService: SubgroupService
  ) { }

  ngOnInit() {
    this.category = this.route.snapshot.paramMap.get('type');
    this.category_id = this.categories[this.category];

    if (!this.category || !this.category_id) {
      this.router.navigateByUrl('/admin/masters/elements');
    }

    this.loading = true;
    const masterId = this.route.snapshot.paramMap.get('id');
    const duplicateMode = this.route.snapshot.paramMap.get('dupe');

    if (masterId) {
      // tslint:disable-next-line: radix
      this.id = parseInt(masterId);
      this.editMode = true;
      this.backUrl = '../../';
    }

    if (duplicateMode) {
      this.duplicateMode = true;
      this.backUrl = '../../../';
    }

    this.groupService.httpGetGroupsNew();

    this.groupService.onGetGroups
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              this.groups = response.data;

              this.clientService.httpGetClientsNew({}, 'list');
            }

            if (response.status === 'error') {
              this.loading = false;
              swal('Error getting groups', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.clientService.onGetClients
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              this.clients = response.data;

              this.mediaAttributeService.httpGetMediaAttributesNew();
            }

            if (response.status === 'error') {
              this.loading = false;
              swal('Error getting clients', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.mediaAttributeService.onGetMediaAttributes
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              this.mediaAttributes = response.data;

              this.subgroupService.httpGetSubGroupsNew();
            }

            if (response.status === 'error') {
              this.loading = false;
              swal('Error getting media attributes', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.subgroupService.onGetSubGroups
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              this.subgroups = response.data;

              this.departmentService.httpGetDepartmentsNew();
            }

            if (response.status === 'error') {
              this.loading = false;
              swal('Error getting media attributes', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.departmentService.onGetDepartments
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;
            if (response.status === 'success') {
              this.departments = response.data;

              if (this.editMode) {
                this.masterService.httpGetMasterNew(masterId);
                this.loading = true;
              } else {
                const groupId = typeof this.masterService.group_id === 'string' ?
                // tslint:disable-next-line: radix
                parseInt(this.masterService.group_id) : this.masterService.group_id;

                if (groupId) {
                  this.masterForm.patchValue({
                    group_id: groupId
                  });
                }
              }
            }

            if (response.status === 'error') {
              this.loading = false;
              swal('Error getting media attributes', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.masterService.onGetMaster
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;
            if (response.status === 'success') {
              this.master = response.data;
              this.categoryHolder = this.master.category_id;

              this.initFormEdit();
            }

            if (response.status === 'error') {
              swal('Error getting master', 'Contact Dev Team', 'error');
              this.router.navigateByUrl('/admin/masters/elements');
            }
          }
        }
      }
    );

    this.masterService.masterOperation
    .takeUntil(this.destroy$)
    .subscribe(
      (master: Master) => {
        if ( typeof master.ID !== 'undefined' ) {
          this.loading = false;
          this.router.navigate([this.backUrl], {relativeTo: this.route});
        }
      }
    );

    this.initForm();
  }

  private initForm() {
    this.masterForm = new FormGroup({
      'title': new FormControl('', Validators.required),
      'description': new FormControl(''),
      'provider': new FormControl(['all'], Validators.required),
      'group_id': new FormControl(1, Validators.required),
      'media_attribute_id': new FormControl(1, Validators.required),
      'department_id': new FormControl(1, Validators.required),
      'client_id': new FormControl(0, Validators.required),
      'subgroup_id': new FormControl(0),
      'is_active': new FormControl(1, Validators.required),
      'show_element': new FormControl(0, Validators.required),
      'icon': new FormControl(''),
      'thumbnail_image': new FormControl(''),
      'enable_ckeditor': new FormControl('1', Validators.required),
      'enable_contenteditable': new FormControl('1', Validators.required),
      'enable_hugconnect': new FormControl('1', Validators.required),
      'validation_code': new FormControl(''),
      'puppeteer': new FormControl(0, Validators.required)
    });
  }

  private initFormEdit() {
    this.master_title = this.master.title;
    this.masterForm.patchValue({
      'title': this.master.title,
      'description': this.master.description,
      'provider': this.master.provider,
      'group_id': this.master.group_id,
      'media_attribute_id': this.master.media_attribute_id,
      'department_id': this.master.department_id,
      'client_id': this.master.client_id,
      'subgroup_id': this.master.subgroup_id,
      'is_active': (this.duplicateMode) ? 0 : this.master.is_active,
      'show_element': this.master.show_element,
      'icon': this.master.icon,
      'thumbnail_image': this.master.thumbnail_image,
      'enable_ckeditor': this.master.enable_ckeditor,
      'enable_contenteditable': this.master.enable_contenteditable,
      'enable_hugconnect': this.master.enable_hugconnect,
      'validation_code': this.master.validation_code,
      'puppeteer': this.master.puppeteer
    });
  }

  onSubmit() {
    const masterValues = this.masterForm.value;

    const icon = this.fileIcon.nativeElement;
    const thumbnailImage = this.thumbnailImage.nativeElement;
    this.loading = true;

    if (icon.files.length > 0) {
      masterValues['iconFile'] = icon.files[0];
    }

    if(thumbnailImage.files.length > 0) {
      masterValues['thumbnailImageFile'] = thumbnailImage.files[0];
    }
    
    if (this.editMode && !this.duplicateMode) {
      this.masterService.httpPutMaster(this.id, masterValues);
    } else if (this.duplicateMode) {
      masterValues['category_id'] = this.categoryHolder;

      this.masterService.httpPostMaster(masterValues);
    } else {
      masterValues['category_id'] = this.category_id;

      this.masterService.httpPostMaster(masterValues);
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
