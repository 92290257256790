<div class="container-element-property-icons sidebar-setting">
  <div class="content-property-icons">
    <div class="e-property-icons-head">
      <span class="h-title">Property Icons Settings</span>
      <div class="pull-right">
        <i class="icon feather icon-x close-sidebar-setting" (click)="closeSideBarSettings()"></i>
      </div>
    </div>
    <form [formGroup]="elementPropertyForm">
      <ng-container formArrayName="icons" *ngFor="let eProperty of getElementPropertyIcons().controls; let propertyIconIdx = index; let firstPropertyIcon = first; let lastPropertyIcon = last">
        <ng-container [formGroupName]="propertyIconIdx">
          <div class="row mb-3">
            <div class="col p-0">
              <div class="dropdown">
                <span data-bs-toggle="dropdown"><i class="icon feather icon-more-vertical"></i></span>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <li *ngIf="!firstPropertyIcon"><span class="dropdown-item c-pointer sidebar-dropdown-item" (click)="movePropertyIcon(0, $event)"><i class="icon feather icon-arrow-up"></i> Move Up</span></li>
                  <li *ngIf="!lastPropertyIcon"><span class="dropdown-item c-pointer sidebar-dropdown-item" (click)="movePropertyIcon(1, $event)"><i class="icon feather icon-arrow-down"></i> Move Down</span></li>
                </ul>
              </div>
            </div>
            <div class="col-md property-icon-setting">
              <img src="{{ eProperty.value.img }}"><span class="title text-capitalize">{{ eProperty.value.label }}</span>
              <label class="switch pull-right">
                <input [checked]="eProperty.value.is_show" [attr.data-position]="eProperty.value.position" [attr.data-is-show]="eProperty.value.is_show" (click)="togglePropertyIcon(eProperty, propertIconIndex, $event)" type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </form>
  </div>
</div>

<div class="container-global template-settings">
  <div class="row">
    <div class="ts-header">
      <span class="h-name">Template Settings</span>
      <span class="pull-right fw-bolder" (click)="closeSideBarSettings()"><i class="icon feather icon-x close-template-setting"></i></span>
    </div>
  </div>
  <ng-container *ngIf="globalSettings?.length > 0; then hasGlobalSetting; else noGlobalSetting"></ng-container>
  <ng-template #hasGlobalSetting>
    <div class="row global-template-setting">
      <form [formGroup]="globalSettings" (ngSubmit)="saveUserGlobalSetting()">
        <ng-container *ngFor="let globalSetting of globalSettings.controls; let groupItemIndex = index; let lastGroupItemIndex = last">
          <div class="mb-3">
            <ng-container *ngIf="globalSetting.value.group_id != null; then groupGlobalSetting else nonGroupGlobalSetting"></ng-container>
            <ng-container [formGroupName]="groupItemIndex">
              <ng-template #groupGlobalSetting>
                <span class="group-setting-name">{{ globalSetting.value.group_name }}</span>
                <div formArrayName="data" class="mt-1">
                  <ng-container *ngFor="let groupSettingItem of globalSetting.controls.data.controls; let groupSettingItemIdx = index">
                    <ng-container [formGroupName]="groupSettingItemIdx">
                      <ng-container *ngIf="groupSettingItem.value.settings_type == 1; then groupToggleSetting else groupDropdownSetting"></ng-container>
                      <ng-template #groupToggleSetting>
                        <div class="mb-3">
                          <span class="setting-title">{{ groupSettingItem.value.title }}</span>
                          <label class="switch pull-right">
                            <input type="checkbox" [checked]="groupSettingItem.value.is_class_exists ? true : null" value="{{ groupSettingItem.value.class_name }}" (click)="toggleDropDownClass($event.target, groupSettingItem.value.id)"><span class="slider round"></span>
                          </label>
                          <div class="setting-description">{{ groupSettingItem.value.description }}</div>
                        </div>
                      </ng-template>
                      <ng-template #groupDropdownSetting>
                        <div class="parent-drpdwn">
                          <span class="pb-0 setting-title">{{ groupSettingItem.value.title }}</span>
                          <div class="setting-description mb-2">{{ groupSettingItem.value.description }}</div>
                          <select class="form-select form-select-sm" (change)="chooseSettingOption($event, $event.target.value, groupSettingItem.value.id)">
                            <option value="">-----</option>
                            <option *ngFor="let option of groupSettingItem.value.dropdown_options" value="{{ option.class_name }}" [selected]="option.is_class_exists">{{ option.title }}</option>
                          </select>
                        </div>
                      </ng-template>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-template>
              <ng-template #nonGroupGlobalSetting>
                <ng-container *ngIf="globalSetting.value.settings_type == 1; then nonGroupToggleSetting else nonGroupDropdownSetting"></ng-container>
                <ng-template #nonGroupToggleSetting>
                  <div class="mb-1">
                    <span class="fw-bold">{{ globalSetting.value.title }}</span>
                    <label class="switch pull-right">
                      <input type="checkbox" [checked]="globalSetting.value.is_class_exists" value="{{ globalSetting.value.class_name }}" (click)="toggleDropDownClass($event.target, globalSetting.value.id)"><span class="slider round"></span>
                    </label>
                    <div class="setting-description">{{ globalSetting.value.description }}</div>
                  </div>
                </ng-template>
                <ng-template #nonGroupDropdownSetting>
                  <div class="parent-drpdwn">
                    <span class="pb-0 fw-bold">{{ globalSetting.value.title }}</span>
                    <div class="setting-description mb-2">{{ globalSetting.value.description }}</div>
                    <select class="form-select form-select-sm" (change)="chooseSettingOption($event, $event.target.value, globalSetting.value.id)">
                      <option value="">-----</option>
                      <option *ngFor="let option of globalSetting.value.dropdown_options" value="{{ option.class_name }}" [selected]="option.is_class_exists">{{ option.title }}</option>
                    </select>
                  </div>
                </ng-template>
              </ng-template>
            </ng-container>
          </div>
          <hr *ngIf="!lastGroupItemIndex">
        </ng-container>
        <div class="col mt-1 mb-3">
          <button type="submit" class="form-control btn btn-save-global-setting">Save</button>
        </div>  
      </form>
    </div>
  </ng-template>
  <ng-template #noGlobalSetting>
    <span class="pull-left">No available settings for this template</span>
  </ng-template>
</div>

<div class="container-element-setting template-settings">
  <div class="row">
    <div class="ts-header">
      <span class="h-name">Element Template Settings</span>
      <span class="pull-right fw-bolder" (click)="closeSideBarSettings()"><i class="icon feather icon-x close-template-setting"></i></span>
    </div>
  </div>
  <div *ngIf="elementTemplateSettingList?.length >0; then hasElementSetting; else noElementSetting"></div>
  <ng-template #hasElementSetting>
    <div class="row" *ngIf="elementTemplateSettingList?.length > 0">
      <ng-container *ngFor="let esetting of elementTemplateSettingList; let first_esetting_idx = first; let last_esetting_idx = last">
        <div>
          <div *ngIf="esetting.group_id != null; then elemGroupSettings else elemRowSettings"></div>
          <ng-template #elemGroupSettings>
            <div class="template-group-setting">
              <span class="group-setting-name">
                {{ esetting.group_name }}
              </span>
              <ng-container *ngFor="let elem_group_item of esetting.data; let first_elem_setting = first; let last_elem_setting = last">
                <div *ngIf="elem_group_item.settings_type == 1; then ToggleSetting else DropdownSetting"></div>
                <div class="mb-3">
                  <ng-template #ToggleSetting>
                    <div>
                      <span class="pb-0 setting-title">{{ elem_group_item.title }}</span>
                      <label class="switch pull-right">
                        <input type="checkbox" value="{{ elem_group_item.class_name }}" (click)="toggleRowElementSetting($event)" [checked]="elem_group_item.is_class_exists ? true : null"><span class="slider round"></span>
                      </label>
                    </div>
                    <div class="gsetting-description"><small>{{ elem_group_item.description }}</small></div>
                  </ng-template>
                  <ng-template #DropdownSetting>
                    <div>
                      <span class="pb-0 setting-title">{{ elem_group_item.title }}</span>
                      <div class="gsetting-description mb-2"><small>{{ elem_group_item.description }}</small></div>
                      <select class="form-select form-select-sm" (change)="selectElementSettingOption($event, elem_group_item.id, elem_group_item)">
                        <option value="">-----</option>
                        <option *ngFor="let opt of elem_group_item.settings_dropdown_options" [selected]="opt.is_class_exists" value="{{ opt.class_name }}">{{ opt.title }}</option>
                      </select>
                    </div>
                  </ng-template>
                </div>
              </ng-container>
            </div>
          </ng-template>
          <ng-template #elemRowSettings>
            <div *ngIf="esetting.settings_type == 0; then elemRowSettingDropdown else elemRowSettingToggle"></div>
            <ng-template #elemRowSettingDropdown>
              <div>
                <span class="pb-0 fw-bold">{{ esetting.title }}</span>
                <div class="esetting-description mb-2"><small>{{ esetting.description }}</small></div>
                <select class="form-select form-select-sm" (change)="selectElementSettingOption($event, esetting.id, esetting)">
                  <option value="">-----</option>
                  <option *ngFor="let opt of esetting.settings_dropdown_options; let first_opt = first" [selected]="opt.is_class_exists" value="{{ opt.class_name }}">{{ opt.title }}</option>
                </select>
              </div>
            </ng-template>
            <ng-template #elemRowSettingToggle>
              <div>
                <span class="pb-0 fw-bold">{{ esetting.title }}</span>
                <label class="switch pull-right">
                  <input type="checkbox" [checked]="esetting.is_class_exists ? true : null" value="{{ esetting.class_name }}" (click)="toggleRowElementSetting($event)"><span class="slider round"></span>
                </label>
              </div>
              <div class="setting-description"><small>{{ esetting.description }}</small></div>
            </ng-template>
          </ng-template>
          <hr *ngIf="!last_esetting_idx">
        </div>
      </ng-container>
    </div>
  </ng-template>
  <ng-template #noElementSetting>
    <span class="pull-right">No available settings for this element</span>
  </ng-template>
</div>
<!-- <a (click)="onFeedback()" id="my_feedback" class="btn-feedback">Send Feedback</a> -->
<div class="height-100">
  <div class="tui-editor-backdrop">
    <div class="spinner">
      <div class="rect1"></div>
      <div class="rect2"></div>
      <div class="rect3"></div>
      <div class="rect4"></div>
      <div class="rect5"></div>
    </div>
    <p>This might take awhile. Saving Image...</p>
  </div>
  <div id="builder-html" #builderHtml></div>
</div>
<div #mdPropSearchModal id="mdPropSearch" class="modal fade edit-box preview app-media" role="dialog">
  <div class="modal-dialog modal-center modal-fullscreen" role="document">
    <div class="modal-content">
      <div class="modal-title">
          <button type="button" #mdPropSearchModalClose (click)="closeListingSearchModal()" class="btn property-close-modal"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="icon feather icon-x"></i> Close
        </button>
      </div>
      <div class="modal-body">
        <app-property-search [template]="template" [maxLimit]="maxLimit" (back)="back()"></app-property-search>
      </div>
    </div>
  </div>
</div>

<div #mdLibrarySearchModal id="mdLibrarySearch" class="modal fade edit-box preview app-media app-media-new" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-center modal-dialog-custom" role="document">
    <div class="modal-content modal-content-custom">
      <div class="modal-body modal-body-custom">
        <button type="button" #mdLibrarySearchModalClose class="close close-outside close-outside-library" data-bs-dismiss="modal" aria-label="Close" (click)="closeLibraryModal()">
          <span aria-hidden="true">&times;</span>
        </button>
        <ng-container>
          <app-image-library-revamp libraryType="image"></app-image-library-revamp>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div #mdParticaSearchModal id="mdParticaSearch" class="modal fade edit-box preview app-media" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-center modal-dialog-custom" role="document">
    <div class="modal-content modal-content-custom">
      <div class="modal-body modal-body-custom">
        <button type="button" #mdParticaSearchModalClose class="close close-outside close-outside-library" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <ng-container *ngIf="showPartica">
          <app-partica page="builder"></app-partica>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div #mdPricefinderSearchModal id="mdPricefinderSearch" class="modal fade edit-box preview app-media" role="dialog">
  <div class="modal-dialog modal-center modal-fullscreen" role="document">
    <div class="modal-content">
      <form class="form-horizontal">
        <div class="modal-body">
          <div class="title-bar">
            <button type="button" class="pricefinder-back">
              <i class="icon feather icon-chevron-left" aria-hidden="true" data-bs-dismiss="modal" aria-label="Close"></i>
              Back
            </button>
            <p class="title">Suburb Search</p>
            <button type="button" #mdPricefinderSearchModalClose class="pricefinder-close-modal" data-bs-dismiss="modal" aria-label="Close"><i class="icon feather icon-x" aria-hidden="true"></i> Close</button>
          </div>
          <ng-container *ngIf="propertySearchEndPoint == 'corelogic'">
            <app-corelogic></app-corelogic>
          </ng-container>
          <ng-container *ngIf="propertySearchEndPoint != 'corelogic'">
            <app-pricefinder></app-pricefinder>
          </ng-container>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Modal -->
<div id="supportModal" class="modal fade" role="dialog">
  <div class="modal-dialog modal-xl">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close close-outside" data-bs-dismiss="modal">&times;</button>
        <app-support page="builder"></app-support>
      </div>
    </div>
  </div>
</div>

<div class="help-box help-box-control hidden">
  <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
    <li>
      <a href="/help/training-videos" target="_blank">
        <p><i class="fa fa-video-camera"></i> Watch Training Videos</p>
      </a>
    </li>
    <li>
      <a href="javascript:void(0)" (click)="openSupportModal()">
        <p><i class="fa fa-comment"></i> Submit a support request</p>
      </a>
    </li>
  </ul>
</div>

<div class="help-box view-as-control hidden">
  <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
    <li>
      <a href="javascript:void(0)" (click)="onView('code')">
        <p><i class="icon feather icon-code"></i> View HTML</p>
      </a>
    </li>
    <li>
      <a href="javascript:void(0)" (click)="onView('browser')">
        <p><i class="icon feather icon-globe"></i> View in Browser</p>
      </a>
    </li>
  </ul>
</div>

<ng-container *ngIf="frontService.authService.getApp() === 'Designly' || frontService.authService.getApp() === 'MyDesign3'">
  <div class="save-options hidden">
    <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
      <!-- <li *ngIf="template?.category_id !== 2 && template?.category_id !== 11 && template?.category_id !== 10"><a href="javascript:void(0)" (click)="saveQuickDesign()">Save as Quick Design</a></li> -->

      
      <!-- <li *ngIf="template?.quickdesign && (!isVaultMail || template?.category_id !== 11)"><a href="javascript:void(0)" (click)="onShareQuickDesign()">Share template</a></li> -->
      <li *ngIf="!template?.quickdesign && (!isVaultMail || template?.category_id !== 11)"><a href="javascript:void(0)" (click)="showSaveAsTemplateModal()">Save as Template</a></li>
      <li *ngIf="template?.quickdesign && (!isVaultMail || template?.category_id !== 11)"><a href="javascript:void(0)" (click)="removeFromCustomCategory()">Remove as
            Template</a></li>
      <li
        *ngIf="frontService.appConfig.SKIN !== 'signifi' && authUser?.brandid !== '6' && authUser?.brandid !== '4' && template?.category_id !== 2 && template?.category_id !== 11 && template?.category_id !== 10 && template?.category_id !== 14">
        <a href="javascript:void(0)" (click)="selectCampaign()">Save to Campaign</a></li>

      <ng-container *ngIf="authUser?.is_master && template?.category_id !== 14">
        <li *ngIf="!template?.is_master_template && (!isVaultMail || template?.category_id !== 11)"><a href="javascript:void(0)" (click)="saveMasterTemplate('add')">Save
            as Master Template</a></li>
        <li *ngIf="template?.is_master_template && (!isVaultMail || template?.category_id !== 11)"><a href="javascript:void(0)"
            (click)="saveMasterTemplate('remove')">Remove as Master Template</a></li>
      </ng-container>
      <li *ngIf="template?.category_id === 2 || template?.category_id === 11">
        <a href="javascript:void(0)" (click)="sendTestEmail()">
          <p>Send Test Email</p>
        </a>
      </li>
    </ul>
  </div>
</ng-container>

<div class="help-box highres-pdf hidden">
  <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
    <li>
      <a href="javascript:void(0)" (click)="downloadPdf(true)">
        <p>For Print</p>
      </a>
    </li>
    <li>
      <a href="javascript:void(0)" (click)="downloadPdf(false)">
        <p>For Email</p>
      </a>
    </li>
  </ul>
</div>

<div class="help-box save-as-image hidden">
  <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
    <li>
      <a href="javascript:void(0)" (click)="imageSaveAs('jpg')">
        <p>Save as JPG</p>
      </a>
    </li>
    <li>
      <a href="javascript:void(0)" (click)="imageSaveAs('png')">
        <p>Save as PNG</p>
      </a>
    </li>
  </ul>
</div>

<div class="emarketing-options hidden">
  <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
    <li>
      <a href="javascript:void(0)" (click)="sendTestEmail()">
        <p>Send Test Email</p>
      </a>
    </li>
  </ul>
</div>

<div *ngIf="showScreenshot" class="take-screenshot" data-html2canvas-ignore="true">
  <h3>You're taking a screenshot for your support request</h3>
  <button (click)="onSupportCapture()" class="btn-admin btn-admin-primary">Capture</button>
  <button (click)="onSupportCancel()" class="btn-admin btn-admin-danger">Cancel</button>
</div>

<div id="selectCampaign" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Select Campaign</h4>
        <button type="button" class="close close-outside" data-bs-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <ng-select [items]="s2CampaignData" [clearable]="false" bindLabel="text" bindValue="id" placeholder="Select files" [multiple]="true" [(ngModel)]="s2CampaignValue" [ngModelOptions]="{standalone: true}">
        </ng-select>
        <button (click)="onCampaignSave()" type="button" class="btn-admin btn-admin-primary mt-3">Save</button>
      </div>
    </div>
  </div>
</div>
<!-- Hug Connect Modal -->
<div id="uploadHugConnect" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <form [formGroup]="uploadCabinateForm" (ngSubmit)="onSubmitUploadFileCabinate()">
      <div class="modal-header">
        <h4 class="modal-title mt-0">Upload to Filing Cabinet</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="container-fluid ">
          <div class="row">
            <p>A PDF will be uploaded to the property filing cabinet within MRI Vault.</p>
            <div class="form-group mt-3">
              <label for="title"  class="col-sm-12">File Name</label>   
              <div class="col-sm-12">
                <input formControlName="title" required type="text" class="form-control" id="title" autocomplete="off" placeholder="" />
              </div>
            </div>
            <div class="form-group mt-3">
              <label for="folder" class="col-sm-4">Select Folder</label>           
              <div class="col-sm-12">
                <select  formControlName="folder" class="form-select" required>
                  <option value="">Select Folder</option>
                  <option *ngFor="let folder of filingCabinetFolders" value="{{ folder?.id }}">
                    {{ folder?.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group mt-3" *ngIf="template?.master.puppeteer">
              <label for="resolutionFilter" class="col-sm-8">Select Upload Resolution Type</label>
              <div class="col-sm-12 d-flex">
                <div class="form-check first-option">
                  <input type="radio" name="resolutionFilter" formControlName="resolutionFilter"
                         value="for-print" checked/>
                  <label> &nbsp; For Print </label>
                </div>
                <div class="form-check">
                  <input type="radio" name="resolutionFilter" formControlName="resolutionFilter"
                         value="for-email"/>
                  <label> &nbsp; For Email </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancel</button>
        <button [disabled]="!uploadCabinateForm.valid" class="btn-admin btn-admin-primary">Upload</button>
      </div>
      </form>
    </div>
  </div>
</div>
<div id="sendTestEmail" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <form [formGroup]="testEmailForm" (ngSubmit)="onSubmitTestEmail()">
      <div class="modal-header">
        <h4 class="modal-title mt-0">Send Test Email</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
                <p>Please note that sending a test email from Designly will not populate VaultRE/EA specific merge fields, for example $$greeting$$.</p>
                <label for="testEmail" class="form-label">Email</label>
                <input formControlName="email" required type="text" class="form-control" id="testEmail" autocomplete="off" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button [disabled]="!testEmailForm.valid" class="btn-admin btn-admin-primary">Send</button>
      </div>
      </form>
    </div>
  </div>
</div>


<div id="mdAgentsSearch" class="modal fade edit-box" role="dialog" aria-labelledby="mdAgentsSearchLabel">
  <div class="modal-dialog modal-center">
      <div class="modal-content p-0">
          <div class="modal-header border-bottom mb-2">
              <h4 class="modal-title m-0" id="mdAgentsSearchLabel">Insert agent/s</h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="form-horizontal">
              <div class="modal-body">
                  <div class="row mb-4">
                      <div class="col-sm-12">
                          <input type="text" class="form-control" autocomplete="off" autofocus
                              placeholder="Search agents name or ID" [(ngModel)]="agentSearchFilter">
                      </div>
                  </div>
                  <div class="row mb-3">
                      <div class="col-sm-12">
                          <div class="agentListScroll">
                              <ul>
                                  <li *ngFor="let clientAgent of clientAgents | webbookAgent: agentSearchFilter">
                                      <div class="d-flex align-items-center">
                                          <div class="flex-shrink-0">
                                              <img [src]="clientAgent?.imageurl" class="agent-image rounded-circle">
                                          </div>
                                          <div class="flex-grow-1 ms-3">
                                              <h6>{{clientAgent?.firstname}} {{clientAgent?.lastname}}</h6>
                                              <p class="m-0">{{clientAgent?.agent_id}}</p>
                                          </div>
                                          <button *ngIf="checkAgentIfSelected(clientAgent?.ID); else elseNotSelected"
                                              class="btn btn-primary btn-author-actions"
                                              (click)="unselectAgent(clientAgent?.ID)">Unselect</button>

                                          <ng-template #elseNotSelected>
                                              <button type="button" class="btn btn-default btn-author-actions"
                                                  (click)="selectAgent(clientAgent)">Select</button>
                                          </ng-template>
                                      </div>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="modal-footer d-flex justify-content-between">
                  <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                  <button type="button" class="btn-admin btn-admin-primary" (click)="insertAgents()">Insert</button>
              </div>
          </div>
      </div>
  </div>
</div>

<!-- // Save As Template -->
<div id="saveAsTemplateModal" class="modal fade edit-box" role="dialog" aria-labelledby="saveAsTemplateModalLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="saveToCategoryLabel">Save as Template</h4>
      </div>

      <div class="d-flex tabs">
        <div class="mr-2" [class.active]="saveAsTemplateState.selectedTab === 'details-tab'" (click)="selectSaveAsTemplateTab('details-tab')">Details</div>
        <div class="mr-2" [class.active]="saveAsTemplateState.selectedTab === 'folders-tab'" (click)="selectSaveAsTemplateTab('folders-tab')">Folders</div>
        <div [hidden]="true" [class.active]="saveAsTemplateState.selectedTab === 'sharing-tab'" (click)="selectSaveAsTemplateTab('sharing-tab')">Sharing</div>
      </div>


      <hr class="tab-divider" />

      <form id="saveAsTemplateForm" [formGroup]="customCategoryForm" (ngSubmit)="onSaveToCustomCategory()">
      <!-- TAB CONTENT -->
      <div [hidden]="saveAsTemplateState.selectedTab !== 'details-tab'" id="details-tab" class="tab-content">
        <p>This design will be saved as a template. You (and anyone you share this design with) will be able to use this design as a starting point for new designs.</p>
        
          <div class="form-group">
            <label for="template-title" class="col-sm-4">Title*</label>           
            <div class="col-sm-12 mt-2">
              <input id="template-title" formControlName="title" class="form-control" type="text" placeholder="Title"/>
            </div>
          </div>

          <div class="form-group mt-3">
            <label for="template-description" class="col-sm-4">Description</label>           
            <div class="col-sm-12 mt-2">
              <textarea 
                formControlName="description"
                id="template-description" 
                placeholder="Give your template a short description"
                class="form-control" rows="3" 
                style="resize: none;"></textarea>
            </div>
          </div>

          <div class="form-group mt-3">
            <label for="saveToCategoryIcon" class="col-sm-4">Thumbnail</label>           
            <div class="col-sm-8 mt-2">
              <input #customCategoryIcon type="file" accept="image/*" class="form-control" id="saveToCategoryIcon" autocomplete="off" autofocus />
            </div>
            <div class="col-sm-12 mt-2">              
              <small>Maximum file size: 500KB</small> <br />
              <small>Template thumbnails will auto generate however you can upload a custom thumbnail if needed.</small>
            </div>
          </div>

          <div class="form-group mt-3">
            <label for="templateTags" class="col-sm-4">Tags</label>     
            <div class="col-sm-12 mt-2">
              <input id="templateTags" class="form-control" type="text" placeholder="Tags"/>
            </div>  
            <div class="col-sm-12 mt-2">
              <small>Assign your template with keywords to help search and filter your different templates.</small>
            </div>        
          </div>
      </div>

      <div [hidden]="saveAsTemplateState.selectedTab !== 'folders-tab'" id="folders-tab" class="tab-content">
        <p>Save your template into different categories or folders.</p>

          <div class="form-group">
            <label for="template-category" class="col-sm-4">Save to Category</label>           
            <div class="col-sm-12 mt-2">
              <select #selectCustomCategory formControlName="reference" class="form-select" (change)="onSelectedCustomCategories(selectCustomCategory.value)">
                <option *ngIf="showReferenceOption('emails')" value="emails">Emails</option>
                <option *ngIf="showReferenceOption('printables')" value="printables">{{ quickDesignService.quickdesignCategoryLabels.printables }}</option>
                <option *ngIf="showReferenceOption('socialmedia')" value="socialmedia">Social Media</option>
                <option *ngIf="showReferenceOption('signboards')" value="signboards">Signboards</option>
                <option *ngIf="showReferenceOption('pricefinder')" value="pricefinder">Market Snapshot</option>
                <option *ngFor="let customCategory of customCategories" [value]="customCategory.ID">
                  {{ customCategory?.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group">
            <label for="template-folder" class="col-sm-4 mt-3">Folder</label>
            <div class="col-sm-12">
              <ng-select formControlName="quick_design_group_id" [multiple]="true">
                <ng-option *ngFor="let quickDesignGroup of quickDesignGroups" value="{{ quickDesignGroup?.ID }}">{{ quickDesignGroup?.name }}</ng-option>        
              </ng-select>
            </div>
          </div>

          <p class="mt-3">Note: Changing the folder category will reset the previously made selection changes.</p>

      </div>
      <div [hidden]="saveAsTemplateState.selectedTab !== 'sharing-tab'" id="sharing-tab" class="tab-content">
        <p>By default saving templates will only appear in your account in My Templates. If you want others to be able to use this template try sharing the template in the options below.</p>
        
          <div class="form-group">
            <label for="template-share-with" class="col-sm-4">Share With</label>
            <div class="col-sm-12">
              <select class="form-select mt-2" [(ngModel)]="templateShareWith" 
                [ngModelOptions]="{ standalone: true }">
                <option value="agent">Agent</option>
                <option value="office">Office</option>
              </select>
            </div>
          </div>

        <div class="template-share-agents mt-3" [hidden]="templateShareWith === 'office'">

          <div class="form-group">
            <div class="col-sm-12">              
              <small>Select a staff member in your office below to share this template with</small>
            </div>
            <div class="col-sm-12 mt-2">
              <input (keyup)="onSearchUser($event.target.value)" type="text" class="form-control" placeholder="Search agent"/>
            </div>
          </div>

          <div class="template-agent-list mt-3">            
            <div *ngFor="let user of templateShareUsers" class="template-agent-selection"
              [hidden]="!user.isVisible"
              (click)="user.isSelected = !user.isSelected">
              <div class="form-check">
                <input [checked]="user.isSelected" class="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate">
              </div>
              <div>{{user.name}}</div>
            </div>
          </div>
        </div>

        <div class="form-group" *ngIf="false">
          <label for="template-share-with" class="col-sm-4 mt-3" style="color: #37474f !important">Visible in VaultRE/EA</label>
          <div class="col-sm-12">
            <select class="form-select mt-2" [(ngModel)]="templateVisibleInVault" 
              [ngModelOptions]="{ standalone: true }">
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
        </div>
      
      </div>
      <!-- END OF TAB CONTENT -->
      </form>

      <div class="modal-footer justify-content-between">
        <div>
          <button type="button" class="btn-admin me-2" data-bs-dismiss="modal">Cancel</button>
          <button [hidden]="saveAsTemplateState.selectedTab !== 'sharing-tab'" type="button" class="btn btn-link" (click)="removeAllSharing()">Remove all sharing</button>
        </div>
        <button form="saveAsTemplateForm" [disabled]="!customCategoryForm.valid" type="submit" class="btn-admin btn-admin-primary me-2">Save</button>
      </div>

    </div>

  </div>
</div>
<!-- // End of Save As Template -->

<div #saveToCategory id="saveToCategory" class="modal fade edit-box" role="dialog" aria-labelledby="saveToCategoryLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #saveToCategoryModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="saveToCategoryLabel">Save as Template</h4>
      </div>
      <form class="form-horizontal">
        <div class="modal-body">
          <div class="form-group d-flex">
            <label for="saveToCategoryName" class="col-sm-4">Category<i class="text-danger">*</i></label>
            <div class="col-sm-8">
              <select  class="form-control" (change)="onSelectedCustomCategories(selectCustomCategory.value)">
                <option *ngIf="showReferenceOption('emails')" value="emails">Emails</option>
                <option *ngIf="showReferenceOption('printables')" value="printables">{{ quickDesignService.quickdesignCategoryLabels.printables }}</option>
                <option *ngIf="showReferenceOption('socialmedia')" value="socialmedia">Social Media</option>
                <option *ngIf="showReferenceOption('signboards')" value="signboards">Signboards</option>
                <option *ngIf="showReferenceOption('pricefinder')" value="pricefinder">Market Snapshot</option>
                <option *ngFor="let customCategory of customCategories" [value]="customCategory.ID">
                  {{ customCategory?.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group d-flex" [class.hidden]="quickDesignGroups?.length === 0">
            <label for="saveToCategoryName" class="col-sm-4">Folder</label>
            <div class="col-sm-8">
              <select  class="form-control">
                <option value="">Select Folder</option>
                <option *ngFor="let quickDesignGroup of quickDesignGroups" value="{{ quickDesignGroup?.ID }}">
                  {{ quickDesignGroup?.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group d-flex">
            <label for="saveToCategoryIcon" class="col-sm-4">Icon</label>
            <div class="col-sm-8">
              <input type="file" accept="image/*" class="form-control" autocomplete="off" autofocus />
              <p>Maximum file size: 500KB</p>
            </div>
          </div>

          <!-- <div class="form-group">
            <label for="templateTags" class="col-sm-4">Tags</label>            
            <br>
            <div class="col-sm-12">
              <small>Adding templates tags allows you to filter all your templates by specific keywords</small>
            </div>            
            <div class="col-sm-12 mt-2">
              <input id="templateTags" class="form-control" type="text" placeholder="Press enter to create multiple tags"/>
            </div>
          </div> -->

          <div class="form-group">
            <label for="saveToCategoryDescription" class="col-sm-4">Description</label>
            <div class="col-sm-12">
              <textarea name="" id="saveToCategoryDescription" rows="30" class="form-control"></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-start">
          <button type="submit" [disabled]="!customCategoryForm.valid" class="btn-admin btn-admin-primary me-2">Save</button>
          <button type="button" class="btn-admin" data-bs-dismiss="modal">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Cropping Modal -->
<div class="modal fade" id="cropImageModal" tabindex="-1" aria-labelledby="cropImageModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-fullscreen">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title" id="cropImageModalLabel">Crop Image</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
              <div class="d-flex justify-content-center gap-3">
                  <button class="btn-admin btn-admin-primary" type="button"
                      (click)="cropChangeAspectRatio(0)">Free</button>
                  <button class="btn-admin btn-admin-primary" type="button"
                      (click)="cropChangeAspectRatio(3/2)">3:2</button>
                  <button class="btn-admin btn-admin-primary" type="button"
                      (click)="cropChangeAspectRatio(4/3)">4:3</button>
                  <button class="btn-admin btn-admin-primary" type="button"
                      (click)="cropChangeAspectRatio(5/4)">5:4</button>
                  <button class="btn-admin btn-admin-primary" type="button"
                      (click)="cropChangeAspectRatio(7/5)">7:5</button>
                  <button class="btn-admin btn-admin-primary" type="button"
                      (click)="cropChangeAspectRatio(16/9)">16:9</button>
              </div>
              <div class="d-flex justify-content-center gap-3 mt-3">
                <button class="btn-admin btn-admin-primary" type="button"
                (click)="cropRotateCanvas(45)">Rotate 45°</button>
                <button class="btn-admin btn-admin-primary" type="button"
                (click)="cropRotateCanvas(-45)">Rotate -45°</button>
                <button class="btn-admin btn-admin-primary" type="button"
                    (click)="cropFlipImage(true, false)">Flip Horizontal</button>
                <button class="btn-admin btn-admin-primary" type="button"
                    (click)="cropFlipImage(false, true)">Flip Vertical</button>
              </div>
              <hr />
              <image-cropper [imageURL]="cropImageUrl" 
                [aspectRatio]="defaultAspectRatio" 
                [maintainAspectRatio]="maintainAspectRatio"
                [canvasRotation]="cropperCanvasRotation"
                [transform]="cropperImageTransform"
                  (imageCropped)="imageCropped($event)" format="jpeg"></image-cropper>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancel</button>
              <button type="button" class="btn-admin btn-admin-primary" (click)="onUploadCropped()"
                  data-bs-dismiss="modal">Save</button>
          </div>
      </div>
  </div>
</div>


<!-- SHARE TEMPLATE Modal -->
<div id="shareTemplateModal" class="modal fade edit-box" role="dialog" aria-labelledby="shareTemplateModalLabel">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      
      <div class="modal-header">
        <button type="button" class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="shareTemplateModalLabel"><strong>Share Template</strong></h4>
      </div>

      <div class="modal-body">
        <div class="form-group">
          <label for="template-share-with" class="col-sm-4">Share With</label>
          <div class="col-sm-12">
            <select class="form-select mt-2" [(ngModel)]="templateShareWith">
              <option value="agent">Agent</option>
              <option value="office">Office</option>
            </select>
          </div>
        </div>

        <div class="template-share-agents mt-3" [hidden]="templateShareWith === 'office'">

          <div class="form-group">
            <div class="col-sm-12">              
              <small>Select a staff member in your office below to share this template with</small>
            </div>
            <div class="col-sm-12 mt-2">
              <input (keyup)="onSearchUser($event.target.value)" type="text" class="form-control" placeholder="Search agent"/>
            </div>
          </div>

          <div class="template-agent-list mt-3">            
            <div *ngFor="let user of templateShareUsers" class="template-agent-selection"
              [hidden]="!user.isVisible"
              (click)="user.isSelected = !user.isSelected">
              <div class="form-check">
                <input [checked]="user.isSelected" class="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate">
              </div>
              <div>{{user.name}}</div>
            </div>
          </div>
          
        </div>

      </div>

      <div class="modal-footer justify-content-between">
        <div>          
        <button type="button" class="btn-admin me-2" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-link" data-bs-dismiss="modal" (click)="unshare()">Remove all sharing</button>
        </div>
        <button (click)="shareQuickDesignTemplate()" type="button" class="btn-admin btn-admin-primary">Save</button>
      </div>

    </div>
  </div>
</div>
<div class="modal fade" id="sendEmailModal" tabindex="-1" aria-labelledby="sendEmailModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Send to MRI Vault</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="col">
          <label *ngIf="isVaultTemplate" class="pb-4">This template has already been sent to MRI Vault</label>
        </div>
        <div class="col">
          <label class="pb-3" style="font-size: 14px;">Feel free to share the template below if you'd like others to use it in MRI Vault.</label>
        </div>
        <div class="col">
          <label>Share template with</label>
          <ng-select 
            [items]="vaultShareUsers" 
            [searchable]="true"
            [disabled]="shareWithEveryone"
            [clearable]="false"
            [clearSearchOnAdd]="true"
            [closeOnSelect]="false"
            [multiple]="true" 
            [(ngModel)]="selectedStaffMembers" 
            [ngModelOptions]="{standalone: true}"
            bindLabel="name" 
            bindValue="agent_id" 
            placeholder="Select staff members">
          </ng-select>
        </div>
        <div class="d-flex col pt-2">
          <div class="share-everyone-button" (click)="onShareWithEveryone(!shareWithEveryone ? true : false)">
            <input
              type="checkbox"
              [(ngModel)]="shareWithEveryone"
            />
            <label class="share-label">Share template with everyone</label>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-start">
        <button
          type="button"
          class="btn-admin btn-admin-primary me-2"
          (click)="onShareStaffMembers(selectedStaffMembers)"
        >
          {{ isVaultTemplate ? 'Update' : 'Send' }}
        </button>
        <button type="button" class="btn-admin" data-bs-dismiss="modal">Cancel</button>
        <label *ngIf="isVaultTemplate" class="remove-template ms-auto" (click)="onRemoveVaultTemplate(template?.ID, template?.vault_template_id)"
          >Remove template</label
        >
      </div>
    </div>
  </div>
</div>

<!-- <div class="global-setting">
  <button class="btn"><i class="fa fa-cog"></i></button>
</div> -->
<ngx-loading class="main-loader" [show]="loading"></ngx-loading>
<app-progress-loader></app-progress-loader>
