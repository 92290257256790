<div class="row">
    <div class="col-md-12 heading">
        <h2 class="pull-left">{{product | titlecase}} Elements</h2>

        <button routerLink="new" type="button" class="btn-admin">
            <i class="icon feather icon-plus"></i><span>Add New {{product | titlecase}} Element</span>
        </button>
    </div>
</div>
<div id="box" class="row">
    <div class="col-md-12">
        <div class="d-flex">
            <div class="w-25">
                <label for="selectBlockId" class="form-label">Block Filter</label>
                <select #selectBlockId class="form-control" (change)="onChangeBlockFilter(selectBlockId.value)">
                    <option value="">All</option>
                    <option *ngFor="let proBlock of proBlocks" [value]="proBlock?.UID">{{proBlock?.title}}</option>
                </select>
            </div>
            <div class="w-25">
                <label for="selectGroup" class="form-label">Groups</label>
                <select #selectGroupId class="form-control" (change)="onChangeGroupFilter(selectGroupId.value)">
                    <option value="">All</option>
                    <option *ngFor="let group of groups" [value]="group?.ID">{{ group?.title }}</option>
                </select>
            </div>
        </div>
        <div class="table-box">
            <app-admin-table-component [rowData]="proElements">
                <thead>
                    <tr>
                        <th>Block</th>
                        <th>Themes</th>
                        <th>Groups</th>
                        <th>Excluded Groups</th>
                        <th>Clients</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Sort</th>
                        <th>Last Updated</th>
                        <th class="text-end">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let proElement of proElements">
                        <td><strong>{{proElement?.pro_block?.title}}</strong></td>
                        <td>
                            <span *ngIf="proElement?.pro_theme_whitelist === 'all'">ALL</span>
                            <ng-container *ngIf="proElement?.pro_theme_whitelist === 'selected'">
                                <ng-container *ngFor="let proTheme of proElement?.pro_themes">
                                <span class="badge badge-primary">{{proTheme?.title}}</span><br />
                                </ng-container>
                            </ng-container>
                        </td>
                        <td>
                            <span *ngIf="proElement?.whitelist === 'all'">ALL</span>
                            <ng-container *ngIf="proElement?.whitelist === 'selected'">
                                <ng-container *ngFor="let group of proElement?.groups">
                                <span class="badge badge-primary">{{group?.title}}</span><br />
                                </ng-container>
                            </ng-container>
                        </td>
                        <td>
                            <span *ngIf="!proElement?.excluded_groups?.length">None</span>
                            <ng-container *ngIf="proElement?.excluded_groups?.length">
                                <ng-container *ngFor="let group of proElement?.excluded_groups">
                                <span class="badge badge-primary">{{group?.title}}</span><br />
                                </ng-container>
                            </ng-container>
                        </td>
                        <td>
                            <span *ngIf="proElement?.client_whitelist === 'all'">ALL</span>
                            <ng-container *ngIf="proElement?.client_whitelist === 'selected'">
                                <ng-container *ngFor="let client of proElement?.clients">
                                <span class="badge badge-primary">{{client?.name}}</span><br />
                                </ng-container>
                            </ng-container>
                        </td>
                        <td>{{proElement?.title}}</td>
                        <td>{{proElement?.description}}</td>
                        <td>{{proElement?.sort}}</td>
                        <td>{{proElement?.modified | date:'medium'}}</td>
                        <td class="text-end">
                            <button (click)="editElement(proElement?.UID)" class="btn-table" title="Edit"><i class="icon feather icon-edit"></i></button>
                            <button (click)="duplicateElement(proElement?.UID)" class="btn-table" title="Duplicate"><i class="icon feather icon-copy"></i></button>
                            <button (click)="deleteElement(proElement)" class="btn-table" title="Delete"><i class="icon feather icon-trash"></i></button>
                        </td>
                    </tr>
                </tbody>
            </app-admin-table-component>
        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>