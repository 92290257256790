import { Injectable, Inject } from '@angular/core';
import { HttpParams,  HttpClient,   HttpRequest } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';

import 'rxjs/Rx';

import { Subgroup } from '../models/subgroup.model';
import { APP_CONFIG } from '../app.config';

export class SubgroupService {
  subgroupGetAll = new Subject<Subgroup[]>();
  subgroupGet = new Subject<Subgroup>();
  subgroupPost = new Subject<Subgroup>();
  subgroupPut = new Subject<Subgroup>();
  subgroupDelete = new Subject<Subgroup>();

  private subgroups: Subgroup[] = [];

  // New Subscriptions
  onGetSubGroups = new Subject();

  constructor(@Inject(APP_CONFIG) private appConfig, private httpClient: HttpClient) {}

  httpGetSubGroupsNew(params: any = {}) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    return this.httpClient.get(`${this.appConfig.NEW_API_ENDPOINT}/subgroups`, {
      params: httpParams
    })
    .subscribe(
      (response: any) => {
        this.onGetSubGroups.next(response);
      },
      (response: any) => {
        this.onGetSubGroups.next(response.error);
      }
    );
  }

  httpGetSubgroups(params: any = []) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.NEW_API_ENDPOINT + '/subgroups',
      {
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.subgroupGetAll.next(response);
      },
      (response: any) => {
        this.subgroupGetAll.next(response.error);
      }
    );
  }

  httpGetSubgroup(id: number) {
    const req = new HttpRequest(
      'GET',
      this.appConfig.NEW_API_ENDPOINT + '/subgroups/' + id,
    );

    return this.httpClient.request(
      req
    )
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.subgroupGet.next(response);
      },
      (response: any) => {
        this.subgroupGet.next(response.error);
      }
    );
  }

  httpPostSubgroup(data: Subgroup) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.NEW_API_ENDPOINT + '/subgroups',
      data
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.subgroupPost.next(response);
      },
      (response: any) => {
        this.subgroupPost.next(response.error);
      }
    );
  }

  httpPutSubgroup(id: number, data: any) {
    const req = new HttpRequest(
      'PUT',
      this.appConfig.NEW_API_ENDPOINT + '/subgroups/' + id,
      data
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.subgroupPut.next(response);
      },
      (response: any) => {
        this.subgroupPut.next(response.error);
      }
    );
  }

  httpDeleteSubgroup(id: number) {
    const req = new HttpRequest(
      'DELETE',
      this.appConfig.NEW_API_ENDPOINT + '/subgroups/' + id
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.subgroupDelete.next(response);
      },
      (response: any) => {
        this.subgroupDelete.next(response.error);
      }
    );
  }

}
