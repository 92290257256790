<div class="row height-100">
  <div #sidebarBox class="col-xs-6 col-sm-6 col-md-6 height-100">
    <div id="sidebar-box" class="height-100 sidebar-admin-box">
      <div class="header">
        <a *ngIf="authUser.brandid !== '6' && adminService.authService.isRaywhite() === 'no' && adminService.authService.isSignifi() === 'no'" routerLink="/templates">
          <img src="/assets/img/designly-logo.svg" class="logo" />
        </a>
        <a *ngIf="authUser.brandid !== '6' && adminService.authService.isRaywhite() === 'no' && adminService.authService.isSignifi() === 'yes'" routerLink="/templates">
          <img src="/assets/img/signify_design_logo.svg" class="logo signifi-logo" />
        </a>
        <a *ngIf="authUser.brandid === '6'" routerLink="/templates">
          <img src="/assets/img/rh_designplus_icon.svg" class="logo" />
        </a>
        <a *ngIf="adminService.authService.isRaywhite() === 'yes'" routerLink="/templates">
          <img src="/assets/img/rw-logo.svg" class="logo" />
        </a>
      </div>
      <div class="heading">ADMIN</div>
      <div class="menu">
        <ul>
          <li>
            <a routerLinkActive="active" routerLink="clients"> <i class="icon feather icon-user"></i><span class="hidden-sm hidden-xs">Clients</span> </a>
          </li>
          <li>
            <a routerLinkActive="active" routerLink="pro-analytics"> <i class="icon feather icon-activity"></i><span class="hidden-sm hidden-xs">Pro Analytics</span> </a>
          </li>
          <li>
            <a routerLinkActive="active" routerLink="pros"> <i class="icon feather icon-aperture"></i><span class="hidden-sm hidden-xs">Designly Pro</span></a>
          </li>
          <li>
            <a routerLinkActive="active" routerLink="templates/elements/enewsletter"> <i class="icon feather icon-file"></i><span class="hidden-sm hidden-xs">Templates</span> </a>
          </li>
          <li>
            <a routerLinkActive="active" routerLink="groups"> <i class="icon feather icon-users"></i><span class="hidden-sm hidden-xs">Groups</span> </a>
          </li>
          <li>
            <a routerLinkActive="active" routerLink="subgroups"> <i class="icon feather icon-user-plus"></i><span class="hidden-sm hidden-xs">Client Subgroups</span> </a>
          </li>
          <li>
            <a routerLinkActive="active" routerLink="groupsubgroups"> <i class="icon feather icon-user-plus"></i><span class="hidden-sm hidden-xs">Subgroups</span> </a>
          </li>
          <li>
            <a routerLinkActive="active" routerLink="help/training-videos"> <i class="icon feather icon-message-square"></i><span class="hidden-sm hidden-xs">Help Center</span> </a>
          </li>
          <li>
            <a routerLinkActive="active" routerLink="analytics"> <i class="icon feather icon-activity"></i><span class="hidden-sm hidden-xs">Analytics</span> </a>
          </li>
          <li>
            <a routerLinkActive="active" routerLink="styles"> <i class="icon feather icon-feather"></i><span class="hidden-sm hidden-xs">Styles</span> </a>
          </li>
          <li>
            <a routerLinkActive="active" routerLink="icons"> <i class="icon feather icon-shield"></i><span class="hidden-sm hidden-xs">Icons</span> </a>
          </li>
          <li>
            <a routerLinkActive="active" routerLink="websites"> <i class="icon feather icon-layers"></i><span class="hidden-sm hidden-xs">Websites</span> </a>
          </li>
          <li>
            <a routerLinkActive="active" routerLink="settings"> <i class="icon feather icon-settings"></i><span class="hidden-sm hidden-xs">Settings</span> </a>
          </li>
          <li class="intro-side-power">
            <a href="javascript:void(0)" (click)="onLogout()"> <i class="icon feather icon-power"></i><span class="hidden-sm hidden-xs">Logout</span> </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div #sidebarSubBox class="height-100">
    <div id="sidebar-sub-box" class="height-100">
      <div id="malihusb" class="height-100">
        <ul class="menu-sub-list">
          <li *ngFor="let menu of menus">
            <a [ngClass]="{ 'no-link': menu.subs && menu.url === '' }">{{ menu.name }}</a>
            <ul *ngIf="menu?.subs">
              <li *ngFor="let sub of menu.subs">
                <a id="menu-{{ slugLink(sub.url) }}" routerLinkActive="active" [routerLink]="[sub.url]">{{ sub.name }}</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
