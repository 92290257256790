import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AdminService } from '../../../../admin.service';
import { VideoService } from '../../../../../services/video.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Menu } from '../../../../../models/menu.model';
import { Video } from '../../../../../models/video.model';
import swal from 'sweetalert2';
import { VideoItem } from '../../../../../models/videoitem.model';
import { GroupService } from '../../../../../services/group.service';
import { Group } from '../../../../../models/group.model';

@Component({
  selector: 'app-training-videos-videos-edit',
  templateUrl: './training-videos-videos-edit.component.html',
  styleUrls: ['./training-videos-videos-edit.component.css']
})
export class TrainingVideosVideosEditComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  editMode = false;
  loading = true;
  menus: Menu[] = [];
  navigationType = 'link';
  navigationDefault = 'training-videos';

  video: Video;
  videoItem: VideoItem;
  videoItemForm: FormGroup;
  groups: Group[];

  constructor(
    private adminService: AdminService,
    private videoService: VideoService,
    private groupService: GroupService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.groupService.httpGetGroupsNew();

    this.menus = [
      new Menu('Training', '', [
        new Menu('Training Videos', 'help/training-videos'),
      ]),
      new Menu('Changelogs', '', [
        new Menu('Changelogs', 'help/changelogs')
      ])
    ];
    this.adminService.loadMenu(this.menus);

    this.adminService.routeData.next({
      'route': null,
      default: this.navigationDefault,
      type: this.navigationType
    });

    this.groupService.onGetGroups
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;

            if (response.status === 'success') {
              this.groups = response.data;

              const videoId = this.route.snapshot.paramMap.get('id');
              if (videoId) {
                this.loading = true;
                this.videoService.httpGetVideo(videoId);
              }
            }
            if (response.status === 'error') {
              swal('Internal Server Error', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.videoService.onGetVideo
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;

            if (response.status === 'success') {
              this.video = response.data;

              const videoItemId = this.route.snapshot.paramMap.get('vid');

              if (videoItemId) {
                this.editMode = true;
                this.videoService.httpGetVideoItem(this.video.UID, videoItemId);
              }
            }

            if (response.status === 'error') {
              swal('Internal Server Error', 'Contact Dev Team', 'error');
              return this.router.navigateByUrl('/admin/help/training-videos');
            }
          }
        }
      }
    );

    this.videoService.onPostVideoItem
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;

            if (response.status === 'success') {
              swal('Video Item added', '', 'success');
              this.router.navigateByUrl(`/admin/help/training-videos/${this.video.UID}/videos`);
            }

            if (response.status === 'error') {
              swal('Internal Server Error', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.videoService.onGetVideoItem
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;

            if (response.status === 'success') {
              this.videoItem = response.data;

              this.initFormEdit();
            }

            if (response.status === 'error') {
              swal('Internal Server Error', 'Contact Dev Team', 'error');
              return this.router.navigateByUrl('/admin/help/training-videos');
            }
          }
        }
      }
    );

    this.videoService.onUpdateVideoItem
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;

            if (response.status === 'success') {
              swal('Video Item updated', '', 'success');
            }

            if (response.status === 'error') {
              swal('Internal Server Error', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );

    this.initForm();
  }

  initForm() {
    this.videoItemForm = new FormGroup({
      group_id: new FormControl([], Validators.required),
      exclude_group_id: new FormControl([]),
      title: new FormControl('', Validators.required),
      url: new FormControl('', Validators.required),
      sort: new FormControl(0, Validators.required)
    });
  }

  initFormEdit() {
    this.videoItemForm.patchValue({
      title: this.videoItem.title,
      url: this.videoItem.url,
      sort: this.videoItem.sort
    });

    if (this.videoItem.group_whitelist === 'all') {
      this.videoItemForm.patchValue({
        group_id: ['all']
      });
    } else {
      const groupUids = this.videoItem.groups.map((group: any) => group.group.UID);

      this.videoItemForm.patchValue({
        group_id: groupUids
      });
    }

    if (this.videoItem.excluded_groups) {
      const groupUids = this.videoItem.excluded_groups.map((group: any) => group.group.UID);

      this.videoItemForm.patchValue({
        exclude_group_id: groupUids
      });
    }
  }

  onSubmit() {
    const videoItemFormValues = this.videoItemForm.value;

    if (this.videoItemForm.valid) {
      if (!this.editMode) {
        this.videoService.httpPostVideoItem(this.video.UID, videoItemFormValues);
      } else {
        this.videoService.httpPutVideoItem(this.video.UID, this.videoItem.UID, videoItemFormValues);
      }
    }

    this.loading = true;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
