import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { ProTheme } from '../../../../models/pro-theme.model';
import { ProThemeService } from '../../../../services/pro-theme.service';

import swal from 'sweetalert2';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-admin-pros-themes-dashboard',
  templateUrl: './admin-pros-themes-dashboard.component.html',
  styleUrls: ['./admin-pros-themes-dashboard.component.scss'],
})
export class AdminProsThemesDashboardComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = false;

  proThemes: ProTheme[] = [];
  product: string;

  constructor(private proThemeService: ProThemeService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.showLoading(true);
    this.callThemes();
    this.loadThemes();
    this.onDeleteThemes();
  }

  private callThemes(): void {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params: ParamMap) => {
      this.product = params.get('product');

      if (this.product) {
        this.proThemeService.httpGetAll({
          product: this.product,
        });
      }
    });
  }

  private loadThemes(): void {
    this.proThemeService.onGetAll.pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response) {
        this.showLoading(false);
        if (response.status === 'success') {
          this.proThemes = response.data;
        }

        if (response.status === 'error') {
          this.handleError('Error getting Themes.');
        }
      }
    });
  }

  private onDeleteThemes(): void {
    this.proThemeService.onDelete.pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response) {
        this.showLoading(false);

        if (response.status === 'success') {
          this.callThemes();
          swal('Deleted!', response.message, 'success');
        } else {
          this.handleError('Error getting Groups');
        }
      }
    });
  }

  deleteTheme(proTheme: ProTheme): void {
    swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    })
      .then((result) => {
        if (result) {
          this.showLoading(true);
          this.proThemeService.httpDelete(proTheme.UID);
        }
      })
      .catch(swal.noop);
  }

  private showLoading(value: boolean): void {
    this.loading = value;
  }

  private handleError(message: string): void {
    this.showLoading(false);
    swal(message, 'Contact Dev Team', 'error');
  }

  editTheme(uid: string): void {
    this.router.navigateByUrl('/admin/pros/themes/' + this.product + '/' + uid + '/edit');
  }

  duplicateTheme(uid: string): void {
    this.router.navigateByUrl('/admin/pros/themes/' + this.product + '/' + uid + '/edit/duplicate');
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
