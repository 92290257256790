import { Component, OnInit, OnDestroy } from '@angular/core';
import { CampaignService } from '../../../services/campaign.service';
import { Subject } from 'rxjs';
import { FrontService } from '../../front.service';
import { ElementService } from '../../../services/element.service';

import * as moment from 'moment';
import { TemplateService } from '../../../services/template.service';
import { ProgressService } from '../../../shared/progress-loader/progress.service';
import swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-campaign-generate',
  templateUrl: './campaign-generate.component.html',
  styleUrls: ['./campaign-generate.component.scss']
})
export class CampaignGenerateComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  templates = [];

  authUser: any;
  clientSetting: any;
  groupSetting: any;

  elementListingHtml: any[] = [];

  currentTemplate = 0;
  savedTemplate = 0;
  currentTemplateIndex = 0;
  failedTemplates = 0;

  constructor(
    private frontService: FrontService,
    private campaignService: CampaignService,
    private elementService: ElementService,
    private templateService: TemplateService,
    private progressService: ProgressService
  ) { }

  ngOnInit() {
    this.authUser = this.frontService.authService.auth;
    this.clientSetting = this.frontService.authService.auth.client;
    this.groupSetting = this.clientSetting.group.master;

    this.elementService.elementListingHtml
    .takeUntil(this.destroy$)
    .subscribe(
      (htmls: any) => {
        if ( typeof htmls !== 'undefined' && htmls.status === 'success' ) {
          this.elementListingHtml = htmls.data;
        }
      }
    );

    this.campaignService.campaignGenerateTemplates
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.templates = response.data;
          this.currentTemplate = 1;

          this.fillTemplate(this.templates[0]);

          this.progressService.initProgress({
            type: 'campaign'
          });
        }
        if (typeof response !== 'undefined' && response.status === 'empty') {
          this.campaignService.campaignOnGenerateFinish.next(false);
          $('#generateCampaign').modal('hide');
        }
      }
    );

    this.templateService.templateBuild
    .takeUntil(this.destroy$)
    .subscribe(
      (template: any) => {
        if ( typeof template.ID !== 'undefined' ) {
          this.savedTemplate += 1;
          this.progressService.progress(this.currentTemplate / this.templates.length);

          if (this.currentTemplate === this.templates.length) {
            this.progressService.hideProgress();
            this.currentTemplate = 0;
            this.savedTemplate = 0;

            if (this.failedTemplates > 0) {
              swal(
                `${this.failedTemplates} templates failed to generate.`,
                'Please wait while we redirect you.',
                'warning'
              );
            }
  
            setTimeout(() => {
              this.campaignService.campaignOnGenerateFinish.next(true);
            }, 1500);
          }

          if (this.currentTemplate !== this.templates.length) {
            this.currentTemplateIndex += 1;
            this.currentTemplate += 1;

            this.fillTemplate(this.templates[this.currentTemplateIndex]);
          }
        }
      }
    );

    this.templateService.templateBuildError
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        this.failedTemplates += 1;

        this.progressService.progress(this.currentTemplate / this.templates.length);

        if (this.currentTemplate === this.templates.length) {
          this.progressService.hideProgress();

          this.currentTemplate = 0;
          this.savedTemplate = 0;

          if (this.failedTemplates > 0) {
            swal(
              `${this.failedTemplates} templates failed to generate.`,
              'Please wait while we redirect you.',
              'warning'
            );
          }

          setTimeout(() => {
            this.campaignService.campaignOnGenerateFinish.next(true);
          }, 1500);
        }

        this.currentTemplateIndex += 1;
        this.currentTemplate += 1;

        this.fillTemplate(this.templates[this.currentTemplateIndex]);
      }
    );
  }

  loadTemplates() {
    let timeout = 2000;
    for (const template of this.templates) {
      setTimeout(() => {
        this.currentTemplate += 1;
        this.fillTemplate(template);
      }, timeout);

      timeout += 2000;
    }
  }

  fillTemplate(template) {
    const external_css_list = (template.style.external_css).split('\n');
    let extraFont = '';
    const external_font_list = (template.style.external_font).split('\n');
    for (const external_font of external_font_list) {
      if (external_font) {
        extraFont += `<link rel="stylesheet" href="${external_font}">`;
      }
    }
    let extraCss = '';
    for (const external_css of external_css_list) {
      if (external_css) {
        extraCss += `<link rel="stylesheet" type="text/css" href="${external_css}">`;
      }
    }
    const header = `
      ${extraFont}
      ${extraCss}
    `;
    const html_raw = `
      ${template.html_raw}
    `;

    const formatted_html = <any>{
      html_raw: this.fillTest(template, [], template.html_raw, header),
      isCampaign: 1
    };

    this.templateService.httpBuildTemplate(template.ID, formatted_html);
  }

  fillTest(template, listing, html, header) {
    try {
      $('#test-preview').html('');
      $('#test-preview').append(html);
      listing = this.campaignService.listing;
      const $this = this;
      const elementBlock = $('#test-preview');

        if (listing.images !== null && listing.images.length > 0) {
          const imgEl = elementBlock.find('.md-prop-field-image')
            .attr('src', listing.images[0].url);
          const imgElHighRes = elementBlock.find('.md-prop-field-hiresimage')
            .attr('src', listing.images[0].hiresurl);

          if (template.category_id !== 7 &&
            template.category_id !== 1 &&
            template.category_id !== 2 &&
            template.category_id !== 3) {
              if ( typeof imgEl !== 'undefined' ) {
                imgEl.css({top: 0, left: 0});
              }

              if ( typeof imgElHighRes !== 'undefined' ) {
                imgElHighRes.css({top: 0, left: 0});
              }
          }
          // const imgParentEl = imgEl.parent();

          for ( let i = 0; i < (listing.images).length; i++ ) {
            const idx = i + 1;

            const imgElSub = elementBlock.find('.md-prop-field-image-' + idx)
              .attr('src', listing.images[i].url);
            const imgElSubHighRes = elementBlock.find('.md-prop-field-hiresimage-' + idx)
              .attr('src', listing.images[i].hiresurl);

            if (template.category_id !== 7 &&
              template.category_id !== 1 &&
              template.category_id !== 2 &&
              template.category_id !== 3) {
                if ( typeof imgElSub !== 'undefined' ) {
                  imgElSub.css({top: 0, left: 0});
                }

                if ( typeof imgElSubHighRes !== 'undefined' ) {
                  imgElSubHighRes.css({top: 0, left: 0});
                }
            }
          }
        }

        const listing_floorplans = ( listing.floorplans ) ? listing.floorplans : '';
        elementBlock.find('.md-prop-field-imagefloorplan-sml')
          .attr('src', listing_floorplans).removeAttr('style');
        elementBlock.find('.md-prop-field-imagefloorplan-lrg')
          .attr('src', listing_floorplans).removeAttr('style');

        if ( this.frontService.authService.getApp() === 'MyDesign3' ) {
          if ( elementBlock.find('[class*="md-prop-field-bulletpoint-"]').length > 0 ) {
            for ( let i = 0; i < elementBlock.find('[class*="md-prop-field-bulletpoint-"]').length; i++ ) {
              const bullet_number = i + 1;

              if ( typeof listing.bulletpoints[i] !== 'undefined' ) {
                elementBlock.find('.md-prop-field-bulletpoint-' + bullet_number).html(listing.bulletpoints[i].item);
              } else {
                elementBlock.find('.md-prop-field-bulletpoint-' + bullet_number).html('');
              }
            }
          }
        }

        elementBlock.find('.md-prop-field-auctiondate-title').html('');
        if (listing.auctiondate != null) {
          elementBlock.find('.md-prop-field-listing-type').html('For Auction');
          elementBlock.find('.md-prop-field-status').html('For Auction');
          elementBlock.find('.md-prop-field-rh-status').html('Guide');

          elementBlock.find('.md-prop-field-auctiondate-title').html('<b>Auction</b> ' + listing.auctiondate);
        } else {
          if (listing.listing_type === 'sale') {
            elementBlock.find('.md-prop-field-listing-type').html('For Sale');
            elementBlock.find('.md-prop-field-status').html('For Sale');
            elementBlock.find('.md-prop-field-rh-status').html('Guide');
          }
          if (listing.listing_type === 'rent') {
            elementBlock.find('.md-prop-field-listing-type').html('For Rent');
            elementBlock.find('.md-prop-field-status').html('For Rent');
            elementBlock.find('.md-prop-field-rh-status').html('Lease');
          }
          if (listing.listing_type === 'rent' && listing.listing_category === 'commercial') {
            elementBlock.find('.md-prop-field-listing-type').html('For Lease');
            elementBlock.find('.md-prop-field-status').html('For Lease');
            elementBlock.find('.md-prop-field-rh-status').html('Lease');
          }
          if (listing.listing_type === 'rent') {
            elementBlock.find('.md-prop-field-listing-type').html('For Rent');
            elementBlock.find('.md-prop-field-status').html('For Rent');
            elementBlock.find('.md-prop-field-rh-status').html('Lease');
          }
          if (listing.listing_type === 'rent' &&  ( listing.status === 'settled' || listing.status === 'leased' ) ) {
            elementBlock.find('.md-prop-field-status').html('Leased');
            elementBlock.find('.md-prop-field-rh-status').html('Leased');
          }
          if (listing.listing_type === 'sale' && listing.status === 'settled') {
            elementBlock.find('.md-prop-field-status').html('Sold');
            elementBlock.find('.md-prop-field-rh-status').html('Sold');
          }
          if (listing.listing_category === 'holidayRental') {
            elementBlock.find('.md-prop-field-status').html('Holiday Rental');
          }
        }

        elementBlock.find('.md-prop-field-heading').html(listing.heading);
        elementBlock.find('.md-prop-field-beds').html(listing.beds);
        elementBlock.find('.md-prop-field-baths').html(listing.baths);
        elementBlock.find('.md-prop-field-cars').html(listing.cars);
        elementBlock.find('.md-prop-field-open-parking').html(listing.openparkingspaces);
        elementBlock.find('.md-prop-field-price').html(listing.price);
        if ( listing.sales && typeof listing.sales.saleprice !== 'undefined' && listing.sales.saleprice ) {
          elementBlock.find('.md-prop-field-price').html(listing.sales.saleprice);
        }

        if ( this.frontService.authService.getApp() === 'Designly' ) {
          elementBlock.find('[href*="rh.com.au"]').each(function() {
            if ( $this.authUser.client.group_id === 12 ) {
              let propertyUrl = 'https://www.raineandhorne.com.au/';

              if ( listing.saleLifeId !== null ) {
                propertyUrl = `${propertyUrl}L${listing.saleLifeId}`;
              }

              if ( listing.leaseLifeId !== null ) {
                propertyUrl = `${propertyUrl}R${listing.leaseLifeId}`;
              }

              $(this).attr('href', propertyUrl);
            }
          });
        }

        let address_unitnum = '';

        if ( listing.address_unitnum !== null ) {
          address_unitnum = listing.address_unitnum + '/';
          elementBlock.find('.md-prop-field-unitnum').html(listing.address_unitnum);
        }

        elementBlock.find('.md-prop-field-address').html(listing.displayaddress);
        if ( listing.address_streetnum !== null ) {
          elementBlock.find('.md-prop-field-streetnum').html(address_unitnum + listing.address_streetnum);
        }
        if ( listing.address_street !== null ) {
          elementBlock.find('.md-prop-field-street').html(listing.address_street);
        }
        if ( listing.address_suburb !== null ) {
          elementBlock.find('.md-prop-field-suburb').html(listing.address_suburb);
        }
        if ( listing.address_state !== null ) {
          elementBlock.find('.md-prop-field-state').html(listing.address_state);
        }

        elementBlock.find('.md-prop-field-landarea').html(listing.landarea);
        if ( typeof listing.landarea !== 'undefined' && listing.landarea !== 0 && listing.landarea !== null ) {
          elementBlock.find('.md-prop-field-landareatype').html(listing.landareatype);
          elementBlock.find('.md-prop-field-landarea-title').html('<b>Land size</b>&nbsp' + listing.landarea + listing.landareatype);
        }

        elementBlock.find('.md-prop-field-description').html(listing.description);
        elementBlock.find('.md-prop-field-description-50').html(listing.description.substring(0, 50));
        elementBlock.find('.md-prop-field-description-100').html(listing.description.substring(0, 100));
        elementBlock.find('.md-prop-field-description-200').html(listing.description.substring(0, 200));
        elementBlock.find('.md-prop-field-description-300').html(listing.description.substring(0, 300));
        elementBlock.find('.md-prop-field-description-400').html(listing.description.substring(0, 400));
        elementBlock.find('.md-prop-field-description-500').html(listing.description.substring(0, 500));
        elementBlock.find('.md-prop-field-description-600').html(listing.description.substring(0, 600));

        const listing_id = ( listing.id ) ? listing.id : ' ';
        elementBlock.find('.md-prop-field-id').html(listing_id);

        const listing_refid = ( listing.refid ) ? listing.refid : ' ';
        elementBlock.find('.md-prop-field-refid').html(listing_refid);

        const listing_propertytype = ( listing.propertytype ) ? listing.propertytype : ' ';
        elementBlock.find('.md-prop-field-proptype').html(listing_propertytype);

        if ( listing.listing_category !== 'land' ) {
          const listing_buildingarea = ( listing.buildingarea ) ? listing.buildingarea  : ' ';
          elementBlock.find('.md-prop-field-buildingarea').html(listing_buildingarea);
        } else {
          elementBlock.find('.md-prop-field-buildingarea').html(' ');
        }

        const listing_landarea = ( listing.landarea ) ? listing.landarea : ' ';
        elementBlock.find('.md-prop-field-landarea').html(listing_landarea);

        const listing_tenderdate = ( listing.tenderdate ) ? listing.tenderdate : ' ';
        elementBlock.find('.md-prop-field-tenderenddate').html(listing_tenderdate);

        let listing_agent1_name = ' ';
        let listing_agent1_imageurl = '';
        let listing_agent1_email = ' ';

        let listing_agent2_name = ' ';
        let listing_agent2_imageurl = '';
        let listing_agent2_email = ' ';

        if ( this.frontService.authService.getApp() === 'MyDesign3' ) {
          if ( typeof listing.rates.water.amount !== 'undefined' && listing.rates.water.amount !== null) {
            elementBlock.find('.md-prop-field-waterrates').html(listing.rates.water.amount);
          }

          if ( typeof listing.rates.strata.amount !== 'undefined' && listing.rates.strata.amount !== null) {
            elementBlock.find('.md-prop-field-stratarates').html(listing.rates.strata.amount);
          }

          // wb-7635 for harcourts other rates field
          if ( typeof listing.rates.strata.amount !== 'undefined' && listing.rates.strata.amount !== null) {
            elementBlock.find('.md-prop-field-stratarates-harcourts').html(listing.rates.strata.amount);
          }

          if ( typeof listing.rates.council.amount !== 'undefined' && listing.rates.council.amount !== null) {
            elementBlock.find('.md-prop-field-councilrate').html(listing.rates.council.amount);
          }

          if (listing.agent1.firstname && listing.agent1.lastname) {
            listing_agent1_name = listing.agent1.firstname + ' ' + listing.agent1.lastname;
          }
          elementBlock.find('.md-prop-field-lname').html(listing_agent1_name);

          let listing_agent1_mobiledisplay = ( listing.agent1.mobiledisplay ) ? listing.agent1.mobiledisplay : ' ';
          listing_agent1_mobiledisplay = listing_agent1_mobiledisplay.replace('+61', '').replace('+64', '').trim();

          if ( listing_agent1_mobiledisplay ) {
            listing_agent1_mobiledisplay = String(listing_agent1_mobiledisplay);
            if ( !listing_agent1_mobiledisplay.startsWith('1800') && listing_agent1_mobiledisplay.charAt(0) !== '0' ) {
              listing_agent1_mobiledisplay = '0' + listing_agent1_mobiledisplay;
            }
          }
          elementBlock.find('.md-prop-field-lmobile').html( listing_agent1_mobiledisplay );

          listing_agent1_email = ( listing.agent1.email ) ? listing.agent1.email : ' ';
          elementBlock.find('.md-prop-field-lemail').html(listing_agent1_email);

          listing_agent1_imageurl = ( listing.agent1.imageurl ) ? listing.agent1.imageurl : '';
          elementBlock.find('.md-prop-field-limage').attr('src', listing_agent1_imageurl).css('display', 'inline');
          if ( !listing_agent1_imageurl ) {
            elementBlock.find('.md-prop-field-limage').css('display', 'none');
          }

          const listing_agent1_telephone = ( listing.agent1.telephone ) ? listing.agent1.telephone : ' ';
          elementBlock.find('.md-prop-field-lphone').html(listing_agent1_telephone);

          if (listing.agent2.firstname  && listing.agent2.lastname) {
            listing_agent2_name = listing.agent2.firstname + ' ' + listing.agent2.lastname;
          }
          elementBlock.find('.md-prop-field-lname2').html(listing_agent2_name);

          let listing_agent2_mobiledisplay = ( listing.agent2.mobiledisplay ) ? listing.agent2.mobiledisplay : ' ';
          listing_agent2_mobiledisplay = listing_agent2_mobiledisplay.replace('+61', '').replace('+64', '').trim();
          if ( listing_agent2_mobiledisplay ) {
            listing_agent2_mobiledisplay = String(listing_agent2_mobiledisplay);
            if ( !listing_agent2_mobiledisplay.startsWith('1800') && listing_agent2_mobiledisplay.charAt(0) !== '0' ) {
              listing_agent2_mobiledisplay = '0' + listing_agent2_mobiledisplay;
            }
          }
          elementBlock.find('.md-prop-field-lmobile2').html( listing_agent2_mobiledisplay );

          listing_agent2_email = ( listing.agent2.email ) ? listing.agent2.email : ' ';
          elementBlock.find('.md-prop-field-lemail2').html(listing_agent2_email);

          listing_agent2_imageurl = ( listing.agent2.imageurl ) ? listing.agent2.imageurl : '';
          elementBlock.find('.md-prop-field-limage2').attr('src', listing_agent2_imageurl).css('display', 'inline');
          if ( !listing_agent2_imageurl ) {
            elementBlock.find('.md-prop-field-limage2').css('display', 'none');
          }

          const listing_agent2_telephone = ( listing.agent2.telephone ) ? listing.agent2.telephone : ' ';
          elementBlock.find('.md-prop-field-lphone2').html(listing_agent2_telephone);
        }

        if ( this.frontService.authService.getApp() === 'Designly' ) {
          if ( listing.agent1 !== null ) {
            if (listing.agent1.firstname && listing.agent1.lastname) {
              listing_agent1_name = listing.agent1.firstname + ' ' + listing.agent1.lastname;
            }
            elementBlock.find('.md-prop-field-lname').html(listing_agent1_name);

            let listing_agent1_mobiledisplay = ( listing.agent1.mobiledisplay ) ? listing.agent1.mobiledisplay : ' ';
            listing_agent1_mobiledisplay = listing_agent1_mobiledisplay.replace('+61', '').replace('+64', '').trim();

            if ( listing_agent1_mobiledisplay ) {
              listing_agent1_mobiledisplay = String(listing_agent1_mobiledisplay);
              if ( !listing_agent1_mobiledisplay.startsWith('1800') && listing_agent1_mobiledisplay.charAt(0) !== '0' ) {
                listing_agent1_mobiledisplay = '0' + listing_agent1_mobiledisplay;
              }
            }
            elementBlock.find('.md-prop-field-lmobile').html( listing_agent1_mobiledisplay );

            listing_agent1_email = ( listing.agent1.email ) ? listing.agent1.email : ' ';
            elementBlock.find('.md-prop-field-lemail').html(listing_agent1_email);

            listing_agent1_imageurl = ( listing.agent1.imageurl ) ? listing.agent1.imageurl : '';
            elementBlock.find('.md-prop-field-limage').attr('src', listing_agent1_imageurl).css('display', 'inline');
            if ( !listing_agent1_imageurl ) {
              elementBlock.find('.md-prop-field-limage').css('display', 'none');
            }

            const listing_agent1_telephone = ( listing.agent1.telephone ) ? listing.agent1.telephone : ' ';
            elementBlock.find('.md-prop-field-lphone').html(listing_agent1_telephone);
          }

          if ( listing.agent2 !== null ) {
            if (listing.agent2.firstname  && listing.agent2.lastname) {
              listing_agent2_name = listing.agent2.firstname + ' ' + listing.agent2.lastname;
            }
            let listing_agent2_mobiledisplay = ( listing.agent2.mobiledisplay ) ? listing.agent2.mobiledisplay : ' ';
            listing_agent2_mobiledisplay = listing_agent2_mobiledisplay.replace('+61', '').replace('+64', '').trim();
            if ( listing_agent2_mobiledisplay ) {
              listing_agent2_mobiledisplay = String(listing_agent2_mobiledisplay);
              if ( !listing_agent2_mobiledisplay.startsWith('1800') && listing_agent2_mobiledisplay.charAt(0) !== '0' ) {
                listing_agent2_mobiledisplay = '0' + listing_agent2_mobiledisplay;
              }
            }
            elementBlock.find('.md-prop-field-lmobile2').html( listing_agent2_mobiledisplay );

            listing_agent2_email = ( listing.agent2.email ) ? listing.agent2.email : ' ';
            elementBlock.find('.md-prop-field-lemail2').html(listing_agent2_email);

            listing_agent2_imageurl = ( listing.agent2.imageurl ) ? listing.agent2.imageurl : '';
            elementBlock.find('.md-prop-field-limage2').attr('src', listing_agent2_imageurl).css('display', 'inline');
            if ( !listing_agent2_imageurl ) {
              elementBlock.find('.md-prop-field-limage2').css('display', 'none');
            }

            const listing_agent2_telephone = ( listing.agent2.telephone ) ? listing.agent2.telephone : ' ';
            elementBlock.find('.md-prop-field-lphone2').html(listing_agent2_telephone);
          } else {
            elementBlock.find('.md-prop-field-lmobile2').html('');
            elementBlock.find('.md-prop-field-lphone2').html('');
            elementBlock.find('.md-prop-field-limage2').html('');
            elementBlock.find('.md-prop-field-lname2').html('');
            elementBlock.find('.md-prop-field-lemail2').html('');
          }

          elementBlock.find('.md-prop-field-lname2').html(listing_agent2_name);
        }

        if ( typeof listing.buildingareatype !== 'undefined' && listing.listing_category !== 'land' ) {
          elementBlock.find('.md-prop-field-buildingareatype').html( listing.buildingareatype );
        }

        if ( this.authUser.provider === 'mydesktop' ) {
          elementBlock.find('.md-prop-field-buildingareatype').html( 'sqm' );
        }

        const listing_dateavailable = ( listing.dateavailable ) ? listing.dateavailable : ' ';
        elementBlock.find('.md-prop-field-dateavailable').html(listing_dateavailable);

        const listing_tenancydetails = ( listing.tenancydetails ) ? listing.tenancydetails : ' ';
        elementBlock.find('.md-prop-field-tenancydetails').html(listing_tenancydetails);

        const listing_legal_description = ( listing.legal_description ) ? listing.legal_description : ' ';
        elementBlock.find('.md-prop-field-legaldescription').html(listing_legal_description);

        const listing_legal_landvalue = ( listing.legal_landvalue ) ? listing.legal_landvalue : ' ';
        elementBlock.find('.md-prop-field-landvalue').html(listing_legal_landvalue);

        const listing_legal_localauthority = ( listing.legal_localauthority ) ? listing.legal_localauthority : ' ';
        elementBlock.find('.md-prop-field-localauthority').html(listing_legal_localauthority);

        const listing_legal_lot = ( listing.legal_lot ) ? listing.legal_lot : ' ';
        elementBlock.find('.md-prop-field-lot').html(listing_legal_lot);

        const listing_legal_rpdp = ( listing.legal_rpdp ) ? listing.legal_rpdp : ' ';
        elementBlock.find('.md-prop-field-rpdp').html(listing_legal_rpdp);

        const listing_legal_gvyear = ( listing.legal_gvyear ) ? listing.legal_gvyear : ' ';
        elementBlock.find('.md-prop-field-gvyear').html(listing_legal_gvyear);

        const listing_legal_governmentvalue = ( listing.legal_governmentvalue ) ? listing.legal_governmentvalue : ' ';
        elementBlock.find('.md-prop-field-govvalue').html(listing_legal_governmentvalue);

        const listing_legal_zoning = ( listing.legal_zoning ) ? listing.legal_zoning : ' ';
        elementBlock.find('.md-prop-com-zoning').html(listing_legal_zoning);

        elementBlock.find('.md-prop-com-leasesale').html(listing.leasesale);
        elementBlock.find('.md-prop-com-type').html(listing.commercialtypes);

        const listing_listingdate = ( listing.auctiondate ) ? listing.listingdate : ' ';
        elementBlock.find('.md-prop-field-listingdate').html(listing_listingdate);

        const listing_auctiondate = ( listing.auctiondate ) ? listing.auctiondate : '';
        elementBlock.find('.md-prop-field-auctiondate').html(listing_auctiondate);

        const listing_auctionvenue = ( listing.auctionvenue ) ? listing.auctionvenue : '';
        elementBlock.find('.md-prop-field-auctionvenue').html(listing_auctionvenue);

        if ( this.clientSetting.branch_name ) {
          elementBlock.find('.md-office-field-branch').html(this.clientSetting.branch_name);
        } else {
          if (typeof this.groupSetting !== 'undefined') {
            elementBlock.find('.md-office-field-branch').html(this.groupSetting.branch_name);
          }
        }

        let listing_auction_details = '';
        if ( listing_auctiondate !== ' ' ) {
          listing_auction_details = listing_auctiondate;
        }

        if ( listing_auctionvenue !== ' ' ) {
          listing_auction_details = `${listing_auction_details} ${listing_auctionvenue}`;
        }

        elementBlock.find('.md-prop-field-auction').html(listing_auction_details);

        // Non Class Property - Merge Field
        let elementBlockHtml = elementBlock.html();

        let listing_url = '#';

        if ( listing.url && listing.url !== '' ) {
          listing_url = listing.url;
        } else {
          if ( this.clientSetting.link_website !== '' ) {
            listing_url = this.clientSetting.link_website;
          } else {
            if ( this.groupSetting.link_website !== '' ) {
              listing_url = this.groupSetting.link_website;
            }
          }
        }

        elementBlockHtml = elementBlockHtml.replace(/\{%VIEWLINKURL%\}/ig, listing_url);

        const listing_videourl = ( listing.videourl ) ? listing.videourl : '';
        elementBlockHtml = elementBlockHtml.replace(/\{%VIDEOURL%\}/ig, listing_videourl);

        const listing_virtualtour = ( listing.virtualtour ) ? listing.virtualtour : '';
        elementBlockHtml = elementBlockHtml.replace(/\{%VIRTUALTOURURL%\}/ig, listing_virtualtour);
        elementBlockHtml = elementBlockHtml.replace(/\{%LPHOTO%\}/ig, listing_agent1_imageurl);
        elementBlockHtml = elementBlockHtml.replace(/\{%LPHOTO2%\}/ig, listing_agent2_imageurl);
        elementBlockHtml = elementBlockHtml.replace(/\{%LEMAIL%\}/ig, listing_agent1_email);
        elementBlockHtml = elementBlockHtml.replace(/\{%LEMAIL2%\}/ig, listing_agent2_email);
        elementBlockHtml = elementBlockHtml.replace(/\{%ID%\}/ig, listing_id);
        elementBlockHtml = elementBlockHtml.replace(/\{%OFFICEID%\}/ig, this.frontService.authService.auth.groupid);
        elementBlockHtml = elementBlockHtml.replace(/\{%REFID%\}/ig, listing_refid);
        elementBlockHtml = elementBlockHtml.replace(/\{%LEMAIL%\}/ig, listing_agent1_email);
        elementBlockHtml = elementBlockHtml.replace(/\{%LEMAIL2%\}/ig, listing_agent2_email);

        elementBlock.html(elementBlockHtml);

        elementBlock.find('.md-prop-field-opentime').html('By appointment');
        elementBlock.find('.md-prop-field-opentimes-title').html('');
        if ( listing.inspections && listing.inspections.length > 0 ) {

          if ( typeof listing.inspections[0].opendate !== 'undefined' && listing.inspections[0].opendate) {
            let listing_opentimes = moment(listing.inspections[0].opendate).format('dddd MMMM D [at] h:mma');
            if ( typeof listing.inspections[0].closedate !== 'undefined' && listing.inspections[0].closedate) {
              listing_opentimes += ' - ' + moment(listing.inspections[0].closedate).format('h:mma');
            }
            elementBlock.find('.md-prop-field-opentime').html(listing_opentimes);

            elementBlock.find('.md-prop-field-opentimes-title').html('<b>View</b> ' + listing_opentimes);
          }
        }

        if ( listing.sales ) {
          if ( typeof listing.sales.saledate !== 'undefined' && listing.sales.saledate ) {
            elementBlock.find('.md-prop-field-saledate')
              .html(moment(listing.sales.saledate).format('Do, MMM YYYY'));
          }
          let sale_price = listing.pricefrom;
          if ( typeof listing.sales.saleprice !== 'undefined' && listing.sales.saleprice ) {
            sale_price = listing.sales.saleprice;
          }
          const ratePerM2 = parseInt(sale_price, 0) / parseInt(listing.buildingarea, 0);

          elementBlock.find('.md-prop-field-rate').html(ratePerM2);
        }

        return elementBlock.html();
    } catch (err) {
      console.log(err);
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}