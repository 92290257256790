import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SigninComponent } from './signin/signin.component';
import { LoginComponent } from './login/login.component';
import { VersionComponent } from './version/version.component';
import { AuthGuard } from '../services/auth-guard.service';
import { AutoLoginComponent } from './auto-login/auto-login.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { MaintenanceStatusComponent } from './maintenance/maintenance-status/maintenance-status.component';
import { MaintenanceGuard } from '../services/maintenance-guard.service';

import { environment } from '../../environments/environment';
import { VaultreLoginComponent } from './vaultre-login/vaultre-login.component';
import { DomainLoginComponent } from './domain-login/domain-login.component';
import { CentralLoginComponent } from './central-login/central-login.component';
import { EmailLoginComponent } from './email-login/email-login.component';
import { VaultreSsoComponent } from './vaultre-sso/vaultre-sso.component';

const login = {
  path: 'login',
  component: (environment.app === 'Designly' ? VaultreLoginComponent : LoginComponent),
  canActivate: [MaintenanceGuard]
};

const authRoutes: Routes = [
  { path: 'admin/signin', component: SigninComponent},
  { path: 'login', component: CentralLoginComponent, canActivate: [MaintenanceGuard]},
  { path: 'autologin', component: AutoLoginComponent},
  { path: 'view/version', component: VersionComponent, canActivate: [AuthGuard]},
  { path: 'maintenance', component: MaintenanceComponent},
  { path: 'maintenance/status', component: MaintenanceStatusComponent},
  { path: 'domain/login', component: DomainLoginComponent },
  { path: 'vaultea/login', component: VaultreLoginComponent},
  { path: 'sso/mail', component: EmailLoginComponent },
  { path: 'vaultre/sso', component: VaultreSsoComponent }
];

@NgModule({
  imports: [RouterModule.forChild(authRoutes)],
  exports: [RouterModule]
})
export class AuthRouteModule {}
