<div class="row">
    <div class="col-md-12 heading">
        <h2 class="pull-left">Sub Groups</h2>
        <button type="button" class="btn-admin" (click)="showCreateSubgroupModal()">
            <i class="icon feather icon-plus"></i><span>Add Group</span>
        </button>
    </div>
</div>

<div id="box" class="row">
    <div class="col-md-12">
        <div class="table-box">
            <table class="table table-hover" id="subgroup_table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Title</th>
                        <th>Group</th>
                        <th style="text-align: right">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let subgroup of subGroups">
                        <td>{{ subgroup.id }}</td>
                        <td>{{ subgroup.title }}</td>
                        <td>{{ subgroup.group.title }}</td>
                        <td style="text-align: right">
                            <button (click)="showCreateSubgroupModal(subgroup)" type="button" class="btn-table" title="Edit"><i
                                    class="icon feather icon-edit"></i></button>
                            <button (click)="deleteSubGroup(subgroup.id)" type="button" class="btn-table" title="Delete"><i
                                    class="icon feather icon-trash"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- <pagination-controls (pageChange)="page = $event"></pagination-controls> -->
        </div>
    </div>
</div>

<div id="createSubgroupModal" class="modal fade edit-box" role="dialog" aria-labelledby="createSubgroupLabel">
    <div class="modal-dialog modal-center" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <!-- <button type="button" class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">&times;</span></button> -->
                <h4 class="modal-title" id="createSubgroupLabel">{{ selectedId ? 'Edit' : 'Create' }} Subgroup</h4>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label class="col-sm-4">Title</label>
                    <div class="col-sm-12">
                        <input type="text" class="form-control" [formControl]="titleFormControl" />
                    </div>
                </div>
                <div class="form-group" [hidden]="selectedId !== null">
                    <label class="col-sm-4">Group</label>
                    <div class="col-sm-12">
                        <select class="form-select" [formControl]="groupIdFormControl">
                            <option *ngFor="let group of groups" [value]="group.ID">{{group.title}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-start">
                <button type="button" class="btn-admin btn-admin-primary me-2"
                    [disabled]="!groupIdFormControl.valid || !titleFormControl.valid"
                    (click)="submitForm()">Save</button>
                <button type=" button" class="btn-admin" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<ngx-loading [show]="loading"></ngx-loading>