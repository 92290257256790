import { HttpParams, HttpRequest,  HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import { MediaAttribute } from '../models/media-attribute.model';
import { ActivatedRoute } from '@angular/router';

import 'rxjs/Rx';

import { APP_CONFIG } from '../app.config';

@Injectable()
export class MediaAttributeService {
  mediaAttributesChanged = new Subject<MediaAttribute[]>();
  mediaAttributeChanged = new Subject<MediaAttribute>();
  mediaAttributeOperation = new Subject<MediaAttribute>();

  private mediaAttributes: MediaAttribute[] = [];

  // New Subscriptions
  onGetMediaAttributes = new Subject();

  constructor(@Inject(APP_CONFIG) private appConfig, private httpClient: HttpClient) {}

  setMediaAttributes(mediaAttributes: MediaAttribute[]) {
    this.mediaAttributes = mediaAttributes;
    this.mediaAttributesChanged.next(this.mediaAttributes.slice());
  }

  getMediaAttributes() {
    return this.mediaAttributes.slice();
  }

  getIdIndex(id: number) {
    return this.mediaAttributes.findIndex(m => m.ID === id);
  }

  httpGetMediaAttributesNew(params: any = {}, columns: string = '') {
    let httpParams = new HttpParams();

    if (columns) {
      httpParams = httpParams.append('select', columns);
    }

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/media-attributes`, {
        params: httpParams
    })
    .subscribe(
      (response: any) => {
        this.onGetMediaAttributes.next(response);
      },
      (response: any) => {
        this.onGetMediaAttributes.next(response.error);
      }
    );
  }

  httpGetMediaAttributes(params: any = {}, columns: string = '', order: string = '') {
    let httpParams = new HttpParams();

    if ( columns ) {
      httpParams = httpParams.append('select', columns);
    }
    if ( order ) {
      httpParams = httpParams.append('order', order);
    }

    for (let p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.NEW_API_ENDPOINT + '/media-attributes',
      {
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: MediaAttribute[]) => {
        this.setMediaAttributes(response);
      }
    );
  }

  httpGetMediaAttribute(id: number) {
    const req = new HttpRequest(
      'GET',
      this.appConfig.NEW_API_ENDPOINT + '/media-attributes/' + id,
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: MediaAttribute) => {
        this.mediaAttributeChanged.next(response);
      }
    );
  }

  httpPostMediaAttribute(data: MediaAttribute) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.NEW_API_ENDPOINT + '/media-attributes',
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.mediaAttributeOperation.next(response);
      }
    );
  }

  httpPutMediaAttribute(id: number, data: MediaAttribute) {
    const req = new HttpRequest(
      'PUT',
      this.appConfig.NEW_API_ENDPOINT + '/media-attributes/' + id,
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.mediaAttributeOperation.next(response);
      }
    );
  }

  httpDeleteMediaAttribute(id: number) {
    const req = new HttpRequest(
      'DELETE',
      this.appConfig.NEW_API_ENDPOINT + '/media-attributes/' + id
    );

    return this.httpClient.request<any>(req)
    .subscribe(
      (response: any) => {
        this.httpGetMediaAttributes();
      }
    );
  }
}
