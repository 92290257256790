import { Component, OnInit } from '@angular/core';
import { Menu } from '../../models/menu.model';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  menus: Menu[] = [];

  constructor(private adminService: AdminService) { }

    ngOnInit() {
      this.menus = [
        new Menu('Training', '', [
          new Menu('Training Videos', 'help/training-videos'),
        ]),
        new Menu('Changelogs', '', [
          new Menu('Changelogs', 'help/changelogs')
        ])
      ];

      this.adminService.loadMenu(this.menus);
    }
}
