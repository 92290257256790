<div class="row">
    <div class="col-md-12 heading">
        <h2 class="pull-left">Element Template - {{ element?.title }}</h2>

        <button routerLink="../../../{{ element?.category?.slug }}" type="button" class="btn-admin">
            <i class="icon feather icon-arrow-left"></i><span>Back</span>
        </button>
    </div>
</div>
<div class="edit-box element-setting">
    <div class="row">
        <div class="col-md-12">
            <ul class="nav nav-tabs" id="elementSettingTabContent" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="element-setting-tab" data-bs-toggle="tab" data-bs-target="#element-setting" type="button" role="tab" aria-selected="true">Element Settings</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="element-setting-group-tab" data-bs-toggle="tab" data-bs-target="#element-setting-group" type="button" role="tab" aria-selected="true">Setting Groups</button>
                </li>
            </ul>
            <div class="tab-content" id="elementSettingTabContent">
                <div class="tab-pane fade show active" id="element-setting" role="tabpanel">
                    <div>
                        <div class="col mb-3 div-add-setting">
                            <span class="c-pointer btn-admin btn-admin-primary" (click)="modalEvent('add_setting', 'show')"><i class="icon feather icon-plus"></i> Add Setting</span>
                        </div>
                        <form [formGroup]="elementSettingForm" (ngSubmit)="submitElementSetting()">
                            <div class="setting-list-container">
                                <ng-container formArrayName="data">
                                    <ng-container *ngFor="let esetting of elementSettings().controls; let setting_idx = index; let first_setting_idx = first; let last_setting_idx = last" [formGroupName]="setting_idx">
                                        <div *ngIf="esetting.value.group_id; then groupSetting else rowSetting"></div>
                                        <ng-template #groupSetting>
                                            <div class="row g-2" [class.mb-3]="!last_setting_idx">
                                                <div class="col arrow-dir-vertical">
                                                    <div class="dropdown">
                                                        <span class="c-pointer" data-bs-toggle="dropdown"><i class="icon feather icon-more-vertical"></i></span>
                                                        <ul class="dropdown-menu dropdown-elm-action">
                                                            <li class="c-pointer" *ngIf="!first_setting_idx" (click)="moveItem(setting_idx, 0)"><span class="dropdown-item"><i class="icon feather icon-chevron-up"></i>Move Up</span></li>
                                                            <li class="c-pointer" *ngIf="!last_setting_idx" (click)="moveItem(setting_idx, 1)"><span class="dropdown-item"><i class="icon feather icon-chevron-down"></i>Move Down</span></li>
                                                            <li class="c-pointer" (click)="addRowSettingItem(setting_idx, 0)"><span class="dropdown-item"><i class="icon feather icon-plus-square"></i>Insert Above</span></li>
                                                            <li class="c-pointer" (click)="addRowSettingItem(setting_idx, 1)"><span class="dropdown-item"><i class="icon feather icon-plus-square"></i>Insert Below</span></li>
                                                            <li *ngIf="elementSettingForm.value.data.length > 1"><hr class="dropdown-divider"></li>
                                                            <li class="c-pointer" *ngIf="elementSettingForm.value.data.length > 1" (click)="removeRowSettingItem(setting_idx)">
                                                                <span class="dropdown-item remove-row"><i class="icon feather icon-trash-2"></i>Delete</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="col-md">
                                                    <div class="card" [class.mb-3]="!last_setting_idx">
                                                        <div class="card-header">
                                                            <span>{{ esetting.value.group_name }}</span>
                                                        </div>
                                                        <div class="card-body">
                                                            <ng-container formArrayName="items">
                                                                <ng-container *ngFor="let egsetting of elementGroupSettings(setting_idx).controls; let egsetting_idx = index; let egsetting_first_idx = first; let egsetting_last_idx = last" [formGroupName]="egsetting_idx">
                                                                    <div class="row g-2 mb-3">
                                                                        <div class="col arrow-dir-vertical">
                                                                            <div class="dropdown">
                                                                                <span class="c-pointer" data-bs-toggle="dropdown"><i class="icon feather icon-more-vertical"></i></span>
                                                                                <ul class="dropdown-menu dropdown-elm-action">
                                                                                    <li class="c-pointer" *ngIf="!egsetting_first_idx" (click)="moveGroupItem(setting_idx, egsetting_idx, 0)"><span class="dropdown-item"><i class="icon feather icon-chevron-up"></i>Move Up</span></li>
                                                                                    <li class="c-pointer" *ngIf="!egsetting_last_idx" (click)="moveGroupItem(setting_idx, egsetting_idx, 1)"><span class="dropdown-item"><i class="icon feather icon-chevron-down"></i>Move Down</span></li>
                                                                                    <li class="c-pointer" (click)="insertGroupItem(setting_idx, egsetting_idx, 0)"><span class="dropdown-item"><i class="icon feather icon-plus-square"></i>Insert Above</span></li>
                                                                                    <li class="c-pointer" (click)="insertGroupItem(setting_idx, egsetting_idx, 1)"><span class="dropdown-item"><i class="icon feather icon-plus-square"></i>Insert Below</span></li>
                                                                                    <li *ngIf="elementSettingForm.value.data.length > 1"><hr class="dropdown-divider"></li>
                                                                                    <li class="c-pointer" *ngIf="elementSettingForm.value.data.length > 1" (click)="removeGroupItem(setting_idx, egsetting_idx)">
                                                                                        <span class="dropdown-item remove-row"><i class="icon feather icon-trash-2"></i>Delete</span>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md">
                                                                            <div>
                                                                                <label><small>Type</small></label>
                                                                                <select class="form-select" [attr.disabled]="(egsetting.value.id ? true : null)" formControlName="settings_type" (change)="onSelectSettingType($event.target.value, egsetting)">
                                                                                    <option value="" selected>--------</option>
                                                                                    <option value="0">Dropdown</option>
                                                                                    <option value="1">Toggle</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md">
                                                                            <div>
                                                                                <label><small>Title</small></label>
                                                                                <input type="text" class="form-control" formControlName="title">
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md">
                                                                            <div>
                                                                                <label><small>Description</small></label>
                                                                                <input type="text" class="form-control" formControlName="description">
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md">
                                                                            <div>
                                                                                <label><small>Class Name</small></label>
                                                                                <input type="text" class="form-control" formControlName="class_name" [readonly]="egsetting.value.settings_type == 0">
                                                                            </div>
                                                                        </div>
                                                                        <ng-container formArrayName="options" *ngIf="egsetting.value.options"> <!-- Options -->
                                                                            <div class="row m-3 mb-0" *ngFor="let option of elementGroupDropdownoptions(setting_idx, egsetting_idx).controls; let option_index = index; let option_first = first" [formGroupName]="option_index">
                                                                                <div class="col arrow-dir-vertical">
                                                                                    <div class="dropdown">
                                                                                        <span class="c-pointer" data-bs-toggle="dropdown"><i class="icon feather icon-more-vertical"></i></span>
                                                                                        <ul class="dropdown-menu dropdown-elm-action">
                                                                                            <li class="c-pointer" (click)="addGroupDropdownOption(setting_idx, egsetting_idx, option_index)"><span class="dropdown-item"><i class="icon feather icon-plus-square"></i>Insert new option</span></li>
                                                                                            <li class="c-pointer" *ngIf="!option_first" (click)="removeGroupDropdownOption(setting_idx, egsetting_idx, option_index)">
                                                                                                <span class="dropdown-item remove-row"><i class="icon feather icon-trash-2"></i>Remove</span>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md">
                                                                                    <div>
                                                                                        <label><small>Option[{{ option_index+1 }}] Title</small></label>
                                                                                        <input type="text" formControlName="title" class="form-control">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md">
                                                                                    <div>
                                                                                        <label><small>Option[{{ option_index+1 }}] Description</small></label>
                                                                                        <input type="text" formControlName="description" class="form-control">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md">
                                                                                    <div>
                                                                                        <label><small>Option[{{ option_index+1 }}] Class Name</small></label>
                                                                                        <input type="text" formControlName="class_name" class="form-control">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template #rowSetting>
                                            <div class="row g-2" [class.mb-3]="!last_setting_idx">
                                                <div class="col arrow-dir-vertical">
                                                    <div class="dropdown">
                                                        <span class="c-pointer" data-bs-toggle="dropdown"><i class="icon feather icon-more-vertical"></i></span>
                                                        <ul class="dropdown-menu dropdown-elm-action">
                                                            <li class="c-pointer" *ngIf="!first_setting_idx" (click)="moveItem(setting_idx, 0)"><span class="dropdown-item"><i class="icon feather icon-chevron-up"></i>Move Up</span></li>
                                                            <li class="c-pointer" *ngIf="!last_setting_idx" (click)="moveItem(setting_idx, 1)"><span class="dropdown-item"><i class="icon feather icon-chevron-down"></i>Move Down</span></li>
                                                            <li class="c-pointer" (click)="addRowSettingItem(setting_idx, 0)"><span class="dropdown-item"><i class="icon feather icon-plus-square"></i>Insert Above</span></li>
                                                            <li class="c-pointer" (click)="addRowSettingItem(setting_idx, 1)"><span class="dropdown-item"><i class="icon feather icon-plus-square"></i>Insert Below</span></li>
                                                            <li><hr class="dropdown-divider"></li>
                                                            <li class="c-pointer" (click)="removeRowSettingItem(setting_idx)">
                                                                <span class="dropdown-item remove-row"><i class="icon feather icon-trash-2"></i>Delete</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="col-md">
                                                    <div>
                                                        <label><small>Type</small></label>
                                                        <select class="form-select" formControlName="settings_type" [attr.disabled]="(esetting.value.id ? true : null)" (change)="onSelectSettingType($event.target.value, esetting)">
                                                            <option value="" selected>--------</option>
                                                            <option value="0">Dropdown</option>
                                                            <option value="1">Toggle</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md">
                                                    <div>
                                                        <label><small>Title</small></label>
                                                        <input type="text" class="form-control" formControlName="title">
                                                    </div>
                                                </div>
                                                <div class="col-md">
                                                    <div>
                                                        <label><small>Description</small></label>
                                                        <input type="text" class="form-control" formControlName="description">
                                                    </div>
                                                </div>
                                                <div class="col-md">
                                                    <div>
                                                        <label><small>Class Name</small></label>
                                                        <input [readonly]="esetting.value.settings_type == 0" type="text" class="form-control" formControlName="class_name">
                                                    </div>
                                                </div>
                                                <ng-container formArrayName="options"> <!-- Options -->
                                                    <div class="row m-3 mb-0" *ngFor="let option of elementDropdownOptions(setting_idx).controls; let option_index = index; let option_first = first" [formGroupName]="option_index">
                                                        <div class="col arrow-dir-vertical">
                                                            <div class="dropdown">
                                                                <span class="c-pointer" data-bs-toggle="dropdown"><i class="icon feather icon-more-vertical"></i></span>
                                                                <ul class="dropdown-menu dropdown-elm-action">
                                                                    <li class="c-pointer" (click)="addRowSettingOption(setting_idx, option_index)"><span class="dropdown-item"><i class="icon feather icon-plus-square"></i>Insert new option</span></li>
                                                                    <li *ngIf="!option_first" class="c-pointer" (click)="removeRowSettingOption(setting_idx, option_index)">
                                                                        <span class="dropdown-item remove-row"><i class="icon feather icon-trash-2"></i>Remove</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="col-md">
                                                            <div>
                                                                <label><small>Option[{{ option_index+ 1 }}] Title</small></label>
                                                                <input type="text" formControlName="title" class="form-control">
                                                            </div>
                                                        </div>
                                                        <div class="col-md">
                                                            <div>
                                                                <label><small>Option[{{ option_index+ 1 }}] Description</small></label>
                                                                <input type="text" formControlName="description" class="form-control">
                                                            </div>
                                                        </div>
                                                        <div class="col-md">
                                                            <div>
                                                                <label><small>Option[{{ option_index+ 1 }}] Class Name</small></label>
                                                                <input type="text" formControlName="class_name" class="form-control">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </ng-template>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <div class="col mt-3">
                                <button class="btn-admin btn-admin-primary btn-save-element-setting">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="tab-pane fade" id="element-setting-group" role="tabpanel">
                    <div class="col mb-3 div-add-setting">
                        <span class="btn-admin btn-admin-primary c-pointer" (click)="addNewElementTemplateGroup()"><i class="icon feather icon-plus"></i>Add Group</span>
                    </div>
                    <div class="setting-list-container">
                        <table class="table caption-top">
                            <caption>List of Groups</caption>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let tgData of templateSettingGroupList?.data; let tGroup = index">
                                    <td>{{ tgData.id }}</td>
                                    <td>{{ tgData.group_name }}</td>
                                    <td>
                                        <span class="btn-table c-pointer" (click)="editElementTemplateGroup(tgData)"><i class="icon feather icon-edit"></i></span>
                                        <span class="btn-table c-pointer" (click)="deleteElementTemplateGroup(tgData.id)"><i class="icon feather icon-trash-2"></i></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade mt-5" #modalInsertNewSetting>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form [formGroup]="newElementSettingFormData" (ngSubmit)="preInsertNewSetting()">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Add setting</h5>
                    <span class="btn-close c-pointer" (click)="modalEvent('add_setting', 'hide')"></span>
                </div>
                <div class="modal-body">
                    <div class="new-element-setting-form">
                        <div class="mb-3 row">
                            <label for="new-settings-group" class="col-sm-2 col-form-label">Setting Group</label>
                            <div class="col-sm-10">
                                <select id="new-settings-group" class="form-select" formControlName="group_id">
                                    <option value="">----</option>
                                    <option *ngFor="let group of templateSettingGroupList?.data" value="{{ group.id}}">{{ group.group_name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label for="new-settings-type" class="col-sm-2 col-form-label">Settings Type <span title="Required">*</span></label>
                            <div class="col-sm-10">
                                <select id="new-settings-type" class="form-select" formControlName="settings_type" (change)="changeNewSettingTypeForm($event.target.value)">
                                    <option value="">----</option>
                                    <option value="0">Dropdown</option>
                                    <option value="1">Toggle</option>
                                </select>
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label for="new-title" class="col-sm-2 col-form-label">Title <span title="Required">*</span></label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" id="new-title" formControlName="title">
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label for="new-description" class="col-sm-2 col-form-label">Description</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" id="new-description" formControlName="description">
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label for="new-class-name" class="col-sm-2 col-form-label">Class Name</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" id="new-class-name" formControlName="class_name">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-admin btn-admin-primary">Insert</button>
                </div>
            </form>
        </div>
    </div>
</div>

<ngx-loading [show]="loading"></ngx-loading>
