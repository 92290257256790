import { Component, OnInit,  ElementRef, ViewChild, OnDestroy, AfterViewInit, HostListener } from '@angular/core';
import { FrontService } from '../../front.service';
import { ClientService } from '../../../services/client.service';
import { Client } from '../../../models/client.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Auth } from '../../../models/auth.model';
import { Icon } from '../../../models/icon.model';
import { IconService } from '../../../services/icon.service';
import { User } from '../../../models/user.model';
import { UserService } from '../../../services/user.service';
import { Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Menu } from '../../../models/menu.model';

declare var $: any;
declare var jQuery: any;
declare var introJs: any;
declare var swal: any;
declare var _bugHerd: any;

@Component({
  selector: 'app-brand-assets-default',
  templateUrl: './brand-assets-default.component.html',
  styleUrls: ['./brand-assets-default.component.scss']
})
export class BrandAssetsDefaultComponent implements OnInit, AfterViewInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject;
  loading = false;
  client: Client;
  logo_link = '';
  subscription: Subscription;
  icons: Icon[] = [];
  menus: Menu[];
  title = 'Brand Settings';
  selectedBrandAssetsType = '';
  authUser: Auth;
  user = {
    ID: 21,
    client_id: 5,
    name: 'John Doe',
    photo: 'assets/img/user_photo.jpg',
    email: 'johndoe@websiteblue.com',
    mobile: '+6112345678',
    telephone: '1234 5678',
    fax: '9876 5432'
  };

  users: User[];

  currentTimestamp = Date.now();

  public files: any[] = [];

  logoForm: FormGroup;
  formFontColor: FormGroup;
  linksForm: FormGroup;

  color1 = '';
  color2 = '';
  color4 = '';
  color6 = '';
  color7 = '';
  color9 = '';
  color10 = '';
  customColors = [];
  colorIcon = '';

  @ViewChild('displayUpload', { static: true }) displayUpload: ElementRef;
  @ViewChild('canvasEl', { static: true }) canvasElem: ElementRef;
  defaultTab: string;

  constructor(
    public frontService: FrontService,
    private clientService: ClientService,
    private iconService: IconService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.params
    .takeUntil(this.destroy$)
    .subscribe(
      (params: any) => {
        this.selectedBrandAssetsType = params.type;
        this.title = params.type.replace('-', ' ');
        this.initTooltip();
      }
    );

    this.authUser = this.frontService.authService.auth;
    this.subscription = this.frontService.getSharedMenu()
      .subscribe((menuList: Menu[]) => {
        let idx = menuList.findIndex(function(menu) {
            return menu.name == "Brand Assets";
        });
        /* Check if there's existing sub-menu */
        if (idx >= 0 && menuList[idx].subs.length > 0) {
          /* Re-route if current route is not in the sub menu of brand assets */
          let subIdx = menuList[idx].subs.findIndex((sub) => {
            return sub.url == this.router.url;
          });
          if (subIdx < 0) {
            this.router.navigate([menuList[idx].subs[0].url]);
          }
        }
      }
    );

    this.user = {
      ID: this.authUser.user.agent_id,
      client_id: this.authUser.client.ID,
      name: this.authUser.user.firstname + ' ' + this.authUser.user.firstname,
      photo: this.authUser.user.photo,
      email: this.authUser.user.email,
      mobile: this.authUser.user.mobile,
      telephone: this.authUser.user.telephone,
      fax: this.authUser.user.fax
    };

    if (this.authUser.is_admin || this.authUser.is_master) {
      this.userService.httpGetUsers({'client_id': this.user.client_id });
    }

    this.client = this.authUser.client;

    if (this.client.is_account_master === 1) {
      this.defaultTab = 'logos';
    } else {
      if (this.client.group.setting_menu_link === 1) {
        this.defaultTab = 'website-links';
      }
      if (this.client.group.setting_menu_icon === 1) {
        this.defaultTab = 'icons';
      }
      if (this.client.group.setting_menu_font === 1) {
        this.defaultTab = 'fonts';
      }
      if (this.client.group.setting_menu_color === 1) {
        this.defaultTab = 'colours';
      }
      if (this.client.group.setting_menu_logo === 1) {
        this.defaultTab = 'logos';
      }
    }

    this.iconService.httpGetIcons();

    this.clientService.clientLink
    .takeUntil(this.destroy$)
    .subscribe(
      (client: Client) => {
        this.loading = false;
        if (typeof client.ID !== 'undefined') {
          this.client = client;
          this.frontService.authService.updateAuthCookieField('client', JSON.stringify(client));
        }
      }
    );

    this.clientService.clientLogo
    .takeUntil(this.destroy$)
    .subscribe(
      (client: Client) => {
        this.loading = false;
        this.logo_link = client.primary_logo;
        if (typeof client.ID !== 'undefined') {
          this.client = client;
          this.frontService.authService.updateAuthCookieField('client', JSON.stringify(client));
        }
      }
    );

    this.clientService.clientColor
    .takeUntil(this.destroy$)
    .subscribe(
      (client: Client) => {
        this.loading = false;

        if (typeof client.ID !== 'undefined') {
          this.client = client;
          this.frontService.authService.updateAuthCookieField('client', JSON.stringify(client));
        }
      }
    );

    this.clientService.clientIcon
    .takeUntil(this.destroy$)
    .subscribe(
      (client: Client) => {
        this.loading = false;

        if (typeof client.ID !== 'undefined') {
          this.client = client;
          this.frontService.authService.updateAuthCookieField('client', JSON.stringify(client));
        }
      }
    );

    this.iconService.iconsChanged
    .takeUntil(this.destroy$)
    .subscribe(
      (icons: Icon[]) => {
        this.icons = icons;
      }
    );

    this.logo_link = this.client.primary_logo;
    console.log(this.client.group.master.color_6);
    console.log(this.client.color_6);
    const groupMaster = this.client.group.master;
    this.color1 = this.client.color_1 && this.client.color_1 !== 'undefined' && this.client.color_1 !== '#' ? this.client.color_1 : groupMaster.color_1;
    this.color2 = this.client.color_2 && this.client.color_2 !== 'undefined' && this.client.color_2 !== '#' ? this.client.color_2 : groupMaster.color_2;
    this.color4 = this.client.color_4 && this.client.color_4 !== 'undefined' && this.client.color_4 !== '#' ? this.client.color_4 : groupMaster.color_4;
    this.color6 = this.client.color_6 && this.client.color_6 !== 'undefined' && this.client.color_6 !== '#' ? this.client.color_6 : groupMaster.color_6;
    this.color7 = this.client.color_7 && this.client.color_7 !== 'undefined' && this.client.color_7 !== '#' ? this.client.color_7 : groupMaster.color_7;
    this.color9 = this.client.color_9 && this.client.color_9 !== 'undefined' && this.client.color_9 !== '#' ? this.client.color_9 : groupMaster.color_9;
    this.color10 = this.client.color_10 && this.client.color_10 !== 'undefined' && this.client.color_10 !== '#' ? this.client.color_10 : groupMaster.color_10;
    this.colorIcon = this.client.colorIcon ? this.client.colorIcon : '';

    this.customColors.push({count: 11, value: ''});
    this.initfontColorForm();
    this.initTooltip();
    this.initlinksForm();
    this.initlinksFormEdit();
  }

  isGroupVisible(fieldData: number = 0) {
    // tslint:disable-next-line: max-line-length
    if (( (this.authUser.is_admin || this.authUser.is_group_admin) || (this.authUser.client.allow_agent_settings === 1 && fieldData === 1)) ) {
      return true;
    }
    return false;
  }

  checkGroupVisible(fieldName) {
    let isVisible = false;
    switch (fieldName) {
      case 'logos':
        isVisible =  this.isGroupVisible(this.client.group.setting_menu_logo);
        break;
      case 'colours':
        isVisible = this.isGroupVisible(this.client.group.setting_menu_color);
        break;
      case 'fonts':
        isVisible = this.isGroupVisible(this.client.group.setting_menu_fonts);
      break;
      case 'icons':
        isVisible = this.isGroupVisible(this.client.group.setting_menu_icon);
        break;
      case 'website-links':
        isVisible = this.isGroupVisible(this.client.group.setting_menu_link);
        break;
      case 'social-links':
        isVisible = this.isGroupVisible(this.client.group.setting_menu_link);
        break;
      case 'disclaimers':
        isVisible = this.isGroupVisible(this.client.group.setting_menu_link);
        break;
      default :
        isVisible = true;
        break;
    }

    return isVisible;
  }

  uploadLogo(event: Event) {
    const target: HTMLInputElement = event.target as HTMLInputElement;

    for (let i = 0; i < target.files.length; i++) {
      this.clientService.httpPutLogo(this.authUser.client.ID, 'primary', target.files[i]);
    }
    this.loading = true;
  }

  uploadSecondaryLogo(event: Event) {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    for (let i = 0; i < target.files.length; i++) {
      this.clientService.httpPutLogo(this.authUser.client.ID, 'secondary', target.files[i]);
    }
    this.loading = true;
  }

  addFile(type = '') {
    this.files = [];
    $(this.displayUpload.nativeElement).modal('show');
  }

  closeModal(id) {
    $('#' + id).modal('hide');
  }

  initializeColorPicker() {

    function readURL(input) {
      if (input.files && input.files[0]) {
          const reader = new FileReader();
          reader.onload = function (e) {
            const target: any = e.target;
            const content: string = target.result;

            const image_placement = jQuery(input).attr('data-action');
            jQuery(image_placement).attr('src', content);
          };

          reader.readAsDataURL(input.files[0]);
      }
    }

    jQuery('#image-primary').change(function(){
        readURL(this);
    });

    jQuery('#image-secondary').change(function(){
      readURL(this);
    });
  }

  onFileChange(pFileList: File[]) {
    this.files = Object.keys(pFileList).map(key => pFileList[key]);
    console.log('Successfully upload!');
  }

  deleteFile(f) {
    this.files = this.files.filter(function(w){ return w.name !== f.name; });
    console.log('Successfully delete!');
  }

  addColor() {
    const color =  this.customColors[this.customColors.length - 1];
    const colorCount = (color.count + 1);
    this.customColors.push({count: colorCount, value: ''});
  }

  removeColor(i) {
    this.customColors.forEach((e, index) => {
      if ( e.count === i ) {
        this.customColors.splice(index, 1);
      }
    });
  }

  keypressEvent(event) {
    const $this = this;
    addEventListener('keypress', function abc(e){
      if (e.key === 'Enter') {
        $this.saveColor();
        removeEventListener('keypress', abc, false);
      }
    }, false);
  }

  saveColor() {
    let color_1 = this.color1;
    let color_2 = this.color2;
    let color_4 = this.color4;
    let color_6 = this.color6;
    let color_7 = this.color7;
    let color_9 = this.color9;
    let color_10 = this.color10;
    let colorIcon = this.colorIcon;

    if (color_1 === '' || color_1 === '#') {
      if (this.client.color_1 === '' || this.client.color_1 === '#') {
        color_1 = '';
      } else {
        color_1 = this.client.color_1;
      }
    } else {
        color_1 = color_1;
    }

    if (color_2 === '' || color_2 === '#') {
      if (this.client.color_2 === '' || this.client.color_2 === '#') {
        color_2 = '';
      }else {
        color_2 = this.client.color_2;
      }
    } else {
        color_2 = color_2;
    }

    if (color_4 === '' || color_4 === '#') {
      if (this.client.color_4 === '' || this.client.color_4 === '#') {
        color_4 = '';
      } else {
        color_4 = this.client.color_4;
      }
    } else {
        color_4 = color_4;
    }

    if (color_6 === '' || color_6 === '#') {
      if (this.client.color_6 === '' || this.client.color_6 === '#') {
        color_6 = '';
      } else {
        color_6 = this.client.color_6;
      }
    } else {
        color_6 = color_6;
    }

    if (color_7 === '' || color_7 === '#') {
      if (this.client.color_7 === '' || this.client.color_7 === '#') {
        color_7 = '';
      } else {
        color_7 = this.client.color_7;
      }
    } else {
        color_7 = color_7;
    }

    if (color_9 === '' || color_9 === '#') {
      if (this.client.color_9 === '' || this.client.color_9 === '#') {
        color_9 = '';
      } else {
        color_9 = this.client.color_9;
      }
    } else {
        color_9 = color_9;
    }

    if (color_10 === '' || color_10 === '#') {
      if (this.client.color_10 === '' || this.client.color_10 === '#') {
        color_10 = '';
      } else {
        color_10 = this.client.color_10;
      }
    } else {
        color_10 = color_10;
    }

    if (colorIcon === '' || colorIcon === '#') {
      if (this.client.colorIcon === '' || this.client.colorIcon === '#') {
        colorIcon = '';
      } else {
        colorIcon = this.client.colorIcon;
      }
    } else {
      colorIcon = colorIcon;
    }

    this.formFontColor.patchValue({
      'color_1': (color_1),
      'color_2': (color_2),
      'color_4': (color_4),
      'color_6': (color_6),
      'color_7': (color_7),
      'color_9': (color_9),
      'color_10': (color_10),
      'colorIcon': (colorIcon)
    });

    const fontValue = this.formFontColor.value;
    this.clientService.httpPutColor(this.user.client_id, fontValue);

    this.color1 = color_1;
    this.color2 = color_2;
    this.color4 = color_4;
    this.color6 = color_6;
    this.color7 = color_7;
    this.color9 = color_9;
    this.color10 = color_10;
    this.colorIcon = colorIcon;

    this.loading = true;

  }

  displayImageColorPicker(id) {
    const image = new Image;

    const $this = this;

    const canvas = this.canvasElem.nativeElement;
    const ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    image.crossOrigin = 'Anonymous';

    image.onload = function() {
      canvas.width = 500;
      canvas.height = 370;

      const w = image.width;
      const h = image.height;

      const sizes = scalePreserveAspectRatio(w, h, canvas.width, canvas.height);

      ctx.drawImage(image, 0, 0, w * sizes, h * sizes);
    };

    const default_logo = this.logo_link;

    if (typeof default_logo === 'undefined' || default_logo === '') {
      jQuery('#error_msg_image').css('display', 'block');
    } else {
      image.src = default_logo  + '?' + new Date().getTime();
    }

    function scalePreserveAspectRatio(imgW, imgH, maxW, maxH) {
      return(Math.min((maxW / imgW), (maxH / imgH)));
    }

    jQuery(canvas).unbind('click').click(function(e) {
      const canvasOffset = jQuery(canvas).offset();
      const canvasX = Math.floor(e.pageX - canvasOffset.left);
      const canvasY = Math.floor(e.pageY - canvasOffset.top);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const pixels = imageData.data;
      const pixelRedIndex = ((canvasY - 1) * (imageData.width * 4)) + ((canvasX - 1) * 4);
      // tslint:disable-next-line: max-line-length
      const pixelcolor = 'rgba(' + pixels[pixelRedIndex] + ', ' + pixels[pixelRedIndex + 1] + ', ' + pixels[pixelRedIndex + 2] + ', ' + pixels[pixelRedIndex + 3] + ')';

      jQuery('#rVal').val(pixels[pixelRedIndex]);
      jQuery('#gVal').val(pixels[pixelRedIndex + 1]);
      jQuery('#bVal').val(pixels[pixelRedIndex + 2]);
      jQuery('#rgbVal').val(pixels[pixelRedIndex] + ', ' + pixels[pixelRedIndex + 1] + ', ' + pixels[pixelRedIndex + 2]);
      // tslint:disable-next-line: max-line-length
      jQuery('#rgbaVal').val(pixels[pixelRedIndex] + ', ' + pixels[pixelRedIndex + 1] + ', ' + pixels[pixelRedIndex + 2] + ', ' + pixels[pixelRedIndex + 3]);
      jQuery('#hexVal').val('#' + toHex(pixels[pixelRedIndex]) + toHex(pixels[pixelRedIndex + 1]) + toHex(pixels[pixelRedIndex + 2]));

      jQuery('#preview').css('background', pixelcolor);

      if (id === 1) {
        $this.color1  = '#' + toHex(pixels[pixelRedIndex]) + toHex(pixels[pixelRedIndex + 1]) + toHex(pixels[pixelRedIndex + 2]);
      }

      if (id === 2) {
        $this.color2  = '#' + toHex(pixels[pixelRedIndex]) + toHex(pixels[pixelRedIndex + 1]) + toHex(pixels[pixelRedIndex + 2]);
      }

      if (id === 4) {
        $this.color4  = '#' + toHex(pixels[pixelRedIndex]) + toHex(pixels[pixelRedIndex + 1]) + toHex(pixels[pixelRedIndex + 2]);
      }

      if (id === 6) {
        $this.color6  = '#' + toHex(pixels[pixelRedIndex]) + toHex(pixels[pixelRedIndex + 1]) + toHex(pixels[pixelRedIndex + 2]);
      }

      if (id === 7) {
        $this.color7  = '#' + toHex(pixels[pixelRedIndex]) + toHex(pixels[pixelRedIndex + 1]) + toHex(pixels[pixelRedIndex + 2]);
      }

      if (id === 9) {
        $this.color9  = '#' + toHex(pixels[pixelRedIndex]) + toHex(pixels[pixelRedIndex + 1]) + toHex(pixels[pixelRedIndex + 2]);
      }

      if (id === 10) {
        $this.color10 = '#' + toHex(pixels[pixelRedIndex]) + toHex(pixels[pixelRedIndex + 1]) + toHex(pixels[pixelRedIndex + 2]);
      }

      jQuery('#cp' + id).css('background', pixelcolor);
    });

    function toHex(n) {
      let hex = n.toString(16);
      while (hex.length < 2) {hex = '0' + hex; }
      return hex;
    }
    // if (id !== 9) {
      jQuery('#modalImageCpicker').modal('show');
    // }
  }

  changeIcon(type: string, icon: string, element) {
    const iconValues = {
      [type]: icon
    };

    $(`.${type}`).parent().removeClass('icon-holder-active');

    if ($(element.target).hasClass('icon-holder')) {
      $(element.target).addClass('icon-holder-active');
    } else {
      $(element.target).parent('.icon-holder').addClass('icon-holder-active');
    }

    this.clientService.httpPutIcon(this.user.client_id, iconValues);
    this.loading = true;
  }

  initTooltip() {
    setTimeout(() => {
      let timeout;
      $('[data-tooltip]').on({
        'mouseenter': function () {
          timeout = setTimeout(() => {
              $('[data-tooltip]').removeClass('hovered');
              $(this).addClass('hovered');
            }, 350);
        },
        'mouseleave' : function () {
          clearTimeout(timeout);
          $(this).removeClass('hovered');
        }
      });
    }, 300);
  }

  private initfontColorForm() {
    this.formFontColor = new FormGroup({
      'color_1': new FormControl(''),
      'color_2': new FormControl(''),
      'color_3': new FormControl(''),
      'color_4': new FormControl(''),
      //'color_5': new FormControl(''),
      'color_6': new FormControl(''),
      'color_7': new FormControl(''),
      'color_8': new FormControl(''),
      'color_9': new FormControl(''),
      'color_10': new FormControl(''),
      'colorIcon': new FormControl(''),
      'font_family': new FormControl(''),
    });
  }

  get url(){
    return this.linksForm.controls;
  }

  private initlinksForm() {
    this.linksForm = new FormGroup({
      'link_website': new FormControl(''),
      'link_short_website': new FormControl(''),
      'link_buy': new FormControl(''),
      'link_sell': new FormControl(''),
      'link_rent': new FormControl(''),
      'link_unsubscribe': new FormControl(''),
      'link_home': new FormControl(''),
      'link_about': new FormControl(''),
      'link_careers': new FormControl(''),
      'link_blog': new FormControl(''),
      'link_facebook': new FormControl(''),
      'link_twitter': new FormControl(''),
      'link_youtube': new FormControl(''),
      'link_instagram': new FormControl(''),
      'link_linkedin': new FormControl(''),
      'link_pinterest': new FormControl(''),
      // 'link_gplus': new FormControl(''),
      'link_ratemyagent': new FormControl(''),
      'link_googlereviews': new FormControl(''),
      'link_contact': new FormControl(''),
      'link_testimonials': new FormControl(''),
      'disclaimer_email': new FormControl(''),
      'disclaimer_print': new FormControl(''),
      'legal_1': new FormControl(''),
      'legal_2': new FormControl(''),
      'legal_3': new FormControl('')
    });
  }

  private initlinksFormEdit() {
    this.linksForm.patchValue({
      'link_website': this.client.link_website,
      'link_short_website': this.client.link_short_website,
      'link_buy': this.client.link_buy,
      'link_sell': this.client.link_sell,
      'link_rent': this.client.link_rent,
      'link_unsubscribe': this.client.link_unsubscribe,
      'link_home': this.client.link_home,
      'link_about': this.client.link_about,
      'link_careers': this.client.link_careers,
      'link_blog': this.client.link_blog,
      'link_facebook': this.client.link_facebook,
      'link_twitter': this.client.link_twitter,
      'link_youtube': this.client.link_youtube,
      'link_instagram': this.client.link_instagram,
      'link_linkedin': this.client.link_linkedin,
      'link_pinterest': this.client.link_pinterest,
      // 'link_gplus': this.client.link_gplus,
      'link_ratemyagent': this.client.link_ratemyagent,
      'link_googlereviews': this.client.link_googlereviews,
      'link_contact': this.client.link_contact,
      'link_testimonials': this.client.link_testimonials,
      'disclaimer_email': this.client.disclaimer_email,
      'disclaimer_print': this.client.disclaimer_print,
      'legal_1': this.client.legal_1,
      'legal_2': this.client.legal_2,
      'legal_3': this.client.legal_3
    });
  }

  private checkUrl() {
    const linksValue = this.linksForm.value;

    const linksGroup = [
      linksValue.link_website,
      linksValue.link_buy,
      linksValue.link_sell,
      linksValue.link_rent,
      linksValue.link_unsubscribe,
      linksValue.link_home,
      linksValue.link_about,
      linksValue.link_careers,
      linksValue.link_blog,
      linksValue.link_testimonials,
      linksValue.link_contact,
    ];

    const linksControl = this.linksForm.controls;

    const linksControlGroup = [
      linksControl.link_website,
      linksControl.link_buy,
      linksControl.link_sell,
      linksControl.link_rent,
      linksControl.link_unsubscribe,
      linksControl.link_home,
      linksControl.link_about,
      linksControl.link_careers,
      linksControl.link_blog,
      linksControl.link_testimonials,
      linksControl.link_contact
    ];


    for (let i = 0; i < linksGroup.length ; i++) {
      if (!this.validateUrl(linksGroup[i])) {
        linksControlGroup[i].setErrors({status : 'INVALID'});

        return false;
      }
    }

    return true;
  }

  private checkSocialUrl() {
    const linksValue = this.linksForm.value;

    const linksGroup = [
      linksValue.link_facebook,
      linksValue.link_twitter,
      linksValue.link_youtube,
      linksValue.link_instagram,
      linksValue.link_linkedin,
      linksValue.link_pinterest,
      linksValue.link_ratemyagent,
      linksValue.link_googlereviews,
    ];

    const linksControl = this.linksForm.controls;

    const linksControlGroup = [
      linksControl.link_facebook,
      linksControl.link_twitter,
      linksControl.link_youtube,
      linksControl.link_instagram,
      linksControl.link_linkedin,
      linksControl.link_pinterest,
      linksControl.link_ratemyagent,
      linksControl.link_googlereviews,
    ];


    for (let i = 0; i < linksGroup.length ; i++) {
      if (!this.validateUrl(linksGroup[i])) {
        linksControlGroup[i].setErrors({status : 'INVALID'});

        return false;
      }
    }

    return true;
  }

  private checkShortUrl() {

    if (!this.validateShortUrl(this.linksForm.value.link_short_website)) {
      this.linksForm.controls.link_short_website.setErrors({status : 'INVALID'});
      return false;
    }

    return true;
  }

  saveLinks() {
    if (!this.checkUrl()) {
      return false;
    }

    if (!this.checkShortUrl()) {
      return false;
    }

    this.linksForm.patchValue({
      'link_website': this.linksForm.get('link_website').value,
      'link_buy': this.linksForm.get('link_buy').value,
      'link_sell': this.linksForm.get('link_sell').value,
      'link_rent': this.linksForm.get('link_rent').value,
      'link_unsubscribe': this.linksForm.get('link_unsubscribe').value,
      'link_home': this.linksForm.get('link_home').value,
      'link_about': this.linksForm.get('link_about').value,
      'link_careers': this.linksForm.get('link_careers').value,
      'link_blog': this.linksForm.get('link_blog').value,
      'disclaimer_email': this.linksForm.get('disclaimer_email').value,
      'disclaimer_print': this.linksForm.get('disclaimer_print').value,
      'link_contact': this.linksForm.get('link_contact').value,
      'link_testimonials': this.linksForm.get('link_testimonials').value,
    });
    const linksValue = this.linksForm.value;


    this.clientService.httpPutLinks(this.user.client_id, linksValue);
    this.loading = true;
  }

  saveSocialLinks() {
    if (!this.checkSocialUrl()) {
      return false;
    }

    this.linksForm.patchValue({
      'link_facebook': this.linksForm.get('link_facebook').value,
      'link_twitter': this.linksForm.get('link_twitter').value,
      'link_youtube': this.linksForm.get('link_youtube').value,
      'link_instagram': this.linksForm.get('link_instagram').value,
      'link_linkedin': this.linksForm.get('link_linkedin').value,
      'link_pinterest': this.linksForm.get('link_pinterest').value,
      // 'link_gplus': this.linksForm.get('link_gplus').value,
      'link_ratemyagent': this.linksForm.get('link_ratemyagent').value,
      'link_googlereviews': this.linksForm.get('link_googlereviews').value,
    });
    const linksValue = this.linksForm.value;


    this.clientService.httpPutLinks(this.user.client_id, linksValue);
    this.loading = true;
  }

  validateUrl(url) {
    const regexp = /((https?\:\/\/)|(http\.))(\S+)(\w{2,4})(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/g;

    if (url === null || url === '') {
      return true;
    }

    if (url.match(regexp)) {
      return true;
    } else {
      swal(
        'Invalid URL!',
        'Please check the URL is correct (for example http://www.example.com)',
        'error'
      );
      return false;
    }
  }

  validateShortUrl(url) {
    const regexp = /((https?\:\/\/)|(http\.))(\S+)(\w{2,4})(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/g;

    if (url === '') {
      return true;
    }

    if (url.match(regexp)) {
      swal(
        'Invalid Website URL!',
        'It is recommend not to include HTTP in the Short Website URL field.',
        'error'
      );

      return false;
    } else {
      return true;
    }
  }

  isLinksSection(type: string) {
    return ['website-links', 'social-links', 'disclaimers'].includes(type);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngAfterViewInit() {
    const $this = this;
    this.initializeColorPicker();
  }
}
