import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Menu } from '../../../../../models/menu.model';
import { AdminService } from '../../../../admin.service';
import { VideoService } from '../../../../../services/video.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Video } from '../../../../../models/video.model';
import swal from 'sweetalert2';

declare const jQuery: any;

@Component({
  selector: 'app-training-videos-videos-dashboard',
  templateUrl: './training-videos-videos-dashboard.component.html',
  styleUrls: ['./training-videos-videos-dashboard.component.css']
})
export class TrainingVideosVideosDashboardComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = true;
  menus: Menu[] = [];
  navigationType = 'link';
  navigationDefault = 'training-videos';

  video: Video;

  constructor(
    private adminService: AdminService,
    private videoService: VideoService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.menus = [
      new Menu('Training', '', [
        new Menu('Training Videos', 'help/training-videos'),
      ]),
      new Menu('Changelogs', '', [
        new Menu('Changelogs', 'help/changelogs')
      ])
    ];
    this.adminService.loadMenu(this.menus);

    this.adminService.routeData.next({
      'route': null,
      default: this.navigationDefault,
      type: this.navigationType
    });

    const videoId = this.route.snapshot.paramMap.get('id');

    if (videoId) {
      this.loading = true;
      this.videoService.httpGetVideo(videoId);
    }

    this.videoService.onGetVideo
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.loading = false;

            if (response.status === 'success') {
              this.video = response.data;

              setTimeout(
                () => {
                  jQuery('table').DataTable({
                    'pagingType': 'full_numbers',
                    // 'oLanguage': { 'sSearch': '', 'sLengthMenu': '_MENU_' },
                    language: {
                      search: '_INPUT_',
                      searchPlaceholder: 'Search',
                      'paginate': {
                        'first': '<i aria-hidden="true" class="fa fa-angle-double-left"></i>',
                        'previous': '<i aria-hidden="true" class="fa fa-angle-left"></i>',
                        'next': '<i aria-hidden="true" class="fa fa-angle-right"></i>',
                        'last': '<i aria-hidden="true" class="fa fa-angle-double-right"></i>'
                      }
                    },
                    'pageLength': 50,
                    'retrieve': true,
                    'order': []
                  });

                  jQuery('input[type=search]').addClass('btn-search');
                  jQuery('input[type=search]').attr('placeholder','Search');
                  jQuery('select').addClass('select_datatable');
                  jQuery('select').append('<option selected value="-1">Filter</option>');
                }, 1000
              );
            }

            if (response.status === 'error') {
              swal('Internal Server Error', 'Contact Dev Team', 'error');
              return this.router.navigateByUrl('/admin/help/training-videos');
            }
          }
        }
      }
    );

    this.videoService.onDeleteVideoItem
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              this.videoService.httpGetVideo(videoId);
            }
            if (response.status === 'error') {
              swal('Internal Server Error', 'Contact Dev Team', 'error');
            }
          }
        }
      }
    );
  }

  onDelete(UID: string) {
    const $this = this;
    swal({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.loading = true;
        $this.videoService.httpDeleteVideoItem(this.video.UID, UID);
      }
    }).catch(swal.noop);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
