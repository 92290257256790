import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-groupsubgroups',
  templateUrl: './groupsubgroups.component.html',
  styleUrls: ['./groupsubgroups.component.scss']
})
export class GroupsubgroupsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
