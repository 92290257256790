import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Client } from '../../../../models/client.model';
import { Group } from '../../../../models/group.model';
import { ProTheme } from '../../../../models/pro-theme.model';
import { EditorOptions } from '../../../../interface/admin-pros.interface';
import { AdminService } from '../../../../admin/admin.service';
import { ClientService } from '../../../../services/client.service';
import { GroupService } from '../../../../services/group.service';
import { ProThemeService } from '../../../../services/pro-theme.service';
import { StorageService } from '../../../../services/storage.service';

import swal from 'sweetalert2';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-pros-themes-edit',
  templateUrl: './admin-pros-themes-edit.component.html',
  styleUrls: ['./admin-pros-themes-edit.component.scss'],
})
export class AdminProsThemesEditComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = false;
  editMode = false;
  duplicateMode = false;

  proThemeForm: FormGroup;
  clients: Client[] = [];
  groups: Group[] = [];

  proTheme: ProTheme;

  product: string;
  editorOptions: EditorOptions = { theme: 'vs-dark', language: 'html' };
  code = '';

  constructor(
    public adminService: AdminService,
    private proThemeService: ProThemeService,
    private groupService: GroupService,
    private storageService: StorageService,
    private clientService: ClientService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.showLoading(true);
    this.product = this.route.snapshot.paramMap.get('product');
    const proThemeId = this.route.snapshot.paramMap.get('id');
    this.duplicateMode = this.route.snapshot.paramMap.get('duplicate') ? true : false;

    this.groupService.httpGetGroupsNew();

    if (proThemeId && !this.duplicateMode) {
      this.editMode = true;
    }

    this.loadInitialData(proThemeId);
    this.initForms();
  }

  private loadInitialData(proThemeId?: string): void {
    this.onLoadGroups();
    this.onLoadClients(proThemeId);
    this.onCreateTheme();
    this.onLoadTheme();
    this.onUpdateTheme();
    this.onUploadImage();
  }

  private onLoadGroups(): void {
    this.groupService.onGetGroups.pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response) {
        if (response.status === 'success') {
          this.groups = response.data;

          this.clientService.httpGetClientsNew({}, 'list');
        }

        if (response.status === 'error') {
          this.handleError('Error getting groups.');
          this.router.navigateByUrl(`/admin/pros/themes/${this.product}`);
        }
      }
    });
  }

  private onLoadClients(proThemeId: string): void {
    this.clientService.onGetClients.pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response) {
        this.showLoading(false);

        if (response.status === 'success') {
          this.clients = response.data;

          if (proThemeId) {
            this.proThemeService.httpGet(proThemeId);
            this.showLoading(true);
          }
        }

        if (response.status === 'error') {
          this.handleError('Error getting Clients.');
          this.router.navigateByUrl(`/admin/pros/themes/${this.product}`);
        }
      }
    });
  }

  private onCreateTheme(): void {
    this.proThemeService.onPost.pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response) {
        this.showLoading(false);

        if (response.status === 'success') {
          this.proTheme = response.data;
          this.toastrService.success('Pro Theme created');
          this.router.navigateByUrl(`/admin/pros/themes/${this.product}/${this.proTheme.UID}/edit`);
        }

        if (response.status === 'VALIDATION_ERROR') {
          swal({
            title: 'Validation Error',
            text: response.message,
            type: 'error',
          });
        }

        if (response.status === 'error') {
          this.handleError('Error getting Pro Block.');
        }
      }
    });
  }

  private onLoadTheme(): void {
    this.proThemeService.onGet.pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response) {
        this.showLoading(false);

        if (response.status === 'success') {
          this.proTheme = response.data;
          this.initFormEdit();
        }

        if (response.status === 'error') {
          this.handleError('Error getting Pro Theme.');
        }
      }
    });
  }

  private onUpdateTheme(): void {
    this.proThemeService.onPut.pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response) {
        this.showLoading(false);

        if (response.status === 'success') {
          this.toastrService.success('Pro Theme updated');
        }

        if (response.status === 'error') {
          this.handleError('Error updating Theme.');
        }
      }
    });
  }

  private onUploadImage(): void {
    this.storageService.onUpload.pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response) {
        this.showLoading(false);

        if (response.status === 'success') {
          this.proThemeForm.patchValue({
            thumbnail: response.data,
          });
        }

        if (response.status === 'error') {
          this.handleError('Error uploading file. Try again.');
        }
      }
    });
  }

  private initForms(): void {
    this.proThemeForm = new FormGroup({
      group_id: new FormControl([], Validators.required),
      excluded_group_id: new FormControl([]),
      client_id: new FormControl([], Validators.required),
      title: new FormControl('', Validators.required),
      description: new FormControl(''),
      thumbnail: new FormControl(),
      html: new FormControl('', Validators.required),
      external_css: new FormControl(''),
      external_js: new FormControl(''),
      external_fonts: new FormControl(''),
      sort: new FormControl(0, Validators.required),
      is_active: new FormControl('', Validators.required),
    });
  }

  private initFormEdit(): void {
    this.proThemeForm.patchValue({
      title: this.proTheme.title,
      description: this.proTheme.description,
      thumbnail: this.proTheme.thumbnail,
      html: this.proTheme.html,
      external_css: this.proTheme.external_css,
      external_js: this.proTheme.external_js,
      external_fonts: this.proTheme.external_fonts,
      sort: this.proTheme.sort,
      is_active: this.proTheme.is_active,
    });

    if (this.proTheme.whitelist === 'all') {
      this.proThemeForm.patchValue({
        group_id: ['all'],
      });
    } else {
      const groupIds = this.proTheme.groups.map((group) => group.UID);

      this.proThemeForm.patchValue({
        group_id: groupIds,
      });
    }

    if (this.proTheme.client_whitelist === 'all') {
      this.proThemeForm.patchValue({
        client_id: ['all'],
      });
    } else {
      const clientIds = this.proTheme.clients.map((client) => client.ID);

      this.proThemeForm.patchValue({
        client_id: clientIds,
      });
    }

    if (this.proTheme.excluded_groups && this.proTheme.excluded_groups.length) {
      const excludedGroupIds = this.proTheme.excluded_groups.map((group) => group.UID);

      this.proThemeForm.patchValue({
        excluded_group_id: excludedGroupIds,
      });
    }
  }

  private showLoading(value: boolean): void {
    this.loading = value;
  }

  private handleError(message: string): void {
    this.showLoading(false);
    swal(message, 'Contact Dev Team', 'error');
  }

  onUpload(files: File[]): void {
    if (files.length === 1) {
      this.storageService.httpUpload(files[0]);
      this.showLoading(true);
    }
  }

  onSubmit(): void {
    const proThemeFormValues = this.proThemeForm.value;

    if (this.proThemeForm.valid) {
      if (this.editMode) {
        this.proThemeService.httpPut(this.proTheme.UID, proThemeFormValues);
      } else {
        proThemeFormValues.product = this.product;
        this.proThemeService.httpPost(proThemeFormValues);
      }

      this.showLoading(true);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
