import { keys } from './keys';

export const environment = {
  app: 'Designly',
  htmlEndpoint: 'https://stage-app.designly.com.au',
  apiEndpoint: 'https://stage-api.mydesign3.websiteblue.com',
  s3BucketUrl: 'https://s3-ap-southeast-2.amazonaws.com/stage-html-md3.wbclients.com/resources/',
  newS3BucketUrl: 'https://s3-ap-southeast-2.amazonaws.com/stage-html-md3.wbclients.com/',
  particaEndpoint: 'https://api.dev.partica.online',
  particaViewerEndpoint: 'https://viewerdev.cdn.partica.online/viewer',
  particaArticleEndpoint: 'https://articles.dev.partica.online',
  vaultClientId: keys.VAULT_CLIENT_ID,
  particaPartnerId: keys.PARTICA_PARTNER_ID,
  slackWebHook: keys.SLACK_WEBHOOK,
  domainClientId: keys.DOMAIN_CLIENT_ID,
  vaultUkClientId: keys.VAULT_UK_CLIENT_ID,
  isSignifi: 'no',
  isRaywhite: 'no',
  isUk: false,
  production: false,
  skin: 'designly',
  crmUrl: 'https://stage.crm.designly.com.au',
  crmEndpoint: 'https://stage.api.crm.designly.com.au',
  newApiEndpoint: 'https://staging.api.designly.com.au',
  proBuilderUrl: 'https://staging-pro.designly.com.au',
  chargebeeSiteId: 'designly-test',
  satismeterWriteKey: keys.SATISMETER_WRITE_KEY,
  cachedS3BucketUrl: 'https://stage-html-md3.wbclients.com/resources/',
};
