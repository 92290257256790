import { Component, OnInit, OnDestroy } from '@angular/core';
import { ClientService } from '../../../../services/client.service';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService } from '../../../../services/account.service';
import { UserService } from '../../../../services/user.service';

declare var swal: any;
declare var $: any;

@Component({
  selector: 'app-users-dashboard',
  templateUrl: './users-dashboard.component.html',
  styleUrls: ['./users-dashboard.component.scss']
})
export class UsersDashboardComponent implements OnInit, OnDestroy {
  loading = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  clientId: number;
  accounts = [];

  client: any;
  users = [];
  usersHolder = [];

  searchTerm = "";
  searchTimeout = null;
  isEnableWebbooks = false;
  isEnableProposals = false;
  isEnableOwnerReports = false;
  isAllUser = false;
  selectedUser: any;
  designlyProModalTitle = 'Enable Designly Modules for all users';

  constructor(
    private clientService: ClientService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
      (params: Params) => {
        this.clientId = +params['id'];

        const clientID = (isNaN(this.clientId)) ? 0 : this.clientId;
        this.clientService.httpGetClient(clientID);
        this.loading = true;
      }
    );

    this.userService.usersChanged
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined' && response.status === 'success') {
            this.loading = false;

            const users = response.data.map((user: any) => {
              var accessLevel = "user";
              if (user.is_admin) {
                accessLevel = "admin";
              } else if (user.is_group_admin) {
                accessLevel = "group_admin";
              }
              return { ...user, accessLevel: accessLevel }
            })

            this.users = users;
            this.usersHolder = users;
          }
        }
      }
    );

    this.clientService.clientChanged
    .takeUntil(this.destroy$)
    .subscribe(
      (client: any) => {
        if (typeof client.status !== 'undefined' && client.status == 'error') {
          this.router.navigate(['admin','clients']);
          return;
        }
        if (typeof client !== 'undefined' && typeof client.ID !== 'undefined') {
          this.userService.httpGetUsers({
            client_id: client.ID
          });

          this.client = client;
        }
      }
    );

    this.userService.userOperation
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.ID !== 'undefined') {
            this.userService.httpGetUsers({
              client_id: this.client.ID
            });
          }
        }
      }
    );

    this.userService.userAccessChanged
    .takeUntil(this.destroy$)
    .subscribe(
      (users: any) => {
        if ( typeof users !== 'undefined' && users.status === 'success' ) {
          this.loading = false;
          swal({
            type: 'success',
            title: 'Changes has been saved',
            showConfirmButton: false,
            timer: 1500,
            allowEscapeKey: false,
            allowOutsideClick: false
          });

          setTimeout(() => {
            location.reload();
          }, 1000);
        }
      }
    );

  }

  resetFirstLogin(ID: number) {
    this.userService.httpPutUser(ID, <any>{
      is_first: 1,
      template_popup: 0
    });

    this.loading = true;
  }

  onSetGroupAdmin(ID: number) {
    const $this = this;
    swal({
      title: 'Are you sure you want to make this user as a group admin?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed'
    }).then(function () {
      $this.userService.httpPutSetGroupAdmin(ID, 'set');
      $this.loading = true;
    }).catch(swal.noop);
  }

  onRemoveGroupAdmin(ID: number) {
    const $this = this;
    swal({
      title: 'Are you sure you want to remove this user as a group admin?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed'
    }).then(function () {
      $this.userService.httpPutSetGroupAdmin(ID, 'remove');
      $this.loading = true;
    }).catch(swal.noop);
  }

  onGiveUsersAdminAccess() {
    const $this = this;
    swal({
      title: 'Are you sure you want to give admin privileges to all users?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed'
    }).then(function () {
      $this.loading = true;
      $this.userService.adminAccess($this.clientId).subscribe((response: any) => {
        $this.loading = false;
          swal({
            type: 'success',
            title: 'Changes has been saved',
            showConfirmButton: false,
            timer: 1500,
            allowEscapeKey: false,
            allowOutsideClick: false
          });

          setTimeout(() => {
            location.reload();
          }, 1000);
      });
    }).catch(swal.noop);
  }

  getCheckboxAttribute() {
    if (this.isEnableWebbooks === true || this.isEnableProposals === true || this.isEnableOwnerReports === true) {
      return false
    }
    return true;
  }

  getCheckboxAttributeDisable(mode = 'all') {
    if (mode === 'all') {
      if (this.isEnableWebbooks === true && this.isEnableProposals === true && this.isEnableOwnerReports === true) {
        return false;
      }
      return true;
    } else {
      if (!this.isEnableWebbooks && !this.isEnableProposals && !this.isEnableOwnerReports) {
        return true;
      }
      return false;
    }
  }

  showEnableDesignlyProUsersModal() {
    this.designlyProModalTitle = 'Enable Designly Modules for all users';
    this.isAllUser = true;
    this.isEnableWebbooks = false;
    this.isEnableProposals = false;
    this.isEnableOwnerReports = false;
    $('#enableDesignlyProModal').modal('show');
  }

  showEnableDesignlyProUserModal(user) {
    this.selectedUser = user;
    this.designlyProModalTitle = 'Enable Designly Modules for ' + user.firstname + ' ' + user.lastname;
    this.isAllUser = false;
    this.isEnableWebbooks = user.enable_webbooks;
    this.isEnableProposals = user.enable_proposals;
    this.isEnableOwnerReports = user.enable_owner_reports;
    $('#enableDesignlyProModal').modal('show');
  }

  onUpdateUsersDesignlyPro(type = 1) {
    const $this = this;
    let title = type == 1 ? 'Enable' : 'Disable'
    swal({
      title: 'Are you sure you want to ' + title + ' for all users?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed'
    }).then(function () {
      $this.loading = true;
      $this.userService.designlyProAccessUsers($this.clientId, type, $this.isEnableWebbooks, $this.isEnableProposals, $this.isEnableOwnerReports).subscribe((response: any) => {
        $this.loading = false;
          swal({
            type: 'success',
            title: 'Changes has been saved',
            showConfirmButton: false,
            timer: 1500,
            allowEscapeKey: false,
            allowOutsideClick: false
          });

          setTimeout(() => {
            location.reload();
          }, 1000);
      });
    }).catch(swal.noop);
  }

  onUpdateUserDesignlyPro(type = 1) {
    const $this = this;
    let title = type == 1 ? 'Enable' : 'Disable'
    swal({
      title: 'Are you sure you want to ' + title + ' for ' + this.selectedUser.firstname + ' ' + this.selectedUser.lastname,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed'
    }).then(function () {
      $this.loading = true;
      $this.userService.designlyProAccessUser($this.clientId, $this.selectedUser.ID ,type, $this.isEnableWebbooks, $this.isEnableProposals, $this.isEnableOwnerReports).subscribe((response: any) => {
        $this.loading = false;
          swal({
            type: 'success',
            title: 'Changes has been saved',
            showConfirmButton: false,
            timer: 1500,
            allowEscapeKey: false,
            allowOutsideClick: false
          });

          setTimeout(() => {
            location.reload();
          }, 1000);
      });
    }).catch(swal.noop);
  }

  onDisableDesignlyPro(ID: number) {
    const $this = this;
    swal({
      title: 'Are you sure you want to enable Designly for this user?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed'
    }).then(function () {
      $this.loading = true;
      $this.userService.designlyProAccess(ID, 'remove').subscribe((response: any) => {
        $this.loading = false;
          swal({
            type: 'success',
            title: 'Changes has been saved',
            showConfirmButton: false,
            timer: 1500,
            allowEscapeKey: false,
            allowOutsideClick: false
          });

          setTimeout(() => {
            location.reload();
          }, 1000);
      });
    }).catch(swal.noop);
  }

  onChangeAccessLevel(data: any, user: any) {
    const oldValue = data.target.getAttribute("data-value");
    const userId = user.ID;

    const $this = this;
    swal({
      title: 'Are you sure you want to change Access Level for this user?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed',
    }).then(function () {
      $this.loading = true;
      $this.userService.accessLevel(userId, data.target.value).subscribe((response: any) => {
        $this.loading = false;
          swal({
            type: 'success',
            title: 'Changes has been saved',
            showConfirmButton: false,
            timer: 1500,
            allowEscapeKey: false,
            allowOutsideClick: false
          });

          setTimeout(() => {
            location.reload();
          }, 1000);
      });
    }, () => {
      this.users = this.users.map((user: any) => {
        if (userId === user.ID) {
          user.accessLevel = oldValue;
        }
        return user;
      })
    }).catch(swal.noop);


  }

  onFilter() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout)
    }

    this.searchTimeout = setTimeout(() => {
      if (!this.searchTerm) {
        this.users = [...this.usersHolder]
        return;
      }
      this.users = this.usersHolder.filter((user) => {
        return user.firstname.toLocaleLowerCase().includes(this.searchTerm.toLocaleLowerCase()) || 
        user.lastname.toLocaleLowerCase().includes(this.searchTerm.toLocaleLowerCase()) ||
        `${user.agent_id}`.toLocaleLowerCase().includes(this.searchTerm.toLocaleLowerCase());
      });
    }, 250);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
