import { HttpParams,   HttpRequest, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import 'rxjs/Rx';

import { APP_CONFIG } from '../app.config';
import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';

@Injectable()
export class StorageRevampService {
  onGetFolders = new Subject<any[]>();
  onGetImages = new Subject<any>();
  storageOperation = new Subject<any>();

  private storages = [];

  headers = new Headers();

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.headers.append('Authorization', `Bearer ${this.authService.getToken()}`);
  }

  setFolders(storages: any[]) {
    this.storages = storages;
    this.onGetFolders.next(this.storages);
  }

  setImages(storages: any[]) {
    this.storages = storages;
    this.onGetImages.next(this.storages);
  }

  getStorages() {
    return this.storages.slice();
  }

  getClient(id: number) {
    return this.storages[id];
  }

  httpGetFolders(params: any = {}) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    let queryString = httpParams.toString();

    if (queryString) {
      queryString = '?' + queryString;
    }

    return this.httpClient.get(
      this.appConfig.NEW_API_ENDPOINT + '/files/folders' + queryString
    )
    .pipe(map(
      (response: any) => {
        if (response) {
          if (typeof response.data !== 'undefined') {
            if (response.data) {
              return response;
            }
          }
        }

        return [];
      }
    ))
    .subscribe(
      (response: any[]) => {
        this.setFolders(response);
      }
    );
  }

  httpGetImages(params: any = {}) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    let queryString = httpParams.toString();

    if (queryString) {
      queryString = '?' + queryString;
    }

    return this.httpClient.get(
      this.appConfig.NEW_API_ENDPOINT + '/files' + queryString
    )
    .pipe(map(
      (response: any) => {
        if (response) {
          if (typeof response.data !== 'undefined') {
            if (response.data) {
              return response;
            }
          }
        }

        return [];
      }
    ))
    .subscribe(
      (response: any[]) => {
        this.setImages(response);
      }
    );
  }

  httpPostFolder(data: any) {
    const formData = new FormData();
    formData.append('name', data['name']);
    formData.append('type', data['type']);
    formData.append('library_type', data['library_type']);
    formData.append('parent_files_folder_id', data['parent_files_folder_id']);

    return this.httpClient.post(`${this.appConfig.NEW_API_ENDPOINT}/files/folders`, formData)
    .subscribe(
      (response: any) => {
        this.storageOperation.next(response);
      },
      (response: any) => {
        this.storageOperation.next(response.error);
      }
    );
  }

  httpPutFolder(uuid: any, data: any) {
    const putData = {
      name: data['name']
    };
    return this.httpClient.put(`${this.appConfig.NEW_API_ENDPOINT}/files/folders/${uuid}`, putData)
    .subscribe(
      (response: any) => {
        this.storageOperation.next(response);
      },
      (response: any) => {
        this.storageOperation.next(response.error);
      }
    );
  }

  httpDeleteFolder(uuid: number) {
    return this.httpClient.delete(`${this.appConfig.NEW_API_ENDPOINT}/files/folders/${uuid}`)
    .subscribe(
        (response: any) => {
            this.storageOperation.next(response);
        },
        (response: any) => {
            this.storageOperation.next(response.error);
        }
    );
  }

  httpPostImage(data: any) {
    const formData = new FormData();

    formData.append('file', data['file']);
    formData.append('type', data['type']);
    formData.append('library_type', data['library_type']);
    formData.append('files_folder_id', data['files_folder_id']);

    if ( data['overwrite'] ) {
      formData.append('overwrite', data['overwrite']);
    }

    return this.httpClient.post(`${this.appConfig.NEW_API_ENDPOINT}/files`, formData)
    .subscribe(
      (response: any) => {
        response.fileIndex = data['file_index'];
        this.storageOperation.next(response);
      },
      (response: any) => {
        response.error.fileIndex = data['file_index'];
        this.storageOperation.next(response.error);
      }
    );
  }

  httpMoveImage(uuid: any, data: any) {
    return this.httpClient.post(`${this.appConfig.NEW_API_ENDPOINT}/files/${uuid}/move`, data)
    .subscribe(
      (response: any) => {
        this.storageOperation.next(response);
      },
      (response: any) => {
        this.storageOperation.next(response.error);
      }
    );
  }

  httpDeleteImage(uuid: number, params: any = {}) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    let queryString = httpParams.toString();

    if (queryString) {
      queryString = '?' + queryString;
    }

    return this.httpClient.delete(`${this.appConfig.NEW_API_ENDPOINT}/files/${uuid}${queryString}`)
    .subscribe(
        (response: any) => {
            this.storageOperation.next(response);
        },
        (response: any) => {
            this.storageOperation.next(response.error);
        }
    );
  }

}
