import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-vaultre-sso',
  templateUrl: './vaultre-sso.component.html',
  styleUrls: ['./vaultre-sso.component.scss']
})
export class VaultreSsoComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    const token = this.route.snapshot.queryParamMap.get('token');

    if (token) {
      this.authService.setAuthStorage({
        token,
        expires: new Date().getTime() + 3600000,
      });
    }
  }

}
