import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { APP_CONFIG } from '../app.config';
import { Proposal } from '../models/proposal.model';

@Injectable({
  providedIn: 'root'
})
export class ProposalService {
  onGetAll = new Subject();
  onGetStats = new Subject();
  onGet = new Subject();
  onPost = new Subject();
  onUpdate = new Subject();
  onBuild = new Subject();
  onDelete = new Subject();
  onPreview = new Subject();
  onUpdateStatus = new Subject();
  onDuplicate = new Subject();
  onPublish = new Subject();
  onSendEmail = new Subject();
  onAddNotification = new Subject();
  onGetAllNotifications = new Subject();
  onDeleteNotifications = new Subject();
  onUpdateInvestments = new Subject();
  onUpdateMenuManager = new Subject();

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    private httpClient: HttpClient
  ) { }

  httpGetAll(params: any = {}) {
    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/proposals`, {
      params
    })
    .subscribe(
      (response: any) => {
        this.onGetAll.next(response);
      },
      (response: any) => {
        this.onGetAll.next(response.error);
      }
    );
  }

  httpGetStats() {
    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/proposals/stats`)
    .subscribe(
      (response: any) => {
        this.onGetStats.next(response);
      },
      (response: any) => {
        this.onGetStats.next(response.error);
      }
    );
  }

  get(UID: string) {
    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/proposals/${UID}`);
  }

  httpGet(UID: string) {
    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/proposals/${UID}`)
    .subscribe(
      (response: any) => {
        this.onGet.next(response);
      },
      (response: any) => {
        this.onGet.next(response.error);
      }
    );
  }

  save(data: any) {
    return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/proposals`, data);
  }

  httpPost(data: any) {
    return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/proposals`, data)
    .subscribe(
      (response: any) => {
        this.onPost.next(response);
      },
      (response: any) => {
        this.onPost.next(response.error);
      }
    );
  }

  update(UID: string, data: any) {
    return this.httpClient.put(`${this.appConfig.API_ENDPOINT}/proposals/${UID}`, data);
  }

  httpUpdate(UID: string, data: any) {
    return this.httpClient.put(`${this.appConfig.API_ENDPOINT}/proposals/${UID}`, data)
    .subscribe(
      (response: any) => {
        this.onUpdate.next(response);
      },
      (response: any) => {
        this.onUpdate.next(response.error);
      }
    );
  }

  httpUpdateInvestments(UID: string, listingID: any, data: any) {
    return this.httpClient.put(`${this.appConfig.API_ENDPOINT}/proposals/${UID}/investment/${listingID}`, data)
    .subscribe(
      (response: any) => {
        this.onUpdateInvestments.next(response);
      },
      (response: any) => {
        this.onUpdateInvestments.next(response.error);
      }
    );
  }

  httpDelete(UID: string) {
    return this.httpClient.delete(`${this.appConfig.API_ENDPOINT}/proposals/${UID}`)
    .subscribe(
      (response: any) => {
        this.onDelete.next(response);
      },
      (response: any) => {
        this.onDelete.next(response.error);
      }
    );
  }

  httpBuild(UID: string, data: any) {
    return this.httpClient.put(`${this.appConfig.API_ENDPOINT}/proposals/${UID}/build`, data)
    .subscribe(
      (response: any) => {
        this.onBuild.next(response);
      },
      (response: any) => {
        this.onBuild.next(response.error);
      }
    );
  }

  preview(UID: string, data: any) {
    return this.httpClient.put(`${this.appConfig.API_ENDPOINT}/proposals/${UID}/preview`, data)
  }

  httpPreview(UID: string, data: any) {
    return this.httpClient.put(`${this.appConfig.API_ENDPOINT}/proposals/${UID}/preview`, data)
    .subscribe(
      (response: any) => {
        this.onPreview.next(response);
      },
      (response: any) => {
        this.onPreview.next(response.error);
      }
    );
  }

  httpPublish(UID: string) {
    return this.httpClient.put(`${this.appConfig.API_ENDPOINT}/proposals/${UID}/publish`, {
      publish: true
    })
    .subscribe(
      (response: any) => {
        this.onPublish.next(response);
      },
      (response: any) => {
        this.onPublish.next(response.error);
      }
    );
  }

  httpUpdateStatus(UID: string, data: any) {
    return this.httpClient.put(`${this.appConfig.API_ENDPOINT}/proposals/${UID}/status`, data)
    .subscribe(
      (response: any) => {
        this.onUpdateStatus.next(response);
      },
      (response: any) => {
        this.onUpdateStatus.next(response.error);
      }
    );
  }

  httpDuplicate(UID: string, data: any = {}) {
    return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/proposals/${UID}/duplicate`, {
      duplicate: true,
      ...data
    })
    .subscribe(
      (response: any) => {
        this.onDuplicate.next(response);
      },
      (response: any) => {
        this.onDuplicate.next(response.error);
      }
    );
  }

  httpSendEmail(UID: string, data: any) {
    return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/proposals/${UID}/email`, data)
    .subscribe(
      (response: any) => {
        this.onSendEmail.next(response);
      },
      (response: any) => {
        this.onSendEmail.next(response.error);
      }
    );
  }

  httpAddNotification(data: any) {
    return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/proposals/notification`, data)
    .subscribe(
      (response: any) => {
        this.onAddNotification.next(response);
      },
      (response: any) => {
        this.onAddNotification.next(response.error);
      }
    );
  }

  httpGetAllNotifications(params: any = {}) {
    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/proposals/notifications`, {
      params
    })
    .subscribe(
      (response: any) => {
        this.onGetAllNotifications.next(response);
      },
      (response: any) => {
        this.onGetAllNotifications.next(response.error);
      }
    );
  }

  httpDeleteNotifications() {
    return this.httpClient.delete(`${this.appConfig.API_ENDPOINT}/proposals/notifications/delete`)
    .subscribe(
      (response: any) => {
        this.onDeleteNotifications.next(response);
      },
      (response: any) => {
        this.onDeleteNotifications.next(response.error);
      }
    );
  }

  UpdatePropertyDisplayAddress(UID: string, params: string) {
    return this.httpClient.put(`${this.appConfig.API_ENDPOINT}/proposals/${UID}/property/displayaddress`, params);
  }

  httpUpdateMenuManager(UID: string, data: any) {
    return this.httpClient.put(`${this.appConfig.NEW_API_ENDPOINT}/proposals/${UID}/menu-manager`, data)
        .subscribe(
            (response: any) => {
              this.onUpdateMenuManager.next(response);
            },
            (response: any) => {
              this.onUpdateMenuManager.next(response.error);
            }
        );
  }

}
