import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';
import { APP_CONFIG } from '../app.config';
import { WebBook } from '../models/webbook.model';

@Injectable()
export class WebBookService {
    onGetAll = new Subject();
    onGet = new Subject();
    onPost = new Subject();
    onUpdate = new Subject();
    onBuild = new Subject();
    onPreview = new Subject();
    onPublish = new Subject();
    onDuplicate = new Subject();
    onDelete = new Subject();
    onGenerateQr = new Subject();
    onUpdateMenuManager = new Subject();

    constructor(
        @Inject(APP_CONFIG) private appConfig,
        private httpClient: HttpClient
    ) { }

    httpGetAll(params: any = {}) {
        let httpParams = new HttpParams();

        for (const p of Object.keys(params)) {
            httpParams = httpParams.append(p, params[p]);
        }

        return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/webbooks`, {
            params: httpParams
        })
        .subscribe(
            (response: any) => {
                this.onGetAll.next(response);
            },
            (response: any) => {
                this.onGetAll.next(response.error);
            }
        );
    }

    httpGet(UID: string) {
        return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/webbooks/${UID}`)
        .subscribe(
            (response: any) => {
                this.onGet.next(response);
            },
            (response: any) => {
                this.onGet.next(response.error);
            }
        );
    }

    httpPost(data: any) {
        return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/webbooks`, data)
        .subscribe(
            (response: any) => {
                this.onPost.next(response);
            },
            (response: any) => {
                this.onPost.next(response.error);
            }
        );
    }

    httpUpdate(UID: string, data: WebBook) {
        return this.httpClient.put(`${this.appConfig.API_ENDPOINT}/webbooks/${UID}`, data)
        .subscribe(
            (response: any) => {
                this.onUpdate.next(response);
            },
            (response: any) => {
                this.onUpdate.next(response.error);
            }
        );
    }

    httpBuild(UID: string, data: any) {
        return this.httpClient.put(`${this.appConfig.API_ENDPOINT}/webbooks/${UID}/build`, data)
        .subscribe(
            (response: any) => {
                this.onBuild.next(response);
            },
            (response: any) => {
                this.onBuild.next(response.error);
            }
        );
    }

    httpPreview(UID: string, data: any, offersUrl = null) {
        if (offersUrl !== null) {
            data.offers_url = offersUrl;
        }
        return this.httpClient.put(`${this.appConfig.API_ENDPOINT}/webbooks/${UID}/preview`, data)
        .subscribe(
            (response: any) => {
                this.onPreview.next(response);
            },
            (response: any) => {
                this.onPreview.next(response.error);
            }
        );
    }

    httpPublish(UID: string, data: any) {
        return this.httpClient.put(`${this.appConfig.API_ENDPOINT}/webbooks/${UID}/publish`, data)
        .subscribe(
            (response: any) => {
                this.onPublish.next(response);
            },
            (response: any) => {
                this.onPublish.next(response.error);
            }
        );
    }

    httpDuplicate(UID: string) {
        return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/webbooks/${UID}/duplicate`, {})
        .subscribe(
            (response: any) => {
                this.onDuplicate.next(response);
            },
            (response: any) => {
                this.onDuplicate.next(response.error);
            }
        );
    }

    httpDelete(UID: string) {
        return this.httpClient.delete(`${this.appConfig.API_ENDPOINT}/webbooks/${UID}`)
        .subscribe(
            (response: any) => {
                this.onDelete.next(response);
            },
            (response: any) => {
                this.onDelete.next(response.error);
            }
        );
    }

    httpGenerateQr(url: string) {
        return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/webbooks/qr/generate`, {
            value: url
        })
        .subscribe(
            (response: any) => {
                this.onGenerateQr.next(response);
            },
            (response: any) => {
                this.onGenerateQr.next(response.error);
            }
        );
    }

    httpUpdateMenuManager(UID: string, data: any) {
        return this.httpClient.put(`${this.appConfig.NEW_API_ENDPOINT}/webbooks/${UID}/menu-manager`, data)
            .subscribe(
                (response: any) => {
                    this.onUpdateMenuManager.next(response);
                },
                (response: any) => {
                    this.onUpdateMenuManager.next(response.error);
                }
            );
    }

}
