<div class="page-content container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div [class.panel-box-shadow]="page !== 'builder'" [class.panel]="page !== 'builder'">
        <form [formGroup]="supportForm" (ngSubmit)="onSubmit()" class="form-horizontal support-form">
          <h2 [class.h2-pad]="page !== 'builder'" [class.h2-build]="page === 'builder'">Hi {{ authUser?.user?.firstname }} {{ authUser?.user?.lastname }}</h2>
          <div class="panel-body">
            <p>Need assistance? We're here to help! Submit a detailed request below to our support team and we will get back to you as soon as possible.</p>
            <div class="margin-top-30">
              <!-- hidden elements  -->
              <div class="form-group hidden">
                <div class="col-md-3">
                  <label class="control-label" for="firstname">Name<i class="text-danger">*</i></label>
                  <input type="text" class="form-control" id="firstname" placeholder="First Name" autocomplete="off" formControlName="firstname" />
                </div>
                <div class="col-md-3">
                  <label class="control-label" for="lastname">&nbsp;</label>
                  <input type="text" class="form-control" id="lastname" placeholder="Last Name" autocomplete="off" formControlName="lastname" />
                </div>
                <div class="col-md-6">
                  <label class="control-label" for="office">Office Name<i class="text-danger">*</i></label>
                  <input type="text" class="form-control" id="office" autocomplete="off" formControlName="office" />
                </div>
              </div>
              <div class="form-group hidden">
                <div class="col-sm-3">
                  <label class="control-label" for="phone">Phone<i class="text-danger">*</i></label>
                  <input type="phone" class="form-control" id="phone" autocomplete="off" formControlName="phone" />
                </div>

                <div class="col-sm-4">
                  <label class="control-label" for="email">Email<i class="text-danger">*</i></label>
                  <input type="email" class="form-control" id="email" autocomplete="off" formControlName="email" />
                </div>
              </div>
              
              <!-- template option -->
              <div class="template-option">
                <p><b>What type of assistance are you needing?</b></p>
                <select class="intro-template-filter" formControlName="type">
                  <option *ngFor="let option of options" [value]="option.value">{{ option.text }}</option>
                </select>
              </div>

              <!-- investigation requirements -->
              <!-- bug or issues  -->
              <div class="investigation-reqs" *ngIf="supportForm.get('type').value === 'bug'">
                <p><strong>To help us investigate an issue please provide</strong></p>
                <div class="form-group">
                  <ol>
                    <li>Steps to reproduce the issue/s</li>
                    <li>The expected result</li>
                    <li>Actual result</li>
                  </ol>
                </div>
              </div>
              <!-- template transfer -->
              <div class="template-transfer" *ngIf="supportForm.get('type').value === 'transfer'">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="control-label">Are you wanting to transfer templates from one account to another?</label>
                  </div>
                </div>
                <div class="col-sm-12 row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="control-label bold" for="officeLoc">Office name templates are located</label>
                      <input type="text" class="form-control" placeholder="Insert office name or office ID" formControlName="officeLoc" />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="control-label bold" for="officeTransfer">Office name templates are to be transferred to</label>
                      <input type="text" class="form-control" placeholder="Insert office name or office ID" formControlName="officeTransfer" />
                    </div>
                  </div>
                </div>
              </div>

              <!-- personalised templates -->
              <div class="personalised-templates" *ngIf="supportForm.get('type').value === 'template'">
                <strong>Looking for more personalised templates?</strong> <br/>
                <label>We offer custom templates and elements to fit any requirements.</label>
                
                <div class="template-checkbox">
                  <p><strong>Type of template/s?</strong></p>
                  <label *ngFor="let type of templateTypes">
                    <input type="checkbox" [formControl]="supportForm.get('templateOptions').get(type)"> {{ type }}
                  </label>
                </div>
              </div>

              <!-- textarea for information description -->
              <div class="template-desc">
                <div class="form-group">
                  <div class="col-sm-12">
                    <textarea formControlName="information" class="form-control col-sm-12" rows="6" placeholder="Please provide as much information as possible regarding your inquiry"></textarea>
                  </div>
                </div>
              </div>

              <!-- upload screenshot -->
              <div class="template-screenshot">
                <div class="form-group">
                  <div *ngIf="page === 'builder'" class="col-md-12">
                    <strong>if you have a screenshot or example you would like to include, please feel free to upload it.</strong> <br />
                    <label class="file-upload">
                      <input #fileAttached class="form-control support-file-upload" type="file" accept="image/*" id="fileAttached" formControlName="fileAttached" multiple />
                    </label>
                      
                    <button type="button" (click)="takeScreenshot()" class="screenshot-btn btn btn-primary">Take Screenshot</button>
                    
                    <span class="screenshot-count text-success" *ngIf="builder_screenshots.length > 0">{{ builder_screenshots.length }} screenshot uploaded</span>
                    <!-- <p><small class="gray4-color">*You can select multiple files</small></p> -->
                  </div>

                  <div *ngIf="page !== 'builder'" class="col-sm-12">
                    <strong>if you have a screenshot or example you would like to include, please feel free to upload it.</strong> <br />
                    <label class="file-upload">
                      <input #fileAttached class="form-control" type="file" accept="image/*" id="fileAttached" formControlName="fileAttached" multiple />
                    </label>
                    <!-- <small class="gray4-color">*You can select multiple files</small> -->
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="panel-footer" [ngClass]="page === 'builder' ? 'support' : ''">
            <button [disabled]="!supportForm.valid" class="btn btn-primary">Submit Request</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div #previewTour id="previewTour" class="modal fade edit-box preview" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-center modal-lg modal-video">
    <div class="modal-content">
      <button type="submit" #previewTourModalClose class="close close-outside" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span>Test</button>
      <div class="modal-body">Tour Modal</div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
