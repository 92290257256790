import { HttpClient,   HttpRequest } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';
import { APP_CONFIG } from '../app.config';

@Injectable()
export class QuickDesignService {
    onGetAll = new Subject();
    onAdd  = new Subject();
    onDelete = new Subject();
    onAddGroup = new Subject();
    onGetGroupsByCategory = new Subject();
    onGetGroupTemplates = new Subject();
    onUpdateGroup = new Subject();
    onUpdateDescription = new Subject();
    onDeleteGroup = new Subject();
    onReplaceIcon = new Subject();
    onPostSort = new Subject();
    quickdesignCategoryLabels = { emails: 'Emails', printables: 'Printables', socialMedia: 'Social Media', signboards: 'Signboards' };
    
    constructor(
        @Inject(APP_CONFIG) private appConfig,
        private httpClient: HttpClient
    ) { }

    getAll = () => {
        return this.httpClient.get(`${this.appConfig.NEW_API_ENDPOINT}/quickdesigns`)
    }
    httpGetAll(_params: any = []) {
        return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/quickdesigns`)
        .subscribe(
            (response: any) => {
                this.onGetAll.next(response);
            },
            (response: any) => {
                this.onGetAll.next(response.error);
            }
        );
    }

    httpAdd(data: any, files = []) {
        const formData = new FormData();

        formData.append('reference', data.reference);
        formData.append('template_id', data.template_id);
        formData.append('description', data.description);
        formData.append('tags', data.tags);

        if (data.shared_users && data.shared_users.length){
            formData.append('shared_users', JSON.stringify(data.shared_users));
        }
            
        if (data.shared_with && data.shared_with === 'office') {
            formData.append('shared_to_office', "true");
        }

        if (data.visible_in_vault) {
            formData.append('visible_in_vault', "true");
        }

        if (data.title){
            formData.append('title', data.title);
        }

        if (typeof data.quick_design_group_id !== 'undefined' && data.quick_design_group_id !== '') {
            formData.append('quick_design_group_id', data.quick_design_group_id);
        }

        if (files.length > 0) {
            for (const file of files) {
                formData.append('file', file, file.name);
            }
        }

        const req = new HttpRequest(
            'POST',
            `${this.appConfig.API_ENDPOINT}/quickdesigns`,
            formData
        );

        return this.httpClient.request(req)
        .map(
            (response: any) => {
                if (response) {
                    if (response.body) {
                        if (response.body.status === 'success') {
                            return response.body;
                        }
                    }
                }
            }
        )
        .subscribe(
            (response: any) => {
                this.onAdd.next(response);
            },
            (response: any) => {
                this.onAdd.next(response.error);
            }
        );
    }


    delete(ID: number){
        return this.httpClient.delete(
          `${this.appConfig.NEW_API_ENDPOINT}/quickdesigns/${ID}`
        );
    }
    httpDelete(ID: number) {
        return this.httpClient.delete(`${this.appConfig.API_ENDPOINT}/quickdesigns/${ID}`)
        .subscribe(
        (response: any) => {
            this.onDelete.next(response);
        },
        (response: any) => {
            this.onDelete.next(response.error);
        }
        );
    }


    addGroup(data: any, files = []) {
        const formData = new FormData();

        formData.append('reference', data.reference);
        formData.append('category', data.category);
        formData.append('name', data.name);
        if (files.length > 0) {
            for (const file of files) {
                formData.append('file', file, file.name);
            }
        }

        return this.httpClient.post(
          `${this.appConfig.NEW_API_ENDPOINT}/quickdesigns/groups`,
          formData
        );
    }
    httpAddGroup(data: any, files = []) {
        const formData = new FormData();

        formData.append('reference', data.reference);
        formData.append('category', data.category);
        formData.append('name', data.name);
        if (files.length > 0) {
            for (const file of files) {
                formData.append('file', file, file.name);
            }
        }

        const req = new HttpRequest(
            'POST',
            `${this.appConfig.API_ENDPOINT}/quickdesigns/groups`,
            formData
        );

        return this.httpClient.request(req)
        .map(
            (response: any) => {
                if (response) {
                    if (response.body) {
                        if (response.body.status === 'success') {
                            return response.body;
                        }
                    }
                }
            }
        )
        .subscribe(
            (response: any) => {
                this.onAddGroup.next(response);
            },
            (response: any) => {
                this.onAddGroup.next(response.error);
            }
        );
    }


    updateGroup(ID: number, data: any, files = []){
        const formData = new FormData();

        formData.append('name', data.name);
        if (files.length > 0) {
            for (const file of files) {
                formData.append('file', file, file.name);
            }
        }

        return this.httpClient.post(
          `${this.appConfig.NEW_API_ENDPOINT}/quickdesigns/groups/${ID}`,
          formData
        );
    }
    httpUpdateGroup(ID: number, data: any, files = []) {
        const formData = new FormData();

        formData.append('name', data.name);
        if (files.length > 0) {
            for (const file of files) {
                formData.append('file', file, file.name);
            }
        }

        const req = new HttpRequest(
            'POST',
            `${this.appConfig.API_ENDPOINT}/quickdesigns/groups/${ID}`,
            formData
        );

        return this.httpClient.request(req)
        .map(
            (response: any) => {
                if (response) {
                    if (response.body) {
                        if (response.body.status === 'success') {
                            return response.body;
                        }
                    }
                }
            }
        )
        .subscribe(
            (response: any) => {
                this.onUpdateGroup.next(response);
            },
            (response: any) => {
                this.onUpdateGroup.next(response.error);
            }
        );
    }

    getGroupsByCategory(reference: any, category: string) {
        return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/quickdesigns/groups/${reference}/${category}`)
    }

    httpGetGroupsByCategory(reference: string, category: string) {
        return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/quickdesigns/groups/${reference}/${category}`)
        .subscribe(
            (response: any) => {
                this.onGetGroupsByCategory.next(response);
            },
            (response: any) => {
                this.onGetGroupsByCategory.next(response.error);
            }
        );
    }

    getGroupTemplates(ID: number){
        return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/quickdesigns/groups/${ID}`)
    }
    httpGetGroupTemplates(ID: number) {
        return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/quickdesigns/groups/${ID}`)
        .subscribe(
            (response: any) => {
                this.onGetGroupTemplates.next(response);
            },
            (response: any) => {
                this.onGetGroupTemplates.next(response.error);
            }
        );
    }


    deleteGroup(ID: number){
        return this.httpClient.delete(
          `${this.appConfig.NEW_API_ENDPOINT}/quickdesigns/groups/${ID}`
        );
    }
    httpDeleteGroup(ID: number) {
        return this.httpClient.delete(`${this.appConfig.API_ENDPOINT}/quickdesigns/groups/${ID}`)
        .subscribe(
            (response: any) => {
                this.onDeleteGroup.next(response);
            },
            (response: any) => {
                this.onDeleteGroup.next(response.error);
            }
        );
    }

    replaceIcon(type: string, ID: number, files = []) {
        const formData = new FormData();

        if (files.length > 0) {
          for (const file of files) {
            formData.append("file", file, file.name);
          }
        }

        return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/quickdesigns/icon/${type}/${ID}`, formData);
    }
    httpReplaceIcon(type: string, ID: number, files = []) {
        const formData = new FormData();

        if (files.length > 0) {
            for (const file of files) {
                formData.append('file', file, file.name);
            }
        }

        const req = new HttpRequest(
            'POST',
            `${this.appConfig.API_ENDPOINT}/quickdesigns/icon/${type}/${ID}`,
            formData
        );

        return this.httpClient.request(req)
        .map(
            (response: any) => {
                if (response) {
                    if (response.body) {
                        if (response.body.status === 'success') {
                            return response.body;
                        }
                    }
                }
            }
        )
        .subscribe(
            (response: any) => {
                this.onReplaceIcon.next(response);
            },
            (response: any) => {
                this.onReplaceIcon.next(response.error);
            }
        );
    }

    httpUpdateSort(data: any) {
        return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/quickdesigns/sort`, data)
        .subscribe(
            (response: any) => {
                this.onPostSort.next(response);
            },
            (response: any) => {
                this.onPostSort.next(response.error);
            }
        );
    }

    updateDescription(id: number, data: any) {
        return this.httpClient.put(this.appConfig.API_ENDPOINT + '/quickdesigns/description/' + id, data);
    }
    httpUpdateDescription(id: number, data: any) {
        const req = new HttpRequest(
          'PUT',
          this.appConfig.API_ENDPOINT + '/quickdesigns/description/' + id,
          data
        );
    
        return this.httpClient.request<any>(req)
        .map(
          (response: any) => {
              console.log(response);
            if (response.body) {
              if (response.body.status === 'success') {
                return response.body;
              }
            }
          }
        )
        .subscribe(
          (response: any) => {
            this.onUpdateDescription.next(response);
          }
        );
    }

    getCategories(params: any = {}) {
        return this.httpClient.get(
          `${this.appConfig.NEW_API_ENDPOINT}/quickdesigns/categories`, { params }
        );
    }

    getCategoryTemplates(reference: any, params: any = {}) {
        return this.httpClient.get(
          `${this.appConfig.NEW_API_ENDPOINT}/quickdesigns/categories/${reference}/templates`,
          {
            params,
          }
        ); 
    }

    getCategoryTemplatesTags(reference: any, params: any = {}) {
        return this.httpClient.get(
          `${this.appConfig.NEW_API_ENDPOINT}/quickdesigns/categories/${reference}/templates/tags`,
          {
            params,
          }
        ); 
    }

    updateTags(ID: any, data: any) {
        return this.httpClient.put(
            `${this.appConfig.NEW_API_ENDPOINT}/quickdesigns/${ID}/tags`,
            {
                ...data,
            }
        );
    }

    share(templateId: any, users: any[], shareToOffice = false) {
        return this.httpClient.put(
            `${this.appConfig.NEW_API_ENDPOINT}/quickdesigns/${templateId}/share`,
            {
                users: users,
                shared_to_office: shareToOffice
            }
        );
    }

    unshare(templateId: any) {
        return this.httpClient.delete(
            `${this.appConfig.NEW_API_ENDPOINT}/quickdesigns/${templateId}/share`
        );
    }

    getShared(templateId: any) {
        return this.httpClient.get(
            `${this.appConfig.NEW_API_ENDPOINT}/quickdesigns/${templateId}/share`
        );
    }

    update(ID: any, data: any) {

        const { title, description, quick_design_group_id, reference, tags, remove_from_current_folder } = data;

        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('quick_design_group_id', quick_design_group_id);
        formData.append('reference', reference);
        formData.append('tags', tags);
        formData.append('remove_from_current_folder', remove_from_current_folder);

        let result = this.httpClient.post(
            `${this.appConfig.NEW_API_ENDPOINT}/quickdesigns/${ID}/update`, formData
        ); 
        return result;
    }

    httpGetByTemplateId(templateId: any) {
        return this.httpClient.get(
            `${this.appConfig.NEW_API_ENDPOINT}/quickdesigns/getbytemplate/${templateId}`
        );
    }
}
