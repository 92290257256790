import { Component, OnInit } from '@angular/core';
import { Menu } from '../../../models/menu.model';
import { AdminService } from '../../admin.service';
import Swal from 'sweetalert2';
import { ClientService } from '../../../services/client.service';
import { GroupService } from '../../../services/group.service';
import { Subscription } from 'rxjs';
import { Group } from '../../../models/group.model';
import { FormControl, Validators } from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-groupsubgroups-dashboard',
  templateUrl: './groupsubgroups-dashboard.component.html',
  styleUrls: ['./groupsubgroups-dashboard.component.scss']
})
export class GroupsubgroupsDashboardComponent implements OnInit {


  subscription: Subscription;

  loading = false;

  menus: Menu[] = [];

  groups = [];

  subGroups = [];

  selectedId: any = null;

  titleFormControl = new FormControl('', Validators.required);
  groupIdFormControl = new FormControl(1, Validators.required);

  constructor(private adminService: AdminService, private clientService: ClientService, private groupService: GroupService) { }

  ngOnInit() {

    this.adminService.loadMenu(this.menus);

    this.subscription = this.groupService.groupsChanged.subscribe(
      (groups: Group[]) => {
        if (typeof groups !== 'undefined') {
          this.loading = false;
          this.groups = groups.sort((a, b) => a.ID - b.ID);
        }
      }
    );

    this.groupService.httpGetGroups();


    this.groupService.getGroupSubGroup().subscribe((response: any) => {
      this.subGroups = response;
    })

  }

  showCreateSubgroupModal(subgroup: any = null) {

    $('#createSubgroupModal').modal('show');

    if (subgroup) {
      this.selectedId = subgroup.id;
      this.titleFormControl.patchValue(subgroup.title);
      this.groupIdFormControl.patchValue(subgroup.group_id);
    } else {
      this.selectedId = null;
      this.titleFormControl.patchValue('');
      this.groupIdFormControl.patchValue(1);
    }

  }
  

  createGroupSubGroup() {
    this.loading = true;
    $('#createSubgroupModal').modal('hide');
    this.groupService.createGroupSubGroup({ title: this.titleFormControl.value, group_id: this.groupIdFormControl.value })
      .subscribe((response: any) => {
        this.loading = false;
        this.subGroups.push(response);
        Swal(
          'Success!',
          'Subgroup has been created.',
          'success'
        );
      });
  }

  editGroupSubGroup() {
    this.loading = true;
    $('#createSubgroupModal').modal('hide');
    this.groupService.editGroupSubGroup(this.selectedId, { title: this.titleFormControl.value, group_id: this.groupIdFormControl.value })
      .subscribe((response: any) => {
        this.loading = false;
        this.subGroups = this.subGroups.map((sg: any) => {
          if (sg.id === response.id) {
            return response;
          }
          
          return sg;
        })
        Swal(
          'Success!',
          'Subgroup has been updated.',
          'success'
        );
      });
  }

  submitForm() {
    if (this.selectedId) {
      this.editGroupSubGroup();
    } else {
      this.createGroupSubGroup();
    }
  }

  deleteSubGroup(id: number) {
    const $this = this;
    Swal({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {

        $this.loading = true;

        $this.groupService.deleteGroupSubGroup(id).subscribe((response: any) => {

          this.subGroups = this.subGroups.filter((subgroup: any) => {
            return id !== subgroup.id;
          });

          $this.loading = false;

          Swal(
            'Deleted!',
            'Subgroup has been deleted.',
            'success'
          );

        });

      }
    }).catch(Swal.noop);
  }

}
