import { Injectable } from '@angular/core';
@Injectable({
  	providedIn: 'root'
})
export class GlobalEmitterService {
	constructor() { }

	setShowInactiveFlag(value: boolean) {
		sessionStorage.setItem("show_inactive_users", value.toString());
	}

	getShowInactiveFlag() {
		let inactiveUserFlag = sessionStorage.getItem("show_inactive_users");
		return inactiveUserFlag === 'true';
	}
}
