
import { Component, OnInit, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { APP_CONFIG } from '../../app.config';
import { CorelogicService } from '../../services/corelogic.service';
import { FormControl } from '@angular/forms';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { Subject } from 'rxjs';

import swal from 'sweetalert2';
@Component({
  selector: 'app-corelogic',
  templateUrl: './corelogic.component.html',
  styleUrls: ['./corelogic.component.scss']
})

export class CorelogicComponent implements OnInit, AfterViewInit, OnDestroy {
  loading = false;
  destroy$: Subject<boolean> = new Subject();

  coreLogicPropertyStatus = 'sales';
  coreLogicSearchResult = false;

  coreLogicInput: FormControl;
  corelogicSuburbs = [];
  coreLogicPropertyType = 'HOUSE';
  isSearchCompleted = false;

  coreLogicSuburbListings: any;
  priceFinderRecentRentals = [];

  priceFinderRecentHouseSales = [];
  priceFinderRecentUnitSales = [];
  priceFinderRecentData: any;

  selectedListings = [];
  maxSelectedListings = 10;

  priceFinderCustomValues: any;
  filterBeds = '';
  filterBaths = '';
  filterSort = '';
  displayBothPropertyType = false;

  coreLogicPropertyTypeTitle = 'Recent House Sales';
  selectedLocalityId = null;

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    private corelogicService: CorelogicService,
    private mScrollbarService: MalihuScrollbarService
  ) {
    this.coreLogicInput = new FormControl('');
  }

  ngOnInit() {

    this.corelogicService.onSuburbSearch
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          this.isSearchCompleted = true;
          if (typeof response !== 'undefined' && response.status === 'success' ) {
            this.corelogicSuburbs = response.suggestions;

            this.loading = false;
          } else {
            this.loading = false;
            swal(`Unable to fetch the results.`, '', 'error');
          }
        }
      }
    );

    this.corelogicService.onGetSuburb
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response !== 'undefined' && response.status === 'success' ) {
            this.coreLogicSuburbListings = response._embedded.propertySummaryList;
            this.loading = false;
            this.coreLogicSearchResult = true;

            this.selectedListings = this.coreLogicSuburbListings.filter((listing, index) => (index + 1) <= this.maxSelectedListings);

            // this.corelogicService.httpGetRecents(response.data.suburb.id, {
            //   type: this.coreLogicPropertyType,
            //   status: this.coreLogicPropertyStatus
            // });
          } else {
            this.loading = false;
            swal(`Unable to fetch the results.`, '', 'error');
          }
        }
      }
    );

    this.corelogicService.onGetRecents
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response !== 'undefined' && response.status === 'success' ) {
            this.priceFinderRecentData = response.data;
            this.priceFinderRecentHouseSales = [];
            this.priceFinderRecentUnitSales = [];

            this.priceFinderRecentRentals = [];
            this.selectedListings = [];

            if (typeof response.data.sales !== 'undefined' && response.data.sales) {
              this.priceFinderRecentHouseSales = response.data.sales.house;
              this.priceFinderRecentUnitSales = response.data.sales.unit;
            }

            this.selectedListings = this.priceFinderRecentHouseSales.filter((listing, index) => (index + 1) <= this.maxSelectedListings);

            this.loading = false;
            this.coreLogicSearchResult = true;
          }
        }
      }
    );

    this.coreLogicInput.valueChanges
    .takeUntil(this.destroy$)
    .debounceTime(500)
    .subscribe(searchText => {
      if (typeof searchText !== 'undefined') {
        if (searchText.length > 3) {
          this.isSearchCompleted = false;
          this.corelogicService.httpSearch({
            query: searchText
          });
          this.loading = true;
        }
      }
    });

    // this.corelogicService.onSearch
    // .takeUntil(this.destroy$)
    // .subscribe(response => {
    //   if (response) {
    //     this.priceFinderCustomValues = response;

    //     if (typeof this.priceFinderCustomValues.maxListings !== 'undefined') {
    //       this.maxSelectedListings = this.priceFinderCustomValues.maxListings;
    //     }
    //   }
    // });
  }

  ngAfterViewInit() {
    this.mScrollbarService.initScrollbar('.propertyListScroller', { axis: 'y', theme: 'minimal-dark', scrollInertia: 600 });
    this.mScrollbarService.initScrollbar('#suburbFilter .search .search-result', { axis: 'y', theme: 'minimal-dark', scrollInertia: 600 });
  }

  onSelectPropertyStatus(type) {
    this.coreLogicPropertyStatus = type;

    if (typeof this.coreLogicSuburbListings !== 'undefined' && this.coreLogicSuburbListings) {
      this.loading = true;
      // this.corelogicService.httpGetRecents(this.coreLogicSuburbListings.suburb.id, {
      //   type: this.coreLogicPropertyType,
      //   status: this.coreLogicPropertyStatus
      // });
    }

  }

  onSelectSuburb(suburb) {
    this.corelogicSuburbs = [];
    this.isSearchCompleted  = false;
    this.coreLogicInput.setValue(suburb.suggestion, {emitEvent: false});
    this.selectedLocalityId = suburb.localityId;
    let filters = {
      beds: this.filterBeds,
      baths: this.filterBaths,
    };
    this.corelogicService.httpGetSuburbPropertyListings(suburb.localityId, this.coreLogicPropertyType, filters);
    this.loading = true;
  }

  selectCoreLogicPropertyType(type) {
    this.coreLogicPropertyType = type;
    if(type == 'UNIT,HOUSE'){
      this.coreLogicPropertyTypeTitle = 'Recent House & Unit Sales';
      this.displayBothPropertyType = true;
    } else {
      this.coreLogicPropertyTypeTitle = type == 'UNIT' ? 'Recent Unit Sales' : 'Recent House Sales';
      this.displayBothPropertyType = false;
    }
    let filters = {
      beds: this.filterBeds,
      baths: this.filterBaths,
    };
    this.corelogicService.httpGetSuburbPropertyListings(this.selectedLocalityId, type, filters);
    this.loading = true;
  }

  selectListingProperty(property) {
    const propertyIndex = this.selectedListings.findIndex(prop => prop.propertySummary.id == property.propertySummary.id);

    if (propertyIndex === -1) {
      if (this.selectedListings.length === this.maxSelectedListings) {
        swal(`Listing limit of ${this.maxSelectedListings} reached.`, '', 'error');
        return false;
      }

      this.selectedListings.push(property);
    } else {
      this.selectedListings.splice(propertyIndex, 1);
    }
  }

  checkIfPropertySelected(property) {
    const propertyIndex = this.selectedListings.findIndex(prop => prop.propertySummary.id == property.propertySummary.id);

    if (propertyIndex === -1) {
      return false;
    }

    return true;
  }

  onGenerateReport() {
    if (!this.selectedListings.length) {
      swal('Select up to 10 listing/s to insert in your report', '', 'error');
      return false;
    }

    this.priceFinderRecentData['suburb'] = this.coreLogicSuburbListings;
    this.priceFinderRecentData['listings'] = this.selectedListings;
    this.priceFinderRecentData['queryData'] = {
      type: this.coreLogicPropertyType,
      status: this.coreLogicPropertyStatus,
      displayBoth: this.displayBothPropertyType
    };

    this.corelogicService.onGenerateReport.next(this.priceFinderRecentData);
  }

  onPriceFinderSortChange(sort: string) {
    this.filterSort = sort;
    // this.corelogicService.httpGetRecents(this.coreLogicSuburbListings.suburb.id, {
    //   type: this.coreLogicPropertyType,
    //   status: this.coreLogicPropertyStatus,
    //   sort: this.filterSort,
    //   beds: this.filterBeds,
    //   baths: this.filterBaths
    // });
    this.loading = true;
  }
  // bath and bed filter
  onCoreLogicSortByBathAndBed(type: string = "beds", value: string) {
    if (type == 'beds') {
      this.filterBeds = value;
    }
    if (type == 'baths') {
      this.filterBaths = value;
    }

    let filters = {
      beds: this.filterBeds,
      baths: this.filterBaths,
    };

    this.corelogicService.httpGetSuburbPropertyListings(this.selectedLocalityId, this.coreLogicPropertyType, filters);
    this.loading = true;
  }

  ngOnDestroy() {
    this.mScrollbarService.destroy('.propertyListScroller');
    this.mScrollbarService.destroy('#suburbFilter .search .search-result');
  }

}
