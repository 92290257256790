<div class="title-bar">
  <p class="title"><button type="button" data-bs-dismiss="modal" class="btn" (click)="onBack()"><i class="icon feather icon-chevron-left"></i> Back</button> <i class="icon feather icon-sliders"></i> Property Search</p>
</div>
<div id="propertyFilter" class="row filter-holder mx-0">
  <div *ngIf="caller === 'latest-builder' && priceFinderEnabled" class="prop-category-container">
    <div class="block">
      <label>Search via Category</label>
      <div class="form-group m-0">
        <div class="w-100">
          <select2 [value]="s2SearchValue" required ngDefaultControl [data]="s2SearchData" [options]="s2SearchOptions" [disabled]="priceFinderAddressSearchLoading"
            (valueChanged)="s2ValueChanged('searchcategory', $event.value)" id="search-category"></select2>
        </div>
      </div>
    </div>
    <hr />
  </div>
  <div [class.d-none]="caller !== 'latest-builder' || (caller === 'latest-builder' && (!['listed-in-market','sold-in-market','rentals-in-market','leased-in-market'].includes(s2SearchValue)))"
    class="price-finder-search-container">
    <div class="block">
      <label>Search By</label>
      <div class="form-group m-0">
        <div class="w-100">
          <select2 [value]="s2SearchByValue" required ngDefaultControl 
            [data]="s2SearchByData" 
            [options]="s2SearchOptions"
            [disabled]="priceFinderAddressSearchLoading"
            (valueChanged)="s2SearchByValueChanged($event.value)" id="search-by"></select2>
        </div>
      </div>
    </div>
    <div class="block">
      <label>Property Search</label>
      <ng-select [class.d-none]="s2SearchByValue !== 'search-by-address'" [items]="priceFinderProperties" [clearable]="false" [typeahead]="priceFinderSearchInput"
        [loading]="priceFinderAddressSearchLoading" [minTermLength]="3" id="select-property-search"
        [(ngModel)]="priceFinderSearchedPropertyLabel"
        placeholder="Please enter a property address" (change)="onPriceFinderSelectProperty($event)">
      </ng-select>
      
      <ng-select [class.d-none]="s2SearchByValue !== 'search-by-suburb'" [items]="priceFinderSuburbs" [clearable]="false" [typeahead]="priceFinderSearchSuburbInput"
        [loading]="priceFinderAddressSearchLoading" [minTermLength]="3" id="select-property-suburb-search"
        [(ngModel)]="priceFinderSearchedPropertyLabel"
        placeholder="Please enter a suburb" (change)="onPriceFinderSelectProperty($event)">
      </ng-select>
    </div>
    <hr />
    <div *ngIf="priceFinderSearchedPropertyId" class="p-0 search-filter-height">
      <form [formGroup]="priceFinderSearchForm" (ngSubmit)="onPriceFinderSearch()" class="h-100">
        <div class="form-group m-0 mb-2 submit-button-container p-0">
          <button id="search-listings" type="submit" class="btn btn-submit col-sm-12">
            <i class="icon feather icon-search"></i> Search
          </button>
        </div>
        <div [class.block]="s2SearchByValue === 'search-by-address'" 
          [class.d-none]="s2SearchByValue !== 'search-by-address'">
          <label>Radius</label>
          <div class="form-group m-0">
            <div class="w-100">
              <ng-select formControlName="radius" [clearable]="false" [searchable]="false">
                <ng-option value="500">500 M</ng-option>
                <ng-option value="1000">1 KM</ng-option>
                <ng-option value="1500">1.5 KM</ng-option>
                <ng-option value="2000">2 KM</ng-option>
                <ng-option value="5000">5 KM</ng-option>
                <ng-option value="10000">10 KM</ng-option>
                <ng-option value="20000">20 KM</ng-option>
                <ng-option value="50000">50 KM</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="block">
          <label>{{ this.priceFinderSearch ? 'Listed ' : 'Sold' }} in the last</label>
          <div class="form-group m-0">
            <div class="w-100">
              <ng-select formControlName="listed_date_from" class="form-control listed_date" [clearable]="false" [searchable]="false">
                <ng-option value="today-3m">3 months</ng-option>
                <ng-option *ngFor="let listedDate of listedDates" [value]="listedDate?.value">{{listedDate.text}}</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="block">
          <label>Select Property Type</label>
          <div class="form-group m-0">
            <div class="w-100">
              <ng-select formControlName="property_type" [clearable]="false" [searchable]="false">
                <ng-option value="">Include All</ng-option>
                <ng-option value="house">House</ng-option>
                <ng-option value="unit">Unit</ng-option>
                <ng-option value="vacant">Vacant</ng-option>
                <ng-option value="commercial">Commercial</ng-option>
                <ng-option value="industrial">Industrial</ng-option>
                <ng-option value="other">Other</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="block">
          <label>Number of Bedrooms</label>
          <div class="form-group m-0">
            <div class="w-100">
              <ng-select formControlName="minimum_beds" [clearable]="false" [searchable]="false">
                <ng-option value="0">Include All</ng-option>
                <ng-option value="1">1</ng-option>
                <ng-option value="2">2</ng-option>
                <ng-option value="3">3</ng-option>
                <ng-option value="4">4</ng-option>
                <ng-option value="5">5+</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="block">
          <label>Number of Bathrooms</label>
          <div class="form-group m-0">
            <div class="w-100">
              <ng-select formControlName="minimum_baths" [clearable]="false" [searchable]="false">
                <ng-option value="0">Include All</ng-option>
                <ng-option value="1">1</ng-option>
                <ng-option value="2">2</ng-option>
                <ng-option value="3">3</ng-option>
                <ng-option value="4">4</ng-option>
                <ng-option value="5">5+</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="block">
          <label>Number of Carparks</label>
          <div class="form-group m-0">
            <div class="w-100">
              <ng-select formControlName="minimum_car_parks" [clearable]="false" [searchable]="false">
                <ng-option value="0">Include All</ng-option>
                <ng-option value="1">1</ng-option>
                <ng-option value="2">2</ng-option>
                <ng-option value="3">3</ng-option>
                <ng-option value="4">4</ng-option>
                <ng-option value="5">5+</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="block block-price-range">
          <label>{{ this.priceFinderSearch ? 'Listed ' : 'Sold' }} Price</label>
          <div class="input-group m-0">
            <select2 formControlName="listed_price_min" [value]="s2ListedPriceMinValue" ngDefaultControl [data]="s2ListedPriceMinData"
              [options]="s2ListedPriceMinOptions" (valueChanged)="s2ValueChanged('listedpricemin', $event.value)" id="listedPriceMin"
              class="form-control listed_date">
            </select2>
            <div class="dashline"> to </div>
            <select2 formControlName="listed_price_max" [value]="s2ListedPriceMaxValue" ngDefaultControl [data]="s2ListedPriceMaxData"
              [options]="s2ListedPriceMaxOptions" (valueChanged)="s2ValueChanged('listedpricemax', $event.value)" id="listedPriceMax"
              class="form-control listed_date">
            </select2>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div [class.d-none]="caller === 'latest-builder' && s2SearchValue !== 'listed-in-agency'" [class.h-200]="caller === 'latest-builder' && priceFinderEnabled" class="p-0 search-filter-height">
    <form (ngSubmit)="onSelectListings()" [formGroup]="mdListingsForm" class="form-horizontal h-100">
      <div class="form-group m-0 mb-2 submit-button-container">
        <button id="search-listings" type="button" (click)="listingSearchChange(0, false)" class="btn btn-submit col-sm-12">
          <i class="icon feather icon-search"></i> Search
        </button>
      </div>
      <div id="prop-filter-container">
        <div class="block mb-2">
          <a style="color: #37AAE1; text-decoration: none;" (click)="$event.preventDefault()" data-bs-toggle="modal" data-bs-target="#propertyAddress">{{ searchPropertySelectedAddress }}</a>
        </div>
        <div class="block">
          <label>Property Class</label>
          <div class="form-group m-0">
            <div class="w-100">
              <select2 formControlName="classification" [value]="s2ClassificationValue" required ngDefaultControl
                [data]="s2ClassificationData" [options]="s2ClassificationOptions"
                (valueChanged)="s2ValueChanged('classification', $event.value)" id="classification"></select2>
            </div>
          </div>
        </div>
        <div *ngIf="authUser?.provider === 'idashboard'" class="block">
          <label>Property Method</label>
          <div class="form-group m-0">
            <div class="w-100">
              <select2 formControlName="method" [value]="" required ngDefaultControl [data]="s2MethodData"
                [options]="s2MethodOptions" (valueChanged)="s2ValueChanged('method', $event.value)"
                [disabled]="s2MethodDisabled" id="status"></select2>
            </div>
          </div>
        </div>
        <div class="block">
          <label>Property Status</label>
          <div class="form-group m-0">
            <div class="w-100">
              <select2 formControlName="status" [value]="s2StatusValue" required ngDefaultControl [data]="s2StatusData"
                [options]="s2StatusOptions" (valueChanged)="s2ValueChanged('status', $event.value)"
                [disabled]="s2StatusDisabled" id="status"></select2>
            </div>
          </div>
        </div>
        <ng-container *ngIf="sharedService.authService.getApp() === 'Designly'">
          <div class="block" *ngIf="!s2SubStatusDisabled">
            <label>Property {{ mdListingsForm.get("status").value || "" | titlecase }} Status</label>
            <div class="form-group m-0">
              <div class="w-100">
                <select2 formControlName="substatus" value="management" required ngDefaultControl
                  [data]="s2SubStatusData" [options]="s2SubStatusOptions"
                  (valueChanged)="s2ValueChanged('substatus', $event.value)" [disabled]="s2SubStatusDisabled"
                  id="status"></select2>
              </div>
            </div>
          </div>
          <div class="block" *ngIf="sharedService?.appConfig?.SKIN !== 'idashboard' && !s2AvailableDisabled">
            <label>Show Available Listings Only</label>
            <div class="form-group m-0">
              <div class="w-100">
                <select2 formControlName="available" value="1" required ngDefaultControl [data]="s2AvailableData"
                  [options]="s2AvailableOptions" (valueChanged)="s2ValueChanged('available', $event.value)"
                  [disabled]="s2AvailableDisabled" id="available"></select2>
              </div>
            </div>
          </div>
          <div class="block" *ngIf="!s2BasedOnDisabled">
            <label>Based On</label>
            <div class="form-group m-0">
              <div class="w-100">
                <select2 formControlName="basedon" [value]="s2BasedOnValue" required ngDefaultControl
                  [data]="s2BasedOnData" [options]="s2BasedOnOptions" [disabled]="s2BasedOnDisabled"
                  (valueChanged)="s2ValueChanged('basedon', $event.value)" id="basedon"></select2>
              </div>
            </div>
          </div>
          <div class="block" *ngIf="s2StatusValue === 'sold' && authUser.provider === 'vaultre'">
            <label>Settlement or Unconditional Date</label>
            <div class="form-group m-0">
              <div class="w-100">
                <select2 formControlName="soldfilter" [value]="" required ngDefaultControl [data]="s2SoldFilterData"
                  [options]="s2SoldFilterOptions" (valueChanged)="s2ValueChanged('soldfilter', $event.value)"
                  id="soldfilter"></select2>
              </div>
            </div>
          </div>
          <div
            [class.hidden]="s2SoldFilterValue !== 'settlementSince' && s2SoldFilterValue !== 'settlementBefore' && s2SoldFilterValue !== 'unconditionalBefore' && s2SoldFilterValue !== 'unconditionalSince'"
            class="block">
            <label>Select Date</label>
            <div class="form-group m-0">
              <div class="w-100">
                <input id="sortDatePicker" type="text" data-bs-toggle="datepicker" class="form-control" />
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="sharedService.authService.getApp() === 'MyDesign3'">
          <div class="block" *ngIf="!s2BasedOnDisabled">
            <label>Based On</label>
            <div class="form-group m-0">
              <div class="w-100">
                <select2 formControlName="basedon" [value]="s2BasedOnValue" required ngDefaultControl
                  [data]="s2BasedOnData" [options]="s2BasedOnOptions" [disabled]="s2BasedOnDisabled"
                  (valueChanged)="s2ValueChanged('basedon', $event.value)" id="basedon"></select2>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- <div class="block prop-search-morefilter">
            <a data-bs-toggle="collapse" href="#collapseExtraFilters" role="button" aria-expanded="false"
              aria-controls="collapseExtraFilters">More filters <i class="icon feather icon-chevron-down"></i></a>
          </div> -->
        <!-- <div class="collapse" id="collapseExtraFilters"> -->
        <ng-container *ngIf="sharedService.authService.getApp() === 'Designly' && authUser?.provider !== 'idashboard'">
          <div class="block"
            *ngIf="authUser?.provider === 'vaultre' && (mdListingsForm.get('status').value === 'listing' || mdListingsForm.get('status').value === 'rental')">
            <label>Filter by Period</label>
            <div class="period-controls-group">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="periodFilter" id="periodFilterWebLive"
                  value="webdate" checked (click)="changePeriodSelection('webdate')" />
                <label class="form-check-label" for="periodFilterWebLive"> &nbsp; Web Date </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="periodFilter" id="periodFilterAuthority"
                  value="authority" (click)="changePeriodSelection('authority')" />
                <label class="form-check-label" for="periodFilterAuthority"> &nbsp; Listing Authority </label>
              </div>

              <div class="form-group m-0 mt-2 pb-0">
                <div class="w-100">
                  <select2 formControlName="period" [value]="" required ngDefaultControl [data]="s2PeriodData"
                    [options]="s2ExtraFiltersOptions" (valueChanged)="s2ValueChanged('period', $event.value)"
                    id="period">
                  </select2>
                </div>
              </div>
            </div>
          </div>
          <div class="block"
            *ngIf="mdListingsForm.get('status').value === 'listing' || mdListingsForm.get('status').value === 'rental'">
            <label>Extra Filters</label>
            <div class="form-group m-0">
              <div class="w-100">
                <select2 formControlName="extrafilters" [value]="" required ngDefaultControl [data]="s2ExtraFiltersData"
                  [options]="s2ExtraFiltersOptions" (valueChanged)="s2ValueChanged('extrafilters', $event.value)"
                  id="extrafilters"></select2>
              </div>
            </div>
            <div class="form-group m-0" *ngIf="!s2OpenHomeDaysDisabled">
              <div class="w-100">
                <select2 formControlName="openhomedays" [value]="s2OpenHomeDaysValue" required ngDefaultControl [data]="s2OpenHomeDaysData"
                  [options]="s2OpenHomeDaysOptions" (valueChanged)="s2ValueChanged('openhomedays', $event.value)"
                  id="openhomedays"></select2>
              </div>
            </div>
          </div>
          <div class="block suburb-block">
            <label>Filter by Suburb</label>
            <div class="period-controls-group">
              <div class="w-100">
                <div class="d-flex align-items-center suburb-filter p-0 m-0">
                    <input id="groupSuburbs" class="checkbox-multi mx-2" type="checkbox" />
                    <label for="groupSuburbs" class="flex-fill">Group suburbs by district</label>
                </div>
                <div class="d-flex align-items-center suburb-filter p-0 m-0">
                  <input id="exactMatch" class="checkbox-multi mx-2" type="checkbox" />
                  <label for="exactMatch" class="flex-fill">Exact match (fewer results)</label>
                </div>
                <div class="mt-2 pb-0">
                  <select2 required ngDefaultControl [value]="s2VaultSuburbValue" [options]="s2VaultSuburbOptions"
                    (valueChanged)="s2ValueChanged('vaultsuburb', $event.value)" id="vaultSuburb">
                  </select2>
                </div>
              </div>
            </div>
          </div>
          <div class="block">
            <label>Filter by Precinct</label>
            <div class="form-group m-0">
              <div class="w-100">
                <select2 formControlName="precincts" [value]="s2PrecinctsValue" ngDefaultControl [data]="s2PrecinctsData"
                  [options]="s2PrecinctsOptions" (valueChanged)="s2ValueChanged('precincts', $event.value)"
                  id="precincts">
                </select2>
              </div>
            </div>
          </div>
          <div class="block">
            <label>Filter by Region</label>
            <div class="form-group m-0">
              <div class="w-100">
                <select2 formControlName="customRegions" [value]="s2AccountRegionsValue" ngDefaultControl [data]="s2AccountRegionsData"
                  [options]="s2AccountRegionsOptions" (valueChanged)="s2ValueChanged('customRegions', $event.value)"
                  id="customRegions">
                </select2>
              </div>
            </div>
          </div>
          <div *ngIf="branches?.length > 1" class="block">
            <label>Filter by Branch</label>
            <div class="form-group m-0">
              <div class="w-100">
                <select2 formControlName="branches" [value]="s2BranchesValue" required ngDefaultControl
                  [data]="s2BranchesData" [options]="s2BranchesOptions"
                  (valueChanged)="s2ValueChanged('branches', $event.value)" id="branches"></select2>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container
          *ngIf="sharedService.authService.getApp() === 'Designly' && s2ClassificationValue === 'residential'">
          <div class="block"
            *ngIf="mdListingsForm.get('status').value === 'listing' || mdListingsForm.get('status').value === 'rental' || mdListingsForm.get('status').value === 'sold'">
            <label>Filter by Bedrooms</label>
            <div class="form-group m-0">
              <div class="w-100">
                <select2 formControlName="bedroom" [value]="s2BedroomValue" ngDefaultControl [data]="s2BedroomData"
                  [options]="s2BedroomOptions" (valueChanged)="s2ValueChanged('bedroom', $event.value)" id="bedroom">
                </select2>
              </div>
            </div>
          </div>
          <div class="block"
            *ngIf="mdListingsForm.get('status').value === 'listing' || mdListingsForm.get('status').value === 'rental' || mdListingsForm.get('status').value === 'sold'">
            <label>Filter by Property Type</label>
            <div class="form-group m-0">
              <div class="w-100">
                <select2 formControlName="propertytype" [value]="s2TypeValue" ngDefaultControl [data]="s2TypeData"
                  [options]="s2TypeOptions" (valueChanged)="s2ValueChanged('propertytype', $event.value)"
                  id="propertytype">
                </select2>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!landBuildingAreaDisabled">
          <div class="block">
            <label>Filter by Land Area</label>
            <div class="input-group m-0 area-filter-div-details">
              <input type="text" formControlName="landareafrom" class="form-control area-filter-details" placeholder="Eg 100" [(ngModel)]="landareaFromValue" (keypress)="numberOnly($event)">
              <div class="input-group-append input-group-text m-0 border-0 area-filter-details">
                <span>sqm</span>
              </div>
              <div class="dashline">-</div>
              <input type="text" formControlName="landareato" class="form-control area-filter-details" placeholder="Eg 500" [(ngModel)]="landareaToValue" (keypress)="numberOnly($event)">
              <div class="input-group-append input-group-text m-0 border-0 area-filter-details">
                <span>sqm</span>
              </div>
            </div>
          </div>
          <div class="block">
            <label>Filter by Building Area</label>
            <div class="input-group m-0 area-filter-div-details">
              <input type="text" formControlName="floorareafrom" class="form-control area-filter-details" placeholder="Eg 100" [(ngModel)]="floorareaFromValue" (keypress)="numberOnly($event)">
              <div class="input-group-append input-group-text m-0 border-0 area-filter-details">
                <span>sqm</span>
              </div>
              <div class="dashline">-</div>
              <input type="text" formControlName="floorareato" class="form-control area-filter-details" placeholder="Eg 500" [(ngModel)]="floorareaToValue" (keypress)="numberOnly($event)">
              <div class="input-group-append input-group-text m-0 border-0 area-filter-details">
                <span>sqm</span>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="sharedService.authService.getApp() === 'MyDesign3'">
          <div class="block">
            <label>Filter by Suburb</label>
            <div class="form-group m-0">
              <div class="w-100">
                <select2 formControlName="suburb" [value]="s2SuburbValue" required ngDefaultControl
                  [data]="s2SuburbData" [options]="s2SuburbOptions"
                  (valueChanged)="s2ValueChanged('suburb', $event.value)" id="suburb">
                </select2>
              </div>
            </div>
          </div>
        </ng-container>

        <div *ngIf="isMultipleOffice" class="block">
          <label>Filter by Office</label>
          <div class="form-group m-0">
            <div class="w-100">
              <select2 formControlName="officeid" [value]="s2OfficeIdValue" required ngDefaultControl
                [data]="s2OfficeIdData" [options]="s2OfficeIdOptions"
                (valueChanged)="s2ValueChanged('officeid', $event.value)" id="agentid"></select2>
            </div>
          </div>
        </div>
        <div *ngIf="sharedService?.appConfig?.SKIN !== 'idashboard'" class="block">
          <label>Filter by Agent</label>
          <div class="form-group m-0">
            <div class="w-100">
              <select2 formControlName="agentid" [value]="s2AgentIdValue" required ngDefaultControl
                [data]="s2AgentIdData" [options]="s2AgentIdOptions"
                (valueChanged)="s2ValueChanged('agentid', $event.value)" id="agentid">
              </select2>
            </div>
          </div>
        </div>
        <div *ngIf="is_local_search === 1" class="block">
          <label>Filter by Price</label>
          <div class="form-group m-0">
            <div class="w-50">
              <select2 formControlName="pricefrom" [value]="s2PriceFromValue" ngDefaultControl [data]="s2PriceFromData"
                [options]="s2PriceFromOptions" (valueChanged)="s2ValueChanged('pricefrom', $event.value)"
                id="pricefrom">
              </select2>
            </div>
            <div class="w-50">
              <select2 formControlName="priceto" [value]="s2PriceToValue" ngDefaultControl [data]="s2PriceToData"
                [options]="s2PriceToOptions" (valueChanged)="s2ValueChanged('priceto', $event.value)" id="priceto">
              </select2>
            </div>
          </div>
        </div>
        <div *ngIf="is_local_search === 1" class="block">
          <label>Filter by Bedrooms</label>
          <div class="form-group m-0">
            <div class="w-50">
              <select2 formControlName="bedfrom" [value]="s2BedFromValue" ngDefaultControl [data]="s2BedFromData"
                [options]="s2BedFromOptions" (valueChanged)="s2ValueChanged('bedfrom', $event.value)" id="bedfrom">
              </select2>
            </div>
            <div class="w-50">
              <select2 formControlName="bedto" [value]="s2BedToValue" ngDefaultControl [data]="s2BedToData"
                [options]="s2BedToOptions" (valueChanged)="s2ValueChanged('bedto', $event.value)" id="bedto"> </select2>
            </div>
          </div>
        </div>
        <div *ngIf="is_local_search === 1" class="block">
          <label>Filter by Bathrooms</label>
          <div class="form-group m-0">
            <div class="w-50">
              <select2 formControlName="bathfrom" [value]="s2BathFromValue" ngDefaultControl [data]="s2BathFromData"
                [options]="s2BathFromOptions" (valueChanged)="s2ValueChanged('bathfrom', $event.value)" id="bathfrom">
              </select2>
            </div>
            <div class="w-50">
              <select2 formControlName="bathto" [value]="s2BathToValue" ngDefaultControl [data]="s2BathToData"
                [options]="s2BathToOptions" (valueChanged)="s2ValueChanged('bathto', $event.value)" id="bathto">
              </select2>
            </div>
          </div>
        </div>
        <div *ngIf="is_local_search === 1" class="block">
          <label>Filter by Parking</label>
          <div class="form-group m-0">
            <div class="w-50">
              <select2 formControlName="carfrom" [value]="s2CarFromValue" ngDefaultControl [data]="s2CarFromData"
                [options]="s2CarFromOptions" (valueChanged)="s2ValueChanged('carfrom', $event.value)" id="carfrom">
              </select2>
            </div>
            <div class="w-50">
              <select2 formControlName="carto" [value]="s2CarToValue" ngDefaultControl [data]="s2CarToData"
                [options]="s2CarToOptions" (valueChanged)="s2ValueChanged('carto', $event.value)" id="carto"> </select2>
            </div>
          </div>
        </div>
        <div class="block" *ngIf="!s2OrderByDisabled">
          <label>Sort by</label>
          <div class="form-group m-0">
            <div class="w-100">
              <select2 formControlName="orderby" value="modifydateDesc" required ngDefaultControl [data]="s2OrderByData"
                [options]="s2OrderByOptions" (valueChanged)="s2ValueChanged('orderby', $event.value)" id="orderby">
              </select2>
            </div>
          </div>
        </div>
        <ng-container *ngIf="s2StatusValue === 'sold' && authUser.provider === 'vaultre'">
          <div class="d-flex align-items-center multi">
            <label for="show-saleprice" class="flex-fill">Unsuppress sale price/s</label>
            <input id="show-saleprice" class="checkbox-multi mx-2" type="checkbox" (change)="onCheckShowSalePrice()" />
          </div>
        </ng-container>
        <ng-container *ngIf="sharedService.authService.getApp() === 'Designly' && authUser.provider === 'vaultre'">
          <div class="d-flex align-items-center multi">
            <label for="internal-marketing" class="flex-fill">Published properties</label>
            <input id="internal-marketing" class="checkbox-multi mx-2" type="checkbox" (change)="onCheckInternal()"
              [checked]="publicCheck" />
          </div>
          <div class="d-flex align-items-center multi">
            <label for="internal-marketing-only" class="flex-fill">Internal marketing properties</label>
            <input id="internal-marketing-only" class="checkbox-multi mx-2" type="checkbox"
              (change)="onCheckMarketing()" />
          </div>
        </ng-container>
        <ng-container *ngIf="sharedService.authService.getApp() === 'Designly' && authUser.provider === 'domain'">
          <div class="d-flex align-items-center multi">
            <label for="multi-office" class="flex-fill">Include multi-office properties</label>
            <input id="multi-office" class="checkbox-multi mx-2" type="checkbox" (change)="onCheckMulti()" [checked]="multiOfficeFranchiseCheck" />
          </div>
        </ng-container>
        <ng-container *ngIf="sharedService.authService.getApp() === 'Designly' && authUser.provider === 'vaultre'">
          <div class="d-flex align-items-center multi">
            <label for="multi-office" class="flex-fill">Include multi-office properties</label>
            <input id="multi-office" class="checkbox-multi mx-2" type="checkbox" (change)="onCheckMulti()" [checked]="multiOfficeFranchiseCheck" />
          </div>
          <div class="d-flex align-items-center multi">
            <label for="internal-marketing-only" class="flex-fill">Include franchise properties</label>
            <input id="franchise-listing" class="checkbox-multi mx-2" type="checkbox" (change)="onCheckFranchiseListings()" [checked]="multiOfficeFranchiseCheck" />
          </div>
          <div class="d-flex align-items-center multi">
            <label for="manual-insert-order" class="flex-fill">Manual Insert order</label>
            <input id="manual-insert-order" class="checkbox-multi mx-2" type="checkbox" />
          </div>
          <div class="d-flex align-items-center multi">
            <label for="openhome-by-appointment" class="flex-fill">Show Open Home "By Appointment"</label>
            <input id="openhome-by-appointment" class="checkbox-multi mx-2" type="checkbox" (change)="onCheckOpenHome()"/>
          </div>
        </ng-container>
        <!-- </div> -->
      </div>
    </form>
  </div>
  <div class="form-group m-0 mt-2 submit-button-container">
    <button id="insert-listing" [disabled]="selectedListings.length === 0" type="button"
      class="btn btn-submit col-sm-12" (click)="onSelectListings()">
      <span *ngIf="loadingInsertListing"><i class="fa fa fa-spinner fa-spin"></i> &nbsp;Loading...</span>
      <span *ngIf="!loadingInsertListing">Insert Listing</span>
    </button>
    <button type="button" id="clear-listing" (click)="clearListingResult()" [disabled]="!mdListings.length"
      type="submit" class="btn btn-clear col-sm-12">
      <span>Clear Result</span>
    </button>
  </div>
</div>

<div id="propertyList" class="media-list is-grid">
  <div *ngIf="mdListings.length > 0" id="propertyList-pages">
    <div class="left-buttons">
      <div class="row">
        <div class="col-md-5">
          <input type="text" class="form-control" placeholder="Search" [(ngModel)]="listingQuery"
            [ngModelOptions]="{standalone: true}">
        </div>
        <div class="col-md-7">
          <span class="selected-listings-count"><strong>{{ selectedListings.length }}</strong> selected</span>
          <span *ngIf="!priceFinderEnabled" class="post-per-page">
            Show <select2 [value]="s2postPerPageValue" [data]="s2postPerPage" [options]="s2postPerPageOptions"
              (valueChanged)="s2ValueChanged('perpage', $event.value)" id="postperpage"></select2> per page
          </span>
        </div>
      </div>

      <!-- <button type="button" (click)="onSelectAll()" class="btn btn-primary">Select All</button> -->
    </div>
    <div class="right-buttons">
      <ng-container *ngIf="authUser?.provider === 'mydesktop'">
        <button type="button" *ngFor="let mdListingPage of mdListingPages"
          [ngClass]="{ active: mdListingCurrentPage === mdListingPage }"
          (click)="listingSearchChange(0, mdListingPage)">{{ mdListingPage }}</button>
      </ng-container>
      <ng-container *ngIf="vaultTotalPages > 1">
        <span>Page <strong>{{ currentVaultPage }}</strong> of <strong>{{ vaultTotalPages }}</strong></span>
        &nbsp; <button type="button" (click)="onPaginate('prev')" *ngIf="showDesignlyPreviousPage"
          class="btn btn-primary"><i class="icon feather icon-chevron-left"></i>Previous Page</button>
        <button type="button" (click)="onPaginate('next')" *ngIf="showDesignlyNextPage" class="btn btn-primary">Next
          Page<i class="icon feather icon-chevron-right"></i></button>
      </ng-container>
      <button
        *ngIf="(template?.category?.slug === 'enewsletter' || template?.category?.slug === 'property-guide' && !isReplace) && (propertySearchService?.propertyInsertSource !== 'button')"
        type="button" class="btn btn-primary" (click)="selectAll()">Select All</button>
      <button
        *ngIf="(template?.category?.slug === 'enewsletter' || template?.category?.slug === 'property-guide' && selectedListings?.length > 0 && !isReplace) && ((propertySearchService?.propertyInsertSource !== 'button'))"
        type="button" class="btn btn-primary" (click)="deselectAll()">Deselect All</button>
    </div>
  </div>
  <div class="property-lists">
    <ul class="blocks blocks-100 blocks-xlg-5 blocks-lg-5 blocks-md-5 blocks-ms-2 blocks-xs-2">
      <ng-container *ngFor="let listing of mdListings | listingsearch: listingQuery" class="">
        <li #propertyList [class.selected]="checkIfSelected(listing.id)">
          <div class="media-item" data-bs-toggle="slidePanel" data-url="panel.tpl">
            <span *ngIf="listing?.images?.length > 1" class="reorder" (click)="reorderImages(listing)" data-tooltip="Reorder Photos">&nbsp;</span>
            <span *ngIf="listing?.agent1" class="listing-agents" [ngClass]="{'show-icon-agent': listingAgentsUpdate.indexOf(listing.id) !== -1}"
              (click)="listListingAgents(listing)" data-tooltip="Select Listing Agent/s"><i
                class="fa fa-user"></i></span>
            <!-- <div class="checkbox-custom checkbox-primary checkbox-lg multi-select">
                    <input type="checkbox" id="media_{{listing.id}}" />
                    <label for="media_{{listing.id}}"></label>
                  </div> -->
            <div class="info-wrap">
              <p class="title" *ngIf="listing.displayaddress">{{ listing.displayaddress }}</p>
              <p class="title" *ngIf="!listing.displayaddress">{{ listing.fulladdress }}</p>
            </div>
            <div class="image-wrap" (click)="selectProperty(listing, propertyList)">
              <div class="img-overlay"></div>
              <img *ngIf="sharedService.authService.getApp() !== 'MyDesign3'" class="image"
                [src]="listing.images && listing.images.length > 0 ? listing.images[0].thumbnail : ''"
                onError="this.src='https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/1/images/propphoto_placeholder_two.svg'"
                alt="{{ listing.displayaddress }}" />
              <img *ngIf="sharedService.authService.getApp() === 'MyDesign3'" class="image"
                [src]="listing.images && listing.images.length > 0 ? listing.images[0].url : ''"
                onError="this.src='https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/1/images/propphoto_placeholder_two.svg'"
                alt="{{ listing.displayaddress }}" />
            </div>
            <div class="info-wrap">
              <div class="row">
                <div class="col-md-12">
                  <p class="pull-left listing-details"
                    *ngIf="listing.listing_category === 'residential' || listing.listing_category === 'rural' || listing.listing_category === 'holidayRental'">
                    <span class="details"><b>Bed:</b> {{listing.beds}}</span>
                    <span class="details"><b>Bath:</b> {{listing.baths}}</span>
                    <span class="details"><b>Car:</b> {{listing.cars}}</span>
                  </p>
                  <p class="pull-left listing-details"
                    *ngIf="listing.listing_category === 'commercial' || listing.listing_category === 'business'">
                    <span class="details">{{listing.commercialtypes}}</span>
                  </p>
                  <p class="pull-left listing-details" *ngIf="listing.listing_category === 'land'">
                    <span class="details">Land Area: {{listing.landarea}}{{listing.landareatype}}</span>
                  </p>

                  <p class="pull-right" *ngIf="!listing.published && caller !== 'latest-builder' && !priceFinderEnabled; else published" style="margin-bottom: 0;">
                    <span class="unpublished">unpublished</span>
                  </p>

                  <p class="pull-right" *ngIf="listing.published === true" style="margin-bottom: 0;">
                    <span class="unpublished">published</span>
                  </p>

                  <p class="pull-right" *ngIf="listing.published === false" style="margin-bottom: 0;">
                    <span class="unpublished-hidden">published</span>
                  </p>
                </div>
              </div>

<!--              <p class="price pull-left"-->
<!--                *ngIf="listing.priceonapplication && !listing.priceonapplication_auction && listing.displayprice === ''">POA</p>-->
<!--              <p class="price pull-left"-->
<!--              *ngIf="listing.priceonapplication && !listing.priceonapplication_auction && listing.displayprice !== ''">{{ listing.displayprice }}</p>-->
<!--              <p class="price pull-left"-->
<!--                *ngIf="listing.priceonapplication && listing.priceonapplication_auction && listing.displayprice === ''">{{ listing.priceMain }}</p>-->
<!--              <p class="price pull-left"-->
<!--                *ngIf="listing.priceonapplication && listing.priceonapplication_auction && listing.displayprice !== ''">{{ listing.displayprice }}</p>-->
<!--              <p class="price pull-left"-->
<!--                *ngIf="listing.listing_status !== 'settled' && listing.listing_status !== 'unconditional' && listing.listing_status !== 'settled,unconditional' && !displayPrice && !listing.priceonapplication && listing.displayprice === ''">-->
<!--                {{ listing.priceMain }}</p>-->
<!--              <p class="price pull-left"-->
<!--                *ngIf="listing.listing_status !== 'settled' && listing.listing_status !== 'unconditional' && listing.listing_status !== 'settled,unconditional' && !displayPrice && !listing.priceonapplication&& listing.displayprice !== ''">-->
<!--                {{ listing.displayprice }}</p>-->
<!--              <p class="sold-price-details"-->
<!--                *ngIf="listing.listing_status === 'settled' || listing.listing_status === 'unconditional' || listing.listing_status === 'settled,unconditional' || displayPrice && !listing.priceonapplication">-->
<!--                <span class="sold-price pull-left d-inline-block text-truncate" title="{{listing.salepricedetails}}" *ngIf="listing.unsuppress_saleprice === true "><span *ngIf="!listing.showsaleprice"><i class="fa fa-eye-slash" title="Price Suppressed"></i></span> {{ listing.salepricedetails }}</span>-->
<!--                <span class="sold-price pull-left d-inline-block text-truncate" title="{{listing.salepricedetails}}" *ngIf="!listing.unsuppress_saleprice && listing.showsaleprice">{{ listing.salepricedetails }}</span>-->
<!--                <span class="sold-price pull-left d-inline-block text-truncate" title="{{listing.salepricedetails}}" *ngIf="!listing.unsuppress_saleprice && !listing.showsaleprice">{{ listing.salepricedetails }}</span>-->
<!--                <span class="sold-price pull-left d-inline-block text-truncate" *ngIf="listing.unsuppress_saleprice === false && listing.showsaleprice === false"> <i class="fa fa-eye-slash" title="Price Suppressed"></i></span>-->
<!--                <span class="sold-price-date pull-right">{{ listing.unconditionalDate }}</span>-->
<!--              </p>-->
              <!-- PRIVATE TREATY -->
              <ng-container *ngIf="listing.methodofsale !== 'Auction' && listing.methodofsale !== 'Tender' && listing.methodofsale !== 'Set Sale Date'; else auctionCheck">
                <div class="row">
                  <div class="col-md-12">
                    <p class="price pull-left"
                      *ngIf="listing.priceonapplication && !listing.priceonapplication_auction && listing.displayprice === '' && listing.portalstatus !== 'Unconditional'"><b>Price: </b>POA</p>
                    <p class="price pull-left"
                      *ngIf="listing.priceonapplication && !listing.priceonapplication_auction && listing.displayprice !== '' && s2StatusValue != 'sold' "><b>Price: </b>PBN</p>
                    <p class="price pull-left"
                      *ngIf="listing.priceonapplication && listing.priceonapplication_auction && listing.displayprice === ''"><b>Price: </b>{{ listing?.priceMain }}</p>
                    <p class="price pull-left"
                      *ngIf="listing.priceonapplication && listing.priceonapplication_auction && listing.displayprice !== ''"><b>Price: </b>{{ listing?.displayprice }}</p>
                    <p class="price pull-left"
                      *ngIf="listing.listing_status !== 'settled' && listing.listing_status !== 'unconditional' && listing.listing_status !== 'settled,unconditional' && !displayPrice && !listing.priceonapplication && listing.displayprice === ''">
                      <b>Price: </b>{{ listing.priceMain }}</p>
                    <p class="price pull-left"
                      *ngIf="listing.listing_status !== 'settled' && listing.listing_status !== 'unconditional' && listing.listing_status !== 'settled,unconditional' && !displayPrice && !listing.priceonapplication&& listing.displayprice !== ''">
                      <b>Price: </b>{{ transformDisplayPrice(listing.displayprice) }}</p>
                    <p class="sold-price-details"
                      *ngIf="listing.listing_status === 'settled' || listing.listing_status === 'unconditional' || listing.listing_status === 'settled,unconditional' || displayPrice && !listing.priceonapplication">
                      <span class="sold-price pull-left d-inline-block text-truncate" title="{{listing.salepricedetails}}" *ngIf="listing.unsuppress_saleprice === true "><b>Price: </b> {{ listing?.salepricedetails }}</span>
                      <span class="sold-price pull-left d-inline-block text-truncate" title="{{listing.salepricedetails}}" *ngIf="!listing.unsuppress_saleprice && listing.showsaleprice"><b>Price: </b>{{ listing?.salepricedetails }}</span>
                      <span class="sold-price pull-left d-inline-block text-truncate" title="{{listing.salepricedetails}}" *ngIf="!listing.unsuppress_saleprice && !listing.showsaleprice && s2StatusValue != 'sold'"><b>Price: </b>{{ listing?.salepricedetails }}</span>
                      <span class="sold-price pull-left d-inline-block text-truncate" *ngIf="listing.unsuppress_saleprice === false && listing.showsaleprice === false && s2StatusValue == 'sold'">
                        <strong>Price:&nbsp;</strong>Sale Price Suppressed</span>
                    </p>
                  </div>
                </div>
                <div class="row" *ngIf="(listing.listing_status === 'settled' || listing.listing_status === 'unconditional' || listing.listing_status === 'settled,unconditional' || displayPrice && !listing.priceonapplication) && s2StatusValue == 'sold'">
                  <div class="col-md-12">
                    <p class="price pull-left">{{ listing?.unconditionalDate | ordinalDate }}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <span class="listing-date pull-left" *ngIf="listing.publishedDate !== null && listing.listing_status !== 'leased'; else listingStatusLeased">
                      {{ listing?.publishedDate | ordinalDate }}
                    </span>

                    <ng-template #listingStatusLeased>
                      <span class="listing-date pull-left" *ngIf="listing.authority_start && listing.listing_status === 'leased'; else listingDate">
                        {{ listing?.authority_start }}
                      </span>
                    </ng-template>

                    <ng-template #listingDate>
                      <span class="listing-date pull-left" *ngIf="(listing.listingdate || listing.listing_status === 'listing') && s2StatusValue !== 'sold'; else emptyListingDate">
                        {{ listing?.listingdate }}
                      </span>
                    </ng-template>

                    <ng-template #emptyListingDate>
                      <span class="listing-date-placeholder pull-left">
                        &nbsp;
                      </span>
                    </ng-template>
                  </div>
                </div>
              </ng-container>

              <!-- AUCTION -->
              <ng-template #auctionCheck>
                <ng-container *ngIf="listing.methodofsale === 'Auction'; else tenderCheck">
                  <div class="row">
                    <div class="col-md-12">
                      <span class="method-of-sale pull-left" *ngIf="listing.methodofsale">
                        <b>Price: </b>{{ listing?.methodofsale }}
                      </span>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <span class="listing-date pull-left" *ngIf="listing.auctiondateformat; else emptyAuctionDate">
                        {{ listing?.auctiondateformat | ordinalDate }}
                      </span>

                      <ng-template #emptyAuctionDate>
                        <span class="listing-date-placeholder pull-left">
                          &nbsp;
                        </span>
                      </ng-template>
                    </div>
                  </div>
                </ng-container>
              </ng-template>

              <!-- TENDER -->
              <ng-template #tenderCheck>
                <ng-container *ngIf="listing.methodofsale === 'Tender'; else setSaleCheck">
                  <div class="row">
                    <div class="col-md-12">
                      <span class="method-of-sale pull-left" *ngIf="listing.methodofsale">
                        <b>Price: </b>{{ listing?.methodofsale }}
                      </span>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <span class="listing-date pull-left" *ngIf="listing.tenderdateformat; else emptyTenderDate">
                        {{ listing?.tenderdateformat | ordinalDate }}
                      </span>

                      <ng-template #emptyTenderDate>
                        <span class="listing-date-placeholder pull-left">
                          &nbsp;
                        </span>
                      </ng-template>
                    </div>
                  </div>
                </ng-container>
              </ng-template>

              <!-- SET SALE DATE -->
              <ng-template #setSaleCheck>
                <ng-container *ngIf="listing.methodofsale === 'Set Sale Date'; else setSaleCheck">
                  <div class="row">
                    <div class="col-md-12">
                      <span class="method-of-sale pull-left" *ngIf="listing.methodofsale">
                        <b>Price: </b>{{ (listing.address_country === "New Zealand" || authUser.client.country === "New Zealand" ? "Deadline Sale" : listing?.methodofsale) }}
                      </span>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <span class="listing-date pull-left" *ngIf="listing.setSaleDateFormat; else emptySetSaleDate">
                        {{ listing?.setSaleDateFormat | ordinalDate }}
                      </span>

                      <ng-template #emptySetSaleDate>
                        <span class="listing-date-placeholder pull-left">
                          &nbsp;
                        </span>
                      </ng-template>
                    </div>
                  </div>
                </ng-container>
              </ng-template>
            </div>
          </div>
        </li>
      </ng-container>
      <ngx-loading [show]="subLoading"></ngx-loading>
    </ul>
  </div>
</div>

<div id="mdReorderImage" class="modal fade edit-box preview app-media" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-center" role="document">
    <div class="modal-content" id="content-images">
      <div class="modal-body" id="body-images">
        <button type="button" class="close close-outside close-outside-library" aria-label="Close"
          (click)="closeModal('mdReorderImage')">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="container">
          <h3>Reorder Images <button type="button" (click)="saveImageOrder()"
              class="btn btn-submit btn-sm">Save</button></h3>
          <div class="row">
            <h4>Property Images</h4>
            <ul dndDropzone dndEffectAllowed="copyMove" (dndDrop)="onDrop($event, listingImages)" id="sortable"
              class="d-flex flex-wrap">
              <li dndPlaceholderRef class="dndPlaceholder"></li>
              <li *ngFor="let image of listingImages; let i = index" [attr.data-url]="image?.url"
                [attr.data-hiresurl]="image?.hiresurl" [attr.data-thumbnail]="image?.thumbnail"
                class="col-md-3 sortable-images" [dndDraggable]="image" dndEffectAllowed="move"
                (dndMoved)="onDragged(image, listingImages, 'move')">
                <div class="image-box">
                  <img src="{{ image.url }}" />
                </div>
              </li>
            </ul>
          </div>
          <br />
          <div *ngIf="authUser?.provider === 'vaultre'" class="row">
            <h4>Property Floorplans</h4>
            <ul dndDropzone dndEffectAllowed="copyMove" (dndDrop)="onDrop($event, listingFloorplans)" id="sortable"
              class="d-flex flex-wrap">
              <li dndPlaceholderRef class="dndPlaceholder"></li>
              <li *ngFor="let floorplan of listingFloorplans; let i = index" [attr.data-url]="floorplan?.url"
                [attr.data-hiresurl]="floorplan?.hiresurl" [attr.data-thumbnail]="floorplan?.thumbnail"
                class="col-md-3 sortable-images" [dndDraggable]="floorplan" dndEffectAllowed="move"
                (dndMoved)="onDragged(floorplan, listingFloorplans, 'move')">
                <div class="image-box">
                  <img src="{{ floorplan.url }}" />
                </div>
              </li>
            </ul>
          </div>
        </div>

        <ngx-loading [show]="subLoading"></ngx-loading>
      </div>
    </div>
  </div>
</div>

<div #mdListingAgentsModal id="mdListingAgents" class="modal fade edit-box preview app-media" tabindex="-1"
  role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" #mdListingAgentsModalClose class="close close-outside close-outside-library"
          (click)="closeModal('mdListingAgents')">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="container">
          <h3>Listing Agents</h3>
          <p>If you would like to change the listing agent/s shown in your template please select an agent/s below.</p>
          <div class="row agent-list">
            <div *ngFor="let agent of listingAgents" class="col-md-6">
              <div class="agent-name">
                <label><input type="checkbox" [checked]="(selectedlistingAgents.indexOf(agent.agent_id) !== -1)"
                    value="{{ agent.agent_id }}" id="agent{{agent.agent_id}}" class="agents"
                    (click)="selectAgent(agent.agent_id)">&nbsp;{{ agent.firstname }} {{ agent.lastname }}</label>
              </div>
            </div>
          </div>
          <h3>
            <button type="button" (click)="saveListingAgents()" class="btn btn-submit btn-sm">Save</button>
            <button type="button" (click)="clearListingAgents()" class="btn btn-clear btn-sm pull-right">Clear</button>
          </h3>
        </div>

        <ngx-loading [show]="subLoading"></ngx-loading>
      </div>
    </div>
  </div>
</div>

<div id="propertyAddress" class="modal fade edit-box preview app-media" tabindex="-1" role="dialog" data-backdrop="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-body-property-address">
      <div class="input-group flex-nowrap">
        <ng-select class="ng-select-custom" [items]="searchProperties" [clearable]="true" [typeahead]="searchPropertyInput"
            [loading]="searchPropertyLoading" [minTermLength]="3" [bindLabel]="searchPropertyLabel"
            placeholder="Search address..." (change)="listingSearchChange(0, $event)" [clearSearchOnAdd]="true" [closeOnSelect]="true">
        </ng-select>
        <span class="input-group-text bg-white"><i class="feather icon-search"></i></span>
      </div>      
    </div>
  </div>
</div>
