import { HttpHeaders, HttpParams,   HttpClient,    HttpRequest } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import { Observable } from 'rxjs/Observable';

import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs/Subscription';

import 'rxjs/Rx';

import { APP_CONFIG } from '../app.config';
import { AuthService } from './auth.service';
import { Auth } from '../models/auth.model';

@Injectable()
export class MyDesktopService {
  subscription: Subscription;
  myDesktopsListingsChanged = new Subject<any[]>();
  myDesktopsAllListingsChanged = new Subject<any[]>();
  myDesktopAuthLogin = new Subject<any>();
  myDesktopAuthSwitch = new Subject<any>();
  myDesktopChanged = new Subject<any>();
  myDesktopSubrubs = new Subject<any>();
  myDesktopCustomParams = new Subject<any>();
  myDesktopAgents = new Subject<any[]>();
  myDesktopAgent = new Subject<any>();
  myDesktopAuthLoginInvalid = new Subject<any>();
  myDesktopAuthLoginBlocked = new Subject<any>();
  myDesktopTwoFactorRequired = new Subject<any>();
  myDesktopInvalid2FA = new Subject<any>();
  syncAgents = new Subject<any[]>();
  myDesktopAuthRefresh = new Subject<any>();
  myDesktopAuthExist = new Subject<any>();
  myDesktopLoginAs = new Subject<any>();
  myDesktopApiError = new Subject<any>();
  myDesktopBillingIssue = new Subject<any>();
  myDesktopGetNewListings = new Subject();
  myDesktopGetNewListing = new Subject();

  private myDesktopListings: any[] = [];

  constructor(@Inject(APP_CONFIG) private appConfig,
              private httpClient: HttpClient,
              public authService: AuthService) {}

  setMyDesktops(myDesktops: any[]) {
    this.myDesktopListings = myDesktops;
    this.myDesktopsListingsChanged.next(this.myDesktopListings);
  }

  getMyDesktops() {
    return this.myDesktopListings.slice();
  }

  getIdIndex(id: number) {
    return this.myDesktopListings.findIndex(e => e.ID === id);
  }

  httpPostAuthLogin(data: any) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/auth/login',
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body;
          } else if (response.body.status === 'invalid') {
            this.myDesktopAuthLoginInvalid.next(response);
          } else if (response.body.status === 'blocked') {
            this.myDesktopAuthLoginBlocked.next(response);
          } else if (response.body.status === 'twofactorcodeneeded') {
            this.myDesktopTwoFactorRequired.next(response);
          } else if (response.body.status === 'invalid2fa') {
            this.myDesktopInvalid2FA.next(response);
          } else if ( response.body.status === 'mdapierror' ) {
            this.myDesktopApiError.next(response);
          } else if ( response.body.status === 'billingissue' ) {
            this.myDesktopBillingIssue.next(response);
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.myDesktopAuthLogin.next(response);
      }
    );
  }

  httpGetAuthRefresh(provider = 'mydesktop'): Observable<any> {
    const groupid = this.authService.auth.groupid;
    const agentid = this.authService.auth.agentid;

    let url = 'auth';

    if (provider === 'vaultre') {
      url = 'vault';
    }

    if ( provider === 'domain' ) {
      url = 'domain';
    }

    let isAdminLink = '/user';
    const isAdminData = localStorage.getItem('isAdmin');

    if ( isAdminData === 'yes' ) {
      isAdminLink = '/admin';
    }

    if ( provider === 'mydesktop' ) {
      isAdminLink = '';
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/' + url + '/refresh/' + groupid + '/' + agentid + isAdminLink
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          return response.body;
        }
      }
    );
  }

  httpGetAuthExist(params: any) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/auth/exist',
      {
        params: httpParams
      }
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }
      }
    )
    .subscribe(
      (response: any) => {
        this.myDesktopAuthExist.next(response);
      }
    );
  }

  httpPostAuthSwitch(data: any, token: string) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/auth/switch',
      data,
      {
        headers: new HttpHeaders().set('MD-Authorization', token)
      }
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (typeof response.body !== 'undefined' && response.body != null) {
          return response.body;
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.myDesktopAuthSwitch.next(response);
      }
    );
  }

  httpPostLoginAs(data: any, token: string) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/auth/login-as',
      data,
      {
        headers: new HttpHeaders().set('MD-Authorization', token)
      }
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (typeof response.body !== 'undefined' && response.body != null) {
          return response.body;
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.myDesktopLoginAs.next(response);
      }
    );
  }

  getMDListings(params: any = [], type = 'mydesktop') {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    if (type === 'vaultre') {
      type = 'vault';
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/' + type + '/listings',
      {
        headers: new HttpHeaders().set('MD-Authorization', this.authService.auth.token),
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    ).map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body;
          }
        }

        if (typeof response.type !== 'undefined' && response.type === 0) {
          return null;
        }

        return [];
      }
    )
  }

  httpGetMDListings(params: any = [], type = 'mydesktop') {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    if (type === 'vaultre') {
      type = 'vault';
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/' + type + '/listings',
      {
        headers: new HttpHeaders().set('MD-Authorization', this.authService.auth.token),
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body;
          }
        }

        if (typeof response.type !== 'undefined' && response.type === 0) {
          return null;
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.myDesktopsListingsChanged.next(response);
      },
      (response: any) => {
        this.myDesktopApiError.next(response);
      }
    );
  }

  httpGetMDAllListings(params: any = []) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/mydesktop/listings',
      {
        headers: new HttpHeaders().set('MD-Authorization', this.authService.auth.token),
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any[]) => {
        this.myDesktopListings = response;
        this.myDesktopsAllListingsChanged.next(this.myDesktopListings);
      }
    );
  }

  httpGetMDListing(id: number) {
    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/mydesktop/listings/' + id,
      {
        headers: new HttpHeaders().set('MD-Authorization', this.authService.auth.token),
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.myDesktopChanged.next(response);
      }
    );
  }

  httpGetSuburbs(params: any = []) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/mydesktop/suburbs',
      {
        headers: new HttpHeaders().set('MD-Authorization', this.authService.auth.token),
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.myDesktopSubrubs.next(response);
      }
    );
  }

  httpGetMDListingByCustomParams(params: any = []) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/mydesktop/bycustomparams',
      {
        headers: new HttpHeaders().set('MD-Authorization', this.authService.auth.token),
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )

    .subscribe(
      (response: any) => {
        this.myDesktopCustomParams.next(response);
      }
    );
  }

  httpGetAgents(params: any = []) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/mydesktop/agents',
      {
        headers: new HttpHeaders().set('MD-Authorization', this.authService.auth.token),
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any[]) => {
        this.myDesktopAgents.next(response);
      }
    );
  }

  httpGetAgent(params: any = []) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/mydesktop/agent/' + this.authService.auth.agentid,
      {
        headers: new HttpHeaders().set('MD-Authorization', this.authService.auth.token),
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any[]) => {
        this.myDesktopAgent.next(response);
      }
    );
  }

  httpSyncAgents(id: number, params: any = []) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/mydesktop/sync/agents/' + id,
      {
        headers: new HttpHeaders().set('MD-Authorization', this.authService.auth.token),
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any[]) => {
        this.syncAgents.next(response);
      }
    );
  }

  httpNewGetListings(params: any = []) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/mydesktop/listings',
      {
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.myDesktopGetNewListings.next(response);
      },
      (response: any) => {
        this.myDesktopGetNewListings.next(response.error);
      }
    );
  }

  httpNewGetListing(id: number, params: any = []) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/mydesktop/listing/' + id,
      {
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.myDesktopGetNewListing.next(response);
      },
      (response: any) => {
        this.myDesktopGetNewListing.next(response.error);
      }
    );
  }

}
