import { Component, OnInit, AfterContentInit, OnDestroy } from '@angular/core';
import { Auth } from '../../models/auth.model';
import { Menu } from '../../models/menu.model';
import { FrontService } from '../front.service';
import { Subject } from 'rxjs';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { CampaignService } from '../../services/campaign.service';
import { DomainService } from '../../services/domain.service';
import { Router } from '@angular/router';
import { MyDesktopService } from '../../services/mydesktop.service';
import { VaultService } from '../../services/vault.service';
import { Client } from '../../models/client.model';
import { ClientService } from '../../services/client.service';
import { AgentHelper } from '../../helpers/agent-helper';
import { GlobalEmitterService } from '../../services/global-emitter.service';
declare var swal: any;
declare var $: any;

@Component({
  selector: 'app-front-campaigns',
  templateUrl: './front-campaigns.component.html',
  styleUrls: ['./front-campaigns.component.scss']
})
export class FrontCampaignsComponent implements OnInit, AfterContentInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  loading = false;
  editMode = false;
  editId = 0;
  authUser: Auth;
  agentUsers = [];
  menus: Menu[] = [];

  campaignForm: FormGroup;
  campaignListingForm: FormGroup;
  headingForm: FormGroup;

  campaigns = [];
  shareListUsers = [];

  lastViewed = '0';

  listing: any;
  listings = [];
  customListings = [];

  selectedCategory = 'Sale';
  currentCategory = 'Sales';

  keywordTyping = false;
  keyword = '';

  tmpId = 0;

  Object = Object;

  isCustom = false;

  campaignCategory = [];

  isMaster = false;
  selected_share = '';
  shareListClients = [];
  selectedSharedClient = [];
  selectedSharedUser = [];
  selectedSubGroup = [];
  shared_template_id: number;

  subgroups: Client[];

  _tmp_template: any;

  shareToClientForm: FormGroup;
  shareToUserForm: FormGroup;
  shareToSubgroupForm: FormGroup;

  sharedCampaignTemplates = [];

  selectedAgent = 'all';

  constructor(
    private frontService: FrontService,
    private campaignService: CampaignService,
    private domainService: DomainService,
    private router: Router,
    private mydesktopService: MyDesktopService,
    private vaultService: VaultService,
    private clientService: ClientService,
    private globalEmitterService: GlobalEmitterService
  ) { }

  ngOnInit() {
    if (this.frontService.appConfig.SKIN === 'md3') {
      return window.location.href = '/templates';
    }

    this.campaignService.vaultStatus = 'listing';
    this.campaignService.campaignCategory = 'Sales';

    this.authUser = this.frontService.authService.auth;
    this.frontService.loadMenu(this.menus);
    this.shareListUsers = this.initUsersDropdown(this.authUser.client.users);
    if ( this.authUser.provider === 'mydesktop' || this.authUser.provider === 'vaultre' ) {
      this.selectedCategory = 'listing';
    }

    if (this.authUser.is_master) {
      this.isMaster = true;
    }

    if (this.authUser.agentid === '7357' && this.authUser.provider === 'vaultre') {
      this.isMaster = true;
    }

    if ( this.isMaster ) {
      this.selected_share = 'offices';
    } else if ( this.authUser.is_subgroup ) {
      this.selected_share = 'subgroup';
    } else {
      this.selected_share = 'agent';
    }

    if ( this.authUser.is_subgroup ) {
      this.clientService.httpGetClientsOfSubGroupID(
        {
          subgroup_id: this.authUser.client.subgroup_id
        },
        'list'
      );
    }

    if (this.isMaster) {
      this.clientService.httpGetClientsOfGroupID(
        {
          group_id: this.authUser.client.group_id,
          is_account_active: '1',
          provider: this.authUser.provider
        },
        'list'
      );
    }

    this.reloadloadListings();

    this.loading = true;

    this.campaignService.campaignPost
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if ( typeof response !== 'undefined' && response.status === 'success') {
          swal(
            'Created',
            'Custom Campaign has been successfully created',
            'success'
          );

          this.reloadloadListings();

          $('#newCampaign').modal('hide');
        }
      }
    );

    this.domainService.onGetDomainListings
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if ( typeof response !== 'undefined' && response.status === 'success' ) {
          this.loading = false;
          this.listings = response.data;
          this.customListings = response.custom;
        }
      }
    );

    this.mydesktopService.myDesktopGetNewListings
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if ( typeof response !== 'undefined' && response.status === 'success' ) {
          this.loading = false;
          this.listings = response.data;
          this.customListings = response.custom;
        }
      }
    );

    this.vaultService.onVaultGetListings
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if ( typeof response !== 'undefined' && response.status === 'success' ) {
          this.loading = false;
          this.listings = response.data;
          this.customListings = response.custom;
        }
      }
    );

    this.campaignService.campaignBuildTemplates
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if ( typeof response !== 'undefined' && response.status === 'success' ) {
          if ( this.isCustom ) {
            window.location.href = `/campaigns/${this.listing.campaign_id}/${this.tmpId}/view`;
          } else {
            window.location.href = `/campaigns/listing/${this.tmpId}/view`;
          }
        }
        if ( typeof response !== 'undefined' && response.status === 'empty' ) {
          this.loading = false;
          swal(
            'Unable to generate campaign',
            'Make sure you have saved templates to this campaign',
            'warning'
          );
        }
      }
    );

    this.campaignService.campaignExport
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if ( typeof response !== 'undefined' && response.status === 'success' ) {
          this.loading = false;

          swal(
            'Success',
            'You can now download the zip file',
            'success'
          );

          const link = document.createElement('a');
          link.download = `${this.tmpId}.zip`;
          link.href = response.file;
          link.target = '_blank';
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
        if ( typeof response !== 'undefined' && response.status === 'okempty' ) {
          this.loading = false;
          swal(
            'Unable to export campaign',
            'Make sure you have generated this campaign',
            'warning'
          );
        }
      }
    );

    this.campaignService.campaignGetAll
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if ( typeof response !== 'undefined' && response.status === 'success' ) {
          this.loading = false;
          this.campaigns = response.data;
          $('#addListing').modal('show');
        }
      }
    );

    this.campaignService.campaignPostListing
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if ( typeof response !== 'undefined' && response.status === 'success' ) {
          swal(
            'Listing added to Campaign',
            '',
            'success'
          );
          $('#addListing').modal('hide');

          this.reloadloadListings();
        }
      }
    );

    this.campaignService.campaignPut
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if ( typeof response !== 'undefined' && response.status === 'success' ) {
          swal(
            'Heading has been updated',
            '',
            'success'
          );

          this.reloadloadListings();

          $('#editHeading').modal('hide');
        }
      }
    );

    this.campaignService.campaignOnDeleteListing
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if ( typeof response !== 'undefined' && response.status === 'success' ) {
          this.reloadloadListings();
        }
      }
    );

    this.campaignService.campaignOnGenerateFinish
    .takeUntil(this.destroy$)
    .subscribe(
      (response: boolean) => {
        if (response) {
          if ( this.isCustom ) {
            // tslint:disable-next-line:max-line-length
            window.location.href = `/campaigns/${this.listing.campaign_id}/${this.tmpId}/view?status=${this.campaignService.vaultStatus}&category=${this.campaignService.campaignCategory}`;
          } else {
            // tslint:disable-next-line:max-line-length
            window.location.href = `/campaigns/listing/${this.tmpId}/view?status=${this.campaignService.vaultStatus}&category=${this.campaignService.campaignCategory}`;
          }
        }
        if (!response) {
          swal(
            'Unable to generate campaign',
            'Make sure you have saved templates to this campaign',
            'warning'
          );

          this.loading = false;
        }
      }
    );

    this.clientService.ClientsOfSubGroupID
    .takeUntil(this.destroy$)
    .subscribe(
      (subgroups: Client[]) => {
        if ( typeof subgroups !== 'undefined' && subgroups.length > 0 ) {
          this.subgroups = subgroups;
        }
      }
    );

    this.clientService.ClientsOfGroupID
    .takeUntil(this.destroy$)
    .subscribe(
      (clients: Client[]) => {
        this.shareListClients = clients;
      }
    );

    this.campaignService.campaignGetShared
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        this.loading = false;
        $('#shareTo').modal('show');

        if (typeof response !== 'undefined' && response.status === 'success') {
          this.sharedCampaignTemplates = response.data;

          this.sharedCampaignTemplates.forEach(shared => {
            jQuery('#shareTo').find('input[value="' + shared.client_id + '"]').prop('checked', true);
          });
        }
      }
    );

    this.campaignService.campaignPostShared
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && response.status === 'success') {
          swal(
            'Shared Successfully',
            '',
            'success'
          );
          this.loading = false;
        }
        if (typeof response !== 'undefined' && response.status === 'error') {
          swal(
            'Sharing failed',
            'Contact Designly',
            'error'
          );
          this.loading = false;
        }
        if (typeof response !== 'undefined' && response.status === 'empty') {
          swal(
            'Sharing failed',
            'No templates found on ' + this.currentCategory,
            'error'
          );
          this.loading = false;
        }
      }
    );

    this.campaignService.campaignDelete
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && response.status === 'success') {
          swal(
            'Custom Campaign deleted successfully',
            '',
            'success'
          );

          this.reloadloadListings();
        }
        if (typeof response !== 'undefined' && response.status === 'error') {
          swal(
            'Error deleting Custom Campaign',
            'Contact Designly',
            'error'
          );
          this.loading = false;
        }
      }
    );

    const tempAgentUsers = this.shareListUsers;
    this.agentUsers = tempAgentUsers.sort(function(a, b) {
      const x = a.firstname.toLowerCase();
      const y = b.firstname.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });

    this.initForm();
  }
  private initUsersDropdown(users) {
    let responseFlag = this.globalEmitterService.getShowInactiveFlag();
    if(responseFlag === false) {
     return users.filter(user => user.is_active); 
    }
    return users
  }
  initForm() {
    this.campaignForm = new FormGroup({
      'type': new FormControl('sales', Validators.required),
      'name': new FormControl('')
    });

    this.campaignListingForm = new FormGroup({
      'campaign_id': new FormControl('', Validators.required)
    });

    this.headingForm = new FormGroup({
      'headline': new FormControl('', Validators.required)
    });

    this.shareToClientForm = new FormGroup({
      'ID': new FormControl('', Validators.required),
      'client_id': new FormControl('', Validators.required)
    });

    this.shareToUserForm = new FormGroup({
      'ID': new FormControl('', Validators.required),
      'user_id': new FormControl('', Validators.required)
    });

    this.shareToSubgroupForm = new FormGroup({
      'ID': new FormControl('', Validators.required),
      'subgroup_id': new FormControl('', Validators.required)
    });
  }

  onSubmit() {
    const campaignValues = this.campaignForm.value;
    campaignValues['client_id'] = this.authUser.client.ID;
    campaignValues['group_id'] = this.authUser.client.group.ID;
    campaignValues['user_id'] = this.authUser.user.ID;
    campaignValues['type'] = 'custom';

    if ( this.editMode ) {
      this.campaignService.httpPutCampaign(this.editId, campaignValues);
    } else {
      this.campaignService.httpPostCampaign(campaignValues);
    }
    this.loading = true;
  }

  getAgentData(id, type) {
    const agentID = this.shareListUsers.findIndex(a => +a.ID === id);
    if ( typeof this.shareListUsers[ agentID ] !== 'undefined') {
      return this.shareListUsers[ agentID ][ type ];
    }

    return ' ';
  }

  onAdd() {
    this.campaignForm.reset();
    this.editMode = false;
  }

  onEdit(campaign: any) {
    this.editMode = true;
    this.editId = campaign.ID;

    this.campaignForm.patchValue({
      'name': campaign.name
    });
  }

  ngAfterContentInit() {
    // grid and list view
    jQuery(document).on('click', '.template-view', function(e){
      e.preventDefault();
      const view = jQuery(this).attr('data-view');

      jQuery('.template-view').removeClass('active');
      jQuery(this).addClass('active');

      if (view === 'list') {
            jQuery('#arrangement-grid').removeClass('active');
            jQuery('.media-list').removeClass('is-grid').addClass('is-list');
      } else {
            jQuery('#arrangement-list').removeClass('active');
            jQuery('.media-list').removeClass('is-list').addClass('is-grid');
      }
    });

    const campaignSelectedAgent = localStorage.getItem('campaign_selected_agent');

    if (campaignSelectedAgent !== null) {
      this.selectedAgent = campaignSelectedAgent;
    }
  }

  openMenu(id) {
    $(`.content-${this.lastViewed}`).css('display', 'none');
    this.lastViewed = id;

    $(`.content-${id}`).css('display', 'block');

    $(`.content-${id}`).on('mouseleave', function() {
      $(`.content-${id}`).css('display', 'none');

      $(`.content-${id}`).off();
    });
  }

  onDelete(id: number) {
    const $this = this;
    swal({
      title: 'Are you sure you want to delete this campaign?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then(function () {
      $this.loading = true;
      $this.campaignService.httpDeleteCampaign(id);
    });
  }

  generateCampaign(listing, type = 'listing') {
    this.tmpId = listing.id;
    this.listing = listing;

    const _tmpListing = listing;
    _tmpListing['client_id'] = this.authUser.client.ID;
    _tmpListing['group_id'] = this.authUser.client.group_id;
    _tmpListing['user_id'] = this.authUser.user.ID;
    _tmpListing['campaignType'] = 'listing';
    _tmpListing['campaignCategory'] = this.currentCategory;

    this.campaignService.httpBuildTemplates(_tmpListing);

    if ( type === 'custom' ) {
      this.isCustom = true;
    }

    this.loading = true;
  }

  viewListingCampaign(id: number, type = 'listing', campaign_id = 0) {
    if ( campaign_id === 0 ) {
      // tslint:disable-next-line: max-line-length
      this.router.navigateByUrl(`/campaigns/${type}/${id}/view?status=${this.campaignService.vaultStatus}&category=${this.campaignService.campaignCategory}`);
    } else {
      // tslint:disable-next-line: max-line-length
      this.router.navigateByUrl(`/campaigns/${campaign_id}/${id}/view?status=${this.campaignService.vaultStatus}&category=${this.campaignService.campaignCategory}`);
    }
  }

  exportCampaign(id: number, campaign_id: number) {
    this.tmpId = id;
    this.campaignService.httpExportCampaign(campaign_id);
    this.loading = true;
  }

  onCategoryChange(category: string) {
    this.loading = true;
    this.currentCategory = this.campaignService.campaignCategory = category;

    if ( this.authUser.provider === 'domain' ) {
      if ( category === 'Sales' ) {
        this.selectedCategory = category = 'Sale';
      }

      if ( category === 'Rental' ) {
        this.selectedCategory = category = 'Rent';
      }

      this.domainService.httpGetListings({
        classification: 'residential',
        status: category,
        client_id: this.authUser.client.ID,
        campaignCategory: this.currentCategory
      });
    }

    if ( this.authUser.provider === 'mydesktop' || this.authUser.provider === 'vaultre' ) {
      if ( category === 'Sales' ) {
        this.selectedCategory = category = this.campaignService.vaultStatus = 'listing';
      }

      if ( category === 'Rental' ) {
        this.selectedCategory = category = this.campaignService.vaultStatus = 'rental';
      }

      if ( category === 'Sold' ) {
        this.selectedCategory = category = this.campaignService.vaultStatus = 'settled';
      }

      if ( this.authUser.provider === 'mydesktop' ) {
        this.mydesktopService.httpNewGetListings({
          classification: 'residential',
          status: this.selectedCategory,
          campaignCategory: this.currentCategory
        });
      }

      if ( this.authUser.provider === 'vaultre' ) {
        if (this.currentCategory === 'Commercial Lease') {
          this.vaultService.httpGetListings({
            classification: 'commercial',
            status: 'rental',
            campaignCategory: this.currentCategory,
            client_id: this.authUser.client.ID
          });
        } else {
          this.vaultService.httpGetListings({
            classification: 'residential',
            status: this.selectedCategory,
            campaignCategory: this.currentCategory,
            client_id: this.authUser.client.ID
          });
        }
      }
    }
  }

  onKeywordChange() {
    if ( !this.keywordTyping ) {
      this.keywordTyping = true;

      setTimeout(() => {
        this.keywordTyping = false;
        this.loading = true;
        this.domainService.httpGetListings({
          classification: 'residential',
          status: this.selectedCategory,
          client_id: this.authUser.client.ID,
          keyword: this.keyword,
          campaignCategory: this.currentCategory
        });
      }, 2000);
    }
  }

  onAddCustomCampaign(listing) {
    const $this = this;
    const campaignData = {
      type: 'custom',
      client_id: this.authUser.client.ID,
      group_id: this.authUser.client.group_id,
      user_id: this.authUser.user.ID,
      property_id: listing.id,
      name: listing.displayaddress,
      headline: listing.heading
    };

    swal({
      title: 'Create Custom Campaign for this listing?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result) {
        $this.loading = true;
        $this.campaignService.httpPostCampaign(campaignData);
      }
    }).catch(swal.noop);
  }

  onAddListingCampaign(listing) {
    this.listing = listing;

    this.campaignService.httpGetCampaigns({
      type: 'custom'
    });
    this.loading = true;
  }

  onAddListing() {
    let category = 'live';
    switch (this.selectedCategory) {
      case 'Sale':
        category = 'live';
        break;
      case 'Rental':
        category = 'rent';
        break;
      case 'Share':
        category = 'share';
        break;
      case 'Sold':
        category = 'sold';
        break;
      case 'NewHomes':
        category = 'newhomes';
        break;
    }

    const customListingValues = this.campaignListingForm.value;
    customListingValues['category'] = category;
    customListingValues['property_id'] = this.listing.id;
    customListingValues['headline'] = this.listing.heading;

    this.campaignService.httpPostCampaignListing(customListingValues);
    this.loading = true;
  }

  onEditHeading(listing) {
    this.listing = listing;

    $('#editHeading').modal('show');
    this.headingForm.patchValue({
      headline: listing.heading
    });
  }

  onSubmitEditHeading() {
    const headingFormValues = this.headingForm.value;
    headingFormValues['category'] = this.currentCategory;
    headingFormValues['type'] = this.listing.listing_status;
    headingFormValues['client_id'] = this.authUser.client.ID;
    headingFormValues['group_id'] = this.authUser.client.group_id;
    headingFormValues['user_id'] = this.authUser.user.ID;
    headingFormValues['property_id'] = this.listing.id;
    headingFormValues['name'] = this.listing.displayaddress;

    this.campaignService.httpPutCampaign(this.listing.campaign_id, headingFormValues);
    this.loading = true;
  }

  removeListingFromCampaign(id: number) {
    const $this = this;
    swal({
      title: 'Are you sure you want to remove this listing from this campaign?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then(function () {
      $this.loading = true;
      swal(
        'Removed!',
        'Your listing has been remove.',
        'success'
      );
      $this.campaignService.httpDeleteListingFromCustomCampaign(id);
    });
  }

  reloadloadListings() {
    if ( this.authUser.provider === 'domain' ) {
      this.domainService.httpGetListings({
        classification: 'residential',
        status: 'Sale',
        client_id: this.authUser.client.ID,
        campaignCategory: this.currentCategory,
        orderby: 'modifydateDesc'
      });
    }

    if ( this.authUser.provider === 'mydesktop' ) {
      this.mydesktopService.httpNewGetListings({
        classification: 'residential',
        status: 'listing',
        campaignCategory: this.currentCategory,
        orderby: 'modifydateDesc'
      });
    }

    if ( this.authUser.provider === 'vaultre' ) {
      this.vaultService.httpGetListings({
        classification: 'residential',
        status: 'listing',
        campaignCategory: this.currentCategory,
        client_id: this.authUser.client.ID,
        orderby: 'modifydateDesc'
      });
    }
  }

  getTemplates(listing: any, type: string) {
    this.listing = this.campaignService.listing = listing;
    this.tmpId = listing.id;

    const _tmpListing = listing;
    _tmpListing['client_id'] = this.authUser.client.ID;
    _tmpListing['group_id'] = this.authUser.client.group_id;
    _tmpListing['user_id'] = this.authUser.user.ID;
    _tmpListing['campaignType'] = 'listing';
    _tmpListing['campaignCategory'] = this.currentCategory;

    if ( type === 'custom' ) {
      this.isCustom = true;
    }

    this.campaignService.httpGenerateCampaignTemplates(_tmpListing);
    this.loading = true;
  }

  onSort(selection) {
    if ( this.authUser.provider === 'domain' ) {
      this.domainService.httpGetListings({
        classification: 'residential',
        status: 'Sale',
        client_id: this.authUser.client.ID,
        campaignCategory: this.currentCategory,
        orderby: selection
      });
    }

    if ( this.authUser.provider === 'mydesktop' ) {
      this.mydesktopService.httpNewGetListings({
        classification: 'residential',
        status: 'listing',
        campaignCategory: this.currentCategory,
        orderby: selection
      });
    }

    if ( this.authUser.provider === 'vaultre' ) {
      this.vaultService.httpGetListings({
        classification: 'residential',
        status: 'listing',
        campaignCategory: this.currentCategory,
        client_id: this.authUser.client.ID,
        orderby: selection
      });
    }

    this.loading = true;
  }

  onShare(template: any) {
    this._tmp_template = template;

    if ( this.selected_share === 'offices' ) {
      this.onShareToClient();
    }

    if ( this.selected_share === 'subgroup' ) {
      this.onShareToSubGroup();
    }

    if ( this.selected_share === 'agent' ) {
      this.onShareToUser();
    }

    this.loading = true;
    this.campaignService.httpGetCampaignShared(this.authUser.client.ID);
  }

  onShareToClient() {
    const template = this._tmp_template;

    this.selectedSharedClient = [];
    this.shared_template_id = template.ID;

    jQuery('#shareTo').find('input[type="checkbox"]').prop('checked', false);

    // for ( const share_client of template.share_clients) {
    //   this.selectedSharedClient.push(share_client.client_id);
    //   jQuery('#shareTo').find('input[value="' + share_client.client_id + '"]').prop('checked', true);
    // }
  }

  onShareToSubGroup() {
    const template = this._tmp_template;

    this.selectedSubGroup = [];
    this.shared_template_id = template.ID;

    jQuery('#shareTo').find('input[type="checkbox"]').prop('checked', false);

    for ( const share_subgroup of template.share_subgroups) {
      this.selectedSubGroup.push(share_subgroup.client_id);
      jQuery('#shareTo').find('input[value="' + share_subgroup.client_id + '"]').prop('checked', true);
    }
  }

  onClear() {
    jQuery('#shareTo').find('input[type="checkbox"]').prop('checked', false);

    this.selectedSharedClient = [];
  }

  onSelectAll() {
    jQuery('#shareTo').find('input[type="checkbox"]').prop('checked', true);

    const elValues = jQuery('#shareToClient input:checkbox:checked').map(function() {
        return this.getAttribute('value');
    }).get();

    this.selectedSharedClient = elValues;
  }

  hasShareable(template): boolean {
    if ( template.share_clients !== 'undefined' ) {
      // tslint:disable-next-line:curly
      if ( template.share_clients.length > 0 )
        return true;
    }

    if ( template.share_subgroups !== 'undefined' ) {
      // tslint:disable-next-line:curly
      if ( template.share_subgroups.length > 0 )
        return true;
    }

    if ( template.share_users !== 'undefined' ) {
      // tslint:disable-next-line:curly
      if ( template.share_users.length > 0 )
        return true;
    }

    return false;
  }

  onCheckSharedClient(el: any) {
    const elCheckbox = el.target;
    const elIsChecked = elCheckbox.checked;
    const elValue = +elCheckbox.value;
    const elExist = this.selectedSharedClient.indexOf(elValue);

    if ( elIsChecked  ) {
      if ( elExist === -1 ) {
        this.selectedSharedClient.push(elValue);
      }
    } else {
      if ( elExist !== -1 ) {
        this.selectedSharedClient.splice(elExist, 1);
      }
    }
  }

  onSubmitShareToClient() {
    this.loading = true;
    const sharedClientValues: Object = {};

    sharedClientValues['share_type'] = 'client';
    sharedClientValues['share_ids'] = this.selectedSharedClient;

    // this.templateService.httpPutTemplate(this.shared_template_id, <any>sharedClientValues);

    // setTimeout(
    //   () => {
    //     this.templateUpdateForm.reset();
    //     this.shareToModalClose.nativeElement.click();
    //   }, 500
    // );
  }

  onShareToUser() {
    const template = this._tmp_template;

    this.selectedSharedUser = [];
    this.shared_template_id = template.ID;

    jQuery('#shareTo').find('input[type="checkbox"]').prop('checked', false);

    for ( const share_user of template.share_users) {
      this.selectedSharedUser.push(share_user.user_id);
      jQuery('#shareTo').find('input[value="' + share_user.user_id + '"]').prop('checked', true);
    }
  }

  onClearShareToUser() {
    jQuery('#shareToUser').find('input[type="checkbox"]').prop('checked', false);
  }

  onSelectAllShareToUser() {
    jQuery('#shareToUser').find('input[type="checkbox"]').prop('checked', true);
  }

  onCheckSharedUser(el: any) {
    const elCheckbox = el.target;
    const elIsChecked = elCheckbox.checked;
    const elValue = +elCheckbox.value;
    const elExist = this.selectedSharedUser.indexOf(elValue);

    if ( elIsChecked  ) {
      if ( elExist === -1 ) {
        this.selectedSharedUser.push(elValue);
      }
    } else {
      if ( elExist !== -1 ) {
        this.selectedSharedUser.splice(elExist, 1);
      }
    }
  }

  onSaveCheck() {
    const $this = this;
    const data = [];

    $('#shareTo input:checked').each(function() {
      data.push($(this).val());
    });

    const shareValues = {
      'category': this.currentCategory,
      'group_id': this.authUser.client.group.ID,
      'client_id': this.authUser.client.ID,
      'client_ids': data
    };

    this.campaignService.httpShareCampaign(shareValues);
    this.loading = true;
  }

  onChangeAgent(agent) {
    localStorage.setItem('campaign_selected_agent', agent);
  }

  getAgentName(agent) {
    if (typeof agent !== 'undefined') {
      let agentName = agent.firstname + ' ' + agent.lastname;
      if (!agent.is_active) {
        agentName = agentName + ' ' + '(Inactive)';
      }
      return agentName;
    }
    return ' ';
  }

  findAgentID(agentID) {
    const agent_id = this.shareListUsers.findIndex(a => +a.agent_id === agentID);
    if ( typeof this.shareListUsers[agent_id] !== 'undefined') {
      return this.shareListUsers[agent_id]['is_active'];
    }
    return false;
  }

  getAgentFilterName(agentUser) {
    return AgentHelper.getAgentFilterName(agentUser);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
