<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">{{ !editMode ? 'Add Subgroup': 'Subgroup - ' + subgroup?.title }} 123</h2>

    <button [routerLink]="[backUrl]" type="button" class="btn-admin">
      <i class="icon feather icon-arrow-left"></i><span>Back</span>
    </button>
  </div>
</div>
<div class="edit-box">
  <div class="row">
    <div class="col-md-12">
      <div class="edit-box-content">
        <h4>Details</h4>
        <form (ngSubmit)="onSubmit()" [formGroup]="subgroupForm" class="form-horizontal">
          <div class="form-group">
            <label for="title" class="col-sm-2">Subgroup Name</label>
            <div class="col-sm-6">
              <input formControlName="title" required type="text" class="form-control" id="title" autocomplete="off" maxlength="80">
            </div>
          </div>
          <br>
          <div class="form-group">
            <label class="col-sm-2">&nbsp;</label>
            <div class="col-sm-10">
              <button [disabled]="!subgroupForm.valid" class="btn-admin btn-admin-primary">Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="edit-box">
  <div class="row">
    <div class="col-md-12">
      <div class="edit-box-content">
        <table id="datatable-clients" class="datatable-clients table table-striped table-borderless table-hover" cellspacing="0" width="100%" style="width:100%">
          <thead>
            <tr>
              <th data-priority="1">ID</th>
              <th data-priority="3">Provider</th>
              <th data-priority="4">Office</th>
              <th>Brand ID</th>
              <th>Office ID</th>
              <th>Show Pro UI</th>
              <th>Franchise Master</th>
              <th>Master</th>
              <th data-priority="5">Status</th>
              <th style="text-align: right" data-priority="2">Actions</th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>