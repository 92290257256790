import { Component, OnInit } from '@angular/core';
import {FrontService} from '../front.service';
import swal from 'sweetalert2';
import {ActivatedRoute} from '@angular/router';
import {TemplateService} from '../../services/template.service';
import {Auth} from '../../models/auth.model';

@Component({
  selector: 'app-front-hug-connect',
  templateUrl: './front-hug-connect.component.html',
  styleUrls: ['./front-hug-connect.component.scss']
})
export class FrontHugConnectComponent implements OnInit {
  authUser: Auth;

  constructor(
      private _route: ActivatedRoute,
      private frontService: FrontService,
      private templateService: TemplateService,
  ) { }

  ngOnInit() {
    this.authUser = this.frontService.authService.auth;
    this.frontService.loadMenu([]);

    swal({
      title: 'Please wait while we generate your HugConnect artwork',
      onOpen: function () {
        swal.showLoading ();
      }
    });

    const hugConnectData: {
      artwork_id: number,
      property_id: number,
      lifeId: number,
      class: string,
      redirect_url: string,
      status: string,
      lifeStatus: string,  
      address: string,
      media: string,
      bookingdate: string,
      size: string,
      specs: string
    } = this._route.snapshot.queryParams as any;

    const hugConnectDataTemplate = {
      artwork_id: hugConnectData.artwork_id,
      property_id: hugConnectData.property_id,
      lifeId: hugConnectData.lifeId,
      class: hugConnectData.class,
      redirect_url: hugConnectData.redirect_url,
      status: hugConnectData.lifeStatus,
      lifeStatus: hugConnectData.status,
      address: hugConnectData.address,
      media: hugConnectData.media,
      bookingdate: hugConnectData.bookingdate,
      size: hugConnectData.size,
      specs: hugConnectData.specs
    };
    
    this.templateService.duplicateTemplate(hugConnectDataTemplate.artwork_id, {
      ID: hugConnectDataTemplate.artwork_id,
      user_agent_id: this.authUser.agentid,
      hug_connect_data: hugConnectDataTemplate
    } as any).subscribe(
        (response: any) => {
          if (typeof response !== 'undefined' && typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              window.location.href = `/templates/${response.data.ID}/build`;
              return;
            }
          }
        },
        () => {
          swal({
            title: 'Error',
            text: 'This template type is not supported. Please try another template.',
            type: 'error',
            confirmButtonText: 'OK'
          });
        }
    );
  }

}
