import { Component, OnDestroy, OnInit } from '@angular/core';
import { FrontService } from '../../front.service';
import { Auth } from '../../../models/auth.model';
import { Subject } from 'rxjs';
import swal from 'sweetalert2';
import { User } from '../../../models/user.model';
import { Client } from '../../../models/client.model';

declare const bootstrap: any;

@Component({
  selector: 'app-settings-designly-pro',
  templateUrl: './settings-designly-pro.component.html',
  styleUrls: ['./settings-designly-pro.component.scss']
})
export class SettingsDesignlyProComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = false;
  authUser: Auth;
  user: User;
  client: Client;
  nextDueDate = '';

  requestDemo = false;
  requestUpgrade = false;
  upgradeModal: any;

  constructor(public frontService: FrontService) { }

  ngOnInit() {
    this.authUser = this.frontService.authService.auth;
    this.upgradeModal = new bootstrap.Modal(document.getElementById('requestUpgradeModal'));
    const $this = this;

    if (localStorage.getItem('isShowUserAlert')) {
      localStorage.removeItem('isShowUserAlert')
      swal({
        title: 'Upgrade to use this feature.',
        html: '<p>You do not have access to this Designly Pro feature.<br>We suggest you contact your administrator in order to Upgrade access.</p>',
        width: '30%',
        type: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok'
      });
    }

    $('#requestDemoModal').on('show.bs.modal', function (e) {

        if (!$this.requestDemo) {
          $this.requestDemo = true;

          const hubspotScript = document.createElement('script');
          const inlineScript = document.createTextNode(`hbspt.forms.create({
            region: "na1",
            portalId: "20238220",
            formId: "80996989-5419-4096-a2ed-42e752b2045b",
            target: "#hubspotFormDemo",
            onFormReady($form){
              $('input[name="firstname"]', $form).val("` + $this.authUser.user.firstname + `").change();
              $('input[name="lastname"]', $form).val("` + $this.authUser.user.lastname + `").change();
              $('input[name="email"]', $form).val("` + $this.authUser.user.email + `").change();
              $('input[name="mobilephone"]', $form).val("` + $this.authUser.user.mobile + `").change();
              $('input[name="company"]', $form).val("` + $this.authUser.client.company_name + `").change();
             }
          });`);

          hubspotScript.appendChild(inlineScript);
          $('#hubspotFormDemo').append(`<div class="form-loader d-flex justify-content-center align-items-center">
          <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
          </div>`);

          setTimeout(() => {
            $('#hubspotFormDemo').find('.form-loader').remove();
            $('head').append(hubspotScript);
          }, 1500);
        }

    });

    $('#requestUpgradeModal').on('show.bs.modal', function (e) {

      if (!$this.requestUpgrade) {
        $this.requestUpgrade = true;

        const hubspotScript = document.createElement('script');
        const inlineScript = document.createTextNode(`hbspt.forms.create({
          region: "na1",
          portalId: "20238220",
          formId: "b564fad0-ab8b-445d-aa13-4d801c4703bd",
          target: "#hubspotFormUpgrade",
          onFormReady($form){
            $('input[name="company"]', $form).val("` + $this.authUser.office + `").change();
            $('input[name="0-2/vault_company_id"]', $form).val("` + $this.authUser.user.office_id + `").change();
            $('input[name="firstname"]', $form).val("` + $this.authUser.user.firstname + `").change();
            $('input[name="lastname"]', $form).val("` + $this.authUser.user.lastname + `").change();
            $('input[name="email"]', $form).val("` + $this.authUser.user.email + `").change();
            $('input[name="mobilephone"]', $form).val("` + $this.authUser.user.mobile + `").change();
            $('input[name="state"]', $form).val("` + $this.authUser.client.state_abbr + `").change();
            $('select[name="country"]', $form).val("` + $this.authUser.client.country + `").change();
           }
        });`);

        hubspotScript.appendChild(inlineScript);
        $('#hubspotFormUpgrade').append(`<div class="form-loader d-flex justify-content-center align-items-center">
        <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
        </div>`);

        setTimeout(() => {
          $('#hubspotFormUpgrade').find('.form-loader').remove();
          $('head').append(hubspotScript);
        }, 1500);
      }
    });

  }

  cancelSubscription() {
    const $this = this;
    swal({
      title: 'Are you sure you want to cancel your subscription?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, cancel it!'
    }).then((result) => {
      if (result) {
        window.open('https://share.hsforms.com/1Jz27CCvyROC4tBDquit-Ugc1rws?company=' + $this.authUser.client.company_name +
        '&firstname=' + $this.authUser.user.firstname + '&lastname=' + $this.authUser.user.lastname + '&email=' +
         $this.authUser.user.email + '&mobilephone=' + $this.authUser.user.mobile, '_blank');
      }
    }).catch(swal.noop);
  }

  showButton(buttonName: string) {

    if (buttonName === 'cancel') {
      if(this.authUser.isUserPro) {
        return true;
      } else {
        return false;
      }
    }

    if (buttonName === 'demo' || buttonName === 'upgrade') {
      if ( (this.authUser.isClientPro && !this.authUser.isUserPro) || (!this.authUser.isClientPro && !this.authUser.isUserPro) ) {
        return true;
      }
    }

    return false;
  }

  upgrade() {
    if (this.authUser.is_admin) {
      this.upgradeModal.show();
    } else {
      swal({
        title: 'This request requires proper authorisation.',
        html: '<p>We suggest to contact your administrator in order to Upgrade to Designly Pro.</p>',
        width: '30%',
        type: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok'
      });
    }
  }

  ngOnDestroy(): void {

  }

}
