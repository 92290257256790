<div id="suburbFilter" class="row center">
    <div class="search">
      <div class="input-group">
        <input [formControl]="coreLogicInput" type="text" class="form-control" name="suburb" placeholder="corelogic Search..." autocomplete="off" />
        <span class="input-group-btn">
          <button class="btn btn-default search-button" type="submit"><i class="icon feather icon-search"></i></button>
        </span>
      </div>
      <div class="search-result list-group" *ngIf="corelogicSuburbs">
        <a *ngFor="let corelogicSuburb of corelogicSuburbs" class="list-group-item list-group-item-action" (click)="onSelectSuburb(corelogicSuburb)">{{ corelogicSuburb?.suggestion }}</a>
      </div>
      <span *ngIf="isSearchCompleted && corelogicSuburbs.length == 0" class="list-group-item" id="no-data-suburb">No data found...</span>
    </div>
    <div class="result" [class.show]="coreLogicSearchResult">
      <div class="container">
        <div class="sub-filter property-types">
          <p>Property Types</p>
          <button class="btn" [class.active]="coreLogicPropertyType === 'HOUSE'" data-proptype="house" (click)="selectCoreLogicPropertyType('HOUSE')">House</button>
          <button class="btn" [class.active]="coreLogicPropertyType === 'UNIT'" data-proptype="unit" (click)="selectCoreLogicPropertyType('UNIT')">Unit</button>
          <button class="btn" [class.active]="coreLogicPropertyType === 'UNIT,HOUSE'" data-proptype="unit" (click)="selectCoreLogicPropertyType('UNIT,HOUSE')">House & Unit</button>
        </div>
        <div>
          <div class="property-heading row">
            <div class="col-md-6">
              <p><span class="title">{{coreLogicPropertyTypeTitle}}</span> <span class="badge badge-info">{{selectedListings.length}}
                  / {{ maxSelectedListings }}</span></p>
            </div>
            <div class="col-md-2 pricefinder-sort">
              <select #coreLogicFilterByBed class="form-control" (change)="onCoreLogicSortByBathAndBed('beds', coreLogicFilterByBed.value)">
                <option value="">All Beds </option>
                <option value="1">1 Bed</option>
                <option value="2">2 Beds</option>
                <option value="3">3 Beds</option>
                <option value="4">4 Beds</option>
                <option value="5">5+ Beds</option>
              </select>
            </div>
            <div class="col-md-2 pricefinder-sort">
              <select #coreLogicFilterByBath class="form-control" (change)="onCoreLogicSortByBathAndBed('baths', coreLogicFilterByBath.value)">
                <option value="">All Baths </option>
                <option value="1">1 Bath</option>
                <option value="2">2 Baths</option>
                <option value="3">3 Baths</option>
                <option value="4">4 Baths</option>
                <option value="5">5+ Baths</option>
              </select>
            </div>
            <!-- There is no sort option -->
            <!-- <div class="col-md-2 pricefinder-sort">
              <select #priceFinderSortHouse class="form-control" (change)="onPriceFinderSortChange(priceFinderSortHouse.value)">
                <option value="-date">Sold Date (Descending)</option>
                <option value="date">Sold Date (Ascending)</option>
                <option value="-price">Price (Descending)</option>
                <option value="price">Price (Ascending)</option>
              </select>
            </div> -->
            <div class="col-md-12">
              <p>Select your desired properties to insert into your report</p>
            </div>
          </div>
          <div class="property-lists propertyListScroller">
            <ul class="blocks blocks-100 blocks-xlg-5 blocks-lg-5 blocks-md-5 blocks-ms-2 blocks-xs-2">
              <li *ngFor="let coreLogicSuburbListing of coreLogicSuburbListings" (click)="selectListingProperty(coreLogicSuburbListing)" [class.selected]="checkIfPropertySelected(coreLogicSuburbListing)">
                <div class="media-item">
                  <div class="info-wrap">
                    <!-- <p class="title">{{coreLogicSuburbListing?.price?.display}}</p> -->
                  </div>
                  <div class="image-wrap">
                    <div class="img-overlay"></div>
                    <img class="image" src="{{ coreLogicSuburbListing?.propertySummary.propertyPhoto.thumbnailPhotoUrl }}" onerror="this.src='assets/img/placeholder.png'" alt="{{ coreLogicSuburbListing?.propertySummary.address.singleLineAddress }}" />
                  </div>
                  <div class="info-wrap">
                    <p class="title">{{coreLogicSuburbListing?.propertySummary.address.singleLineAddress}}</p>
                    <ul class="list-inline">
                      <li class="list-inline-item">BED {{coreLogicSuburbListing?.propertySummary.attributes.bedrooms}}</li>
                      <li class="list-inline-item">BATH {{coreLogicSuburbListing?.propertySummary.attributes.bathrooms}}</li>
                      <li class="list-inline-item">CAR {{coreLogicSuburbListing?.propertySummary.attributes.carSpaces}}</li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="property-actions">
          <button type="button" class="btn btn-default" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
          <button type="button" class="btn btn-primary" (click)="onGenerateReport()">Generate Report</button>
        </div>
      </div>
    </div>
  </div>
  <ngx-loading class="main-loader" [show]="loading"></ngx-loading>
  