<div class="text-start">
    <h2 class="text-capitalize mt-0 mb-3" *ngIf="checkGroupVisible(selectedBrandAssetsType)">
        {{ selectedBrandAssetsType === 'disclaimers' ? 'Disclaimers & Legal' : this?.title }} 
        <ng-container *ngIf="selectedBrandAssetsType === 'fonts'">
            <span class="small" data-tooltip="Please note fonts are only supported in the Pro Builder" data-tooltip-pos="right"><i class="icon feather icon-help-circle"></i></span> 
            <button (click)="addFile('font')" type="button" class="btn-admin btn-admin-primary btn-upload-font pull-right"><i class="icon feather icon-upload"></i> <span>Upload a font</span></button>
        </ng-container>
    </h2>
    <p *ngIf="selectedBrandAssetsType === 'logos'">
        <ng-template [ngIf]="isGroupVisible(client.group.setting_menu_logo)" [ngIfElse]="permissionDenied">
            Save time and upload your logo to add in your designs. <br/>
            Please note the Primary and Secondary logo will automatically add to your designs only.
        </ng-template>
    </p>
    <p *ngIf="selectedBrandAssetsType === 'colours' ">
        <ng-template [ngIf]="isGroupVisible(client.group.setting_menu_color)" [ngIfElse]="permissionDenied">
            Branding colours will apply to your designs automatically and be available to choose when changing colours in the template builder.<br/>
            Colours must be added in HEX format.
        </ng-template>
    </p>
    <p *ngIf="selectedBrandAssetsType === 'fonts'">
        <ng-template [ngIf]="isGroupVisible(client.group.setting_menu_fonts)" [ngIfElse]="permissionDenied">
            Upload Your Custom Fonts to Personalize Your Designs.
        </ng-template>
    </p>
    <p *ngIf="selectedBrandAssetsType === 'icons'">
        <ng-template [ngIf]="isGroupVisible(client.group.setting_menu_icon)" [ngIfElse]="permissionDenied">
            Here you can set what icon styles for Bedrooms, Bathrooms and Parking in your templates.
        </ng-template>
    </p>
    <p *ngIf="selectedBrandAssetsType === 'website-links'">
        <ng-template [ngIf]="isGroupVisible(client.group.setting_menu_link)" [ngIfElse]="permissionDenied">
            Website links auto-populate into your designs to ensure all links direct to your own website.
        </ng-template>
    </p>
    <p *ngIf="selectedBrandAssetsType === 'social-links'">
        <ng-template [ngIf]="isGroupVisible(client.group.setting_menu_link)" [ngIfElse]="permissionDenied">
            Social links auto-populate into your designs to ensure all Social media icons direct to your agencies social media accounts.
        </ng-template>
    </p>
    <p *ngIf="selectedBrandAssetsType === 'disclaimers'">
        <ng-template [ngIf]="isGroupVisible(client.group.setting_menu_link)" [ngIfElse]="permissionDenied">
            Some designs require disclaimers or legal details, the following fields can be auto-populated into your designs to ensure your agency is following legal guidelines.
        </ng-template>
    </p>
</div>

<div class="row mt-5">
    <div class="col-lg-12">
        <div [hidden]="selectedBrandAssetsType !== 'logos'"
            *ngIf="isGroupVisible(client.group.setting_menu_logo)"
            [ngClass]="{ active: defaultTab === 'logo' }" class="tab-pane" id="logoUpload" role="tabpanel">
            <div class="row">
                <div class="col-sm-3">
                    <p>Primary Logo</p>
                    <div class="upload-logo">
                        <img class="img-responsive"
                            src="{{ client?.primary_logo ? client.primary_logo : '/assets/img/add-image.png' }}?timestamp={{currentTimestamp}}"
                            alt="..." id="mainImage" height="200" />
                        <div class="btn_clearfix">
                            <label class="btn btn-block w-100 left-file btn-upload"><i
                                    class="icon feather icon-upload"></i>
                                Upload Logo
                                <input type="file" name="logo" class="_LogoUpload" id="image-primary"
                                    data-action="#mainImage" style="display: none"
                                    (change)="uploadLogo($event)" accept="image/*" /></label>
                        </div>
                    </div>
                </div>

                <div class="col-sm-3">
                    <p>Secondary Logo</p>
                    <div class="upload-logo">
                        <img class="img-responsive"
                            src="{{ client?.secondary_logo ? client.secondary_logo : '/assets/img/add-image.png' }}?timestamp={{currentTimestamp}}"
                            id="secondaryImage" alt="..." />
                        <div class="btn_clearfix">
                            <label class="btn btn-block w-100 left-file btn-upload"><i
                                    class="icon feather icon-upload"></i> Upload Logo <input type="file"
                                    name="logo" class="_LogoUpload" id="image-secondary"
                                    (change)="uploadSecondaryLogo($event)" data-action="#secondaryImage"
                                    style="display: none" accept="image/*" /></label>
                        </div>
                    </div>
                </div>

                <div class="col-sm-3 d-none">
                    <p>&nbsp;</p>
                    <div class="add-logo">
                        <div class="btn_clearfix h-100">
                            <label class="btn btn-block w-100 left-file btn-upload h-100" (click)="addFile('logo')">
                                <span class="icon-label">
                                    <i class="icon feather icon-plus"></i> <br/>Add Logo
                                </span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12">
                    <br />
                    <p class="text-pink mt-3">For optimum result please ensure your logo is at
                        least 600 - 600 pixels wide.*</p>
                </div>
            </div>
        </div>

        <div class="brand-assets-colours" [hidden]="selectedBrandAssetsType !== 'colours'"
        *ngIf="isGroupVisible(client.group.setting_menu_color)">
            <form class="form-horizontal">
                <div class="d-flex form-group">
                    <label class="col-sm-2 control-label text-left darkblue-color">Primary Colour
                    </label>
                    <input type="text" class="asColorpicker1 form-control input-color" [value]="color1"
                        readonly />
                    <div class="position-relative">
                        <button [style.background]="color1" [value]="color1" [cpSaveClickOutside]="false" [cpOKButton]="true" [cpOKButtonText]="'Apply'" [cpOKButtonClass]="'btn btn-apply btn-xs'" [cpCancelButton]="true" [cpCancelButtonClass]="'btn btn-cancel btn-xs'" [(colorPicker)]="color1" (colorPickerSelect)="saveColor()" (click)="keypressEvent($event)" class="cp" id="cp1"></button>
                    </div>
                    <button id="asColorpicker1" class="btn btn-link"
                        (click)="displayImageColorPicker(1)">+ Add color from logo</button>
                </div>

                <div class="d-flex form-group">
                    <label class="col-sm-2 control-label text-left darkblue-color">Secondary Colour
                    </label>
                    <input type="text" class="asColorpicker2 form-control input-color" [value]="color2"
                        readonly />
                    <div class="position-relative">
                        <button [style.background]="color2" [value]="color2" [cpSaveClickOutside]="false" [cpOKButton]="true" [cpOKButtonText]="'Apply'" [cpOKButtonClass]="'btn btn-apply btn-xs'" [cpCancelButton]="true" [cpCancelButtonClass]="'btn btn-cancel btn-xs'" [(colorPicker)]="color2" (colorPickerSelect)="saveColor()" (click)="keypressEvent($event)" class="cp" id="cp2"></button>
                    </div>
                    <button class="btn btn-link"
                        (click)="displayImageColorPicker(2)">+ Add color from logo</button>
                </div>
                <div class="d-flex form-group">
                    <label class="col-sm-2 control-label text-left darkblue-color">Button Colour
                    </label>
                    <input type="text" class="asColorpicker4 form-control input-color" [value]="color4"
                        readonly />
                    <div class="position-relative">
                        <button [style.background]="color4" [value]="color4" [cpSaveClickOutside]="false" [cpOKButton]="true" [cpOKButtonText]="'Apply'" [cpOKButtonClass]="'btn btn-apply btn-xs'" [cpCancelButton]="true" [cpCancelButtonClass]="'btn btn-cancel btn-xs'" [(colorPicker)]="color4" (colorPickerSelect)="saveColor()" (click)="keypressEvent($event)" class="cp" id="cp4"></button>
                    </div>
                    <button class="btn btn-link"
                        (click)="displayImageColorPicker(4)">+ Add color from logo</button>
                </div>
                <!-- <div class="colorpicker-btn mt-5">
                    <button class="btn btn-primary btn-1" (click)="saveColor()">Save Colour</button>
                </div> -->
            </form>

            <hr class="my-5" />

            <div class="text-start">
                <h2 class="text-capitalize mt-0 mb-3">Font Colours</h2>
                <p>Set the colour of fonts here.</p>
            </div>

            <form class="form-horizontal mt-5">
                <div class="d-flex form-group">
                    <label class="col-sm-2 control-label text-left darkblue-color">Main Headings
                    </label>
                    <input type="text" class="asColorpicker6 form-control input-color" [value]="color6" readonly />
                    <div class="position-relative">
                        <button [style.background]="color6" [value]="color6" [cpSaveClickOutside]="false" [cpOKButton]="true" [cpOKButtonText]="'Apply'" [cpOKButtonClass]="'btn btn-apply btn-xs'" [cpCancelButton]="true" [cpCancelButtonClass]="'btn btn-cancel btn-xs'" [(colorPicker)]="color6" (colorPickerSelect)="saveColor()" (click)="keypressEvent($event)" class="cp" id="cp6"></button>
                    </div>
                    <button class="btn btn-link" (click)="displayImageColorPicker(6)">+ Add color from logo</button>
                </div>

                <div class="d-flex form-group">
                    <label class="col-sm-2 control-label text-left darkblue-color">Sub Headings </label>
                    <input type="text" class="asColorpicker7 form-control input-color" [value]="color7" readonly />
                    <div class="position-relative">
                        <button [style.background]="color7" [value]="color7" [cpSaveClickOutside]="false" [cpOKButton]="true" [cpOKButtonText]="'Apply'" [cpOKButtonClass]="'btn btn-apply btn-xs'" [cpCancelButton]="true" [cpCancelButtonClass]="'btn btn-cancel btn-xs'" [(colorPicker)]="color7" (colorPickerSelect)="saveColor()" (click)="keypressEvent($event)" class="cp" id="cp7"></button>
                    </div>
                    <button class="btn btn-link" (click)="displayImageColorPicker(7)">+ Add color from logo</button>
                </div>

                <div class="d-flex form-group">
                    <label class="col-sm-2 control-label text-left darkblue-color">Body Text </label>
                    <input type="text" class="asColorpicker9 form-control input-color" [value]="color9" readonly />
                    <div class="position-relative">
                        <button [style.background]="color9" [value]="color9" [cpSaveClickOutside]="false" [cpOKButton]="true" [cpOKButtonText]="'Apply'" [cpOKButtonClass]="'btn btn-apply btn-xs'" [cpCancelButton]="true" [cpCancelButtonClass]="'btn btn-cancel btn-xs'" [(colorPicker)]="color9" (colorPickerSelect)="saveColor()" (click)="keypressEvent($event)" class="cp" id="cp9"></button>
                    </div>
                    <button class="btn btn-link" (click)="displayImageColorPicker(9)">+ Add color from logo</button>
                </div>

                <div class="d-flex form-group">
                    <label class="col-sm-2 control-label text-left darkblue-color">Inverted Body Text </label>
                    <input type="text" class="asColorpicker10 form-control input-color" [value]="color10" readonly />
                    <div class="position-relative">
                        <button [style.background]="color10" [value]="color10" [cpSaveClickOutside]="false" [cpOKButton]="true" [cpOKButtonText]="'Apply'" [cpOKButtonClass]="'btn btn-apply btn-xs'" [cpCancelButton]="true" [cpCancelButtonClass]="'btn btn-cancel btn-xs'" [(colorPicker)]="color10" (colorPickerSelect)="saveColor()" (click)="keypressEvent($event)" class="cp" id="cp10"></button>
                    </div>
                    <button class="btn btn-link" (click)="displayImageColorPicker(10)">+ Add color from logo</button>
                </div>
            </form>

            <hr class="my-5" />
            <div class="additional-color d-none">
                <p>Add additional colours to use in your designs.</p>
                <div class="d-flex form-group mx-0" *ngFor="let color of customColors;">
                    <input type="text" class="asColorpicker{{ color.count }} form-control input-color" [value]="color.value" readonly />
                    <div class="position-relative">
                        <button [style.background]="color.value" [value]="color.value" [cpSaveClickOutside]="false" [cpOKButton]="true" [cpOKButtonText]="'Apply'" [cpOKButtonClass]="'btn btn-apply btn-xs'" [cpCancelButton]="true" [cpCancelButtonClass]="'btn btn-cancel btn-xs'" [(colorPicker)]="color.value" (colorPickerSelect)="saveColor()" class="cp" id="cp{{ color.count }}"></button>
                    </div>
                    <button class="btn btn-link" (click)="displayImageColorPicker(color.count)">+ Add color from logo</button>
                    <button *ngIf="color.count > 11" class="btn btn-link btn-removecolor"  (click)="removeColor(color.count)"><i class="icon feather icon-trash"></i></button>
                </div>
                <button class="btn btn-primary btn-1 mt-3" (click)="addColor()"><i class="icon feather icon-plus"></i> Add colour</button>
            </div>
        </div>

        <div class="brand-assets-fonts d-none" [hidden]="selectedBrandAssetsType !== 'fonts'"
        *ngIf="isGroupVisible(client.group.setting_menu_fonts)">
            <div class="row">
                <div class="col-8">
                    <ul class="font-items">
                        <li class="font-item">
                            <span>Upload your first font to Designly</span>
                        </li>
                       <li class="font-item">
                           <span>Font Family Name Here</span>
                           <span>ABCabc123456</span>
                           <button type="button" class="btn"><i class="icon feather icon-trash-2"></i></button>
                       </li>
                       <li class="font-item">
                            <span>Font Family Name Here</span>
                            <span>ABCabc123456</span>
                            <button type="button" class="btn"><i class="icon feather icon-trash-2"></i></button>
                        </li>
                    </ul>
                </div>
                <div class="col-4">
                    <div class="card font-form-action">
                        <div class="card-body">
                            <h4>Set Font Family</h4>
                            <p>Set your primary, secondary and body font family</p>
                            <form>
                                <div class="mb-3">
                                    <label class="form-label fw-bold">Primary</label>
                                    <select class="form-select">
                                        <option selected disabled>Select font</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label fw-bold">Secondary</label>
                                    <select class="form-select">
                                        <option selected disabled>Select font</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label fw-bold">Body</label>
                                    <select class="form-select">
                                        <option selected disabled>Select font</option>
                                    </select>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <div class="brand-assets-icons" [hidden]="selectedBrandAssetsType !== 'icons'"
        *ngIf="isGroupVisible(client.group.setting_menu_icon)">
            <form class="form-horizontal">
                <p class="icon-title">Primary Icons
                </p>
                <div class="d-flex form-group">
                    <label class="col-sm-2 control-label text-left darkblue-color">Bed Icon </label>
                    <div class="col-sm-10">
                        <ng-container *ngFor="let icon of icons | icons">
                            <ng-container
                                *ngIf="icon.category_type == 'Primary' && icon.icon_type == 'Bed'">
                                <div class="icon-table">
                                    <div [class.icon-holder-active]="icon.icon_url == client?.primary_icon_bed"
                                        class="icon-holder"
                                        (click)="changeIcon('primary_icon_bed', icon.icon_url, $event)">
                                        <img class="icon-img primary_icon_bed" src="{{ icon.icon_url }}" />
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>

                <div class="d-flex form-group">
                    <label class="col-sm-2 control-label text-left darkblue-color">Bath Icon </label>
                    <div class="col-sm-10">
                        <ng-container *ngFor="let icon of icons | icons">
                            <ng-container
                                *ngIf="icon.category_type == 'Primary' && icon.icon_type == 'Bath'">
                                <div class="icon-table">
                                    <div [class.icon-holder-active]="icon.icon_url == client?.primary_icon_bath"
                                        class="icon-holder"
                                        (click)="changeIcon('primary_icon_bath', icon.icon_url, $event)">
                                        <img class="icon-img primary_icon_bath" src="{{ icon.icon_url }}" />
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>

                <div class="d-flex form-group">
                    <label class="col-sm-2 control-label text-left darkblue-color">Car Icon </label>
                    <div class="col-sm-10">
                        <ng-container *ngFor="let icon of icons | icons">
                            <ng-container
                                *ngIf="icon.category_type == 'Primary' && icon.icon_type == 'Car'">
                                <div class="icon-table">
                                    <div [class.icon-holder-active]="icon.icon_url == client?.primary_icon_car"
                                        class="icon-holder"
                                        (click)="changeIcon('primary_icon_car', icon.icon_url, $event)">
                                        <img class="icon-img icon-primary-car" src="{{ icon.icon_url }}" />
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>

                <p class="icon-title">Inverted Icons</p>

                <div class="d-flex form-group">
                    <label class="col-sm-2 control-label text-left darkblue-color">Bed Icon </label>
                    <div class="col-sm-10">
                        <ng-container *ngFor="let icon of icons | icons">
                            <ng-container
                                *ngIf="icon.category_type == 'Secondary' && icon.icon_type == 'Bed'">
                                <div class="icon-table">
                                    <div [class.icon-holder-active]="icon.icon_url == client?.secondary_icon_bed"
                                        class="icon-holder-dark"
                                        (click)="changeIcon('secondary_icon_bed', icon.icon_url, $event)">
                                        <img class="icon-img secondary_icon_bed"
                                            src="{{ icon.icon_url }}" />
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>

                <div class="d-flex form-group">
                    <label class="col-sm-2 control-label text-left darkblue-color">Bath Icon </label>
                    <div class="col-sm-10">
                        <ng-container *ngFor="let icon of icons | icons">
                            <ng-container
                                *ngIf="icon.category_type == 'Secondary' && icon.icon_type == 'Bath'">
                                <div class="icon-table">
                                    <div [class.icon-holder-active]="icon.icon_url == client?.secondary_icon_bath"
                                        class="icon-holder-dark"
                                        (click)="changeIcon('secondary_icon_bath', icon.icon_url, $event)">
                                        <img class="icon-img secondary_icon_bath"
                                            src="{{ icon.icon_url }}" />
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>

                <div class="d-flex form-group">
                    <label class="col-sm-2 control-label text-left darkblue-color">Car Icon </label>
                    <div class="col-sm-10">
                        <ng-container *ngFor="let icon of icons | icons">
                            <ng-container
                                *ngIf="icon.category_type == 'Secondary' && icon.icon_type == 'Car'">
                                <div class="icon-table">
                                    <div [class.icon-holder-active]="icon.icon_url == client?.secondary_icon_car"
                                        class="icon-holder-dark"
                                        (click)="changeIcon('secondary_icon_car', icon.icon_url, $event)">
                                        <img class="icon-img secondary_icon_car"
                                            src="{{ icon.icon_url }}" />
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>

                <hr class="my-5" />
                <div class="additional-color d-none">
                    <p><strong>Designly Pro</strong> Builder allows you to change the colours of the icons.</p>
                    <div class="d-flex form-group mx-0">
                        <label>Icon Colour</label>
                        <input type="text" class="asColorpickerIcon form-control input-color" [value]="colorIcon" readonly />
                        <div class="position-relative">
                            <button [style.background]="colorIcon" [value]="colorIcon" [cpSaveClickOutside]="false" [cpOKButton]="true" [cpOKButtonText]="'Apply'" [cpOKButtonClass]="'btn btn-apply btn-xs'" [cpCancelButton]="true" [cpCancelButtonClass]="'btn btn-cancel btn-xs'" [(colorPicker)]="colorIcon" (colorPickerSelect)="saveColor()" class="cp" id="cpIcon"></button>
                        </div>
                    </div>
                </div>
            </form>
        </div>  

        <div class="brand-assets-website-links" [hidden]="!isLinksSection(selectedBrandAssetsType)"
        *ngIf="isGroupVisible(client.group.setting_menu_link)">
            <form [formGroup]="linksForm" class="form-horizontal">
                <div [hidden]="selectedBrandAssetsType !== 'website-links'">
                    <div class="d-flex form-group">
                        <label for="form_website" class="col-sm-1">Website URL</label>
                        <div class="col-sm-5">
                            <input type="url" formControlName="link_website"
                                [ngClass]="{ 'invalid-url': url.link_website.status === 'INVALID' }"
                                class="form-control" id="form_website" autocomplete="off"
                                value="{{ client?.link_website }}" />
                        </div>
                    </div>
                    <div class="d-flex form-group">
                        <label for="form_short_website" class="col-sm-1">Short Website URL</label>
                        <div class="col-sm-5">
                            <input type="text" formControlName="link_short_website"
                                [ngClass]="{ 'invalid-url': url.link_short_website.status === 'INVALID' }"
                                class="form-control" id="form_short_website" autocomplete="off"
                                value="{{ client?.link_short_website }}" />
                            <span class="shortWebsite">The short website URL field is used to display your website address in an easier to read format,
                                for example instead of http://www.mywebsite.com you can use mywebsite.com
                                </span>
                        </div>
                    </div>
                    <div [class.hidden]="authUser?.provider === 'idashboard'">
                        <div class="d-flex form-group">
                            <label for="form_buy" class="col-sm-1">Buy</label>
                            <div class="col-sm-5">
                                <input type="text" formControlName="link_buy"
                                    [ngClass]="{ 'invalid-url': url.link_buy.status === 'INVALID' }"
                                    class="form-control" id="form_buy" autocomplete="off"
                                    value="{{ client?.link_buy }}" />
                            </div>
                        </div>
                        <div class="d-flex form-group">
                            <label for="form_sell" class="col-sm-1">Sell</label>
                            <div class="col-sm-5">
                                <input type="text" formControlName="link_sell"
                                    [ngClass]="{ 'invalid-url': url.link_sell.status === 'INVALID' }"
                                    class="form-control" id="form_sell" autocomplete="off"
                                    value="{{ client?.link_sell }}" />
                            </div>
                        </div>
                        <div class="d-flex form-group">
                            <label for="form_rent" class="col-sm-1">Rent</label>
                            <div class="col-sm-5">
                                <input type="text" formControlName="link_rent"
                                    [ngClass]="{ 'invalid-url': url.link_rent.status === 'INVALID' }"
                                    class="form-control" id="form_rent" autocomplete="off"
                                    value="{{ client?.link_rent }}" />
                            </div>
                        </div>
                        <div class="d-flex form-group">
                            <label for="form_home" class="col-sm-1">Home</label>
                            <div class="col-sm-5">
                                <input type="text" formControlName="link_home"
                                    [ngClass]="{ 'invalid-url': url.link_home.status === 'INVALID' }"
                                    class="form-control" id="form_home" autocomplete="off"
                                    value="{{ client?.link_home }}" />
                            </div>
                        </div>
                        <div class="d-flex form-group">
                            <label for="form_about" class="col-sm-1">About</label>
                            <div class="col-sm-5">
                                <input type="text" formControlName="link_about"
                                    [ngClass]="{ 'invalid-url': url.link_about.status === 'INVALID' }"
                                    class="form-control" id="form_about" autocomplete="off"
                                    value="{{ client?.link_about }}" />
                            </div>
                        </div>
                        <div class="d-flex form-group">
                            <label for="form_careers" class="col-sm-1">Careers</label>
                            <div class="col-sm-5">
                                <input type="text" formControlName="link_careers"
                                    [ngClass]="{ 'invalid-url': url.link_careers.status === 'INVALID' }"
                                    class="form-control" id="form_careers" autocomplete="off"
                                    value="{{ client?.link_careers }}" />
                            </div>
                        </div>
                        <div class="d-flex form-group">
                            <label for="form_blog" class="col-sm-1">Blog</label>
                            <div class="col-sm-5">
                                <input type="text" formControlName="link_blog"
                                    [ngClass]="{ 'invalid-url': url.link_blog.status === 'INVALID' }"
                                    class="form-control" id="form_blog" autocomplete="off"
                                    value="{{ client?.link_blog }}" />
                            </div>
                        </div>
                        <div class="d-flex form-group">
                            <label for="form_contact" class="col-sm-1">Contact</label>
                            <div class="col-sm-5">
                                <input type="text" formControlName="link_contact"
                                    [ngClass]="{ 'invalid-url': url.link_contact.status === 'INVALID' }"
                                    class="form-control" id="form_contact" autocomplete="off"
                                    value="{{ client?.link_contact }}" />
                            </div>
                        </div>
                        <div class="d-flex form-group">
                            <label for="form_contact" class="col-sm-1">Testimonials</label>
                            <div class="col-sm-5">
                                <input type="text" formControlName="link_testimonials"
                                    [ngClass]="{ 'invalid-url': url.link_testimonials.status === 'INVALID' }"
                                    class="form-control" id="form_contact" autocomplete="off"
                                    value="{{ client?.link_testimonials }}" />
                            </div>
                        </div>
                        <hr class="my-5" />
                        <div class="d-flex form-group">
                            <div class="col-sm-12">
                                <button type="submit" class="btn-admin btn-admin-primary"
                                    (click)="saveLinks()">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div [hidden]="selectedBrandAssetsType !== 'social-links'">
                    <div class="d-flex form-group">
                        <label for="form_fb" class="col-sm-1">Facebook</label>
                        <div class="col-sm-5">
                            <input type="text" formControlName="link_facebook"
                                [ngClass]="{ 'invalid-url': url.link_facebook.status === 'INVALID' }"
                                class="form-control" id="form_fb" autocomplete="off"
                                value="{{ client?.link_facebook }}" />
                        </div>
                    </div>
                    <div class="d-flex form-group">
                        <label for="form_tw" class="col-sm-1">Twitter</label>
                        <div class="col-sm-5">
                            <input type="text" formControlName="link_twitter"
                                [ngClass]="{ 'invalid-url': url.link_twitter.status === 'INVALID' }"
                                class="form-control" id="form_tw" autocomplete="off"
                                value="{{ client?.link_twitter }}" />
                        </div>
                    </div>
                    <div class="d-flex form-group">
                        <label for="form_yt" class="col-sm-1">Youtube</label>
                        <div class="col-sm-5">
                            <input type="text" formControlName="link_youtube"
                                [ngClass]="{ 'invalid-url': url.link_youtube.status === 'INVALID' }"
                                class="form-control" id="form_yt" autocomplete="off"
                                value="{{ client?.link_youtube }}" />
                        </div>
                    </div>
                    <div class="d-flex form-group">
                        <label for="form_ig" class="col-sm-1">Instagram</label>
                        <div class="col-sm-5">
                            <input type="text" formControlName="link_instagram"
                                [ngClass]="{ 'invalid-url': url.link_instagram.status === 'INVALID' }"
                                class="form-control" id="form_ig" autocomplete="off"
                                value="{{ client?.link_instagram }}" />
                        </div>
                    </div>
                    <div class="d-flex form-group">
                        <label for="form_ig" class="col-sm-1">LinkedIn</label>
                        <div class="col-sm-5">
                            <input type="text" formControlName="link_linkedin"
                                [ngClass]="{ 'invalid-url': url.link_linkedin.status === 'INVALID' }"
                                class="form-control" id="form_linkedin" autocomplete="off"
                                value="{{ client?.link_linkedin }}" />
                        </div>
                    </div>
                    <div class="d-flex form-group">
                        <label for="form_ig" class="col-sm-1">Pinterest</label>
                        <div class="col-sm-5">
                            <input type="text" formControlName="link_pinterest"
                                [ngClass]="{ 'invalid-url': url.link_pinterest.status === 'INVALID' }"
                                class="form-control" id="form_pinterest" autocomplete="off"
                                value="{{ client?.link_pinterest }}" />
                        </div>
                    </div>
                    <div class="d-flex form-group" [class.hidden]="frontService.authService.isUk()">
                        <label for="form_ig" class="col-sm-1">Rate My Agent</label>
                        <div class="col-sm-5">
                            <input type="text" formControlName="link_ratemyagent"
                                [ngClass]="{ 'invalid-url': url.link_ratemyagent.status === 'INVALID' }"
                                class="form-control" id="form_ratemyagent" autocomplete="off"
                                value="{{ client?.link_ratemyagent }}" />
                        </div>
                    </div>
                    <div class="d-flex form-group">
                        <label for="form_ig" class="col-sm-1">Google Reviews</label>
                        <div class="col-sm-5">
                            <input type="text" formControlName="link_googlereviews"
                                [ngClass]="{ 'invalid-url': url.link_googlereviews.status === 'INVALID' }"
                                class="form-control" id="form_googlereviews" autocomplete="off"
                                value="{{ client?.link_googlereviews }}" />
                        </div>
                    </div>
                    <hr class="my-5" />
                    <div class="d-flex form-group">
                        <div class="col-sm-12">
                            <button type="submit" class="btn-admin btn-admin-primary"
                                (click)="saveSocialLinks()">Save</button>
                        </div>
                    </div>
                </div>
                <div [hidden]="selectedBrandAssetsType !== 'disclaimers'">
                    <p class="heading-text">Disclaimer</p>
                    <div class="d-flex form-group" [class.hidden]="authUser?.provider === 'idashboard'">
                        <label for="form_ig" class="col-sm-1">Email</label>
                        <div class="col-sm-5">
                            <textarea rows="5" formControlName="disclaimer_email" class="form-control"
                                id="form_disclaimer_email"
                                autocomplete="off">{{ client?.disclaimer_email }}</textarea>
                        </div>
                    </div>
                    <div class="d-flex form-group">
                        <label for="form_ig" class="col-sm-1">Print</label>
                        <div class="col-sm-5">
                            <textarea rows="5" formControlName="disclaimer_print" class="form-control"
                                id="form_disclaimer_print"
                                autocomplete="off">{{ client?.disclaimer_print }}</textarea>
                        </div>
                    </div>
                    <hr class="my-4"/>
                    <p class="heading-text">Legal</p>
                    <div class="d-flex form-group">
                        <label for="form_ig" class="col-sm-1">Legal 1</label>
                        <div class="col-sm-5">
                            <textarea rows="5" formControlName="legal_1" class="form-control"
                                id="form_legal_1" autocomplete="off">{{ client?.legal_1 }}</textarea>
                        </div>
                    </div>
                    <div class="d-flex form-group">
                        <label for="form_ig" class="col-sm-1">Legal 2</label>
                        <div class="col-sm-5">
                            <textarea rows="5" formControlName="legal_2" class="form-control"
                                id="form_legal_2" autocomplete="off">{{ client?.legal_2 }}</textarea>
                        </div>
                    </div>
                    <div class="d-flex form-group">
                        <label for="form_ig" class="col-sm-1">Legal 3</label>
                        <div class="col-sm-5">
                            <textarea rows="5" formControlName="legal_3" class="form-control"
                                id="form_legal_1" autocomplete="off">{{ client?.legal_3 }}</textarea>
                        </div>
                    </div>
                    <hr class="my-5" />
                    <div class="d-flex form-group">
                        <div class="col-sm-12">
                            <button type="submit" class="btn-admin btn-admin-primary"
                                (click)="saveLinks()">Save</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

    </div>
</div>

<ng-template #permissionDenied>
    <div >
        <h3>You do not have permission to view these settings.</h3>
        <p>The requested page requires proper authorization to access.</p>
        <p>We suggest to contact your administrator for help or report the issue via the <a href="/help/support"> support portal</a>.</p>
    </div>
</ng-template>


<!-- Modal -->
<div #displayUpload id="upload" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="uploadLabel">
    <div class="modal-dialog modal-center" role="document">
      <div class="modal-content">
          <div class="modal-body text-center p-0">
            <div class="dropzone" fileDragDrop (filesChangeEmiter)="onFileChange($event)">
                <button type="button" class="close close-outside"><span (click)="closeModal('upload')" aria-hidden="true">&times;</span></button>
                <ng-container *ngIf="selectedBrandAssetsType === 'logos'">
                    <h3>Upload Logo Here</h3>
                    <form class="form-horizontal">
                        <div class="upload-container">
                            <label class="btn btn-block w-100 left-file btn-add-file">Click or Drag and Drop<input type="file" name="logo" (change)="onFileChange($event.target.files)" style="display: none" accept="image/*" multiple /></label>
                        </div>
                        <p class="mb-4">.JPG or .PNG</p>
                        <p class="mb-4">Please note SVG formatted only supported in Designly Pro.</p>
                    </form>
                </ng-container>
                <ng-container *ngIf="selectedBrandAssetsType === 'fonts'">
                    <h3>Upload font here</h3>
                    <form class="form-horizontal">
                        <div class="upload-container">
                            <label class="btn btn-block w-100 left-file btn-add-file">Click or Drag and Drop<input type="file" name="font" (change)="onFileChange($event.target.files)" style="display: none" accept=".ttf,.woff,.woff2,.svg,.otf" /></label>
                        </div>
                        <div class="mb-4 d-flex justify-content-between">
                           <span>.ttf</span>
                           <span>.woff</span>
                           <span>.woff2</span>
                           <span>.svg</span>
                           <span>.otf</span>
                        </div>
                        <p class="mb-4">By uploading a font you’re confirming that you own and have legal rights to use for intended purposes. </p>
                    </form>
                </ng-container>
                <ng-container *ngIf="selectedBrandAssetsType === 'elements'">
                    <h3>Upload Elements Here</h3>
                    <form class="form-horizontal">
                        <div class="upload-container">
                            <label class="btn btn-block w-100 left-file btn-add-file">Click or Drag and Drop<input type="file" name="logo" (change)="onFileChange($event.target.files)" style="display: none" accept="image/*" multiple /></label>
                        </div>
                        <p class="mb-4">.JPG or .PNG</p>
                        <p class="mb-4">Please note SVG formatted only supported in Designly Pro.</p>
                    </form>
                </ng-container>
                <ng-container *ngIf="selectedBrandAssetsType === 'images'">
                    <h3>Upload Images Here</h3>
                    <form class="form-horizontal">
                        <div class="upload-container">
                            <label class="btn btn-block w-100 left-file btn-add-file">Click or Drag and Drop<input type="file" name="logo" (change)="onFileChange($event.target.files)" style="display: none" accept="image/*" multiple /></label>
                        </div>
                        <p class="mb-4">.JPG .PNG or GIF</p>
                        <p class="mb-4">Please note SVG formatted only supported in Designly Pro.</p>
                    </form>
                </ng-container>
                <ng-container *ngIf="selectedBrandAssetsType === 'files'">
                    <h3>Upload Files Here</h3>
                    <form class="form-horizontal">
                        <div class="upload-container">
                            <label class="btn btn-block w-100 left-file btn-add-file">Click or Drag and Drop<input type="file" name="logo" (change)="onFileChange($event.target.files)" style="display: none" accept="image/*" multiple /></label>
                        </div>
                        <div class="mb-4 d-flex justify-content-between">
                            <span>.txt</span>
                            <span>.csv</span>
                            <span>.pdf</span>
                            <span>.xls</span>
                            <span>.ppt</span>
                            <span>.doc</span>
                            <span>.docx</span>
                            <span>.xlxs</span>
                         </div>
                        <p class="mb-4">By uploading a file you’re confirming that you own and have legal rights to use for intended purposes.</p>
                    </form>
                </ng-container>
            </div>

            <div *ngIf="files.length > 0" class="file-lists">
                <p class="title">Uploaded Files:</p>
                <div class="d-flex gap-1 flex-wrap">
                    <div *ngFor="let file of files" class="file-item">{{ file.name }} 
                        <button type="button" class="btn btn-link btn-sm" (click)="deleteFile(file)"><i class="icon feather icon-x"></i></button>
                    </div>
                </div>
            </div>
          </div>
      </div>
    </div>
</div>

  <!-- Modal -->
<div class="modal" id="modalImageCpicker" aria-hidden="true" aria-labelledby="modalImageCpicker" role="dialog"
tabindex="-1" style="display: none">
<div class="modal-dialog modal-center modal-large">
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close close-outside" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title">Image Color Picker</h4>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-7">
                    <div class="btn_clearfix">
                        <span id="error_msg_image" style="color: red; display: none">Please upload your own logo in
                            our Logo tab page</span>
                    </div>
                    <canvas #canvasEl id="colorPickercanvas" width="500" height="386"></canvas>
                </div>
                <div class="col-md-5 column2">
                    <div>Preview:</div>
                    <div id="preview" style="background-color: rgba(0, 0, 0, 0)"></div>
                    <div>Color:</div>
                    <div class="insertcolor">R: <input type="text" id="rVal" readonly="" class="color" /></div>
                    <div class="insertcolor">G: <input type="text" id="gVal" readonly="" class="color" /></div>
                    <div class="insertcolor">B: <input type="text" id="bVal" readonly="" class="color" /></div>
                    <div class="insertcolor">RGB: <input type="text" id="rgbVal" readonly="" class="color" /></div>
                    <div class="insertcolor">RGBA: <input type="text" id="rgbaVal" readonly="" class="color" />
                    </div>
                    <div class="insertcolor">HEX: <input type="text" id="hexVal" readonly="" class="color" /></div>
                </div>
                <div style="clear: both"></div>
            </div>
        </div>
        <div class="modal-footer justify-content-start">
            <button type="button" class="btn btn-1" data-bs-dismiss="modal" (click)="saveColor()">Add |||</button>
        </div>
    </div>
</div>
</div>

<ngx-loading [show]="loading"></ngx-loading>