<div class="settings-designly-pro">

    <div class="text-start">
        <h2 class="text-capitalize mt-0 mb-3">Designly Pro</h2>
        <p>Get instant access to Designly's Premium content and powerful design features. Here's what you get.</p>
    </div>
    
    <div class="my-4">
        <div class="list-features">
            <div class="row">
                <div class="col-12">
                    <div class="item-wrapper">
                        <span class="circle-icon"><i class="feather icon-pie-chart"></i></span>
                        <h6 class="fw-bold">Unlock online property Webbooks</h6>
                        <p>Fully automated Webbooks triggered right from inside VaultRE or via Designly Pro. Great for listings for sales & rentals or even create event landing pages. The latest online, digital communication and marketing tool which allow you to wrap all of your marketing content into one online space.</p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="item-wrapper">
                        <span class="circle-icon"><i class="feather icon-edit-1"></i></span>  
                        <h6 class="fw-bold">Simply beautiful proposals for your clients.</h6>
                        <p>Web-based proposals, interactive and fully customisable and fully integrated with VaultRE. From appraisal to acceptance all your proposal data flows back into VaultRE.</p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="item-wrapper">
                        <span class="circle-icon"><i class="feather icon-pie-chart"></i></span>
                        <h6 class="fw-bold">Web-based Owner Reports</h6>
                        <p>Unlock beautiful proposals for your clients.</p>
                    </div>
                </div>
            </div> 
        </div><!-- /list-features -->

        <div class="list-options">
            <div class="row">
                <div class="col-12">
                    <div class="item-holder">
                        <div class="row">
                            <div class="col-6">
                                <div class="item-wrapper">
                                    <!-- request demo -->
                                    <div *ngIf="showButton('demo')" class="request-demo-panel">
                                        <div class="request-demo">
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    <p class="mb-4">
                                                        <strong>Request a demo of Designly Pro</strong>
                                                        <span class="d-block small">Connect with us to learn more about how Designly Pro can help you grow your business.</span>
                                                    </p>
                                                    <button type="button" class="btn btn-demo" data-bs-toggle="modal" data-bs-target="#requestDemoModal">Request a Demo</button>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <!-- cancel designly pro -->
                                    <div *ngIf="showButton('cancel')" class="request-demo-panel">
                                        <div class="request-demo">
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    <p class="mb-4">
                                                        <strong>Cancel my Designly Pro Subscription</strong>
                                                        <span class="d-block small">To proceed with the cancellation of your subscription <a href="javascript:void(0)" (click)="cancelSubscription()">click here</a> to fill out a cancellation form.</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2 image-holder">
                                <img _ngcontent-cif-c1="" alt="" height="100%" src="/assets/img/DesignlyPro_Popup_Blue.png" srcset="" width="100%">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> <!-- /list-options -->
    </div>
    
    
    <!-- Demo Modal -->
    <div class="modal fade" id="requestDemoModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-body">
                <div id="hubspotFormDemo"></div>
            </div>
            </div>
        </div>
    </div>

    <!-- Upgrade Modal -->
    <div class="modal fade" id="requestUpgradeModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-body">
                <div id="hubspotFormUpgrade"></div>
            </div>
            </div>
        </div>
    </div>
    
    <ngx-loading [show]="loading"></ngx-loading>

</div>