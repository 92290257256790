import { HttpParams,  HttpClient,   HttpRequest } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import { Style } from '../models/style.model';
import { ActivatedRoute } from '@angular/router';

import 'rxjs/Rx';

import { APP_CONFIG } from '../app.config';
import { StoreGlobalTemplate } from '../models/global-template.model';

@Injectable()
export class StyleService {
  stylesChanged = new Subject<Style[]>();
  styleChanged = new Subject<Style>();
  styleOperation = new Subject<Style>();
  templateChanged = new Subject<any>();
  groupChanged = new Subject<any>();

  private styles: Style[] = [];

  public group_id = 0;

  // New Subscriptions
  onGetStyles = new Subject();

  constructor(@Inject(APP_CONFIG) private appConfig, private httpClient: HttpClient) {}

  setStyles(styles: Style[]) {
    this.styles = styles;
    this.stylesChanged.next(this.styles.slice());
  }

  getStyles() {
    return this.styles.slice();
  }

  getIdIndex(id: number) {
    return this.styles.findIndex(g => g.ID === id);
  }

  httpGetStylesNew(params: any = {}, columns: string = '') {
    let httpParams = new HttpParams();

    if (columns) {
      httpParams = httpParams.append('select', columns);
    }

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/styles`, {
        params: httpParams
    })
    .subscribe(
      (response: any) => {
        this.onGetStyles.next(response);
      },
      (response: any) => {
        this.onGetStyles.next(response.error);
      }
    );
  }

  httpGetStyles(params: any = [], columns: string = '', order: string = '') {

    let httpParams = new HttpParams();

    if ( columns ) {
      httpParams = httpParams.append('select', columns);
    }
    if ( order ) {
      httpParams = httpParams.append('order', order);
    }

    for (let p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/styles',
      {
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (typeof response.body !== 'undefined' && response.body != null) {
            return response.body;
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.stylesChanged.next(response);
      }
    );
  }

  httpGetStyle(id: number) {
    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/styles/' + id,
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: Style) => {
        this.styleChanged.next(response);
      }
    );
  }

  httpPostStyle(data: Style) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/styles',
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.styleOperation.next(response);
      }
    );
  }

  httpPutStyle(id: number, data: Style) {
    const req = new HttpRequest(
      'PUT',
      this.appConfig.API_ENDPOINT + '/styles/' + id,
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.styleOperation.next(response);
      }
    );
  }

  httpDeleteStyle(id: number, group) {
    const req = new HttpRequest(
      'DELETE',
      this.appConfig.API_ENDPOINT + '/styles/' + id
    );

    return this.httpClient.request<any>(req)
    .subscribe(
      (response: any) => {
        this.styleOperation.next(response);
        // if (group === '0') {
        //   this.httpGetStyles();
        // }else {
        //   this.httpGetStyles({group_id: group});
        // }
      }
    );
  }
}
