import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { ProBlock } from '../../../../models/pro-block.model';
import { ProBlockService } from '../../../../services/pro-block.service';

import swal from 'sweetalert2';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-admin-pros-blocks-dashboard',
  templateUrl: './admin-pros-blocks-dashboard.component.html',
  styleUrls: ['./admin-pros-blocks-dashboard.component.scss'],
})
export class AdminProsBlocksDashboardComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = false;

  proBlocks: ProBlock[] = [];
  product: string;

  constructor(private proBlockService: ProBlockService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.showLoading(true);
    this.callBlocks();
    this.loadProBlocks();
    this.onDeleteElement();
  }

  private callBlocks(): void {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params: ParamMap) => {
      this.product = params.get('product');

      if (this.product) {
        this.proBlockService.httpGetAll({
          product: this.product,
        });
      }
    });
  }

  private loadProBlocks(): void {
    this.proBlockService.onGetAll.pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response) {
        this.loading = false;
        if (response.status === 'success') {
          this.proBlocks = response.data;
        }

        if (response.status === 'error') {
          this.handleError('Error getting Pro Blocks');
        }
      }
    });
  }

  private onDeleteElement(): void {
    this.proBlockService.onDelete.pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response) {
        this.showLoading(false);

        if (response.status === 'success') {
          this.callBlocks();
          swal('Deleted!', response.message, 'success');
        } else {
          this.handleError('Error getting Groups');
        }
      }
    });
  }

  private showLoading(value: boolean): void {
    this.loading = value;
  }

  private handleError(message: string): void {
    this.showLoading(false);
    swal(message, 'Contact Dev Team', 'error');
  }

  deleteBlock(proBlock: ProBlock): void {
    swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    })
      .then((result) => {
        if (result) {
          this.showLoading(true);
          this.proBlockService.httpDelete(proBlock.UID);
        }
      })
      .catch(swal.noop);
  }

  editBlock(uid: string): void {
    this.router.navigateByUrl('/admin/pros/blocks/' + this.product + '/' + uid + '/edit');
  }

  duplicateBlock(uid: string): void {
    this.router.navigateByUrl('/admin/pros/blocks/' + this.product + '/' + uid + '/edit/duplicate');
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
