<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">{{ !editMode ? "Add Client" : "Client - " + client_company_name }}</h2>

    <button [routerLink]="backUrl" type="button" class="btn-admin"><i class="icon feather icon-arrow-left"></i><span>Back</span></button>
  </div>
</div>
<div #boxContent>
  <div id="account-details" class="edit-box">
    <div class="row">
      <div class="col-md-12">
        <div class="edit-box-content">
          <h4>Account Details</h4>
          <form [formGroup]="clientForm" (ngSubmit)="onSubmit()" class="form-horizontal">
            <div class="form-group" [class.hidden]="editMode">
              <label for="is_ghost" class="col-sm-2">Is Ghost Client</label>
              <div class="col-sm-5">
                <select #ghostClient formControlName="is_ghost" class="form-control" id="is_ghost" (change)="onGhostClientChange(ghostClient.value)">
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </select>
              </div>
            </div>
            <div class="form-group" [class.hidden]="ghostClient.value === 'yes'">
              <label for="provider" class="col-sm-2">Provider</label>
              <div class="col-sm-5">
                <select #provider formControlName="provider" class="form-control" id="provider" (change)="onProviderChange(provider.value)">
                  <option value="mydesktop">MyDesktop</option>
                  <option value="vaultre">VaultRE</option>
                  <option value="domain">Domain</option>
                  <option value="designly">Designly</option>
                  <option value="idashboard">iDashboard</option>
                </select>
              </div>
            </div>
            <div class="form-group" [class.hidden]="ghostClient.value === 'yes' || provider.value === 'designly'">
              <label for="officeid" class="col-sm-2"> Office ID </label>
              <div class="col-sm-5">
                <input formControlName="office_id" type="text" class="form-control" id="office_id" autocomplete="off" />
              </div>
            </div>
            <div class="form-group" [class.hidden]="ghostClient.value === 'yes' || provider.value === 'designly'">
              <label for="franchise_code" class="col-sm-2">Franchise Code</label>
              <div class="col-sm-5">
                <input formControlName="franchise_code" type="text" class="form-control" id="franchise_code" autocomplete="off" />
              </div>
            </div>
            <div class="form-group" [class.hidden]="ghostClient.value === 'yes' || provider.value === 'designly'">
              <label for="franchise_name" class="col-sm-2">Franchise Name</label>
              <div class="col-sm-5">
                <input formControlName="franchise_name" type="text" class="form-control" id="franchise_name" autocomplete="off" />
              </div>
            </div>
            <!-- <div class="form-group">
              <label for="is_franchise_master" class="col-sm-2">Franchise Master Account</label>
              <div class="col-sm-2">
                <select formControlName="is_franchise_master" class="form-control" id="is_franchise_master">
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div> -->
            <div class="form-group">
              <label for="group_id" class="col-sm-2">Group Name</label>
              <div class="row">
                <div class="col-sm-5">
                  <select formControlName="group_id" class="form-control" id="group_id" (change)="handleGroupChange()">
                    <option value="" disabled>Select a Group</option>
                    <option *ngFor="let mValue of groups" value="{{ mValue.ID }}">{{ mValue.code }} {{ mValue.title }}</option>
                  </select>
                </div>  
                <div class="col-sm-5">       
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="lock_account_group" formControlName="lock_account_group">
                    <label class="form-check-label" for="lock_account_group" style="transform: translateY(5.5px);">
                      Lock Account Group
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group" [class.hidden]="provider.value === 'designly'">
              <label for="is_account_master" class="col-sm-2">Master Account</label>
              <div class="col-sm-3">
                <select formControlName="is_account_master" class="form-control" id="is_account_master">
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>
            <div class="form-group" [class.hidden]="provider.value === 'designly'">
              <label for="subgroup_id" class="col-sm-6">Client Subgroup Name</label>
              <div class="col-sm-5">
                <select formControlName="subgroup_id" class="form-control" id="subgroup_id">
                  <option value="0">Select a Subgroup</option>
                  <option *ngFor="let mValue of subgroups" value="{{ mValue.ID }}">{{ mValue.title }}</option>
                </select>
              </div>
            </div>
            <div class="form-group" [class.hidden]="provider.value === 'designly'">
              <label for="group_sub_group_id" class="col-sm-2">Subgroup Name</label>
              <div class="col-sm-5">
                <select formControlName="group_sub_group_id" class="form-control" id="group_sub_group_id">
                  <option value=""></option>
                  <option *ngFor="let mValue of groupSubGroups" [value]="mValue.id">{{ mValue.title }}</option>
                </select>
              </div>
            </div>
            <div class="form-group" [class.hidden]="provider.value === 'designly'">
              <label for="is_group_sub_group_master" class="col-sm-6">Subgroup Master Account</label>
              <div class="col-sm-3">
                <select formControlName="is_group_sub_group_master" class="form-control" id="is_group_sub_group_master">
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>
            <!-- <div class="form-group">
              <label for="is_subgroup_master" class="col-sm-2">Subgroup Master Account</label>
              <div class="col-sm-3">
                <select formControlName="is_subgroup_master" class="form-control" id="is_subgroup_master">
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div> -->
            <div class="form-group" [class.hidden]="ghostClient.value === 'yes' || provider.value === 'designly'">
              <label for="is_local_search" class="col-sm-2">Listing Search Query</label>
              <div class="col-sm-3">
                <select formControlName="is_local_search" class="form-control" id="is_local_search">
                  <option value="0">Search via Mydesktop API</option>
                  <option value="1">Search via Local Synced Data</option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label for="crm_access_enabled" class="col-sm-2">CRM Access</label>
              <div class="col-sm-3">
                <select formControlName="crm_access_enabled" class="form-control" id="crm_access_enabled">
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label for="pricefinder_enabled" class="col-sm-2">Enable Pricefinder</label>
              <div class="col-sm-3">
                <select formControlName="pricefinder_enabled" class="form-control" id="pricefinder_enabled">
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label for="rss_feed" class="col-sm-2">RSS Feed</label>
              <div class="col-sm-5">
                <input formControlName="rss_feed" type="text" class="form-control" id="rss_feed" autocomplete="off" />
              </div>
            </div>
            <hr />
            <div class="form-group">
              <label for="company_name" class="col-sm-2">Company Name</label>
              <div class="col-sm-5">
                <input formControlName="company_name" type="text" class="form-control" id="company_name" autocomplete="off" />
              </div>
            </div>
            <div class="form-group">
              <label for="name" class="col-sm-2">Name</label>
              <div class="col-sm-5">
                <input formControlName="name" type="text" class="form-control" id="name" autocomplete="off" />
              </div>
            </div>
            <div class="form-group">
              <label for="street" class="col-sm-2">Street</label>
              <div class="col-sm-5">
                <input formControlName="street" type="text" class="form-control" id="street" autocomplete="off" />
              </div>
            </div>
            <div class="form-group">
              <label for="suburb" class="col-sm-2">Suburb</label>
              <div class="col-sm-5">
                <input formControlName="suburb" type="text" class="form-control" id="suburb" autocomplete="off" />
              </div>
            </div>
            <div class="form-group">
              <label for="state" class="col-sm-2">State</label>
              <div class="col-sm-5">
                <input formControlName="state" type="text" class="form-control" id="state" autocomplete="off" />
              </div>
            </div>
            <div class="form-group">
              <label for="state_abbr" class="col-sm-2">State(abbreviation)</label>
              <div class="col-sm-5">
                <input formControlName="state_abbr" type="text" class="form-control" id="state_abbr" autocomplete="off" />
              </div>
            </div>
            <div class="form-group">
              <label for="postcode" class="col-sm-2">Postcode</label>
              <div class="col-sm-5">
                <input formControlName="postcode" type="text" class="form-control" id="postcode" autocomplete="off" />
              </div>
            </div>
            <div class="form-group">
              <label for="country" class="col-sm-2">Country</label>
              <div class="col-sm-5">
                <input formControlName="country" type="text" class="form-control" id="country" autocomplete="off" />
              </div>
            </div>
            <div class="form-group">
              <label for="phone" class="col-sm-2">Phone</label>
              <div class="col-sm-5">
                <input formControlName="phone" type="text" class="form-control" id="phone" autocomplete="off" />
              </div>
            </div>
            <div class="form-group">
              <label for="fax" class="col-sm-2">Fax</label>
              <div class="col-sm-5">
                <input formControlName="fax" type="text" class="form-control" id="fax" autocomplete="off" />
              </div>
            </div>
            <div class="form-group">
              <label for="email" class="col-sm-2">Email</label>
              <div class="col-sm-5">
                <input formControlName="email" type="text" class="form-control" id="email" autocomplete="off" />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2">&nbsp;</label>
              <div class="col-sm-10">
                <button [disabled]="!clientForm.valid" type="submit" class="btn-admin btn-admin-primary">Save</button>
                <button *ngIf="editMode" type="button" class="btn-admin btn-admin-danger" (click)="onAccountActive()">{{ client_account_active ? "Disable Account" : "Enable Account" }}</button>
                <!-- <button *ngIf="editMode" type="button" class="btn-admin" (click)="onTemplateActive()">{{ client_template_active ? 'Disable Templates': 'Enable Templates' }}</button> -->
                <button *ngIf="editMode" type="button" class="btn-admin btn-admin-primary" (click)="onReset()">Reset Version</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div id="colors" class="edit-box hidden">
    <div class="row">
      <div class="col-md-12">
        <div class="edit-box-content">
          <h4>Colour Pallettes</h4>
          <hr />
          <form [formGroup]="colorForm" class="form-horizontal">
            <div class="form-group">
              <label class="col-sm-2 control-label text-left darkblue-color">Primary Colour </label>
              <div class="col-sm-10">
                <input type="text" class="asColorpicker1 form-control input-color" [value]="color1" readonly />
                <button [style.background]="color1" [value]="color1" [(colorPicker)]="color1" class="cp" id="cp1"></button>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-2 control-label text-left darkblue-color">Secondary Colour </label>
              <div class="col-sm-10">
                <input type="text" class="asColorpicker2 form-control input-color" [value]="color2" readonly />
                <button [style.background]="color2" [value]="color2" [(colorPicker)]="color2" class="cp" id="cp2"></button>
              </div>
            </div>

            <!-- <div class="form-group">
              <label class="col-sm-2 control-label text-left darkblue-color">Color #3 </label>
              <div class="col-sm-10">
                  <input type="text" formControlName="color_3" class="asColorpicker3 form-control" data-plugin="asColorPicker" data-mode="simple" (keydown)="onEnterColor($event)" />                        
              </div>
            </div> -->

            <div class="form-group">
              <label class="col-sm-2 control-label text-left darkblue-color">Button Colour </label>
              <div class="col-sm-10">
                <input type="text" class="asColorpicker4 form-control input-color" [value]="color4" readonly />
                <button [style.background]="color4" [value]="color4" [(colorPicker)]="color4" class="cp" id="cp4"></button>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-2 control-label text-left darkblue-color">Email Background Colour </label>
              <div class="col-sm-10">
                <input type="text" class="asColorpicker5 form-control input-color" [value]="color5" readonly />
                <button [style.background]="color5" [value]="color5" [(colorPicker)]="color5" class="cp" id="cp5"></button>
              </div>
            </div>
            <hr />
            <h4>Font Colors</h4>
            <hr />
            <div class="form-group">
              <label class="col-sm-2 control-label text-left darkblue-color">Main Headings</label>
              <div class="col-sm-10">
                <input type="text" class="asColorpicker6 form-control input-color" [value]="color6" readonly />
                <button [style.background]="color6" [value]="color6" [(colorPicker)]="color6" class="cp" id="cp6"></button>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-2 control-label text-left darkblue-color">Sub Headings</label>
              <div class="col-sm-10">
                <input type="text" class="asColorpicker7 form-control input-color" [value]="color7" readonly />
                <button [style.background]="color7" [value]="color7" [(colorPicker)]="color7" class="cp" id="cp7"></button>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-2 control-label text-left darkblue-color">Body Text</label>
              <div class="col-sm-10">
                <input type="text" class="asColorpicker9 form-control input-color" [value]="color9" readonly />
                <button [style.background]="color9" [value]="color9" [(colorPicker)]="color9" class="cp" id="cp9"></button>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-2 control-label text-left darkblue-color">Inverted Body Text</label>
              <div class="col-sm-10">
                <input type="text" class="asColorpicker10 form-control input-color" [value]="color10" readonly />
                <button [style.background]="color10" [value]="color10" [(colorPicker)]="color10" class="cp" id="cp10"></button>
              </div>
            </div>

            <hr />
            <!-- <h4>Icon Colors</h4>
            <hr>
            <div class="form-group">
              <label class="col-sm-2 control-label text-left darkblue-color">Icon color</label>
              <div class="col-sm-10">
                  <input type="text" formControlName="color_8" class="asColorpicker8 form-control" data-plugin="asColorPicker" data-mode="simple" (keydown)="onEnterColor($event)" />                        
              </div>
            </div> -->

            <div class="colorpicker-btn text-end"><button class="btn btn-primary btn-1" (click)="saveColor()">Save Color</button></div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div id="logo" class="edit-box hidden">
    <div class="row">
      <div class="col-md-12">
        <div class="edit-box-content">
          <h4>Logo</h4>
          <hr />

          <div class="row">
            <div class="col-sm-6">
              <p class="text-center">Primary Logo</p>
              <div class="upload-logo">
                <img class="img-responsive" src="{{ client?.primary_logo ? client.primary_logo : '/assets/img/add-image.png' }}" alt="..." id="mainImage" height="200" />
                <div class="btn_clearfix">
                  <label class="btn btn-block left-file btn-upload"><i class="icon feather icon-upload"></i> Upload Logo <input type="file" name="logo" class="_LogoUpload" id="image-primary" data-action="#mainImage" style="display: none" (change)="uploadLogo($event, 'primary')" /></label>
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <p class="text-center">Secondary Logo</p>
              <div class="upload-logo">
                <img class="img-responsive" src="{{ client?.secondary_logo ? client.secondary_logo : '/assets/img/add-image.png' }}" id="secondaryImage" alt="..." />
                <div class="btn_clearfix">
                  <label class="btn btn-block left-file btn-upload"><i class="icon feather icon-upload"></i> Upload Logo <input type="file" name="logo" class="_LogoUpload" id="image-secondary" (change)="uploadLogo($event, 'secondary')" data-action="#secondaryImage" style="display: none" /></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="hires_logo" class="edit-box hidden">
    <div class="row">
      <div class="col-md-12">
        <div class="edit-box-content">
          <h4>High Resolution Logo</h4>
          <hr />

          <div class="row">
            <div class="col-sm-6">
              <p class="text-center">High Resolution Primary Logo</p>
              <div class="upload-logo">
                <img class="img-responsive" src="{{ client?.hires_primary_logo ? client.hires_primary_logo : '/assets/img/add-image.png' }}" alt="..." id="mainHiResImage" height="200" />
                <div class="btn_clearfix">
                  <label class="btn btn-block left-file btn-upload"><i class="icon feather icon-upload"></i> Upload Logo <input type="file" name="logo" class="_LogoUpload" id="image-hires-primary" data-action="#mainHiResImage" style="display: none" (change)="uploadLogo($event, 'hires_primary')" /></label>
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <p class="text-center">High Resolution Secondary Logo</p>
              <div class="upload-logo">
                <img class="img-responsive" src="{{ client?.hires_secondary_logo ? client.hires_secondary_logo : '/assets/img/add-image.png' }}" id="secondaryHiResImage" alt="..." />
                <div class="btn_clearfix">
                  <label class="btn btn-block left-file btn-upload"><i class="icon feather icon-upload"></i> Upload Logo <input type="file" name="logo" class="_LogoUpload" id="image-hires-secondary" (change)="uploadLogo($event, 'hires_secondary')" data-action="#secondaryHiResImage" style="display: none" /></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="group-details" class="edit-box hidden">
    <div class="row">
      <div class="col-md-12">
        <div class="edit-box-content">
          <h4>Group Details</h4>
        </div>
      </div>
    </div>
  </div>
  <div id="subscription-details" class="edit-box hidden">
    <div class="row">
      <div class="col-md-12">
        <div class="edit-box-content">
          <h4>Subscription Details</h4>
          <form [formGroup]="clientForm" (ngSubmit)="onSubmit()" class="form-horizontal">
            <div class="form-group">
              <label for="enable_pro_ui" class="col-sm-2">Enable Pro UI</label>
              <div class="col-sm-5">
                <select formControlName="enable_pro_ui" class="form-control" id="enable_pro_ui">
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label for="pro_trial_period" class="col-sm-2">Trial Period</label>
              <div class="col-sm-5">
                <select formControlName="pro_trial_period" class="form-control" id="pro_trial_period">
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label for="show_billing_page" class="col-sm-2">Show Subscription & Billing Page</label>
              <div class="col-sm-5">
                <select formControlName="show_billing_page" class="form-control" id="show_billing_page">
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label for="show_pro_builder" class="col-sm-2">Show Pro Builder in Subscriptions</label>
              <div class="col-sm-5">
                <select formControlName="show_pro_builder" class="form-control" id="show_pro_builder">
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2">&nbsp;</label>
              <div class="col-sm-10">
                <button [disabled]="!clientForm.valid" type="submit" class="btn-admin btn-admin-primary">Save</button>
              </div>
            </div>
          </form>
          <hr />
          <div *ngIf="client?.subscription">
              <p class="heading-text"><strong>Office Subscription Details:</strong></p>
              <p>Current subscription: <span class="subscription-plan">Designly Pro</span></p>
          </div>
          <div *ngIf="!client?.subscription">
              <p class="heading-text"><strong>Office Subscription Details:</strong></p>
              <p>Current subscription: <span class="subscription-plan">Designly Lite</span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
