import { Inject, Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { APP_CONFIG } from "../app.config";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class TemplateSettingGroupService {
    templateGroupChanged = new Subject<any>();
    constructor(
        @Inject(APP_CONFIG) private appConfig,
        private httpClient: HttpClient
    ) {}

    getList(settingType: string, templateId: number) {
        return this.httpClient.get(this.appConfig.NEW_API_ENDPOINT + `/settings/template/group/get/${settingType}/${templateId}`).subscribe((response: any) => {
            this.templateGroupChanged.next(response);
        });
    }

    storeGroupsInfo(data) { //storing of new template group data or update a single group item
        return this.httpClient.post(this.appConfig.NEW_API_ENDPOINT + '/settings/template/group/store', data);
    }

    updateGroupInfo(data) {
        return this.httpClient.post(this.appConfig.NEW_API_ENDPOINT + '/settings/template/group/update', data);
    }

    deleteGroupRow(type: string, id: number) {
        return this.httpClient.get(this.appConfig.NEW_API_ENDPOINT + `/settings/template/group/delete/${type}/${id}`);
    }
}