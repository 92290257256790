
export class StoreGlobalTemplateResponse {
    constructor (
        public success: boolean,
        public message: string
    ) {}
}

export interface GlobalTemplate {
    success: boolean,
    data: Array<RowGlobalSetting | GroupedGlobalSetting>
}

export interface StoreGlobalTemplate {
    master_id: number
    data: Array<{
        [index: number]: Array<{
            id: number,
            title: string,
            description: string,
            settings_type: number,
            class_name: string,
            master_id: number
            group_id: number,
            options: Array<{
                title: string,
                description: string,
                class_name: string,
                id: number
            }>
        }>
    }>
}

export interface SettingGroup {
    success: boolean,
    data: Array<{
        id: number,
        group_name: string,
        created_at: string,
        updated_at: string
    }>
}

export interface RowGlobalSetting {
    id: number,
    class_name: string,
    description: string,
    group_id: number | null,
    master_id: number,
    settings_type: number,
    title: string,
    settings_dropdown_options: Array<{
        id: number,
        title: string,
        description: string | null,
        class_name: string | null,
        global_template_settings_id: number | null,
        element_template_settings_id: number | null
    }>
}

export interface GroupedGlobalSetting {
    data: Array<RowGlobalSetting>,
    group_id: number,
    group_name: string,
}