<div id="front-wrapper" class="height-100">
  <div class="notification_area" *ngIf="new_notification">
    <h3>
      {{ headline }}
      <button
        *ngIf="!is_changelog_button_hidden"
        (click)="onViewNotification()"
        class="btn-notification btn btn-primary btn-1 text-uppercase"
      >
        view details
      </button>
      <span (click)="onNotificationClose()" aria-hidden="true">×</span>
    </h3>
  </div>
  <div #sidebarWrapper id="sidebar-wrapper" class="height-100">
    <app-sidebar></app-sidebar>
  </div>
  <div #contentWrapper id="content-wrapper" class="height-100">
    <router-outlet></router-outlet>
  </div>
  <div
    *ngIf="isMultipleOffice"
    #switchOffice
    id="switchOffice"
    class="modal fade edit-box"
    role="dialog"
    aria-labelledby="switchOfficeLabel"
  >
    <div class="modal-dialog modal-center" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            #switchOfficeModalClose
            class="close close-outside"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title" id="switchOfficeLabel">Switch Office</h4>
        </div>
        <form
          (ngSubmit)="onSubmitSwitch()"
          [formGroup]="switchOfficeForm"
          class="form-horizontal"
        >
          <div class="modal-body">
            <div class="form-group">
              <label for="title" class="col-sm-2">Office</label>
              <div class="col-sm-8">
                <select formControlName="office_id" class="form-control">
                  <option value="" selected disabled>Select an Office</option>
                  <option
                    *ngFor="let oneOffice of multipleOffice"
                    [value]="oneOffice.id"
                  >
                    {{ oneOffice.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              [disabled]="!switchOfficeForm.valid"
              class="btn-admin btn-admin-primary me-2"
            >
              Switch
            </button>
            <button type="button" class="btn-admin" data-bs-dismiss="modal">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div
    #domainMultiOffice
    id="domainMultiOffice"
    class="modal fade edit-box"
    role="dialog"
    aria-labelledby="domainMultiOfficeLabel"
  >
    <div class="modal-dialog modal-center" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            #domainMultiOfficeModalClose
            class="close close-outside"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title" id="domainMultiOfficeLabel">Switch Office</h4>
        </div>
        <form
          [formGroup]="agencyLoginForm"
          (ngSubmit)="onLoginAgency()"
          class="form-horizontal"
        >
          <div class="modal-body">
            <div class="form-group">
              <div class="col-sm-12">
                <select
                  #agencySelect
                  (change)="onChangeAgency(agencySelect.value)"
                  formControlName="agency_id"
                  class="form-control agent-list"
                >
                  <option value="">Select agency</option>
                  <option *ngFor="let agency of agencies" [value]="agency.id">
                    {{ agency.name }} ({{ agency.id }})
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <div class="col-sm-12">
                <select
                  formControlName="agent_id"
                  class="form-control agent-list"
                >
                  <option value="">Select agent</option>
                  <option *ngFor="let agent of agents" [value]="agent?.id">
                    {{ agent?.firstName }} {{ agent?.lastName }} ({{
                      agent?.id
                    }})
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              [disabled]="!agencyLoginForm.valid"
              class="btn-admin btn-admin-primary me-2"
            >
              Switch
            </button>
            <button type="button" class="btn-admin" data-bs-dismiss="modal">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div id="articleModal" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-bs-dismiss="modal"
          (click)="onDisablePopup()"
        >
          &times;
        </button>
        <h2 class="modal-title">New article marketplace</h2>
        <p>
          Partnering with
          <a
            href="https://realestate.partica.com/mydesignpartica/?aff=RC3WM3Y4VI3"
            target="_blank"
            >Partica</a
          >
          we can now provide agents instant access to thousands of high quality,
          royalty free, articles, ready to insert into your templates.
        </p>
      </div>
      <div class="modal-body">
        <div class="slider-wrapper">
          <button type="button" class="slider-prev" (click)="slider('prev')">
            <i class="icon feather icon-chevron-left"></i>
          </button>
          <button type="button" class="slider-next" (click)="slider('next')">
            <i class="icon feather icon-chevron-right"></i>
          </button>
          <img
            src="assets/img/article-marketplace-slide0{{ currentSlide }}.svg"
          />
          <p class="slider-text">{{ instructions['step' + currentSlide] }}</p>
        </div>
      </div>
      <div class="modal-footer">
        <div class="pull-left footer-switch">
          <label class="switch">
            <input type="checkbox" id="showCheckBox" />
            <span class="slider round"></span>
          </label>
          <span>Don't show again</span>
        </div>
        <div class="pull-right">
          <button
            type="button"
            class="btn btn-default btn-marketplace"
            data-bs-dismiss="modal"
            (click)="onGoToMarketplace()"
          >
            Take me to the marketplace
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- pro version popup -->
<div id="proVersionModal" class="modal fade" role="dialog">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="row">
        <div class="col-6 background p-0">
          <img
            src="/assets/img/DesignlyPro_Popup_Blue.png"
            alt=""
            srcset=""
            height="100%"
            width="100%"
          />
        </div>
        <div class="col-6 content">
          <div *ngIf="!contactSales" class="h-100" id="proVersion-content1">
            <h3 class="header mt-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21.093"
                height="22.611"
                viewBox="0 0 21.093 22.611"
              >
                <g
                  id="Group_2517"
                  data-name="Group 2517"
                  transform="translate(-13.226 -18.625)"
                >
                  <rect
                    id="Rectangle_1209"
                    data-name="Rectangle 1209"
                    width="18.391"
                    height="3.065"
                    rx="0.5"
                    transform="translate(14.067 38.171)"
                    fill="#fac656"
                  />
                  <path
                    id="Path_1028"
                    data-name="Path 1028"
                    d="M28.353,25.715a.77.77,0,0,1-1.148-.295l-2.531-5.333L23.21,17l-1.464,3.085L19.214,25.42a.77.77,0,0,1-1.148.295L13.888,22.7a.772.772,0,0,0-1.216.736l1.657,11.422a.771.771,0,0,0,.764.66H31.327a.771.771,0,0,0,.764-.66l1.657-11.422a.772.772,0,0,0-1.216-.736Z"
                    transform="translate(0.562 1.623)"
                    fill="#fac656"
                  />
                </g>
              </svg>
              Get Designly Pro
            </h3>
            <p>
              Get instant access to Designly’s Premium content and powerful design
              features. Here’s what you get:
            </p>
            <ul class="list-group list-group-flush mt-4">
              <li class="list-group-item border-0">
                <span class="circle-icon"
                  ><i class="feather icon-book-open"></i
                ></span>
                <div class="text">
                  <h5>Unlock online property Webbooks</h5>
                  <p>
                    Online, digital communication and marketing tool which allows
                    you to wrap all of your marketing content into one live,
                    online document.
                  </p>
                </div>
              </li>
              <li class="list-group-item border-0">
                <span class="circle-icon"
                  ><i class="feather icon-edit-1"></i
                ></span>
                <div class="text">
                  <h5>Simply beautiful proposals for your clients.</h5>
                  <p>Web-based proposals, Interactive and fully customizable.</p>
                </div>
              </li>
              <li class="list-group-item border-0">
                <span class="circle-icon"
                  ><i class="feather icon-pie-chart"></i
                ></span>
                <div class="text">
                  <h5>Web-based Owner Reports</h5>
                  <p>
                    Unlock beautiful web-based generated owner reports within VaultRE.
                  </p>
                </div>
              </li>
              <li class="list-group-item border-0 d-none">
                <span class="circle-icon"
                  ><i class="feather icon-calendar"></i
                ></span>
                <div class="text">
                  <h5>Future upgrades</h5>
                  <p>Access to new and upcoming features and more…</p>
                </div>
              </li>
            </ul>
            <button type="button" class="btn btn-default btn-lg btn-block w-100 mb-1" (click)="requestDemo()">
              Request a Demo
            </button>
            <a href="javascript:void(0)" (click)="upgrade()" class="btn btn-primary btn-lg btn-block w-100" aria-label="Close">
              Upgrade to Designly Pro
            </a>
          </div>
          <div id="proVersion-content2"></div>
        </div>
      </div>
    </div>
  </div>
</div>
