export class ProposalProperty {
    constructor(
      public property_id: string,
      public unit_number: string,
      public street_number: string,
      public street: string,
      public suburb: string,
      public state: string,
      public postcode: string,
      public beds: number,
      public baths: number,
      public cars: number,
      public land_area: number,
      public floor_area: number,
      public method_of_sale: string,
      public sold_price: string,
      public price: string,
      public selling_price: string,
      public address_visibility: string,
      public display_address: string,
    ) {}
}
