import { Injectable } from "@angular/core";
import { Auth } from "../models/auth.model";

@Injectable()
export class FeatureFlagService {
    
    private bayleysClientId = [2354, 5930, 5972, 6092, 6126, 6134, 6135]; /* 2354: Demo Account, other: Bayleys Account */
    private harcourtsClientId = [2354, 4887];

    private harcourtsAuGroupId = 35;
    
    constructor() {}

    checkUser(type: string, auth: Auth) {
        if (type === 'bayleys' && this.bayleysClientId.indexOf(auth.user.client.ID) >= 0) {
            return true;
         } else if(type === 'harcourts' && this.harcourtsClientId.indexOf(auth.user.client.ID) >= 0) {
            return true;
        }

        return false;
    }

    checkGroup(type: string, auth: Auth) {
        if (type === 'harcourts' && this.harcourtsAuGroupId === auth.user.client.group_id) {
            return true;
        }
        return false
    }
}