export enum Categories {
    EMARKETING = 1,
    BUYER_MATCH = 2,
    QUICK_ENQUIRY = 3,
    BROCHURE = 4,
    PROPERTY_GUIDE = 5,
    SOCIAL_MEDIA = 6,
    HTML_SIGNATURE = 7,
    DL_CARD = 8,
    SIGNBOARD = 9,
    PDF_FLYERS = 10,
    MATCH_PROPERTIES = 11,
    BUYER_MATCH_VAULT = 12,
    PROPERTY_ALERTS = 13,
    PRICEFINDER = 14,
    WEB_BOOKS = 15,
    OWNER_REPORTS = 16,
    PROPOSALS = 17
}