import { Component, OnInit} from '@angular/core';
import { FrontService } from '../front.service';
import { Menu } from '../../models/menu.model';
import { Auth } from '../../models/auth.model';
import { ClientService } from '../../services/client.service';
import { Client } from '../../models/client.model';

@Component({
  selector: 'app-brand-assets',
  templateUrl: './brand-assets.component.html',
  styleUrls: ['./brand-assets.component.scss']
})
export class BrandAssetsComponent implements OnInit {
  authUser: Auth;
  menus: Menu[] = [];
  client: Client;

  constructor(
    private frontService: FrontService
  ) { }

  ngOnInit() {
    this.authUser = this.frontService.authService.auth;

    this.client = this.authUser.client;

    let defaultMenus = [];
 

    defaultMenus = this.getMenus();


    // defaultMenus = [
    //   new Menu('Logos', '/brand-assets/logos'),
    //   new Menu('Colours', '/brand-assets/colours'),
    //   // new Menu('Fonts', '/brand-assets/fonts'),
    //   new Menu('Icons', '/brand-assets/icons'),
    //   new Menu('Elements', '/brand-assets/elements'),
    //   new Menu('Images', '/brand-assets/images'),
    //   new Menu('Files', '/brand-assets/files'),
    //   new Menu('Website Links', '/brand-assets/website-links'),
    //   new Menu('Social Links', '/brand-assets/social-links'),
    //   new Menu('Disclaimers & Legal', '/brand-assets/disclaimers'),
    // ];
  
    this.menus = [
      new Menu('Brand Assets', '', defaultMenus)
    ];

    this.frontService.loadMenu(this.menus);
  }

  getMenus(isAllMenu = false) {
    let defaultMenus: any = [];
    if (isAllMenu) {
      defaultMenus = [
        new Menu('Logos', '/brand-assets/logos'),
        new Menu('Colours', '/brand-assets/colours'),
        // new Menu('Fonts', '/brand-assets/fonts'),
        new Menu('Icons', '/brand-assets/icons'),
        new Menu('Elements', '/brand-assets/elements'),
        new Menu('Images', '/brand-assets/images'),
        new Menu('Files', '/brand-assets/files'),
        new Menu('Website Links', '/brand-assets/website-links'),
        new Menu('Social Links', '/brand-assets/social-links'),
        new Menu('Disclaimers & Legal', '/brand-assets/disclaimers'),
      ];

      return defaultMenus;
    } else {
      if (this.authUser.client.group.setting_menu_logo || this.isCanAccess('master_group_admin')) {
        defaultMenus.push(new Menu('Logos', '/brand-assets/logos'));
      }
      if (this.authUser.client.group.setting_menu_color || this.isCanAccess('master_group_admin')) {
        defaultMenus.push(new Menu('Colours', '/brand-assets/colours'));
      }
      if (this.authUser.client.group.setting_menu_icon || this.isCanAccess('master_group_admin')) {
        defaultMenus.push(new Menu('Icons', '/brand-assets/icons'));
      }
      defaultMenus.push(new Menu('Elements', '/brand-assets/elements'));
      defaultMenus.push(new Menu('Images', '/brand-assets/images'));
      defaultMenus.push(new Menu('Files', '/brand-assets/files'));
      if (this.authUser.client.group.setting_menu_link || this.isCanAccess('master_group_admin')) {
        defaultMenus.push(new Menu('Website Links', '/brand-assets/website-links'), new Menu('Social Links', '/brand-assets/social-links'), new Menu('Disclaimers & Legal', '/brand-assets/disclaimers'));
      }
      return defaultMenus;
    }
  }

  isCanAccess (type: string) {
    let response = false;
    if (type === "master_group_admin" && (this.authUser.is_master && this.authUser.is_group_admin)) {
      response = true;
    }
    return response;
  }

}
