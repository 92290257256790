import { Component, OnInit, OnDestroy, ViewChild, ElementRef, NgZone } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { AdminService } from '../../admin.service';
import { BlockService } from '../../../services/block.service';
import { ElementService } from '../../../services/element.service';
import { Block } from '../../../models/block.model';
import { Element } from '../../../models/element.model';
import { Subscription } from 'rxjs/Subscription';

import * as moment from 'moment';
import { FormGroup, FormControl } from '@angular/forms';
import { ClientService } from '../../../services/client.service';
import { GroupService } from '../../../services/group.service';
import {Client} from '../../../models/client.model';
import {DepartmentService} from '../../../services/department.service';
import {Department} from '../../../models/department.model';

declare var jQuery: any;
declare const $: any;
declare var swal: any;
@Component({
  selector: 'app-elements',
  templateUrl: './elements.component.html',
  styleUrls: ['./elements.component.scss'],
})
export class ElementsComponent implements OnInit, OnDestroy {
  loading = false;

  blocksSubscription: Subscription;
  blockSubscription: Subscription;

  elementDeleteSubscription: Subscription;

  groupsSubscription: Subscription;
  clientsSubscription: Subscription;
  departmentSubscription: Subscription;
  group_id = '';
  client_ids: [];
  department_ids: [];

  dataTableHtml: any;

  selectedRowDataId = [];

  selectGroupForm: FormGroup;

  @ViewChild('previewModal', { static: true }) previewModal: ElementRef;

  categories = {
    enewsletter: {
      id: 1,
      name: 'Email',
    },
    'buyer-match': {
      id: 2,
      name: 'Buyer Match',
    },
    'quick-enquiry': {
      id: 3,
      name: 'Quick Enquiry',
    },
   'single-property-flyer': {
      id: 4,
      name: 'Single Property Flyer',
    },
    'multi-poroperty-flyer': {
      id: 5,
      name: 'Multi-Property Flyer',
    },
    'social-media': {
      id: 6,
      name: 'Social Media',
    },
    'email-signature': {
      id: 7,
      name: 'Email Signature',
    },
    'dl-card': {
      id: 8,
      name: 'DL Card',
    },
    'sign-board': {
      id: 9,
      name: 'Signboard',
    },
    'property-match': {
      id: 10,
      name: 'PDF Flyers',
    },
    'match-listings': {
      id: 11,
      name: 'Match Properties',
    },
    'buyer-match-vault': {
      id: 12,
      name: 'Buyer Match Vault',
    },
    'property-alerts': {
      id: 13,
      name: 'Property Alerts',
    },
    'pricefinder': {
      id: 14,
      name: 'Pricefinder',
    },
    'webbooks': {
      id: 15,
      name: 'WebBooks',
    },
    'owner-reports': {
      id: 16,
      name: 'Owner Reports',
    },
    'proposals': {
      id: 17,
      name: 'Proposals',
    }
  };

  elements: Element[];

  blocks = [];
  groups = [];
  clients: Client[];
  departments: Department[] = [];
  category: string;
  category_id: number;
  category_name = '';
  block_id = 0;

  constructor(
    private adminService: AdminService,
    private blockService: BlockService,
    private elementService: ElementService,
    private groupService: GroupService,
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    private clientService: ClientService,
    private departmentService: DepartmentService
  ) {
    const $this = this;

    window['onEdit'] = function (id) {
      $this.onEdit(id);
    };

    window['onHtml'] = function (id) {
      $this.onHtml(id);
    };

    window['onDuplicate'] = function (id) {
      $this.onDuplicate(id);
    };

    window['onPreview'] = function (id) {
      $this.onPreview(id);
    };

    window['onDelete'] = function (id) {
      $this.onDelete(id);
    };
  }

  ngOnInit() {
    this.groupService.httpGetGroups();
    this.clientService.httpGetClients({}, 'list');
    this.departmentService.httpGetDepartments();

    this.route.params.subscribe((params: Params) => {
      this.category = params['type'];

      if (this.categories[this.category].id !== this.blockService.category_id) {
        this.blockService.block_id = 0;
      }

      this.category_id = this.blockService.category_id = this.categories[this.category].id;
      this.category_name = this.categories[this.category].name;

      if (this.blockService.group_id !== 0) {
        this.loading = true;
        this.blockService.httpGetBlocks({
          category_id: this.category_id,
          group_id: `0,${this.blockService.group_id}`,
        });
      }
    });

    this.groupsSubscription = this.groupService.groupsChanged.subscribe((groups: any) => {
      this.groups = [];
      if (groups.length > 0) {
        this.groups = groups;
      }
    });

    this.clientsSubscription = this.clientService.clientsChanged.subscribe(
        (clients: any) => {
          if ( typeof clients !== 'undefined' && clients.length > 0 ) {
            this.clients = clients;
          }
        }
    );

    this.departmentSubscription = this.departmentService.departmentsChanged.subscribe(
        (departments: Department[]) => {
          if (typeof departments !== 'undefined' && departments.length > 0) {
            this.departments = departments;
          }
        }
    );

    this.blocksSubscription = this.blockService.blocksChanged.subscribe((blocks: Block[]) => {
      this.blocks = [];
      this.blocks = blocks;
      if (blocks.length > 0) {
        if (this.blockService.block_id !== 0) {
          this.blockService.httpGetBlock(this.blockService.block_id, {
            select: 'dashboard',
          });
          this.loading = true;
        } else {
          this.blockService.block_id = this.block_id = +this.blocks[0].ID;
          if ($.fn.DataTable.isDataTable(this.dataTableHtml)) {
            this.dataTableHtml.destroy();
          }
          this.initDT();
        }
      }
    });

    this.blockSubscription = this.blockService.blockChanged.subscribe((block: Block) => {
      if (typeof block.ID !== 'undefined') {
        this.elements = block.elements;
        this.blockService.block_id = this.block_id = block.ID;

        if ($.fn.DataTable.isDataTable(this.dataTableHtml)) {
          this.dataTableHtml.destroy();
        }
        this.initDT();
      }
    });

    this.elementDeleteSubscription = this.elementService.elementDelete.subscribe((element: any) => {
      if (typeof element !== 'undefined' && element.status === 'success') {
        this.loading = false;
      }
    });

    this.initSelectGroupForm();
    this.initDT();
  }

  initSelectGroupForm() {
    this.selectGroupForm = new FormGroup({
      group_id: new FormControl(''),
      client_ids: new FormControl(''),
      department_ids: new FormControl(''),
    });

    if (this.blockService.group_id !== 0) {
      this.selectGroupForm.patchValue({
        group_id: this.blockService.group_id,
        group_sub_group_id: this.blockService.group_sub_group_id,
      });
    }
  }

  initDT() {
    setTimeout(() => {
      const $this = this;
      let debounceTimer;
      const dttable = this.dataTableHtml = $('.datatable-elements-' + this.category).DataTable({
        'responsive': true,
        pagingType: 'full_numbers',
        processing: true,
        serverSide: true,
        stateSave: true,
        ajax: {
          url: $this.adminService.appConfig.API_ENDPOINT + '/elements/datatable',
          type: 'GET',
          data: function (d) {
            if (d.search && d.search.value) {
              d.search.value = d.search.value.trim();
            }

            d.block_id = $this.blockService.block_id;
            d.group_id = $this.blockService.group_id;
            d.group_sub_group_id = $this.blockService.group_sub_group_id;
            d.client_ids = $this.blockService.client_ids;
            d.department_ids = $this.blockService.department_ids;
          },
          beforeSend: function (request) {
            request.setRequestHeader('Authorization', 'Bearer ' + $this.adminService.authService.auth.__token);
            request.setRequestHeader('MD-Authorization', $this.adminService.authService.auth.token);
          },
          dataSrc: function (json) {
            $this.loading = false;
            return json.data;
          },
        },
        pageLength: 50,
        columns: [
          {
            data: function (row) {
              return row['ID'];
            }
          },
          {
            data: function (row) {
              return row['provider'].join(',');
            },
          },
          {
            data: function (row) {
              return row['title'];
            },
          },
          {
            data: function (row) {
              if (row['group'] === null) {
                return ''
              } else {
                return row['group'].title  !== null ? row['group'].title : '';
              }
            },
          },
          // WB-3851: Add group subgroup to elements
          {
            data: function (row) {
              if (row['group_subgroup'] === null) {
                return ''
              } else {
                return row['group_subgroup'].title  !== null ? row['group_subgroup'].title : '';
              }
            },
          },
          {
            data: function (row) {
              if (row['media_attribute']) {
                return String(row['media_attribute'].slug).toUpperCase();
              }

              return '';
            },
          },
          {
            data: function (row) {
              return row['department'] !== null ? row['department'].title : '';
            },
          },
          {
            data: function (row) {
              if (row['client_id'] === 0) {
                return 'All';
              }

              return row['client'].name;
            },
          },
          {
            data: function (row) {
              if (typeof row['children'] !== 'undefined' && row['children']) {
                let childrenHtml = '';

                row['children'].forEach((child) => {
                  // tslint:disable-next-line: max-line-length
                  childrenHtml += `<a href="/admin/templates/elements/${$this.category}/${child.ID}/edit" target="_blank">${child.listing_category ? child.listing_category : 'undefined'}</a><br />`;
                });

                return childrenHtml;
              }
              return '';
            },
          },
          {
            data: function (row) {
              return moment(row['created']).format('YYYY-MM-DD');
            },
          },
          {
            data: function (row) {
              return moment(row['modified']).format('YYYY-MM-DD');
            },
          },
          {
            data: function (row) {
              if (row['is_active'] === 1) {
                return '<span class="label label-success">Active</span>';
              } else if (row['is_active'] === 0) {
                return '<span class="label label-danger">Inactive</span>';
              } else {
                return '<span class="label label-warning">Hidden</span>';
              }
            },
          },
          {
            data: function (row) {
              let html = '';

              // tslint:disable-next-line:max-line-length
              html += '<button onclick="onEdit(' + row['ID'] + ')" class="btn-table" title="Edit"><i class="icon feather icon-edit"></i></button>';
              html += '<a href="/admin/templates/elements/' + $this.category + '/' + row['ID'] + '/edit" target="_blank" class="btn-table" title="Edit New Tab"><i class="icon feather icon-external-link"></i></a>';
              // tslint:disable-next-line:max-line-length
              // html += '<button onclick="onHtml(' + row['ID'] + ')" type="button" class="btn-table" title="HTML"><i class="icon feather icon-file-text"></i></button>';
              // tslint:disable-next-line:max-line-length
              html += '<button onclick="onDuplicate(' + row['ID'] + ')" type="button" class="btn-table" title="Duplicate"><i class="icon feather icon-copy"></i></button>';
              html += '<button onclick="onPreview(' + row['ID'] + ')" data-bs-target="#previewElement" data-bs-toggle="modal" type="button" class="btn-table" title="Preview"><i class="icon feather icon-eye"></i></button>';
              // tslint:disable-next-line:max-line-length
              html += '<button onclick="onDelete(' + row['ID'] + ')" type="button" class="btn-table" title="Delete"><i class="icon feather icon-trash"></i></button>';
              html += '<a href="/admin/templates/elements/' + $this.category + '/' + row['ID'] + '/setting" class="btn-table"><i class="icon feather icon-settings"></i></a>';
              return html;
            },
          },
        ],
        columnDefs: [
          {
            targets: [0],
            name: 'ID',
            searchable: true,
            visible: true
          },
          {
            targets: [1],
            name: 'provider',
            searchable: true,
          },
          {
            targets: [2],
            name: 'title',
            searchable: true,
          },
          {
            targets: [3],
            name: 'group',
            searchable: true,
            className: 'cut-text',
          },
          {
            targets: [4],
            name: 'media_attribute',
            searchable: true,
          },
          {
            targets: [5],
            name: 'department',
            searchable: true,
            className: 'cut-text',
          },
          {
            targets: [6],
            name: 'client',
            searchable: true,
            className: 'cut-text',
          },
        ],
        order: [],
        language: {
          paginate: {
            first: '<i aria-hidden="true" class="fa fa-angle-double-left"></i>',
            previous: '<i aria-hidden="true" class="fa fa-angle-left"></i>',
            next: '<i aria-hidden="true" class="fa fa-angle-right"></i>',
            last: '<i aria-hidden="true" class="fa fa-angle-double-right"></i>',
          },
        },
        select: {
          'style': 'multiple',
        },
      });

      $.fn.dataTable.ext.search.push(
      function(settings, searchData) {
        const searchValue = $('.dataTables_filter input').val();
        if (!searchValue) return true; // If no search value, don't filter
        }
      );

      // Implementing debounce for search input
      $('.dataTables_filter input').unbind().on('input', function() {
        clearTimeout(debounceTimer);
        const searchTerm = $(this).val().trim(); // Trim the search term here
        debounceTimer = setTimeout(function() {
          dttable.search(searchTerm).draw(); // Use the trimmed search term for the DataTables search
        }, 400); // 400ms debounce time
      });

      $('.datatable-elements-' + this.category).on('select.dt', function ( e, dt, type, indexes ) {
        const data = dt.rows('.selected' ).data().toArray();

        data.forEach((value, index) => {
          if (!$this.selectedRowDataId.includes(value.ID)) {
            $this.selectedRowDataId.push(value.ID);
          }
        });
      });

      $('.datatable-elements-' + this.category).on('deselect.dt', function ( e, dt, type, indexes ) {
        const data = dt.rows(indexes).data().toArray();

        if (data.length !== 0) {
          const element = data[0].ID;

          $this.selectedRowDataId.forEach((value, index) => {
              if (value === element) {
                $this.selectedRowDataId.splice(index, 1);
              }
          });
        }
      });

      this.loading = false;
    }, 500);
  }

  filterClick() {
    return false;
  }

  newBlockElement() {
    this.router.navigate(['new', this.blockService.block_id], { relativeTo: this.route });
  }

  setBlockID(id: number) {
    this.blockService.block_id = id;
    this.blockService.httpGetBlock(this.blockService.block_id, {
      select: 'dashboard',
    });
    this.loading = true;
  }

  onPreview($id: number) {
    this.previewModal.nativeElement.querySelector('.modal-body img').src = this.adminService.appConfig.S3_URL + 'elements/generated-thumbnails/' + $id + '.jpg';
  }

  onDelete(id: number) {
    const $this = this;

    swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    })
      .then((result) => {
        if (result) {
          $this.loading = true;
          $this.elementService.httpDeleteElement(id);

          $this.selectedRowDataId = [];

          if (typeof this.blockService.group_id === 'undefined') {
            this.blockService.group_id = -1;
          }

          this.blockService.httpGetBlocks({
            category_id: this.category_id,
            group_id: `0,${this.blockService.group_id}`,
          });

          swal('Deleted!', 'Element has been deleted.', 'success');
        }
      })
      .catch(swal.noop);
  }

  onDeleteSelected() {
    const $this = this;

    swal({
      title: 'Are you sure you want to delete this data selected?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result) {
        $this.loading = true;

        $this.selectedRowDataId.forEach(element => {
          $this.elementService.httpDeleteElement(element);
        });

        $this.selectedRowDataId = [];

        if (typeof this.blockService.group_id === 'undefined') {
          this.blockService.group_id = -1;
        }

        this.blockService.httpGetBlocks({
          category_id: this.category_id,
          group_id: `0,${this.blockService.group_id}`,
        });

        swal('Deleted!', 'Element has been deleted.', 'success');
      }
    }).catch(swal.noop);
  }

  onEdit(id: number) {
    this.ngZone.run(() => {
      this.router.navigate(['admin', 'templates', 'elements', this.category, id, 'edit']);
    });
    return;
  }

  onHtml(id: number) {
    this.ngZone.run(() => {
      this.router.navigate(['admin', 'templates', 'elements', this.category, id, 'html']);
    });
    return;
  }

  onDuplicate(id: number) {
    this.ngZone.run(() => {
      this.router.navigate(['admin', 'templates', 'elements', this.category, id, 'edit', 'duplicate']);
    });
    return;
  }

  onChangeGroup() {
    const formValues = this.selectGroupForm.value;
    this.blockService.group_id = formValues.group_id;
    this.blockService.group_sub_group_id = formValues.group_sub_group_id;
    this.blockService.client_ids = formValues.client_ids;
    this.blockService.department_ids = formValues.department_ids;
    
    if (typeof this.blockService.group_id === 'undefined') {
      this.blockService.group_id = -1;
    }

    this.loading = true;
    this.blockService.httpGetBlocks({
      category_id: this.category_id,
      group_id: `0,${this.blockService.group_id}`,
    });
    
    this.selectedRowDataId = [];
  }

  ngOnDestroy() {
    this.adminService.authService.cancelRequest();
    this.elementDeleteSubscription.unsubscribe();
    this.blocksSubscription.unsubscribe();
    this.blockSubscription.unsubscribe();
    //this.clientsSubscription.unsubscribe();
    if ($.fn.DataTable.isDataTable(this.dataTableHtml)) {
      this.dataTableHtml.destroy();
    }
  }
}
