import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MyDesktopService } from '../../services/mydesktop.service';
import { Subscription } from 'rxjs/Subscription';
import { FrontService } from '../../front/front.service';

declare var swal: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  loading = false;
  loginForm: FormGroup;
  twoFaForm: FormGroup;
  subscription: Subscription;
  subscriptionInvalid: Subscription;
  subscriptionBlocked: Subscription;
  subscriptionTwoFactor: Subscription;
  subscriptionInvalid2Fa: Subscription;
  subscriptionApiError: Subscription;
  is_2fa = false;
  saved_username = '';
  auth_message = '';

  constructor(private mydesktopService: MyDesktopService,
              private frontService: FrontService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    const $this = this;

    if (this.frontService.appConfig.IS_MAINTENANCE) {
      this.router.navigate(['/maintenance']);
      return false;
    }

    this.mydesktopService.myDesktopBillingIssue.subscribe(
      (auth: any) => {
        this.loading = false;
        swal(
          'Whoops, it looks like your subscription has not been paid.',
          'Please contact 1300 650 520 to arrange payment and get back to designing.',
          'error'
        );
      }
    );

    this.subscriptionInvalid = this.mydesktopService.myDesktopAuthLoginInvalid.subscribe(
      (auth: any) => {
        this.loading = false;
        swal(
          'Wrong username / password',
          'Kindly check your credentials again',
          'error'
        );
      }
    );

    this.subscriptionApiError = this.mydesktopService.myDesktopApiError.subscribe(
      (auth: any) => {
        this.loading = false;
        swal({
          title: '<strong>Couldn\'t connect to MyDesktop</strong>',
          type: 'error',
          html:
            // tslint:disable-next-line:max-line-length
            '<p>Please try again later, if problems persist please submit a <a target="_blank" href="https://websites.formstack.com/forms/mydesign_support_form">support request</a></p>'
        });
      }
    );

    this.subscriptionBlocked = this.mydesktopService.myDesktopAuthLoginBlocked.subscribe(
      (auth: any) => {
        this.loading = false;
        swal(
          'Account has been blocked.',
          'Kindly contact MyDesign Support Team.',
          'error'
        );
      }
    );

    this.subscriptionTwoFactor = this.mydesktopService.myDesktopTwoFactorRequired.subscribe(
      (auth: any) => {
        this.loading = false;
        this.saved_username = auth.body.data.username;
        this.auth_message = auth.body.message;
        this.is_2fa = true;
        swal(
          'Two Factor Code needed',
          auth.body.message,
          'info'
        );
      }
    );
     this.subscriptionInvalid2Fa = this.mydesktopService.myDesktopInvalid2FA.subscribe(
      (auth: any) => {
        this.loading = false;
        swal(
          '2FA Invalid',
          'Try to enter the code again',
          'error'
        );
      }
    );
    this.initLoginForm();
    this.inittwoFaForm();
  }

  initLoginForm() {
    const username = '';
    const password = '';

    this.loginForm = new FormGroup({
      'username': new FormControl(username, Validators.required),
      'password': new FormControl(password, [Validators.required, Validators.minLength(1)])
    });
  }

  inittwoFaForm() {
    this.twoFaForm = new FormGroup({
      'password': new FormControl('', Validators.required)
    });
  }

  onLogin() {
    const loginValues = this.loginForm.value;
    this.loading = true;
    if (this.loginForm.valid) {
      this.loading = true;
      this.mydesktopService.httpPostAuthLogin(loginValues);
    }
  }

  onSubmit() {
    const twoFaValues = this.twoFaForm.value;
    twoFaValues['username'] = this.saved_username;
    twoFaValues['is_2fa'] = 'yes';
     if (this.twoFaForm.valid) {
      this.loading = true;
      this.mydesktopService.httpPostAuthLogin(twoFaValues);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionInvalid.unsubscribe();
    this.subscriptionBlocked.unsubscribe();
    this.subscriptionApiError.unsubscribe();
  }

}
