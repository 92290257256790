import { Inject, Injectable } from "@angular/core";
import { GlobalTemplate, StoreGlobalTemplate } from "../models/global-template.model";
import { HttpClient } from "@angular/common/http";
import { APP_CONFIG } from "../app.config";
import { Subject } from "rxjs";

@Injectable()
export class TemplateGlobalSettingService {
    globalTemplateChanged = new Subject<any>();
    userGlobalTemplateChanged = new Subject<any>();

    constructor(
        @Inject(APP_CONFIG) private appConfig,
        private httpClient: HttpClient
    ){}

    postGlobalTemplate(data: StoreGlobalTemplate) {
        return this.httpClient.post(this.appConfig.NEW_API_ENDPOINT + '/settings/template/global/store', data);
    }

    getGlobalTemplate(id: number | string) {
        return this.httpClient.get(this.appConfig.NEW_API_ENDPOINT + `/settings/template/global/get/${id}`).subscribe(
            (response: any) => {
                this.globalTemplateChanged.next(response);
            }
        )
    }

    postUserGlobalTemplate(data: any) {
        return this.httpClient.post(this.appConfig.NEW_API_ENDPOINT + '/settings/template/global/user/store', data);
    }

    getUserGlobalTemplate(templateId: number, userId: number) {
        return this.httpClient.get<GlobalTemplate>(this.appConfig.NEW_API_ENDPOINT + `/settings/template/global/user/get/${templateId}/${userId}`).subscribe(
            (response: any) => {
                this.userGlobalTemplateChanged.next(response);
            }
        );
    }
}