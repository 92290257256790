import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';
import { APP_CONFIG } from '../app.config';

@Injectable()
export class CorelogicService {
    onSuburbSearch = new Subject();
    onGetSuburb = new Subject();
    onGetRecents = new Subject();
    onGenerateReport = new Subject();
    onGenerateMap = new Subject();
    onSearch = new Subject();
    onGetStats = new Subject();
    onGetStatsChart = new Subject();
    onGetSegmentations = new Subject();
    onGetProperties = new Subject();
    onGetProperty = new Subject();
    onGetPropertyListings = new Subject();
    onGetSingleProperty = new Subject();

    constructor(
        @Inject(APP_CONFIG) private appConfig,
        private httpClient: HttpClient
    ) { }

    searchSuburbs(params: any = {}) {
        let httpParams = new HttpParams();

        for (let p of Object.keys(params)) {
            httpParams = httpParams.append(p, params[p]);
        }

        return this.httpClient.get(`${this.appConfig.NEW_API_ENDPOINT}/corelogic/suburb-search`, {
            params: httpParams
        })
    }

    httpSearch(params: any = {}) {
        let httpParams = new HttpParams();

        for (let p of Object.keys(params)) {
            httpParams = httpParams.append(p, params[p]);
        }

        return this.httpClient.get(`${this.appConfig.NEW_API_ENDPOINT}/corelogic/suburb-search`, {
            params: httpParams
        })
        .subscribe(
            (response: any) => {
                this.onSuburbSearch.next(response);
            },
            (response: any) => {
                this.onSuburbSearch.next(response.error);
            }
        );
    }

    httpGetSuburbPropertyListings(localityId: string, propertyTypes: string, filter : Partial<{ beds: string; baths: string }>) {
        let apiPropertyListingEndpoint = `${this.appConfig.NEW_API_ENDPOINT}/corelogic/propertyListingsByLocality/${localityId}?propertyTypes=${propertyTypes}`;

        if(filter.beds){
            apiPropertyListingEndpoint += '&beds='+filter.beds;
        }

        if(filter.baths){
            apiPropertyListingEndpoint += '&baths='+filter.baths;
        }

        return this.httpClient.get(apiPropertyListingEndpoint)
        .subscribe(
            (response: any) => {
                this.onGetSuburb.next(response);
            },
            (response: any) => {
                this.onGetSuburb.next(response.error);
            }
        );
    }

    httpGetPropertyDetails(propertyId: string) {
        return this.httpClient.get(`${this.appConfig.NEW_API_ENDPOINT}/corelogic/propertyDetails/${propertyId}`)
        .subscribe(
            (response: any) => {
                this.onGetProperty.next(response);
            },
            (response: any) => {
                this.onGetProperty.next(response.error);
            }
        );
    }

    httpGetPropertySummary(suburbId: string, params: any = {}) {
        let httpParams = new HttpParams();

        for (let p of Object.keys(params)) {
            httpParams = httpParams.append(p, params[p]);
        }

        return this.httpClient.get(`${this.appConfig.NEW_API_ENDPOINT}/corelogic/propertySummary`, {
            params: httpParams
        })
        .subscribe(
            (response: any) => {
                this.onGetStats.next(response);
            },
            (response: any) => {
                this.onGetStats.next(response.error);
            }
        );
    }

    httpGetStats(suburbId: string, params: any = {}) {
        let httpParams = new HttpParams();

        for (let p of Object.keys(params)) {
            httpParams = httpParams.append(p, params[p]);
        }

        return this.httpClient.get(`${this.appConfig.NEW_API_ENDPOINT}/corelogic/quartileStatsOfSales`, {
            params: httpParams
        })
        .subscribe(
            (response: any) => {
                this.onGetStats.next(response);
            },
            (response: any) => {
                this.onGetStats.next(response.error);
            }
        );
    }

    httpGetSalesByPriceRangeMap(suburbId: string, data: any, params: any = {}) {
        let httpParams = new HttpParams();

        for (let p of Object.keys(params)) {
            httpParams = httpParams.append(p, params[p]);
        }

        return this.httpClient.post(`${this.appConfig.NEW_API_ENDPOINT}/corelogic/salesByPriceRangeMap`, data, {
            params: httpParams
        })
        .subscribe(
            (response: any) => {
                this.onGetSegmentations.next(response);
            },
            (response: any) => {
                this.onGetSegmentations.next(response.error);
            }
        );
    }

    httpGetsalesByMedianPriceTrendMap(suburbId: string, data: any, params: any = {}) {
        let httpParams = new HttpParams();

        for (let p of Object.keys(params)) {
            httpParams = httpParams.append(p, params[p]);
        }

        return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/corelogic/salesByMedianPriceTrendMap`, data, {
            params: httpParams
        })
        .subscribe(
            (response: any) => {
                this.onGetStatsChart.next(response);
            },
            (response: any) => {
                this.onGetStatsChart.next(response.error);
            }
        );
    }

    httpGenerateMap(data: any) {
        return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/pricefinder/map`, data)
        .subscribe(
            (response: any) => {
                this.onGenerateMap.next(response);
            },
            (response: any) => {
                this.onGenerateMap.next(response.error);
            }
        );
    }
}
