import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Injectable, Inject } from '@angular/core';

import { APP_CONFIG } from '../app.config';
import { Subscription } from 'rxjs/Subscription';
import { SettingService } from './setting.service';

@Injectable()
export class AuthBlockGuard implements CanActivate {
  listingsSubscription: Subscription;

  constructor(@Inject(APP_CONFIG) private appConfig,
              private authService: AuthService,
              private router: Router,
              private settingService: SettingService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.settingService.httpGetSettingListings(1);

    return true;
  }
}
