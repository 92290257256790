import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TemplateElementSettingService } from '../../../../services/template-element-setting.service';
import { ActivatedRoute } from '@angular/router';
import { ElementService } from '../../../../services/element.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TemplateSettingGroupService } from '../../../../services/template-setting-group.service';
import { Subject, Subscription } from 'rxjs';
import { SettingGroup } from '../../../../models/global-template.model';
import { Element } from '../../../../models/element.model';
import { ElementTemplate, GroupedElementSetting, RowElementSetting } from '../../../../models/element-template.model';
declare var swal: any;
declare var $: any;

@Component({
  selector: 'app-element-setting',
  templateUrl: './element-setting.component.html',
  styleUrls: ['./element-setting.component.scss']
})
export class ElementSettingComponent implements OnInit {
  private destroy$: Subject<void> = new Subject<void>();
  subscription: Subscription;
  elementSetting: any;
  elementId: number;
  element: Element;
  elementSettingForm: FormGroup;
  newElementSettingFormData: FormGroup;
  templateSettingGroupList: SettingGroup;
  loading = true;
  settingsType = 'element';

  @ViewChild('modalInsertNewSetting', {static: true}) modalInsertNewSetting: ElementRef;
  @ViewChild('modalCloseInsertNewSetting', {static: true}) modalCloseInsertNewSetting: ElementRef;
  

  constructor(
    private templateElementSettingService: TemplateElementSettingService,
    private elementService: ElementService,
    private templateSettingGroupService: TemplateSettingGroupService,
    private route: ActivatedRoute,
    private fb: FormBuilder
    ) {}
    
    ngOnInit() {
    this.elementId = parseInt(this.route.snapshot.params['id']);
    this.templateElementSettingService.getElementSetting(this.elementId);
    this.templateSettingGroupService.getList(this.settingsType, this.elementId);
    this.elementService.httpGetElement(this.elementId);

    this.elementService.elementChanged
    .takeUntil(this.destroy$)
    .subscribe((response: Element) => {
      this.element = response
    });

    this.templateSettingGroupService.templateGroupChanged
    .takeUntil(this.destroy$)
    .subscribe((response: any) => {
      this.templateSettingGroupList = response;
    });

    this.initForms();
  }

  private initForms() {
    this.elementSettingForm = this.fb.group({
      element_id: [this.elementId, Validators.required],
      data: this.fb.array([
        this.fb.group({
          id: [''],
          title: ['', Validators.required],
          description: [''],
          class_name: ['', Validators.required],
          settings_type: ['', Validators.required],
          element_id: this.elementId,
          group_id: [''],
          options: this.fb.array([])
        })
      ])
    });
    this.patchElementSettingForm();
    this.newElementSettingFormData = this.fb.group({
      id: null,
      title: ['', Validators.required],
      description: '',
      class_name: [''],
      settings_type: ['', Validators.required],
      element_id: this.elementId,
      group_id: '',
      options: this.fb.array([])
    });
  }

  /* Get data of element settings */
  elementSettings(): FormArray {
    return this.elementSettingForm.get('data') as FormArray;
  }

  /* Get data of Grouped element settings */
  elementGroupSettings(index): FormArray {
    return this.elementSettingForm.get(`data.${index}.items`) as FormArray;
  }

  /* Get option data of non-grouped element settings */
  elementDropdownOptions(settingIdx): FormArray {
    return this.elementSettingForm.get(`data.${settingIdx}.options`) as FormArray;
  }

  /* Get option data of grouped element settings */
  elementGroupDropdownoptions(settingIdx, groupItemIdx): FormArray {
    return this.elementSettingForm.get(`data.${settingIdx}.items.${groupItemIdx}.options`) as FormArray;
  }

  /* Update validators for class name when change settings type */
  onSelectSettingType(value, controller, idx) {
    let classNameController = <FormArray>controller.controls.class_name;
    classNameController.setValue(null);
    controller.controls.options.clear();
    classNameController.enable();
    if (parseInt(value) === 0 && value != '') { //is dropdown
      classNameController.setValidators(null);
      classNameController.updateValueAndValidity();
      classNameController.disable();
      controller.controls.options.push(this.fb.group({
        id: null,
        title: ['', Validators.required],
        description: '',
        class_name: ['', Validators.required],
        element_settings_id: controller.value.id
      }));
    } else {
      classNameController.setValidators(Validators.required);
      classNameController.updateValueAndValidity();
    }
  }

  /* add data to element setting form from database */
  patchElementSettingForm() {
    this.templateElementSettingService.elementChanged.takeUntil(this.destroy$).subscribe((response: ElementTemplate) => {
      this.elementSetting = response;

      if (this.elementSetting.data.length > 0) {
        let settingsData = this.elementSettings();
        (settingsData).clear();
        this.elementSetting.data.forEach((obj: RowElementSetting & GroupedElementSetting) => {
          if (obj.group_id != null) {
            let groupIdx = settingsData.value.findIndex(objg => objg.group_id == obj.group_id);
            if (groupIdx < 0) {
              //create a new group setting
              settingsData.push(this.fb.group({
                group_id: obj.group_id,
                group_name: obj.group_name,
                items: this.fb.array([])
              }));
            }

            //get create group data
            groupIdx = settingsData.value.findIndex(objg => objg.group_id == obj.group_id);
            let tempItems = <FormArray>settingsData.at(groupIdx).get('items');
            obj.data.forEach((setting) => {
              let dropdownOptions = this.fb.array([]);
              if (setting.settings_type == 0 && setting.settings_dropdown_options.length > 0) {
                setting.settings_dropdown_options.forEach(option => {
                  dropdownOptions.push(this.fb.group({
                    id: option.id,
                    title: [option.title, Validators.required],
                    description: option.description,
                    class_name: [option.class_name, Validators.required],
                    element_template_settings_id: this.elementId
                  }));
                });
              }
              tempItems.push(this.fb.group({
                id: setting.id,
                title: [setting.title, Validators.required],
                settings_type: [setting.settings_type, Validators.required],
                description: setting.description,
                class_name: [setting.class_name, (setting.settings_type == 1 ? Validators.required : null)],
                group_id: setting.group_id,
                element_id: setting.element_id,
                options: dropdownOptions
              }));
            });
          } else { //row setting 
          let dropdownOptions = this.fb.array([]);
          if (obj.settings_dropdown_options) {
            obj.settings_dropdown_options.forEach((option => {
              dropdownOptions.push(this.fb.group({
                id: [option.id],
                title: [option.title, Validators.required],
                description: option.description,
                class_name: [option.class_name, Validators.required],
                element_template_settings_id: [option.element_template_settings_id]
              }));
            }));
          }
          settingsData.push(this.fb.group({
              id: [obj.id],
              settings_type: [obj.settings_type, Validators.required],
              title: [obj.title, Validators.required],
              description: [obj.description],
              class_name: [obj.class_name, (obj.settings_type == 1 ? Validators.required : null)],
              element_id: obj.element_id,
              group_id: [''],
              options: dropdownOptions
            }));
          }
        });
      }
      this.loading = false;
    });
  }

  /* Create/Update element setting */
  submitElementSetting() {
    if (this.elementSettingForm.invalid) {
      swal({
        title: "Some input fields need to be filled",
        text: "Kindly check and save again",
        type: "error"
      });
      return false;
    }
    let post = this.getReformElementSetting();
    this.templateElementSettingService.storeElementSetting(post)
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        swal({
          title: (response.success) ? 'Saved' : 'Error',
          type: (response.success) ? 'success' : 'error'
        });
        this.templateElementSettingService.getElementSetting(this.elementId);
        if (post.data.length === 0) {
          this.initForms();
        }
      },
      (error) => {
        swal({
          title: 'Invalid request',
          type: 'error'
        });
      }
    );
  }

  /* Re-arrange post data */
  getReformElementSetting() {
    let form = this.elementSettingForm.value;
    let post = {element_id: this.elementId, data: []};
    form.data.forEach((obj) => {
      if (obj.group_id) { //extract group data
        obj.items.forEach((item) => {
          post.data.push(item);
        });
      } else {
        post.data.push(obj);
      }
    });

    return post;
  }

  /* Adding of new setting into form */
  preInsertNewSetting() {
    let settingForm = <FormArray>this.elementSettingForm.get('data');
    let newSetting = this.newElementSettingFormData;
    if (!newSetting.valid) {
      swal({
        title: "Invalid inputs",
        text: "Please fill up required fields (*)",
        type: "error"
      });
      return false;
    } 
    let newSettingItem = this.fb.group({
      id: null,
      settings_type: [newSetting.value.settings_type, (newSetting.value.settings_type == 1 ? Validators.required : null)],
      title: [newSetting.value.title, Validators.required],
      description: newSetting.value.description,
      class_name: [newSetting.value.class_name, (newSetting.value.settings_type == 1 ? Validators.required : null)],
      element_id: this.elementId,
      group_id: newSetting.value.group_id,
      options: (newSetting.value.settings_type == 0) ? this.fb.array([this.fb.group({
        id: null,
        title: ['', Validators.required],
        description: [''],
        class_name: ['', Validators.required],
        element_settings_id: this.elementId
      })]) : this.fb.array([])
    });
    if (newSetting.value.group_id != '') {
      //find the group index from existing setting
      let setting_idx = settingForm.value.findIndex((data) => { return (data.group_id != null && data.group_id == newSetting.value.group_id); });
      if (setting_idx < 0) {
        //create new group
        let group_idx = this.templateSettingGroupList.data.findIndex((grp) => { return (grp.id == newSetting.value.group_id); });
        settingForm.push(this.fb.group({
            group_id: newSetting.value.group_id,
            group_name: this.templateSettingGroupList.data[group_idx].group_name,
            items: this.fb.array([newSettingItem])
          })
        );
      } else {
        //insert into existing group
        settingForm = <FormArray>settingForm.at(setting_idx).get('items');
        settingForm.push(newSettingItem);
      }
    } else {
      (<FormArray>settingForm).push(newSettingItem)
    }
    this.newElementSettingFormData.reset({
      title: '',
      description: '',
      class_name: '',
      settings_type: '',
      element_id: this.elementId,
      group_id: '',
      options: this.fb.array([])
    });
  }

  /* Changing of settings type for pre setting */
  changeNewSettingTypeForm(value) {
    let classNameController = this.newElementSettingFormData.get('class_name');
    (value == 0 && value != '') ? classNameController.disable() : classNameController.enable();
    classNameController.setValue('')
  }

  /* Move non-group setting */
  moveItem(index, dir) {
    let data = this.elementSettingForm.get('data') as FormArray;
    let dir_index = (dir == 0) ? index-1 : index+1;
    const curr_data = data.controls[(index)];
    const dir_data = data.controls[(dir_index)];
    data.controls[(index)] = dir_data;
    data.controls[(dir_index)] = curr_data;
    data.controls[(index)].patchValue(dir_data);
    data.controls[(dir_index)].patchValue(curr_data);
  }

  /* Move grouped setting */
  moveGroupItem(parentIdx, currentIdx, dir) {
    let data = this.elementSettingForm.get(`data.${parentIdx}.items`) as FormArray;
    let directionIdx = (dir == 0) ? currentIdx-1 : currentIdx+1;
    const curr_data = data.controls[(currentIdx)];
    const dir_data = data.controls[(directionIdx)];
    data.controls[(currentIdx)] = dir_data;
    data.controls[(directionIdx)] = curr_data;
    data.controls[(currentIdx)].patchValue(dir_data);
    data.controls[(directionIdx)].patchValue(curr_data);
  }

  /* Adding of grouped setting item */
  insertGroupItem(parentIdx, currentIdx, dir) {
    let data = this.elementSettingForm.get(`data.${parentIdx}.items`) as FormArray;
    let groupId = (<FormArray>this.elementSettingForm.get(`data.${parentIdx}`)).get('group_id');
    data.insert((dir == 0 ? (currentIdx == 0 ? 0 : currentIdx) : currentIdx+1), this.fb.group({
      id: null,
      title: ['', Validators.required],
      description: '',
      class_name: ['', Validators.required],
      settings_type: ['', Validators.required],
      element_id: this.elementId,
      group_id: groupId,
      options: this.fb.array([])
    }));
  }

  /* Remove of grouped setting item */
  removeGroupItem(parentIdx, currentIdx) {
    let data = this.elementSettingForm.get(`data.${parentIdx}.items`) as FormArray;
    data.removeAt(currentIdx);
  }

  /* Add dropdown option of grouped setting item */
  addGroupDropdownOption(parentIdx, itemIdx, optionIdx) {
    let dataOptions = this.elementSettingForm.get(`data.${parentIdx}.items.${itemIdx}.options`) as FormArray;
    dataOptions.insert(optionIdx+1,this.fb.group({
      id: null,
      title: ['', Validators.required],
      description: [''],
      class_name: ['', Validators.required],
      element_settings_id: this.elementId
    }));
  }

  /* Removal of dropdown option of grouped setting item */
  removeGroupDropdownOption(parent_idx, curr_idx, opt_idx) {
    let data = this.elementSettingForm.get(`data.${parent_idx}.items.${curr_idx}.options`) as FormArray;
    data.removeAt(opt_idx);
  }

  /* adding of dropdown option of non-grouped setting item */
  addRowSettingOption(index, optionIdx) {
    let data = this.elementSettingForm.get(`data.${index}.options`) as FormArray;
    data.insert(optionIdx+1, this.fb.group({
      id: null,
      title: ['', Validators.required],
      description: [''],
      class_name: ['', Validators.required],
      element_settings_id: this.elementId
    }))
  }

  /* removal of dropdown option of non-grouped setting item */
  removeRowSettingOption(index, opt_idx) {
    let data = this.elementSettingForm.get(`data.${index}.options`) as FormArray;
    data.removeAt(opt_idx);
  }

  /* Adding of non-grouped setting item */
  addRowSettingItem(index, dir) {
    let data = this.elementSettingForm.get('data') as FormArray;
    data.insert((dir == 0) ? (index == 0 ? 0 : index) : index+1, this.fb.group({
      id: null,
      title: ['', Validators.required],
      description: '',
      class_name: ['', Validators.required],
      settings_type: ['', Validators.required],
      element_id: this.elementId,
      group_id: '',
      options: this.fb.array([])
    }));
  }

  /* Removal of non-grouped setting item */
  removeRowSettingItem(index) {
    let data = this.elementSettingForm.get('data') as FormArray;
    data.removeAt(index);
  }

  modalEvent(modal, action) {
    let element = (modal == 'add_setting') ? this.modalInsertNewSetting.nativeElement : '';
    if (modal != '') {
      $(element).modal(action);
    }
  }

  addNewElementTemplateGroup() {
    const settingsType = this.settingsType;
    const elementId = this.elementId;
    swal({
      title: "Add Element Template Group",
      html: `<input type="text" class="swal2-input" id="swal-input-new-element-group-name" placeholder="Insert Group Name">`,
      confirmButtonText: 'Create',
      showCancelButton: true,
      preConfirm: () => {
        return new Promise( (resolve) => {
          resolve({
            id: null,
            element_template_id: elementId,
            type: settingsType,
            group_name: $('#swal-input-new-element-group-name').val()
          })
        })
      }
    }).then((result) => {
      let new_group = {
        template_type: settingsType,
        id: elementId,
        groups: [result]
      };
      this.templateSettingGroupService.storeGroupsInfo(new_group)
      .takeUntil(this.destroy$)
      .subscribe((response: any) => {
        swal({
          title: response.message,
          type: (response.success) ? 'success' : 'error'
        });
        this.templateSettingGroupService.getList(settingsType, elementId);
      }, (HttpError: any) => {
        swal({
          title: 'Unable to proceed the request',
          type: 'error'
        })
      });
    }).catch(swal.noop);
  }

  editElementTemplateGroup(data) {
    const elementTemplateId = this.elementId;
    swal({
      title: 'Edit Element template group',
      html: `<input type="text" class="swal2-input" id="edit-element-group-name" value="${data.group_name}">`,
      showCancelButton: true,
      confirmButtonText: "Update",
      preConfirm: () => {
        return new Promise((resolve) => {
          resolve({
            id: data.id,
            template_id: elementTemplateId,
            type: 'master',
            group_name: $('#edit-element-group-name').val()
          })
        });
      }
    }).then((result) => {
      this.templateSettingGroupService.updateGroupInfo(result)
      .takeUntil(this.destroy$)
      .subscribe((response: any) => {
        swal({
          title: response.message,
          type: (response.success) ? 'success' : 'error'
        });
        this.templateSettingGroupService.getList(this.settingsType, elementTemplateId);
      }, (HttpError: any) => {
        swal({
          title: 'Unable to proceed the request',
          text: HttpError.error.message,
          type: 'error'
        })
      });
    }).catch(swal.noop)
  }

  deleteElementTemplateGroup(id) {
    const elementTemplateId = this.elementId;
    swal({
      title: 'Are you sure you want to delete?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((isConfirmed) => {
      if (isConfirmed) {
        this.templateSettingGroupService.deleteGroupRow('element', id).subscribe(
          (response: any) => {
            swal({
              title: response.success ? "Success" : "Unable to proceed this request",
              text: response.message,
              type: response.success ? "success" : "error"
            });
            this.templateSettingGroupService.getList(this.settingsType, elementTemplateId);
          }
        )
      }
    }).catch(swal.noop);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
