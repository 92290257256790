
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class RoleHelper {

    public static isAdmin(user:any) {
        return user.is_admin;
    }

    public static isGroupAdmin(user:any) {
        return user.is_group_admin;
    }

    public static isLoginAs(user:any) {
        return user.login_as_flag;

    }
}