import { Component, OnInit, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { APP_CONFIG } from '../../app.config';
import { PricefinderService } from '../../services/pricefinder.service';
import { FormControl } from '@angular/forms';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { Subject } from 'rxjs';

import swal from 'sweetalert2';
@Component({
  selector: 'app-pricefinder',
  templateUrl: './pricefinder.component.html',
  styleUrls: ['./pricefinder.component.scss']
})
export class PricefinderComponent implements OnInit, AfterViewInit, OnDestroy {
  loading = false;
  destroy$: Subject<boolean> = new Subject();

  priceFinderPropertyStatus = 'sales';
  priceFinderSearchResult = false;

  priceFinderInput: FormControl;
  priceFinderSuburbs = [];
  priceFinderPropertyType = 'house';

  priceFinderSuburb: any;
  priceFinderRecentRentals = [];

  priceFinderRecentHouseSales = [];
  priceFinderRecentUnitSales = [];
  priceFinderRecentData: any;

  selectedListings = [];
  maxSelectedListings = 10;

  priceFinderCustomValues: any;
  filterBeds = '';
  filterBaths = '';
  filterSort = '';
  displayBothPropertyType = false;

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    private pricefinderService: PricefinderService,
    private mScrollbarService: MalihuScrollbarService
  ) {
    this.priceFinderInput = new FormControl('');
  }

  ngOnInit() {

    this.pricefinderService.onSuburbSearch
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response !== 'undefined' && response.status === 'success' ) {
            this.priceFinderSuburbs = response.data;
            this.loading = false;
          }
        }
      }
    );

    this.pricefinderService.onGetSuburb
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response !== 'undefined' && response.status === 'success' ) {
            this.priceFinderSuburb = response.data;

            this.pricefinderService.httpGetRecents(response.data.suburb.id, {
              type: this.priceFinderPropertyType,
              status: this.priceFinderPropertyStatus
            });
          }
        }
      }
    );

    this.pricefinderService.onGetRecents
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response !== 'undefined' && response.status === 'success' ) {
            this.priceFinderRecentData = response.data;
            this.priceFinderRecentHouseSales = [];
            this.priceFinderRecentUnitSales = [];

            this.priceFinderRecentRentals = [];
            this.selectedListings = [];

            if (typeof response.data.sales !== 'undefined' && response.data.sales) {
              this.priceFinderRecentHouseSales = response.data.sales.house;
              this.priceFinderRecentUnitSales = response.data.sales.unit;
            }

            this.selectedListings = this.priceFinderRecentHouseSales.filter((listing, index) => (index + 1) <= this.maxSelectedListings);

            this.loading = false;
            this.priceFinderSearchResult = true;
          }
        }
      }
    );

    this.priceFinderInput.valueChanges
    .takeUntil(this.destroy$)
    .debounceTime(500)
    .subscribe(searchText => {
      if (typeof searchText !== 'undefined') {
        if (searchText.length > 3) {
          this.pricefinderService.httpSearch({
            query: searchText
          });
          this.loading = true;
        }
      }
    });

    this.pricefinderService.onSearch
    .takeUntil(this.destroy$)
    .subscribe(response => {
      if (response) {
        this.priceFinderCustomValues = response;

        if (typeof this.priceFinderCustomValues.maxListings !== 'undefined') {
          this.maxSelectedListings = this.priceFinderCustomValues.maxListings;
        }
      }
    });
  }

  ngAfterViewInit() {
    this.mScrollbarService.initScrollbar('.propertyListScroller', { axis: 'y', theme: 'minimal-dark', scrollInertia: 600 });
    this.mScrollbarService.initScrollbar('#suburbFilter .search .search-result', { axis: 'y', theme: 'minimal-dark', scrollInertia: 600 });
  }

  onSelectPropertyStatus(type) {
    this.priceFinderPropertyStatus = type;

    if (typeof this.priceFinderSuburb !== 'undefined' && this.priceFinderSuburb) {
      this.loading = true;
      this.pricefinderService.httpGetRecents(this.priceFinderSuburb.suburb.id, {
        type: this.priceFinderPropertyType,
        status: this.priceFinderPropertyStatus
      });
    }

  }

  onSelectSuburb(suburb) {
    this.priceFinderSuburbs = [];
    this.priceFinderInput.setValue(suburb.display, {emitEvent: false});

    this.pricefinderService.httpGetSuburb(suburb.suburb.id);
    this.loading = true;
  }

  selectPriceFinderPropertyType(type) {
    this.priceFinderPropertyType = type;
  }

  selectPriceFinderSaleProperty(property) {
    const propertyIndex = this.selectedListings.findIndex(prop => prop.id == property.id);

    if (propertyIndex === -1) {
      if (this.selectedListings.length === this.maxSelectedListings) {
        swal(`Listing limit of ${this.maxSelectedListings} reached.`, '', 'error');
        return false;
      }

      this.selectedListings.push(property);
    } else {
      this.selectedListings.splice(propertyIndex, 1);
    }
  }

  checkIfPriceFinderSaleSelected(property) {
    const propertyIndex = this.selectedListings.findIndex(prop => prop.id == property.id);

    if (propertyIndex === -1) {
      return false;
    }

    return true;
  }

  onGenerateReport() {
    if (!this.selectedListings.length) {
      swal('Select up to 10 listing/s to insert in your report', '', 'error');
      return false;
    }

    this.priceFinderRecentData['suburb'] = this.priceFinderSuburb;
    this.priceFinderRecentData['listings'] = this.selectedListings;
    this.priceFinderRecentData['queryData'] = {
      type: this.priceFinderPropertyType,
      status: this.priceFinderPropertyStatus,
      displayBoth: this.displayBothPropertyType
    };

    this.pricefinderService.onGenerateReport.next(this.priceFinderRecentData);
  }

  onPriceFinderSortChange(sort: string) {
    this.filterSort = sort;
    this.pricefinderService.httpGetRecents(this.priceFinderSuburb.suburb.id, {
      type: this.priceFinderPropertyType,
      status: this.priceFinderPropertyStatus,
      sort: this.filterSort,
      beds: this.filterBeds,
      baths: this.filterBaths
    });
    this.loading = true;
  }
  // bath and bed filter
  onPriceFinderSortByBathAndBed(type: string = "beds", value: string) {
    if (type == 'beds') {
      this.filterBeds = value;
    }
    if (type == 'baths') {
      this.filterBaths = value;
    }

    this.pricefinderService.httpGetRecents(this.priceFinderSuburb.suburb.id, {
      type: this.priceFinderPropertyType,
      status: this.priceFinderPropertyStatus,
      sort: this.filterSort,
      beds: this.filterBeds,
      baths: this.filterBaths
    });
    this.loading = true;
  }

  ngOnDestroy() {
    this.mScrollbarService.destroy('.propertyListScroller');
    this.mScrollbarService.destroy('#suburbFilter .search .search-result');
  }

}
