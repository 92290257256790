<div class="row">
  <div class="col-md-12 heading">
    <h2 class="pull-left">{{ !editMode ? 'Add Group': 'Group - ' + group_title }}</h2>

    <button [routerLink]="[backUrl]" type="button" class="btn-admin">
      <i class="icon feather icon-arrow-left"></i><span>Back</span>
    </button>
  </div>
</div>
<div class="edit-box">
  <div class="row">
    <div class="col-md-12">
      <div class="edit-box-content">
        <h4>Details</h4>
        <form #groupFormElement (ngSubmit)="onSubmit()" [formGroup]="groupForm" class="form-horizontal">
          <div class="form-group">
            <label for="master" class="col-sm-2">MyDesktop Master Client</label>
            <div class="col-sm-6">
              <select formControlName="master_client_id" class="form-control" id="master_client_id" >
                <option value="0">Select an Account</option>
                <ng-container *ngFor="let mValue of clients">
                  <option *ngIf="mValue.provider === 'mydesktop'"  value="{{ mValue.ID }}">{{ mValue.office_id }} {{ mValue.company_name }}</option>
                </ng-container>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="master" class="col-sm-2">Vault Master Client</label>
            <div class="col-sm-6">
              <select formControlName="vault_master_client_id" class="form-control" id="master_client_id" >
                <option value="0">Select an Account</option>
                <ng-container *ngFor="let mValue of clients">
                  <option *ngIf="mValue.provider === 'vaultre'"  value="{{ mValue.ID }}">{{ mValue.office_id }} {{ mValue.company_name }}</option>
                </ng-container>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="master" class="col-sm-2">Domain Master Client</label>
            <div class="col-sm-6">
              <select formControlName="domain_master_client_id" class="form-control" id="master_client_id" >
                <option value="0">Select an Account</option>
                <ng-container *ngFor="let mValue of clients">
                  <option *ngIf="mValue.provider === 'domain'"  value="{{ mValue.ID }}">{{ mValue.office_id }} {{ mValue.company_name }}</option>
                </ng-container>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="title" class="col-sm-2">Group Name</label>
            <div class="col-sm-6">
              <input formControlName="title" required type="text" class="form-control" id="title" autocomplete="off" maxlength="80">
            </div>
          </div>
          <div class="form-group">
            <label for="type" class="col-sm-2">Type</label>
            <div class="col-sm-6">
              <select (change)="onChange()" formControlName="type" class="form-control" id="type" >
                <option value="" disabled>Select a Type</option>
                <option value="franchise">Master Franchise</option>
                <option value="group">Group Master</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="code" class="col-sm-2">MyDesktop Franchise ID</label>
            <div class="col-sm-6">
              <input formControlName="code" type="text" class="form-control" id="code" autocomplete="off" maxlength="10">
            </div>
          </div>
          <div class="form-group">
            <label for="vault_code" class="col-sm-2">VaultRE Franchise ID</label>
            <div class="col-sm-6">
              <input formControlName="vault_code" type="text" class="form-control" id="vault_code" autocomplete="off" maxlength="10">
            </div>
          </div>
          <div class="form-group">
            <label for="provider" class="col-sm-2">Provider</label>
            <div class="col-sm-6">
              <select formControlName="provider" class="form-control" id="provider">
                <option value="" disabled>Select a Provider</option>
                <option value="all">All Providers</option>
                <option value="mydesktop">MyDesktop</option>
                <option value="vaultre">VaultRE</option>
                <option value="idashboard">iDashboard</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="vault_code" class="col-sm-2">Admin Email</label>
            <div class="col-sm-6">
              <input formControlName="admin_email" type="email" class="form-control" id="admin_email" autocomplete="off">
            </div>
          </div>
          <br>
          <h4>Setting Options</h4>
          <hr>
          <div class="form-group">
            <label for="type" class="col-sm-2">Logo Menu</label>
            <div class="col-sm-2">
              <select formControlName="setting_menu_logo" class="form-control" id="setting_menu_logo" >
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="type" class="col-sm-2">Color Pallete Menu</label>
            <div class="col-sm-2">
              <select formControlName="setting_menu_color" class="form-control" id="setting_menu_color" >
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="type" class="col-sm-2">Font Color Menu</label>
            <div class="col-sm-2">
              <select formControlName="setting_menu_font" class="form-control" id="setting_menu_font" >
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="type" class="col-sm-2">Icon Menu</label>
            <div class="col-sm-2">
              <select formControlName="setting_menu_icon" class="form-control" id="setting_menu_icon" >
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="type" class="col-sm-2">Link Menu</label>
            <div class="col-sm-2">
              <select formControlName="setting_menu_link" class="form-control" id="setting_menu_link" >
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
          </div>
          <br>
          <h4>Template Builder Options</h4>
          <hr>
          <div class="form-group">
            <label for="type" class="col-sm-2">CK Editor - Font Family</label>
            <div class="col-sm-2">
              <select formControlName="builder_ckeditor_font_family" class="form-control" id="builder_ckeditor_font_family" >
                <option value="1">Active</option>
                <option value="0">Inactive</option>
                <option value="2">Master Only</option>
                <option value="3">Group Only</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="type" class="col-sm-2">CK Editor - Font and Background Color</label>
            <div class="col-sm-2">
              <select formControlName="builder_ckeditor_font_and_background_color" class="form-control" id="builder_ckeditor_font_and_background_color" >
                <option value="1">Active</option>
                <option value="0">Inactive</option>
                <option value="2">Master Only</option>
                <option value="3">Group Only</option>
              </select>
            </div>
          </div>
          <br>
          <h4>Template Dashboard Options</h4>
          <hr>
          <div class="form-group">
            <label for="type" class="col-sm-2">Hide Start From Blank</label>
            <div class="col-sm-2">
              <select formControlName="hide_start_from_blank" class="form-control" id="hide_start_from_blank">
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="type" class="col-sm-2">Hide Order Custom Template</label>
            <div class="col-sm-2">
              <select formControlName="hide_order_custom_template" class="form-control" id="hide_order_custom_template">
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="type" class="col-sm-2">Hide Create DIY</label>
            <div class="col-sm-2">
              <select formControlName="hide_create_diy" class="form-control" id="hide_create_diy" >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="type" class="col-sm-2">Hide DIY Emails</label>
            <div class="col-sm-2">
              <select formControlName="hide_emails" class="form-control" id="hide_emails" >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="type" class="col-sm-2">Hide DIY Printables</label>
            <div class="col-sm-2">
              <select formControlName="hide_printables" class="form-control" id="hide_printables" >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="type" class="col-sm-2">Hide DIY Social media</label>
            <div class="col-sm-2">
              <select formControlName="hide_socials" class="form-control" id="hide_socials" >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="type" class="col-sm-2">Hide DIY Signboards</label>
            <div class="col-sm-2">
              <select formControlName="hide_signboards" class="form-control" id="hide_signboards" >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <!-- HARDCODED TEMPLATES SETTINGS -->

          <div class="form-group">
            <label for="type" class="col-sm-2">Hide Email Templates</label>
            <div class="col-sm-2">
              <select formControlName="hide_templates_email" class="form-control" id="hide_templates_email" >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>

          <div class="form-group">
            <label for="type" class="col-sm-2">Hide Printables Templates</label>
            <div class="col-sm-2">
              <select formControlName="hide_templates_printables" class="form-control" id="hide_templates_printables" >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>

          
          <div class="form-group">
            <label for="type" class="col-sm-2">Hide Social Media Templates</label>
            <div class="col-sm-2">
              <select formControlName="hide_templates_socialmedia" class="form-control" id="hide_templates_socialmedia" >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>

          <div class="form-group">
            <label for="type" class="col-sm-2">Hide Signboards Templates</label>
            <div class="col-sm-2">
              <select formControlName="hide_templates_signboards" class="form-control" id="hide_templates_signboards" >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>

          <div class="form-group">
            <label for="type" class="col-sm-2">Hide Standard Templates</label>
            <div class="col-sm-2">
              <select formControlName="hide_standard_templates" class="form-control" id="hide_standard_templates" >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>

          <!-- END OF HARDCODED TEMPLATES SETTINGS -->
          <br>
          <h4>Price Finder API</h4>
          <hr>
          <div class="form-group">
            <label for="pricefinder_username" class="col-sm-2">Username</label>
            <div class="col-sm-6">
              <input formControlName="pricefinder_username" type="text" class="form-control" id="pricefinder_username" autocomplete="off">
            </div>
          </div>
          <div class="form-group">
            <label for="pricefinder_password" class="col-sm-2">Password</label>
            <div class="col-sm-6">
              <input formControlName="pricefinder_password" type="text" class="form-control" id="pricefinder_password" autocomplete="off">
            </div>
          </div>
          <div class="form-group">
            <label for="pricefinder_template_id" class="col-sm-2">Email Template</label>
            <div class="col-sm-6">
              <select formControlName="pricefinder_template_id" class="form-control" id="pricefinder_template_id">
                <option value="0">Select Pricefinder Email Template</option>
                <option *ngFor="let template of group?.templates" value="{{template?.ID}}">{{template?.title}}</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <div class="col-sm-6">
              <button
              type="button"
              class="btn-admin btn-admin-primary"
              (click)="testCredentials(this.groupForm.controls['pricefinder_username'].value, this.groupForm.controls['pricefinder_password'].value)"
              >Test Pricefinder Credentials</button>
            </div>
          </div>
          <br>
          <h4>Designly Pro</h4>
          <hr>
          <div class="form-group">
            <label for="enable_pro_ui" class="col-sm-2">Enable Pro UI</label>
            <div class="col-sm-2">
              <select formControlName="enable_pro_ui" class="form-control" id="enable_pro_ui" >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="" class="col-sm-2">Hide Webbook</label>
            <div class="col-sm-2">
              <select formControlName="hide_webbooks" class="form-control" id="hide_webbooks" >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="" class="col-sm-2">Hide Proposals</label>
            <div class="col-sm-2">
              <select formControlName="hide_proposals" class="form-control" id="hide_proposals" >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="" class="col-sm-2">Hide Owner Reports</label>
            <div class="col-sm-2">
              <select formControlName="hide_owner_reports" class="form-control" id="hide_owner_reports" >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="enable_designly_pro" class="col-sm-2">Enable Designly Pro</label>
            <div class="col-sm-2">
              <select formControlName="enable_designly_pro" class="form-control" id="enable_designly_pro" >
                <ng-container>
                  <option value="2">State Wide</option>
                  <option value="1">Group Wide</option>
                  <option value="0">No</option>
                </ng-container>
              </select>
            </div>
          </div>
          <div class="form-group" *ngIf="showDesignlyProFields()">
            <label for="pro_enable_states" class="col-sm-2">State</label>
            <div class="col-sm-6">
              <ng-select formControlName="pro_enable_states"  [clearable]="false" [searchable]="false" [multiple]="true" id="pro_enable_states">
                <ng-option *ngFor="let state of states" [value]="state?.value">{{ state?.name }}</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="form-group">
            <label for="type" class="col-sm-2">Hide Latest Builder Styling</label>
            <div class="col-sm-2">
              <select formControlName="disable_latest_builder_styling" class="form-control" id="disable_latest_builder_styling" >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="type" class="col-sm-2">Hide Designly Pro Settings page</label>
            <div class="col-sm-2">
              <select formControlName="hide_designly_pro" class="form-control" id="hide_designly_pro" >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <br>
          <div class="form-group">
            <label class="col-sm-2">&nbsp;</label>
            <div class="col-sm-10">
              <button [disabled]="!groupForm.valid" class="btn-admin btn-admin-primary">Save</button>
              <button *ngIf="editMode" type="button" class="btn-admin btn-admin-danger" (click)="onGroupActive()">{{ group_account_active ? 'Disable Group': 'Enable Group' }}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>