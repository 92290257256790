import { Component, OnInit } from '@angular/core';
import { ClientService } from '../../services/client.service';
import { Auth } from '../../models/auth.model';
import { AuthService } from '../../services/auth.service';
import { Client } from '../../models/client.model';
import { Subscription } from 'rxjs/Subscription';
import { Router } from '@angular/router';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {
  loading = false;
  subscription: Subscription;
  version = '0';

  constructor(private clientService: ClientService,
              private authService: AuthService,
              private router: Router) { }

  ngOnInit() {
   
  }

  onVersion2() {
    const clientValues: Object = {};

    clientValues['version'] = 2;

    this.loading = true;
    this.version = '2';

    this.clientService.httpPutClient(+this.authService.auth._ID, <Client>clientValues);
  }

  onVersion3() {
    const clientValues: Object = {};

    clientValues['version'] = '3';

    this.loading = true;
    this.version = '3';

    this.clientService.httpPutClient(+this.authService.auth._ID, <Client>clientValues);
  }
}
