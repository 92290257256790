import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { AdminService } from '../../admin.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SubgroupService } from '../../../services/subgroup.service';
import { Menu } from '../../../models/menu.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { Subgroup } from '../../../models/subgroup.model';
import { Auth } from '../../../models/auth.model';

declare var jQuery: any;
declare const $: any;

@Component({
  selector: 'app-subgroups-edit',
  templateUrl: './subgroups-edit.component.html',
  styleUrls: ['./subgroups-edit.component.scss']
})
export class SubgroupsEditComponent implements OnInit, OnDestroy {
  loading = false;
  id: number;
  editMode = false;
  menus: Menu[] = [];
  subgroup: Subgroup;
  backUrl = '/admin/subgroups';
  dataTableHtml: any;
  
  selectedRow = 0;
  selectedRowDataId = [];

  authUser: Auth;

  subgroupForm: FormGroup;

  subgroupSubscription: Subscription;
  subgroupPostSubscription: Subscription;
  subgroupPutSubscription: Subscription;

  constructor(
    private adminService: AdminService,
    private route: ActivatedRoute,
    private subgroupService: SubgroupService,
    private router: Router,
    private ngZone: NgZone,
  ) {
    const $this = this;

    window['onEdit'] = function (id) {
      $this.onEdit(id);
    };
  }

  ngOnInit() {
    this.adminService.loadMenu(this.menus);
    this.authUser = this.adminService.authService.auth;

    this.loading = false;
    this.route.params.subscribe(
      (params: Params) => {
        this.id = +params['id'];
        this.editMode = params['id'] != null;

        if (this.editMode) {
          this.loading = true;
          this.subgroupService.httpGetSubgroup(this.id);
          this.initDT(this.id);
        }
      }
    );

    this.subgroupSubscription = this.subgroupService.subgroupGet.subscribe(
      (subgroup: any) => {
        if ( typeof subgroup !== 'undefined' && subgroup.status === 'success' ) {
          this.loading = false;
          this.subgroup = subgroup.data;

          this.initEditSubGroupForm();
        }
      }
    );

    this.subgroupPostSubscription = this.subgroupService.subgroupPost.subscribe(
      (subgroup: any) => {
        if ( typeof subgroup !== 'undefined' && subgroup.status === 'success' ) {
          this.loading = false;

          this.router.navigateByUrl('/admin/subgroups');
        }
      }
    );

    this.subgroupPutSubscription = this.subgroupService.subgroupPut.subscribe(
      (subgroup: any) => {
        if ( typeof subgroup !== 'undefined' && subgroup.status === 'success' ) {
          this.loading = false;

          this.router.navigateByUrl('/admin/subgroups');
        }
      }
    );

    this.initSubGroupForm();
  }

  initDT(subgroupId: number) {
    let queryParams = '?';

    queryParams += `provider=${this.authUser.provider},designly&subgroup_id=${subgroupId}`;
    if (this.adminService.authService.getApp() === 'Designly') queryParams += '&designly=yes';

    const $this = this;
    this.dataTableHtml = $('#datatable-clients').DataTable({
      'responsive': true,
      'pagingType': 'full_numbers',
      'processing': true,
      'serverSide': true,
      'ajax': {
        // tslint:disable-next-line:max-line-length
        'url': $this.adminService.appConfig.API_ENDPOINT + '/clients/datatable' + queryParams,
        'type': 'GET',
        'beforeSend': function (request) {
          request.setRequestHeader('Authorization', 'Bearer ' + $this.adminService.authService.auth.__token);
          request.setRequestHeader('MD-Authorization', $this.adminService.authService.auth.token);
        }
      },
      'pageLength': 50,
      'columns' : [
        { 'data': 'ID' },
        { 'data': function(row) {
          return row['provider'].toUpperCase();
        } },
        { 'data': function(row) {
          return row['name'];
        } },
        { 'data': function(row) {
          return row['franchise_code'];
        } },
        { 'data': function(row) {
          return row['office_id'];
        } },
        { 'data': function(row) {
          return (row['enable_pro_ui'] === 1 ? 'Yes' : 'No');
        } },
        { 'data': function(row) {
          return (row['is_franchise_master'] === 1 ? 'Yes' : 'No');
        } },
        { 'name': 'is_account_master', 
          'data': function(row) {
            return (row['is_account_master'] === 1 ? 'Yes' : 'No');
        } },
        { 'data': function(row) {
          if (row['is_account_active'] === 1) {
            return '<span class="label label-success">Active</span>';
          } else {
            return '<span class="label label-danger">Inactive</span>';
          }
        } },
        {
          data: function (row) {
              let html = '';
              let officeName = row['name'];

              officeName = officeName.replace(/'/g, '_');

              // tslint:disable-next-line:max-line-length
              html += '<button type="button" onclick="onEdit(' + row['ID'] + ')" class="btn-table" title="Edit"><i class="icon feather icon-edit"></i></button>';

              return html;
          }
        }
      ],
      'columnDefs': [ {
        'targets': [ 0 ],
        'visible': false,
        'searchable': false
      }, {
        'targets': [ 1 ],
        'name': 'provider',
        'searchable': true
      }, {
        'targets': [ 2 ],
        'name': 'name',
        'searchable': true
      }, {
        'targets': [ 3 ],
        'name': 'franchise_code',
        'searchable': true
      }, {
        'targets': [ 4 ],
        'name': 'office_id',
        'searchable': true
      }, {
        'targets': [ 6 ],
        'name': 'is_account_master',
        'searchable': false
      }, {
        'targets': [ 5, 7],
        'searchable': false
      }],
      'order': [],
      language: {
        'paginate': {
          'first': '<i aria-hidden="true" class="fa fa-angle-double-left"></i>',
          'previous': '<i aria-hidden="true" class="fa fa-angle-left"></i>',
          'next': '<i aria-hidden="true" class="fa fa-angle-right"></i>',
          'last': '<i aria-hidden="true" class="fa fa-angle-double-right"></i>'
        }
      },
      select: {
        'style': 'multiple',
      },
    });

    $('#datatable-clients').on('select.dt', function ( e, dt, type, indexes ) {
      $this.selectedRow = $this.selectedRow + 1;
      const data = dt.rows('.selected' ).data();

      for (let i = 0; i < $this.selectedRow; i++) {
        if ($this.selectedRowDataId.includes(data[i]['ID'])) {
          continue;
        } else {
          $this.selectedRowDataId.push(data[i]['ID']);
        }
      }
    });

    $('#datatable-clients').on('deselect.dt', function ( e, dt, type, indexes ) {
      $this.selectedRow = $this.selectedRow - 1;
      const data = dt.rows(indexes).data();
      const officeID = data[0]['ID'];

      $this.selectedRowDataId.forEach((value, index) => {
          if (value === officeID) {
            $this.selectedRowDataId.splice(index, 1);
          }
      });
    });

  }

  initSubGroupForm() {
    this.subgroupForm = new FormGroup({
      'title': new FormControl('', Validators.required)
    });
  }

  initEditSubGroupForm() {
    this.subgroupForm.patchValue({
      'title': this.subgroup.title
    });
  }

  onSubmit() {
    const subgroupFormValues = this.subgroupForm.value;

    if ( this.editMode ) {
      this.subgroupService.httpPutSubgroup(this.id, subgroupFormValues);
      this.loading = true;
    } else {
      this.subgroupService.httpPostSubgroup(subgroupFormValues);
      this.loading = true;
    }
  }

  onEdit(id: number) {
    this.ngZone.run(() => {
      const url = this.router.serializeUrl(this.router.createUrlTree([`/admin/clients/${id}/edit`]));
      window.open(url, '_blank');
    });
    return;
  }

  ngOnDestroy() {
    this.subgroupPostSubscription.unsubscribe();
    this.subgroupSubscription.unsubscribe();
    this.subgroupPutSubscription.unsubscribe();
  }

}
