import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { APP_CONFIG } from '../app.config';
import { ProTheme } from '../models/pro-theme.model';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProThemeService {
  onGetAll = new Subject();
  onGet = new Subject();
  onPost = new Subject();
  onPut = new Subject();
  onDelete = new Subject<ProTheme>();

  publicOnGetAll = new Subject();

  constructor(@Inject(APP_CONFIG) private appConfig, private httpClient: HttpClient) {}

  httpGetAll(params: any = {}) {
    this.httpClient
      .get(`${this.appConfig.NEW_API_ENDPOINT}/prothemes`, {
        params,
      })
      .subscribe(
        (response: any) => {
          this.onGetAll.next(response);
        },
        (response: any) => {
          this.onGetAll.next(response.error);
        }
      );
  }

  httpPublicGetAll(params: any = {}) {
    this.httpClient
      .get(`${this.appConfig.API_ENDPOINT}/public/prothemes`, {
        params,
      })
      .subscribe(
        (response: any) => {
          this.publicOnGetAll.next(response);
        },
        (response: any) => {
          this.publicOnGetAll.next(response.error);
        }
      );
  }

  httpGet(UID: string) {
    this.httpClient.get(`${this.appConfig.NEW_API_ENDPOINT}/prothemes/${UID}`).subscribe(
      (response: any) => {
        this.onGet.next(response);
      },
      (response: any) => {
        this.onGet.next(response.error);
      }
    );
  }

  httpPost(data: any) {
    return this.httpClient.post(`${this.appConfig.NEW_API_ENDPOINT}/prothemes`, data).subscribe(
      (response: any) => {
        this.onPost.next(response);
      },
      (response: any) => {
        this.onPost.next(response.error);
      }
    );
  }

  httpPut(UID: string, data: any) {
    return this.httpClient.put(`${this.appConfig.NEW_API_ENDPOINT}/prothemes/${UID}`, data).subscribe(
      (response: any) => {
        this.onPut.next(response);
      },
      (response: any) => {
        this.onPut.next(response.error);
      }
    );
  }

  httpDelete(UID: string) {
    return this.httpClient.delete(this.appConfig.NEW_API_ENDPOINT + '/prothemes/' + UID).subscribe(
      (response: any) => {
        this.onDelete.next(response);
      },
      (response: any) => {
        this.onDelete.next(response.error);
      }
    );
  }
}
