import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { AdminService } from '../../admin.service';
import { GroupService } from '../../../services/group.service';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';

import { ClientService } from '../../../services/client.service';
import { Subscription } from 'rxjs/Subscription';
import { Group } from '../../../models/group.model';
import { Client } from '../../../models/client.model';

import swal from 'sweetalert2';
@Component({
	selector: 'app-group-edit',
	templateUrl: './group-edit.component.html',
	styleUrls: ['./group-edit.component.scss']
})
export class GroupEditComponent implements OnInit, OnDestroy {
	loading = false;
	id: number;
	editMode = false;
	navigationType = 'link';
	navigationDefault = '';
	backUrl = '/admin/groups';

	subscription: Subscription;
	subscriptionClients: Subscription;
	subscriptionResponse: Subscription;

	group_title = '';

	group: Group;
	clients: Client[];

	groupForm: FormGroup;

	group_account_active = 0;

	states = [
		{ name: 'New South Wales', value: 'NSW' },
		{ name: 'Queensland', value: 'QLD' },
		{ name: 'Victoria', value: 'VIC' },
		{ name: 'South Australia', value: 'SA' },
		{ name: 'Western Australia', value: 'WA' },
		{ name: 'Tasmania', value: 'TAS' }
	];

	@ViewChild('groupFormElement', { static: true }) groupFormElement: ElementRef;

	constructor(private adminService: AdminService,
				private clientService: ClientService,
				private groupService: GroupService,
				private route: ActivatedRoute,
				private router: Router) { }

  	ngOnInit() {
		this.clientService.httpGetClients({'is_account_master': 1}, 'list');

		this.route.params.subscribe(
			(params: Params) => {
				this.id = +params['id'];
				this.editMode = params['id'] != null;

				if (this.editMode) {
					this.loading = true;
					this.groupService.httpGetGroup(this.id);
				}
			}
		);

		this.subscriptionClients = this.clientService.clientsChanged.subscribe(
			(clients: Client[]) => {
				this.clients = clients;
			}
		);

		this.subscription = this.groupService.groupChanged.subscribe(
			(group: Group) => {
				this.group = group;

				if ( this.editMode && typeof group !== 'undefined' ) {
					this.loading = false;
					this.group_account_active = group.is_account_active;

					this.initFormEdit();
				}
			}
		);

		this.subscriptionResponse = this.groupService.groupOperation.subscribe(
			(group: Group) => {
				if ( typeof group.ID !== 'undefined' ) {
					this.router.navigate([this.backUrl], {relativeTo: this.route});
				}
			}
		);

		this.initForm();
  	}

	private initForm() {
		this.groupForm = new FormGroup({
			'code': new FormControl('', Validators.required),
			'vault_code': new FormControl(''),
			'title': new FormControl('', Validators.required),
			'master_client_id': new FormControl('0', Validators.required),
			'vault_master_client_id': new FormControl('0', Validators.required),
			'domain_master_client_id': new FormControl('0', Validators.required),
			'type': new FormControl('', Validators.required),
			'provider': new FormControl('all', Validators.required),
			'admin_email': new FormControl(''),
			'setting_menu_logo': new FormControl('1', Validators.required),
			'setting_menu_color': new FormControl('1', Validators.required),
			'setting_menu_font': new FormControl('1', Validators.required),
			'setting_menu_icon': new FormControl('1', Validators.required),
			'setting_menu_link': new FormControl('1', Validators.required),
			'builder_ckeditor_font_family': new FormControl('1', Validators.required),
			'builder_ckeditor_font_and_background_color': new FormControl('1', Validators.required),
			'hide_start_from_blank' : new FormControl('0', Validators.required),
			'hide_order_custom_template' : new FormControl('0', Validators.required),
			'hide_create_diy': new FormControl('0', Validators.required),
			'hide_emails': new FormControl('0', Validators.required),
			'hide_printables': new FormControl('0', Validators.required),
			'hide_socials': new FormControl('0', Validators.required),
			'hide_signboards': new FormControl('0', Validators.required),
			'hide_designly_pro': new FormControl('0', Validators.required),
			'disable_latest_builder_styling': new FormControl('0', Validators.required),
			'pricefinder_username': new FormControl(''),
			'pricefinder_password': new FormControl(''),
			'pricefinder_template_id': new FormControl(0),
			'enable_pro_ui': new FormControl(0),
			'enable_designly_pro': new FormControl(0),
			'pro_enable_states': new FormControl(0),
			'hide_templates_email': new FormControl(0),
			'hide_templates_printables': new FormControl(0),
			'hide_templates_socialmedia': new FormControl(0),
			'hide_templates_signboards': new FormControl(0),
			'hide_standard_templates': new FormControl(0),
			'hide_webbooks': new FormControl('0', Validators.required),
			'hide_proposals': new FormControl('0', Validators.required),
			'hide_owner_reports': new FormControl('0', Validators.required)
		});
	}

	private initFormEdit() {
		this.group_title = this.group.title;
		
		this.groupForm.patchValue({
			'code': this.group.code,
			'vault_code': this.group.vault_code,
			'title': this.group.title,
			'master_client_id': this.group.master_client_id,
			'vault_master_client_id': this.group.vault_master_client_id,
			'domain_master_client_id': this.group.domain_master_client_id,
			'type': this.group.type,
			'provider': this.group.provider,
			'admin_email': this.group.admin_email,
			'setting_menu_logo': this.group.setting_menu_logo,
			'setting_menu_color': this.group.setting_menu_color,
			'setting_menu_font': this.group.setting_menu_font,
			'setting_menu_icon': this.group.setting_menu_icon,
			'setting_menu_link': this.group.setting_menu_link,
			'builder_ckeditor_font_family': this.group.builder_ckeditor_font_family,
			'builder_ckeditor_font_and_background_color': this.group.builder_ckeditor_font_and_background_color,
			'hide_start_from_blank': this.group.hide_start_from_blank,
			'hide_order_custom_template': this.group.hide_order_custom_template,
			'hide_create_diy': this.group.hide_create_diy,
			'hide_emails': this.group.hide_emails,
			'hide_printables': this.group.hide_printables,
			'hide_socials': this.group.hide_socials,
			'hide_signboards': this.group.hide_signboards,
			'hide_designly_pro': this.group.hide_designly_pro,
			'disable_latest_builder_styling': this.group.disable_latest_builder_styling,
			'pricefinder_username': this.group.pricefinder_username,
			'pricefinder_template_id': this.group.pricefinder_template_id,
			'enable_pro_ui': this.group.enable_pro_ui,
			'enable_designly_pro': this.group.enable_designly_pro,
			'pro_enable_states': JSON.parse(this.group.pro_enable_states),
			'hide_templates_email': this.group.hide_templates_email,
			'hide_templates_printables': this.group.hide_templates_printables,
			'hide_templates_socialmedia': this.group.hide_templates_socialmedia,
			'hide_templates_signboards': this.group.hide_templates_signboards,
			'hide_standard_templates': this.group.hide_standard_templates,
			'hide_webbooks':  this.group.hide_webbooks,
			'hide_proposals': this.group.hide_proposals,
			'hide_owner_reports': this.group.hide_owner_reports
		});
	}

	onChange() {
		const master_client_id = this.groupFormElement.nativeElement.querySelector('#master_client_id').value;
		const type = this.groupFormElement.nativeElement.querySelector('#type').value;
		const code = this.groupFormElement.nativeElement.querySelector('#code');

		if (master_client_id && type) {
			const index = this.clients.findIndex(c => c.ID === +master_client_id);
			const client = this.clients[index];

			if (type === 'franchise') {
				this.groupForm.patchValue({code: client.franchise_code});
			} else {
				this.groupForm.patchValue({code: client.office_id});
			}
		}
	}

	onSubmit() {
		if (this.groupForm.valid) {
			this.loading = true;
			const groupValues = this.groupForm.value;
			groupValues.pro_enable_states = JSON.stringify(groupValues.pro_enable_states);

			if ( this.editMode) {
				this.groupService.httpPutGroup(this.id, groupValues);
			} else {
				this.groupService.httpPostGroup(groupValues);
			}
		}
	}

	onGroupActive() {
		const groupValues = this.groupForm.value;

		groupValues['is_account_active'] = (this.group.is_account_active === 1) ? 0 : 1;

		this.groupService.httpPutGroup(this.id, groupValues);
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.subscriptionClients.unsubscribe();
		this.subscriptionResponse.unsubscribe();
	}

  	testCredentials(pricefinderUsername: string, pricefinderPassword:string): void {
		let groupId = this.group.ID;
		this.groupService.testPricefinderCredentials(pricefinderUsername, pricefinderPassword).subscribe(
			(response: any) => {
				swal({
					title: 'Pricefinder credentials valid',
					text: 'Would you want to save this credentials?',
					type: 'success',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, save it!'
				  }).then((result) => {
					
					if (result) {
						this.saveCredentials(groupId, pricefinderUsername, pricefinderPassword);
					}
				  }).catch(swal.noop);
			},
			(error: any) => {
				swal('Pricefinder credentials invalid', '', 'error');
			}
		)
	}
	saveCredentials(groupId: number, pricefinderUsername: string, pricefinderPassword:string) {
		let location = "group";
		this.clientService.savePricefinderCredentials(groupId, location, pricefinderUsername, pricefinderPassword).subscribe(
			(response: any) => {
				swal('Pricefinder credentials are saved', '', 'success');
			},
			(error: any) => {
				swal('Pricefinder credentials invalid', '', 'error');
			}
		)
	}

	showDesignlyProFields()
	{
		return this.groupForm.get('enable_designly_pro').value == 2;
	}


}
