import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GroupService } from '../../../../services/group.service';
import { BlockService } from '../../../../services/block.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Group } from '../../../../models/group.model';

import slugify from 'slugify';

@Component({
  selector: 'app-template-blocks-edit',
  templateUrl: './template-blocks-edit.component.html',
  styleUrls: ['./template-blocks-edit.component.scss']
})
export class TemplateBlocksEditComponent implements OnInit, OnDestroy {
  $destroy: Subject<boolean> = new Subject<boolean>();
  loading = false;
  id: number;
  editMode = false;
  backUrl: string;
  category: string;
  category_id: number;
  duplicateMode = false;
  categoryHolder: any;

  categories = {
    'enewsletter': 1,
    'buyer-match': 2,
    'quick-enquiry': 3,
    'single-property-flyer': 4,
    'multi-poroperty-flyer': 5,
    'social-media': 6,
    'email-signature': 7,
    'dl-card': 8,
    'sign-board': 9,
    'property-match': 10,
    'match-listings': 11,
    'buyer-match-vault': 12,
    'property-alerts': 13,
    'pricefinder': 14,
    'webbooks': 15,
    'owner-reports': 16,
    'proposals': 17
  };

  blockForm: FormGroup;
  groups: Group[];

  block_title = '';
  group_id_title = '';

  constructor(
    private route: ActivatedRoute,
    private groupService: GroupService,
    private blockService: BlockService,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
      (params: Params) => {
        this.id = +params['id'];
        this.editMode = params['id'] != null;
        this.duplicateMode = params['dupe'];
        this.editMode = params['id'] != null;
        if (this.duplicateMode) {
          this.backUrl = '../../../';
        } else {
          this.backUrl = this.editMode ? '../../' : '../';
        }

        this.category = params['type'];
        this.category_id = this.categories[this.category];

        if (this.id) {
          this.loading = true;
        }
      }
    );

    this.groupService.httpGetGroups();

    this.groupService.groupsChanged
    .takeUntil(this.$destroy)
    .subscribe(
      (groups: any) => {
        this.groups = [];
        if ( groups.length > 0 ) {
          this.groups = groups;

          this.blockForm.patchValue({
            group_id: this.blockService.group_id
          });

          if (this.id) {
            this.blockService.httpGetBlock(this.id, {
              select: 'dashboard'
            });
          }
        }
      }
    );

    this.blockService.onAdd
    .takeUntil(this.$destroy)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.router.navigate([this.backUrl], {relativeTo: this.route});
        }
      }
    );

    this.blockService.onPut
    .takeUntil(this.$destroy)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.router.navigate([this.backUrl], {relativeTo: this.route});
        }
      }
    );

    this.blockService.blockChanged
    .takeUntil(this.$destroy)
    .subscribe(
      (response: any) => {
        if (typeof response !== 'undefined' && typeof response.ID !== 'undefined') {
          this.loading = false;

          this.block_title = response.title;

          if (response.group_id === 0) {
            this.group_id_title = 'All';
          } else {
            this.group_id_title = response.group_id;
          }

          this.blockForm.patchValue({
            title: response.title,
            group_id: this.group_id_title,
            sort: response.sort
          });
        }
      }
    );

    this.blockForm = new FormGroup({
      'title': new FormControl('', Validators.required),
      'group_id': new FormControl('', Validators.required),
      'sort': new FormControl('', Validators.required)
    });
  }

  onSubmit() {
    const blockFormValues = this.blockForm.value;

    if (blockFormValues['group_id'] === 'All') {
      blockFormValues['group_id'] = 0;
    }

    if (this.blockForm.valid) {
      blockFormValues['is_active'] = 1;

      if (this.editMode) {
        this.blockService.httpPutBlock(this.id, blockFormValues);
      } else {
        blockFormValues['category_id'] = this.category_id;
        blockFormValues['slug'] = this.slugText(blockFormValues['title']);
        this.blockService.httpPostBlock(blockFormValues);
      }

      this.loading = true;
    }
  }

  slugText(text: string) {
    return slugify((text).trim());
  }

  ngOnDestroy() {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

}
