
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class AgentHelper {

    /* concat agent/user firstname, lastname, agent id and add inactive label if marked as deleted/inactive */
    public static getAgentFilterName(agentUser: any, includeAgentId: boolean = true) {
        if (typeof agentUser !== 'undefined') {
          let user = agentUser.firstname + ' ' + agentUser.lastname;
          if (agentUser.is_active) {
            user = !includeAgentId ? user :  user + ' ' + `(${agentUser.agent_id})`;
          } else {
            user = !includeAgentId ? user + ' ' + '(Inactive)'  :  user  + ' '  + `(${agentUser.agent_id})` + ' ' + '(Inactive)';
          }
          return user;
        }
        return ' '; 
    }

}