import { Injectable, Inject, OnInit } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import { Menu } from '../models/menu.model';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_CONFIG } from '../app.config';
import { AuthService } from '../services/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';

import { AgentHelper } from '../helpers/agent-helper';
import { GlobalEmitterService } from '../services/global-emitter.service';

let tour_video_link;

@Injectable()
export class FrontService {
  menuChanged = new Subject<Menu[]>();
  routeData = new Subject<any>();
  fullPageContent = false;
  supportLink = 'https://websites.formstack.com/forms/mydesign_support_form';
  private sharedMenus = new BehaviorSubject<Menu[]>([]);
  private menus: Menu[] = [];

  public paste_alert = false;
  public new_notification = false;

  constructor(
    @Inject(APP_CONFIG) public appConfig,
    public authService: AuthService,
    private router: Router,
    public globalEmitterService: GlobalEmitterService
  ) {}

  public getSharedMenu(): Observable<Menu[]> {
    // Allow to `subscribe` on changes and get the value every time it changes
    return this.sharedMenus.asObservable();
  }

  loadMenu(menus) {
    this.menus = menus;
    this.sharedMenus.next(menus);
    this.menuChanged.next(menus);
  }

  hasMenu() {
    this.fullPageContent = false;

    return this.menus.length > 0 ? true : false;
  }

  tourVideoLink() {
    tour_video_link = 'https://youtube.com';

    return tour_video_link;
  }

  getAgentFilterName(agentUser) {
    return AgentHelper.getAgentFilterName(agentUser);
  }

}
