import { HttpParams, HttpRequest,  HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import { User } from '../models/user.model';
import { ActivatedRoute } from '@angular/router';

import 'rxjs/Rx';

import { APP_CONFIG } from '../app.config';
import { AuthService } from './auth.service';

@Injectable()
export class UserService {
  usersChanged = new Subject<User[]>();
  userChanged = new Subject<User>();
  userOperation = new Subject<User>();
  usersAccessChanged = new Subject<User>();
  userAccessChanged = new Subject<User>();
  onCrmLogin = new Subject();
  onUpdateThirdpartyTokenAccess = new Subject();

  // New Subscription
  onGet = new Subject();

  private users: User[] = [];

  constructor(@Inject(APP_CONFIG) private appConfig, private httpClient: HttpClient, public authService: AuthService) {}

  get isDesignlyProEnabled() {
    const auth = this.authService.auth;  
    const { user, client: { group, is_pro_trial, client } } = auth;
        return user.enable_designly_pro || is_pro_trial || group.enable_designly_pro || (client? client.enable_designly_pro : 0);
  }

  setUsers(users: User[]) {
    this.users = users;
    this.usersChanged.next(this.users.slice());
  }

  getUsers() {
    return this.users.slice();
  }

  httpGetUsers(params: any = {}, columns: string = '', order: string = '') {
    let httpParams = new HttpParams();

    if ( columns ) {
      httpParams = httpParams.append('select', columns);
    }
    if ( order ) {
      httpParams = httpParams.append('order', order);
    }

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/users',
      {
        params: httpParams
      }
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (typeof response.body !== 'undefined' && response.body != null) {
            return response.body;
        }

        return [];
      }
  )
  .subscribe(
    (response: any) => {
      this.usersChanged.next(response);
    },
    (response: any) => {
      this.usersChanged.next(response.error);
    }
  );
  }

  httpGetUser(UID: string) {
    this.httpClient.get(`${this.appConfig.API_ENDPOINT}/users/${UID}`)
    .subscribe(
      (response: any) => {
        this.onGet.next(response);
      },
      (response: any) => {
        this.onGet.next(response.error);
      }
    );
  }

  httpPostUser(data: User) {
    const req = new HttpRequest(
      'POST',
      this.appConfig.API_ENDPOINT + '/users',
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.userOperation.next(response);
      }
    );
  }

  httpPutUser(id: number, data: User) {
    const req = new HttpRequest(
      'PUT',
      this.appConfig.API_ENDPOINT + '/users/' + id,
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.userOperation.next(response);
      }
    );
  }

  httpPutUserAccess(client_id: number, data: User) {
    const req = new HttpRequest(
      'PUT',
      this.appConfig.API_ENDPOINT + '/users/client/' + client_id,
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.usersAccessChanged.next(response);
      }
    );
  }

  adminAccess(client_id: number) {
    const req = new HttpRequest(
      'PUT',
      this.appConfig.API_ENDPOINT + '/users/client/admin/' + client_id,
      ''
    );

    return this.httpClient.request<any>(req)
  }

  httpPutAdminAccess(client_id: number) {
    const req = new HttpRequest(
      'PUT',
      this.appConfig.API_ENDPOINT + '/users/client/admin/' + client_id,
      ''
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.usersAccessChanged.next(response);
      }
    );
  }

  httpPutSetGroupAdmin(user_id: number, data: any) {
    const req = new HttpRequest(
      'PUT',
      this.appConfig.API_ENDPOINT + '/users/group/' + user_id,
      {type: data}
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.userAccessChanged.next(response);
      }
    );
  }

  httpDeleteUser(id: number) {
    const req = new HttpRequest(
      'DELETE',
      this.appConfig.API_ENDPOINT + '/users/' + id
    );

    return this.httpClient.request<any>(req)
    .subscribe(
      (response: any) => {
        this.httpGetUsers();
      }
    );
  }

  httpGetUserByAgentID(id: any) {

    const req = new HttpRequest(
      'GET',
      this.appConfig.API_ENDPOINT + '/users/agent/' + id,
    );

    return this.httpClient.request(
      req
    )
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: User) => {
        this.userChanged.next(response);
      }
    );
  }

  httpPutUserByAgentID(id: any, data: any) {
    const req = new HttpRequest(
      'PUT',
      this.appConfig.API_ENDPOINT + '/users/agent/' + id,
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
        if (response.body) {
          if (response.body.status === 'success') {
            return response.body.data;
          }
        }

        return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.userOperation.next(response);
      }
    );
  }

  httpCrmLogin(data: any) {
    return this.httpClient.post(`${this.appConfig.CRM_ENDPOINT}/auth/builder`, data)
    .subscribe(
        (response: any) => {
            this.onCrmLogin.next(response);
        },
        (response: any) => {
            this.onCrmLogin.next(response.error);
        }
    );
  }

  httpGetVaultThirdPartyTokenAccess(id, data: any) {
    return this.httpClient.put(`${this.appConfig.API_ENDPOINT}/users/${id}/thirdpartytokenaccess`, {type: data})
    .subscribe(
      (response: any) => {
          this.onUpdateThirdpartyTokenAccess.next(response);
      },
      (response: any) => {
          this.onUpdateThirdpartyTokenAccess.next(response.error);
      }
    );
  }

  designlyProAccess(id: any, data: any) {
    return this.httpClient.put(`${this.appConfig.API_ENDPOINT}/users/${id}/designlyproaccess`, {type: data})
  }

  designlyProAccessUsers(clientId: number, type: number, isEnableWebbooks: boolean, isEnableProposals: boolean, isEnableOwnerReports: boolean) {
    let params = {
      client_id: clientId, 
      type: type,
      enable_webbooks: isEnableWebbooks,
      enable_proposals: isEnableProposals,
      enable_owner_reports: isEnableOwnerReports
    }
    return this.httpClient.put(`${this.appConfig.API_ENDPOINT}/users/designlyproaccess`, params)
  }

  designlyProAccessUser(clientId: number, userId: number, type: number, isEnableWebbooks: boolean, isEnableProposals: boolean, isEnableOwnerReports: boolean) {
    let params = {
      client_id: clientId,
      type: type,
      enable_webbooks: isEnableWebbooks,
      enable_proposals: isEnableProposals,
      enable_owner_reports: isEnableOwnerReports
    }
    return this.httpClient.put(`${this.appConfig.API_ENDPOINT}/users/${userId}/designlyproaccess`, params)
  }

  accessLevel(id: any, data: any) {
    return this.httpClient.put(`${this.appConfig.API_ENDPOINT}/users/${id}/accesslevel`, {level: data})
  }

}
