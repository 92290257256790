import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Group } from '../../../../models/group.model';
import { ProBlock } from '../../../../models/pro-block.model';
import { GroupService } from '../../../../services/group.service';
import { ProBlockService } from '../../../../services/pro-block.service';

import swal from 'sweetalert2';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-pros-blocks-edit',
  templateUrl: './admin-pros-blocks-edit.component.html',
  styleUrls: ['./admin-pros-blocks-edit.component.scss'],
})
export class AdminProsBlocksEditComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = false;
  editMode = false;
  duplicateMode = false;

  proBlockForm: FormGroup;
  groups: Group[] = [];
  proBlock: ProBlock;

  product: string;

  constructor(
    private proBlockService: ProBlockService,
    private groupService: GroupService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.showLoading(true);
    this.product = this.route.snapshot.paramMap.get('product');
    const proBlockId = this.route.snapshot.paramMap.get('id');
    this.duplicateMode = this.route.snapshot.paramMap.get('duplicate') ? true : false;

    this.groupService.httpGetGroupsNew();

    if (proBlockId && !this.duplicateMode) {
      this.editMode = true;
    }

    this.loadInitialData(proBlockId);
    this.initForms();
  }

  private loadInitialData(proBlockId: string): void {
    this.onLoadGroups(proBlockId);
    this.onLoadBlock();
    this.onCreateBlock();
    this.onUpdateBlock();
  }

  private onLoadGroups(proBlockId: string): void {
    this.groupService.onGetGroups.pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response) {
        this.showLoading(false);

        if (response.status === 'success') {
          this.groups = response.data;

          if (this.editMode || this.duplicateMode) {
            this.showLoading(true);
            this.proBlockService.httpGet(proBlockId);
          }
        }

        if (response.status === 'error') {
          swal('Error getting groups', 'Contact Dev Team', 'error');
        }
      }
    });
  }

  private onLoadBlock(): void {
    this.proBlockService.onGet.pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response) {
        this.showLoading(false);

        if (response.status === 'success') {
          this.proBlock = response.data;
          this.initFormEdit();
        }

        if (response.status === 'error') {
          this.handleError('Error getting Pro Block');
        }
      }
    });
  }

  private onCreateBlock(): void {
    this.proBlockService.onPost.pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response) {
        this.showLoading(false);

        if (response.status === 'success') {
          this.proBlock = response.data;
          this.toastrService.success('Pro Block created');
          this.router.navigateByUrl(`/admin/pros/blocks/${this.product}/${this.proBlock.UID}/edit`);
        }

        if (response.status === 'error') {
          this.handleError('Error getting Pro Block');
        }
      }
    });
  }

  private onUpdateBlock(): void {
    this.proBlockService.onPut.pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response) {
        this.showLoading(false);

        if (response.status === 'success') {
          this.proBlock = response.data;
          this.toastrService.success('Pro Block Updated');
        }

        if (response.status === 'error') {
          this.handleError('Error getting Pro Block');
        }
      }
    });
  }

  private initForms(): void {
    this.proBlockForm = new FormGroup({
      product: new FormControl(this.product, Validators.required),
      title: new FormControl('', Validators.required),
      group_id: new FormControl('', Validators.required),
      sort: new FormControl(0, Validators.required),
    });
  }

  private initFormEdit(): void {
    this.proBlockForm.patchValue({
      title: this.proBlock.title,
      sort: this.proBlock.sort,
    });

    if (this.proBlock.whitelist === 'all') {
      this.proBlockForm.patchValue({
        group_id: ['all'],
      });
    } else {
      const groupUids = this.proBlock.groups.map((group) => group.UID);

      this.proBlockForm.patchValue({
        group_id: groupUids,
      });
    }
  }

  onSubmit() {
    const proBlockFormValues = this.proBlockForm.value;

    if (this.proBlockForm.valid) {
      if (this.editMode) {
        this.proBlockService.httpPut(this.proBlock.UID, proBlockFormValues);
      } else {
        this.proBlockService.httpPost(proBlockFormValues);
      }
      this.showLoading(true);
    }
  }

  private showLoading(value: boolean): void {
    this.loading = value;
  }

  private handleError(message: string): void {
    this.showLoading(false);
    swal(message, 'Contact Dev Team', 'error');
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
