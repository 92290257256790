import { Component, OnInit, OnDestroy, Input, OnChanges, AfterViewInit, ChangeDetectorRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { Template } from '../../models/template.model';
import { ProposalProperty } from '../../models/proposal-property.model';

import { MyDesktopService } from '../../services/mydesktop.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Auth } from '../../models/auth.model';
import { Client } from '../../models/client.model';
import { Select2OptionData } from 'ng2-select2';
import { Subject } from 'rxjs';
import { VaultService } from '../../services/vault.service';
import { DomainService } from '../../services/domain.service';
import { ProgressService } from '../progress-loader/progress.service';

import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { IdashboardService } from '../../services/idashboard.service';
import { SharedService } from '../shared.service';
import { PropertySearchService } from './property-search.service';
import * as moment from 'moment';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';
import { templateJitUrl } from '@angular/compiler';
import { PricefinderService } from '../../services/pricefinder.service';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Categories } from './../../enum/categories.enum';

declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-property-search',
  templateUrl: './property-search.component.html',
  styleUrls: ['./property-search.component.scss']
})
export class PropertySearchComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Output() back: EventEmitter<void> = new EventEmitter();
  destroy$: Subject<boolean> = new Subject<boolean>();
  subLoading = false;
  activateInsertListing = false;
  loadingInsertListing = false;

  s2SuburbValueModalInit = false;
  s2SuburbValue: any = '';
  s2SuburbData: Array<Select2OptionData>;
  s2SuburbOptions: Select2Options;

  s2ClassificationValue = 'residential';
  s2ClassificationData: Array<Select2OptionData>;
  s2ClassificationOptions: Select2Options;
  s2StatusValue = 'listing';
  s2StatusData: Array<Select2OptionData>;
  s2StatusOptions: Select2Options;
  s2StatusDisabled = false;
  s2SubStatusValue = '';
  s2SubStatusData: Array<Select2OptionData>;
  s2SubStatusOptions: Select2Options;
  s2SubStatusDisabled = true;
  s2AvailableValue = '1';
  s2AvailableData: Array<Select2OptionData>;
  s2AvailableOptions: Select2Options;
  s2AvailableDisabled = true;
  s2AgentIdValue = '';
  s2AgentIdData: Array<Select2OptionData>;
  s2AgentIdOptions: Select2Options;

  s2PriceFromValue = '';
  s2PriceFromData: Array<Select2OptionData>;
  s2PriceFromOptions: Select2Options;

  s2PriceToValue = '';
  s2PriceToData: Array<Select2OptionData>;
  s2PriceToOptions: Select2Options;

  s2BedroomValue = '';
  s2BedroomData: Array<Select2OptionData>;
  s2BedroomOptions: Select2Options;

  s2TypeValue = '';
  s2TypeData: Array<Select2OptionData>;
  s2TypeOptions: Select2Options;

  s2PrecinctsValue = '';
  s2PrecinctsData: Array<Select2OptionData>;
  s2PrecinctsOptions: Select2Options;

  s2AccountRegionsValue = '';
  s2AccountRegionsData: Array<Select2OptionData>;
  s2AccountRegionsOptions: Select2Options;

  s2BedFromValue = '';
  s2BedFromData: Array<Select2OptionData>;
  s2BedFromOptions: Select2Options;

  s2BedToValue = '';
  s2BedToData: Array<Select2OptionData>;
  s2BedToOptions: Select2Options;

  s2BathFromValue = '';
  s2BathFromData: Array<Select2OptionData>;
  s2BathFromOptions: Select2Options;

  s2BathToValue = '';
  s2BathToData: Array<Select2OptionData>;
  s2BathToOptions: Select2Options;

  s2CarFromValue = '';
  s2CarFromData: Array<Select2OptionData>;
  s2CarFromOptions: Select2Options;

  s2CarToValue = '';
  s2CarToData: Array<Select2OptionData>;
  s2CarToOptions: Select2Options;

  s2OrderByValue = 'modifydateDesc';
  s2OrderByData: Array<Select2OptionData>;
  s2OrderByOptions: Select2Options;
  s2OrderByDisabled = false;

  s2OpenHomeDaysValue = '';
  s2OpenHomeDaysData: Array<Select2OptionData>;
  s2OpenHomeDaysOptions: Select2Options;
  s2OpenHomeDaysDisabled = true;

  s2BasedOnValue = '';
  s2BasedOnData: Array<Select2OptionData>;
  s2BasedOnOptions: Select2Options;
  s2BasedOnDisabled = true;

  s2ExtraFiltersValue = '';
  s2ExtraFiltersData: Array<Select2OptionData>;
  s2ExtraFiltersOptions: Select2Options;

  s2OfficeIdValue = '';
  s2OfficeIdData: Array<Select2OptionData>;
  s2OfficeIdOptions: Select2Options;

  s2SoldFilterValue = '';
  s2SoldFilterData: Array<Select2OptionData>;
  s2SoldFilterOptions: Select2Options;

  s2BranchesValue = '';
  s2BranchesData: Array<Select2OptionData>;
  s2BranchesOptions: Select2Options;

  s2PeriodValue = '';
  s2PeriodData: Array<Select2OptionData>;
  s2PeriodOptions: Select2Options;

  s2MethodValue = '';
  s2MethodData: Array<Select2OptionData>;
  s2MethodOptions: Select2Options;
  s2MethodDisabled = true;
  s2SelectingCategory = false;

  s2SearchValue = '';
  s2SearchData: Array<Select2OptionData>;
  s2SearchOptions: Select2Options;

  s2ListedPriceMinValue = '';
  s2ListedPriceMinData: Array<Select2OptionData>;
  s2ListedPriceMinOptions: Select2Options;

  s2ListedPriceMaxValue = '';
  s2ListedPriceMaxData: Array<Select2OptionData>;
  s2ListedPriceMaxOptions: Select2Options;

  s2SearchByData = <any>[
    {
      id: 'search-by-address',
      text: 'Address'
    },
    {
      id: 'search-by-suburb',
      text: 'Suburb'
    }
  ];

  propertySearchAutoFills = ['search-by-address', 'search-by-suburb'];

  s2SearchByValue = 'search-by-address';

  price_sale: any[] = [];
  price_rent: any[] = [];
  listed_price_range: any[] = [];

  mdListingsForm: FormGroup;
  mdListings: any[] = [];
  mdSuburbs: any[] = [];
  mdTypes: any[] = [];
  mdClassifications: any[] = [];
  mdStatuses: any[] = [];
  mdCustomParams: any[] = [];

  client: Client;

  selectedListings = [];

  mdListingCount = 0;
  mdListingTotal = 0;
  mdListingPages = [];
  mdListingCurrentPage = 1;

  authUser: Auth;
  clientAgents = [];
  templateAgent: any;

  isUK = false;

  is_local_search = 0;

  isMultipleOffice = false;
  multipleOffice = [];

  is_highlightable = true;
  _tmp_selected_element: any;

  _tmp_order_id: number;

  _sender: any;

  listingImages = [];
  listingFloorplans = [];

  _tmp_agent_listing_id: number;
  listingAgents: any[];
  selectedlistingAgents: string[];
  templateAgent1: {};
  templateAgent2: {};
  listingAgentsUpdate: any[];

  // listing filter related variables
  isDatePeriodNeeded = false;
  filterPeriodType = 'n/a';

  listingSearchValues: string;

  showDesignlyPreviousPage = false;
  showDesignlyNextPage = false;
  designlyPreviousLink = '';
  designlyNextLink = '';

  currentVaultPage = 1;
  vaultTotalPages = 1;

  selectAllTriggered = false;
  selectAllCount = 0;
  isInsert = false;
  isReplace = false;

  checkOwners = 0;
  checkTenants = 0;
  checkSalePrice = 0;
  checkInspections = 0;
  checkCustomFields = 0;
  checkRooms = 0;
  checkMapImage = 0;
  checkFilingCabinet = 0;
  checkFeatures = 0;
  checkPropertyOffers = 0;
  checkPropertyAdvertising = 0;
  checkPropertySolicitor = 0;
  checkPropertyOfferConditions = 0;

  activeInsertListing = false;

  selectedSortDate = '';
  s2VaultSuburbValue = [];
  s2VaultSuburbData: Array<Select2OptionData>;
  s2VaultSuburbOptions: Select2Options;
  s2VaultDistrictSuburbsOptions: Select2Options;
  s2VaultDistrictSuburbValue = [];

  isListingSearching = false;

  branches = [];
  listingQuery = '';

  periodFilterType = 'webdate';
  templateListings = [];

  s2postPerPage: Array<Select2OptionData>;
  s2postPerPageOptions: Select2Options;
  s2postPerPageValue = '50';

  selectedListingIDs = [];
  element_listing_limit = 0;

  publicCheck = false;
  priceFinderEnabled = false;

  priceFinderAddressSearchLoading = false;
  priceFinderSearchInput = new Subject<string>();
  priceFinderProperties = [];
  priceFinderSearchSuburbInput = new Subject<string>();
  priceFinderSuburbs = [];
  priceFinderSearchForm: FormGroup;
  priceFinderSearchedPropertyId: number;
  priceFinderSearchedPropertyLabel: string = null;
  lastSelectedSearchPropertyAddress = 0;
  lastSelectedSearchPropertySuburb = 0;
  priceFinderSearch = false;

  propertyTypes = [];
  precincts = [];
  customRegions = [];
  accountBranches = [];

  landareaFromValue = '';
  landareaToValue = '';
  floorareaFromValue = '';
  floorareaToValue = '';
  landBuildingAreaDisabled = false;
  displayPrice = false;

  searchPropertyInput = new Subject<string>();
  searchProperties = [];
  searchPropertyLoading = false;
  searchPropertyLabel = 'displayAddress';
  searchPropertySelectedAddress = 'Search by address';
  listedDates: any[] = [];
  listedPriceMin = '';
  listedPriceMax = '';
  templateCategoryId = Categories;
  filterByAreaValue = 'suburb';
  publishedProperties: boolean = false;
  
  @Input() maxLimit: number;
  @Input() template: Template;
  @Input() caller: string;
  @Input() proposalProperty?: ProposalProperty;
  constructor(
    public sharedService: SharedService,
    private myDesktopService: MyDesktopService,
    private vaultService: VaultService,
    private domainService: DomainService,
    private progressService: ProgressService,
    private mScrollbarService: MalihuScrollbarService,
    private idashboardService: IdashboardService,
    private propertySearchService: PropertySearchService,
    private pricefinderService: PricefinderService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.authUser = this.sharedService.authService.auth;
    this.isUK = this.sharedService.authService.isUk();
    const $this = this;
    this.client = this.authUser.client;

    const tempAgentUsers = this.authUser.client.users.slice(0);
    this.clientAgents = tempAgentUsers.sort(function (a, b) {
      const x = a.firstname.toLowerCase();
      const y = b.firstname.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });

    this.is_local_search = this.client.is_local_search;

    this.s2postPerPage = <any>[
      {id: '50', text: '50'},
      {id: '100', text: '100'},
    ];

    this.s2postPerPageOptions = {
      width: '62px',
      placeholder: '',
      minimumResultsForSearch: -1
    };

    this.s2SuburbOptions = {
      width: '100%',
      placeholder: 'Select a Suburb',
      allowClear: true,
      ajax: {
        headers: {
          Authorization: 'Bearer ' + this.authUser.__token,
        },
        url: this.sharedService.appConfig.API_ENDPOINT + '/locations',
        dataType: 'json',
        delay: 500,
        data: function (params) {
          return {
            name: params.term,
          };
        },
        processResults: function (data, params) {
          const results = data.data.map((obj) => {
            return { id: obj.name, text: obj.name };
          });
          results.unshift({ id: 'all', text: 'All Suburb' });

          return {
            results: results,
          };
        },
        cache: true,
      },
      minimumInputLength: 2,
    };

    if (this.is_local_search) {
      this.s2SuburbOptions['multiple'] = true;
    }

    this.s2SuburbData = <any>[
      { id: '', text: 'Select a Suburb' },
      { id: ' ', text: 'Select a Suburb' },
    ];

    if (this.authUser.provider === 'vaultre') {
      this.s2ClassificationData = <any>[
        { id: '', text: 'Select a Category' },
        { id: 'residential', text: 'Residential'},
        { id: 'commercial', text: 'Commercial' },
        { id: 'business', text: 'Business' },
        { id: 'rural', text: 'Rural' },
        { id: 'land', text: 'Land' },
        { id: 'holidayRental', text: 'Holiday Rental' },
        { id: 'livestock', text: 'Livestock' },
        { id: 'clearingSales', text: 'Clearing Sales' },
      ];
    } else if (this.authUser.provider === 'domain') {
      this.s2ClassificationData = <any>[
        { id: '', text: 'Select a Category' },
        { id: 'residential', text: 'Residential'},
      ];
    } else if (this.authUser.provider === 'idashboard') {
      this.s2ClassificationData = <any>[
        { id: '', text: 'Select a Category' },
        { id: 'residential', text: 'Residential'},
        { id: 'commercial', text: 'Commercial' },
        { id: 'business', text: 'Business' },
        { id: 'rural', text: 'Rural' },
        { id: 'holiday', text: 'Holiday' },
      ];
    } else {
      this.s2ClassificationData = <any>[
        { id: '', text: 'Select a Category' },
        { id: 'residential', text: 'Residential'},
        { id: 'commercial', text: 'Commercial' },
        { id: 'business', text: 'Business' },
        { id: 'rural', text: 'Rural' },
        { id: 'livestock', text: 'Livestock' },
        { id: 'clearingSales', text: 'Clearing Sales' },
      ];
    }

    this.s2ClassificationOptions = {
      width: '100%',
      placeholder: 'Select a Category',
      minimumResultsForSearch: -1,
    };

    // this.s2TypeData = <any>[
    //   { id: 146, text: 'Acreage' },
    //   { id: 5, text: 'Apartment' },
    //   { id: 151, text: 'Block of Unit' },
    //   { id: 8, text: 'Duplex' },
    //   { id: 6, text: 'Flat' },
    //   { id: 1, text: 'House' },
    //   { id: 57, text: 'Off the plan' },
    //   { id: 9, text: 'Other' },
    //   { id: 56, text: 'Retirement' },
    //   { id: 7, text: 'Studio' },
    //   { id: 3, text: 'Townhouse' },
    //   { id: 2, text: 'Unit' },
    //   { id: 4, text: 'Villa' },
    // ];

    // this.s2TypeData.unshift({ id: ' ', text: 'Any' });
    // this.s2TypeData.unshift({ id: '', text: 'Any' });

    this.s2TypeOptions = {
      width: '100%',
      placeholder: 'Any',
      minimumResultsForSearch: -1,
    };

    this.s2PrecinctsOptions = {
      width: '100%',
      placeholder: 'Select Precinct',
      minimumResultsForSearch: -1,
    };

    this.s2AccountRegionsOptions = {
      width: '100%',
      placeholder: 'Select Region',
      minimumResultsForSearch: -1,
    };

    // this.s2StatusData = <any>[
    //   { id: '', text: 'Select a Type' },
    //   { id: ' ', text: 'All Types' },
    //   { id: 'listing', text: 'Sales' },
    //   { id: 'rental', text: 'Rental' },
    //   { id: 'auction', text: 'Auction' },
    //   { id: 'settled', text: 'Sold' },
    //   { id: 'leased', text: 'Leased' },
    //   { id: 'conditional', text: 'Conditional' },
    //   { id: 'unconditional', text: 'Unconditional' },
    // ];

    let managementText = 'Management';
    let appraisalText = 'Appraisal';

    if (this.isUK) {
      managementText = 'Management/Listing Status';
      appraisalText = 'Valuation';
    }

    if (this.isUK) {
      this.s2StatusData =  <any> [
        { id: '', text: 'Select a Type' },
        { id: 'listing', text: 'Listing' },
        { id: 'rental', text: 'Management/Listing' },
        { id: 'auction', text: 'Auction' },
        { id: 'sold', text: 'Sold' },
        { id: 'leased', text: 'Leased' },
        { id: 'listingOrConditional', text: 'Listing / Conditional' },
        { id: 'conditional', text: 'Under Offer' },
        { id: 'prospect', text: 'Prospect/Not currently Listed' },
        { id: 'appraisal', text: 'Valuation' },
      ];
    } else {
      this.s2StatusData =  <any> [
        { id: '', text: 'Select a Type' },
        { id: 'listing', text: 'Listing' },
        { id: 'rental', text: 'Lease' },
        { id: 'auction', text: 'Auction' },
        { id: 'sold', text: 'Sold' },
        { id: 'leased', text: 'Leased' },
        { id: 'listingOrConditional', text: 'Listing / Conditional' },
        { id: 'conditional', text: 'Under Offer / Conditional' },
        { id: 'prospect', text: 'Prospect' },
        { id: 'appraisal', text: 'Appraisal' },
      ];
    }
    

    this.s2ExtraFiltersData = <any>[
      { id: '', text: 'Select extra filter' },
      { id: '0', text: 'Select extra filter' },
      { id: 'openhomes', text: 'Open Homes' },
      { id: 'pricereduced', text: 'Price Reduced' },
    ];
    this.s2ExtraFiltersOptions = {
      width: '100%',
      placeholder: 'Select extra filter',
      minimumResultsForSearch: -1,
    };

    this.s2OpenHomeDaysData = <any>[
      { id: '', text: 'Select Period' },
      { id: 0, text: 'Any period' },
      { id: 7, text: 'Next 7 Days' },
      { id: 14, text: 'Next 14 Days' },
    ];

    this.s2OpenHomeDaysOptions = {
      width: '100%',
      placeholder: 'Select Period',
      minimumResultsForSearch: -1,
    };

    this.s2StatusOptions = {
      width: '100%',
      placeholder: 'Select a Type',
      minimumResultsForSearch: -1,
    };

    this.s2SubStatusData = <any>[];

    this.s2SubStatusOptions = {
      width: '100%',
      placeholder: 'Select a Sub Type',
      minimumResultsForSearch: -1,
    };

    this.s2SubStatusValue = 'management';

    this.s2SubStatusData = [
      { id: 'all', text: 'All' },
      { id: 'prospect', text: 'Prospect' },
      { id: 'appraisal', text: appraisalText },
      { id: 'management', text: managementText },
    ];

    this.s2BasedOnOptions = {
      width: '100%',
      placeholder: 'Select based on',
      minimumResultsForSearch: -1,
    };

    if (this.authUser.provider === 'vaultre') {
      this.s2BasedOnData = [
        { id: '', text: 'Select based on' },
        { id: ' ', text: 'Select based on' },
        { id: 'unconditional', text: 'Unconditional' },
        { id: 'settled', text: 'Settled' },
        { id: 'settled,unconditional', text: 'Unconditional/Settled' },
      ];
    } else {
      this.s2BasedOnData = [
        { id: '', text: 'Select based on' },
        { id: ' ', text: 'Select based on' },
        { id: 'unconditional', text: 'Unconditional' },
        { id: 'settled', text: 'Settled' },
        { id: 'notcurrentlylisted', text: 'Not Currently Listed' },
      ];
    }

    this.s2SoldFilterOptions = {
      width: '100%',
      placeholder: 'Select settlement or unconditional filter',
      minimumResultsForSearch: -1,
    };

    this.s2SoldFilterData = [
      { id: '', text: 'Select settlement or unconditional filter' },
      { id: ' ', text: 'Select settlement or unconditional filter' },
      { id: 'settlementBefore', text: 'Settlement Before' },
      { id: 'settlementSince', text: 'Settlement Since' },
      { id: 'unconditionalBefore', text: 'Unconditional Before' },
      { id: 'unconditionalSince', text: 'Unconditional Since' },
    ];

    this.s2AgentIdData = <any>this.clientAgents.map((obj) => {
      return { id: obj.agent_id, text: obj.firstname + ' ' + obj.lastname };
    });
    this.s2AgentIdData.unshift({ id: ' ', text: 'All Agents' });
    this.s2AgentIdData.unshift({ id: '', text: 'Select an Agent' });

    this.s2AgentIdOptions = {
      width: '100%',
      placeholder: 'Select an Agent',
      minimumResultsForSearch: -1,
    };

    this.s2AvailableOptions = {
      width: '100%',
      placeholder: 'Show Available Only',
      minimumResultsForSearch: -1,
    };

    this.s2AvailableData = [
      { id: '1', text: 'Yes' },
      { id: '0', text: 'No' },
    ];

    if (this.authUser.multiple.length > 1) {
      this.isMultipleOffice = true;
      this.s2OfficeIdData = <any>this.authUser.multiple.map((obj) => {
        return { id: obj.groupid, text: obj.office };
      });

      this.s2OfficeIdData.unshift({ id: '', text: 'Select an Office' });

      this.s2OfficeIdOptions = {
        width: '100%',
        placeholder: 'Select an Office',
        minimumResultsForSearch: -1,
      };
    }

    this.s2BranchesOptions = {
      width: '100%',
      placeholder: 'Select a Branch',
      minimumResultsForSearch: -1,
    };

    this.s2PeriodOptions = {
      width: '100%',
      placeholder: 'Select a Branch',
      minimumResultsForSearch: -1,
    };

    this.s2PeriodData = [
      {
        id: 'any',
        text: 'Any Period',
      },
      {
        id: '7',
        text: 'Last 7 Days',
      },
      {
        id: '14',
        text: 'Last 14 Days',
      },
      {
        id: '30',
        text: 'Last 30 Days',
      },
      {
        id: '60',
        text: 'Last 60 Days',
      },
      {
        id: '90',
        text: 'Last 90 Days',
      },
    ];

    this.s2MethodOptions = {
      width: '100%',
      placeholder: 'Select a Method',
      minimumResultsForSearch: -1,
    };

    this.s2MethodData = [
      {
        id: '',
        text: 'Select a Method',
      },
      {
        id: 'any',
        text: 'Any',
      },
      {
        id: 'open',
        text: 'Open Sale',
      },
      {
        id: 'auction',
        text: 'Auction',
      },
      {
        id: 'exclusive',
        text: 'Exclusive Sale',
      },
      {
        id: 'eoi',
        text: 'Expression of Interest',
      },
      {
        id: 'tender',
        text: 'Tender',
      },
      {
        id: 'lease',
        text: 'Lease',
      },
    ];

    this.price_sale = [
      { id: '50000', text: '$50,000' },
      { id: '100000', text: '$100,000' },
      { id: '150000', text: '$150,000' },
      { id: '200000', text: '$200,000' },
      { id: '250000', text: '$250,000' },
      { id: '300000', text: '$300,000' },
      { id: '350000', text: '$350,000' },
      { id: '400000', text: '$400,000' },
      { id: '450000', text: '$450,000' },
      { id: '500000', text: '$500,000' },
      { id: '550000', text: '$550,000' },
      { id: '600000', text: '$600,000' },
      { id: '650000', text: '$650,000' },
      { id: '700000', text: '$700,000' },
      { id: '750000', text: '$750,000' },
      { id: '800000', text: '$800,000' },
      { id: '850000', text: '$850,000' },
      { id: '900000', text: '$900,000' },
      { id: '950000', text: '$950,000' },
      { id: '1000000', text: '$1,000,000' },
      { id: '1100000', text: '$1,100,000' },
      { id: '1200000', text: '$1,200,000' },
      { id: '1300000', text: '$1,300,000' },
      { id: '1400000', text: '$1,400,000' },
      { id: '1500000', text: '$1,500,000' },
      { id: '1600000', text: '$1,600,000' },
      { id: '1700000', text: '$1,700,000' },
      { id: '1800000', text: '$1,800,000' },
      { id: '1900000', text: '$1,900,000' },
      { id: '2000000', text: '$2,000,000' },
      { id: '2250000', text: '$2,250,000' },
      { id: '2500000', text: '$2,500,000' },
      { id: '2750000', text: '$2,750,000' },
      { id: '3000000', text: '$3,000,000' },
      { id: '3500000', text: '$3,500,000' },
      { id: '4000000', text: '$4,000,000' },
      { id: '5000000', text: '$5,000,000' },
      { id: '6000000', text: '$6,000,000' },
      { id: '7000000', text: '$7,000,000' },
      { id: '8000000', text: '$8,000,000' },
      { id: '9000000', text: '$9,000,000' },
      { id: '10000000', text: '$10,000,000' },
      { id: '11000000', text: '$11,000,000' },
      { id: '12000000', text: '$12,000,000' },
    ];

    this.price_rent = [
      { id: '50', text: '$50' },
      { id: '100', text: '$100' },
      { id: '150', text: '$150' },
      { id: '200', text: '$200' },
      { id: '250', text: '$250' },
      { id: '300', text: '$300' },
      { id: '350', text: '$350' },
      { id: '400', text: '$400' },
      { id: '450', text: '$450' },
      { id: '500', text: '$500' },
      { id: '550', text: '$550' },
      { id: '600', text: '$600' },
      { id: '650', text: '$650' },
      { id: '700', text: '$700' },
      { id: '750', text: '$750' },
      { id: '800', text: '$800' },
      { id: '850', text: '$850' },
      { id: '900', text: '$900' },
      { id: '950', text: '$950' },
      { id: '1000', text: '$1,000' },
      { id: '1500', text: '$1,500' },
      { id: '2000', text: '$2,000' },
      { id: '3000', text: '$3,000' },
      { id: '4000', text: '$4,000' },
      { id: '5000', text: '$5,000' },
    ];

    this.listed_price_range = [
      { id: '0', text: 'Any' },
      { id: '50000', text: '$50,000' },
      { id: '75000', text: '$75,000' },
      { id: '100000', text: '$100,000' },
      { id: '125000', text: '$125,000' },
      { id: '150000', text: '$150,000' },
      { id: '175000', text: '$175,000' },
      { id: '200000', text: '$200,000' },
      { id: '225000', text: '$225,000' },
      { id: '250000', text: '$250,000' },
      { id: '275000', text: '$275,000' },
      { id: '300000', text: '$300,000' },
      { id: '325000', text: '$325,000' },
      { id: '350000', text: '$350,000' },
      { id: '375000', text: '$375,000' },
      { id: '400000', text: '$400,000' },
      { id: '425000', text: '$425,000' },
      { id: '450000', text: '$450,000' },
      { id: '475000', text: '$475,000' },
      { id: '500000', text: '$500,000' },
      { id: '550000', text: '$550,000' },
      { id: '600000', text: '$600,000' },
      { id: '650000', text: '$650,000' },
      { id: '700000', text: '$700,000' },
      { id: '750000', text: '$750,000' },
      { id: '800000', text: '$800,000' },
      { id: '850000', text: '$850,000' },
      { id: '900000', text: '$900,000' },
      { id: '950000', text: '$950,000' },
      { id: '1000000', text: '$1,000,000' },
      { id: '1100000', text: '$1,100,000' },
      { id: '1200000', text: '$1,200,000' },
      { id: '1300000', text: '$1,300,000' },
      { id: '1400000', text: '$1,400,000' },
      { id: '1500000', text: '$1,500,000' },
      { id: '1600000', text: '$1,600,000' },
      { id: '1700000', text: '$1,700,000' },
      { id: '1800000', text: '$1,800,000' },
      { id: '1900000', text: '$1,900,000' },
      { id: '2000000', text: '$2,000,000' },
      { id: '2250000', text: '$2,250,000' },
      { id: '2500000', text: '$2,500,000' },
      { id: '2750000', text: '$2,750,000' },
      { id: '3000000', text: '$3,000,000' },
      { id: '3500000', text: '$3,500,000' },
      { id: '4000000', text: '$4,000,000' },
      { id: '4500000', text: '$4,500,000' },
      { id: '5000000', text: '$5,000,000' },
      { id: '6000000', text: '$6,000,000' },
      { id: '7000000', text: '$7,000,000' },
      { id: '8000000', text: '$8,000,000' },
      { id: '9000000', text: '$9,000,000' },
      { id: '10000000', text: '$10,000,000' },
      { id: '12000000', text: '$12,000,000' },
      { id: '15000000', text: '$15,000,000' },
    ];

    this.s2PriceFromData = this.s2PriceToData = <any>this.price_sale;
    this.s2ListedPriceMinData = this.listed_price_range;
    this.s2ListedPriceMaxData = this.listed_price_range;

    this.s2PriceToData.unshift({ id: ' ', text: 'Any' });
    this.s2PriceToData.unshift({ id: '', text: 'Any' });

    this.s2PriceFromData = this.s2PriceToData;

    this.s2PriceToOptions = this.s2PriceFromOptions = {
      width: '100%',
      placeholder: 'Any',
      minimumResultsForSearch: -1,
    };

    this.s2ListedPriceMinOptions = {
      width: '100%',
      placeholder: 'Any',
      minimumResultsForSearch: -1,
    };
    this.s2ListedPriceMaxOptions = {
      width: '100%',
      placeholder: 'Any',
      minimumResultsForSearch: -1,
    };

    this.s2BedFromData = <any>[
      { id: '1', text: '1' },
      { id: '2', text: '2' },
      { id: '3', text: '3' },
      { id: '4', text: '4' },
      { id: '5', text: '5' },
    ];
    this.s2BedFromData.unshift({ id: ' ', text: 'Any' });
    this.s2BedFromData.unshift({ id: '', text: 'Any' });

    this.s2BedFromOptions = {
      width: '100%',
      placeholder: 'Any',
      minimumResultsForSearch: -1,
    };

    this.s2BedroomData = <any>[
      { id: '1', text: '1+' },
      { id: '2', text: '2+' },
      { id: '3', text: '3+' },
      { id: '4', text: '4+' },
      { id: '5', text: '5+' },
    ];
    this.s2BedroomData.unshift({ id: ' ', text: 'Any' });
    this.s2BedroomData.unshift({ id: '', text: 'Any' });

    this.s2BedroomOptions = {
      width: '100%',
      placeholder: 'Any',
      minimumResultsForSearch: -1,
    };

    this.s2BathFromData = this.s2CarFromData = this.s2BedToData = this.s2BathToData = this.s2CarToData = this.s2BedFromData;

    this.s2BathFromOptions = this.s2CarFromOptions = this.s2BedFromOptions;
    this.s2BathToOptions = this.s2CarToOptions = this.s2BedToOptions = this.s2BedFromOptions;

    let s2OrderByData = [];
    if (this.authUser.provider === 'vaultre') {
      s2OrderByData = <any>[
        { id: 'modifydateDesc', text: 'Sort by: Newest Modified' },
        { id: 'modifydateAsc', text: 'Sort by: Oldest Modified' },
        { id: 'inserteddateDesc', text: 'Sort by: Newest Inserted' },
        { id: 'inserteddateAsc', text: 'Sort by: Oldest Inserted' },
        { id: 'suburbAsc', text: 'Sort by: Suburb (A-Z)' },
        { id: 'suburbDesc', text: 'Sort by: Suburb (Z-A)' },
        { id: 'priceAsc', text: 'Sort by: Price (Low to High)' },
        { id: 'priceDesc', text: 'Sort by: Price (High to Low)' },
        { id: 'landareaAsc', text: 'Sort by: Land (Low to High)' },
        { id: 'landareaDesc', text: 'Sort by: Land (High to Low)' },
        { id: 'buildingareaAsc', text: 'Sort by: Building (Low to High)' },
        { id: 'buildingareaDesc', text: 'Sort by: Building (High to Low)' },
      ];
    } else {
      s2OrderByData = <any>[
        { id: 'modifydateDesc', text: 'Sort by: Newest Modified' },
        { id: 'modifydate', text: 'Sort by: Oldest Modified' },
        { id: 'inserteddateDesc', text: 'Sort by: Newest Inserted' },
        { id: 'inserteddateAsc', text: 'Sort by: Oldest Inserted' },
        { id: 'suburb', text: 'Sort by: Suburb (A-Z)' },
        { id: 'suburbDesc', text: 'Sort by: Suburb (Z-A)' },
        { id: 'landareaAsc', text: 'Sort by: Land (Low to High)' },
        { id: 'landareaDesc', text: 'Sort by: Land (High to Low)' },
        { id: 'buildingareaAsc', text: 'Sort by: Building (Low to High)' },
        { id: 'buildingareaDesc', text: 'Sort by: Building (High to Low)' },
      ];
    }

    this.s2OrderByData = s2OrderByData;

    this.s2OrderByOptions = {
      width: '100%',
      minimumResultsForSearch: -1,
    };

    this.s2VaultSuburbOptions = {
      width: '100%',
      multiple: true,
      closeOnSelect: false,
      placeholder: 'Search Suburb',
      ajax: {
        url: `${this.sharedService.appConfig.API_ENDPOINT}/vault/suburbs`,
        beforeSend: (request) => {
          request.setRequestHeader('Authorization', 'Bearer ' + $this.sharedService.authService.auth.__token);
          request.setRequestHeader('MD-Authorization', $this.sharedService.authService.auth.token);
        },
        dataType: 'json',
        delay: 250,
        cache: true,
        data: (params: any) => {
          return {
            query: params.term,
            groupSuburbs: $('#groupSuburbs').is(':checked') ? true : false,
            exactMatch: $('#exactMatch').is(':checked') ? true : false,
          };
        },
        processResults: (data: any) => {
          return {
            results: data.data,
          };
        },
      },
    };

    // for district
    this.s2VaultDistrictSuburbsOptions = {
      width: '100%',
      multiple: false,
      closeOnSelect: true,
      placeholder: 'Search District',
      ajax: {
        url: `${this.sharedService.appConfig.API_ENDPOINT}/vault/district/suburbs`,
        beforeSend: (request) => {
          request.setRequestHeader('Authorization', 'Bearer ' + $this.sharedService.authService.auth.__token);
          request.setRequestHeader('MD-Authorization', $this.sharedService.authService.auth.token);
        },
        dataType: 'json',
        delay: 250,
        cache: true,
        data: (params: any) => {
          return {
            query: params.term,
            matchSuburb: $('#matchSuburb').is(':checked') ? true : false,
            exactMatch: $('#exactMatchDistrict').is(':checked') ? true : false,
          };
        },
        processResults: (data: any) => {
          return {
            results: data.data,
          };
        },
      },
    }

    this.s2SearchOptions = {
      width: '100%',
      placeholder: 'Please select',
      minimumResultsForSearch: -1,
    };

    this.s2SearchData = <any>[
      {
        id: 'listed-in-market',
        text: 'Listed in the Market'
      },
      {
        id: 'sold-in-market',
        text: 'Sold in the Market'
      },
      {
        id: 'rentals-in-market',
        text: 'Rentals in the Market'
      },
      // {
      //   id: 'leased-in-market',
      //   text: 'Leased in the Market'
      // },
      {
        id: 'listed-in-agency',
        text: 'Listed in the Agency'
      }
    ];

    this.listedDates = <any>[
      {
        value: 'today-6m',
        text: '6 months'
      },
      {
        value: 'today-1y',
        text: '1 year'
      },
      {
        value: 'today-2y',
        text: '2 years'
      },
      {
        value: 'today-3y',
        text: '3 years'
      },
      {
        value: 'today-4y',
        text: '4 years'
      },
      {
        value: 'today-5y',
        text: '5 years'
      },
      {
        value: 'today-6y',
        text: '6 years'
      },
      {
        value: 'today-7y',
        text: '7 years'
      },
      {
        value: 'today-8y',
        text: '8 years'
      },
      {
        value: 'today-9y',
        text: '9 years'
      },
      {
        value: 'today-10y',
        text: '10 years'
      },
      {
        value: 'today-11y',
        text: '11 years'
      },
      {
        value: 'today-12y',
        text: '12 years'
      },
      {
        value: 'today-13y',
        text: '13 years'
      },
      {
        value: 'today-14y',
        text: '14 years'
      },
      {
        value: 'today-15y',
        text: '15 years'
      },
      {
        value: 'today-16y',
        text: '16 years'
      },
      {
        value: 'today-17y',
        text: '17 years'
      },
      {
        value: 'today-18y',
        text: '18 years'
      },
      {
        value: 'today-19y',
        text: '19 years'
      },
      {
        value: 'today-20y',
        text: '20 years'
      },
    ];

    this.myDesktopService.myDesktopsListingsChanged
    .takeUntil(this.destroy$)
    .subscribe((listings: any) => {
      $('#propertyList').find('.no-result').remove();

      if (typeof listings !== 'undefined' && listings &&
      typeof listings.data !== 'undefined' && listings.data && listings.data.length > 0) {
        this.subLoading = false;

        this.mdListings = listings.data;
        this.mdListingCount = listings.count;
        this.mdListingTotal = listings.total;
        this.vaultTotalPages = listings.totalPages;

        this.mdListingPages = [];

        if (this.mdListingTotal > 50 && this.sharedService.authService.getApp() === 'MyDesign3') {
          const pageNumber = Math.ceil(this.mdListingTotal / 50);

          for (let page = 1; page <= pageNumber; page++) {
            this.mdListingPages.push(page);
          }
        }

        if (this.sharedService.authService.getApp() === 'Designly' && this.authUser.provider === 'vaultre') {
          this.showDesignlyPreviousPage = false;
          this.showDesignlyNextPage = false;

          if (listings.prev === null) {
            this.currentVaultPage = 1;
          }

          if (listings.prev !== null) {
            this.designlyPreviousLink = listings.prev;
            this.showDesignlyPreviousPage = true;
          }

          if (listings.next !== null) {
            this.designlyNextLink = listings.next;
            this.showDesignlyNextPage = true;
          }
        }

        if (this.authUser.provider === 'idashboard') {
          if (listings.totalPages !== this.currentVaultPage) {
            this.showDesignlyNextPage = true;
          } else {
            this.showDesignlyNextPage = false;
          }

          if (this.currentVaultPage > 1) {
            this.showDesignlyPreviousPage = true;
          } else {
            this.showDesignlyPreviousPage = false;
          }
        }

        this.mdSuburbs = [];
        this.mdTypes = [];
        this.mdClassifications = [];
        this.mdStatuses = [];

        for (const listing of listings.data) {
          if (typeof listing.address_suburb !== 'undefined' && !this.mdSuburbs.includes(listing.address_suburb)) {
            this.mdSuburbs.push(listing.address_suburb);
          }
          this.mdSuburbs.sort();
          if (typeof listing.listing_type !== 'undefined' && !this.mdTypes.includes(listing.listing_type)) {
            this.mdTypes.push(listing.listing_type);
          }
        }

        this.isListingSearching = false;
        this.displayPrice = false;
      }

      if (typeof listings !== 'undefined' && listings &&
      typeof listings.data !== 'undefined' && listings.data && listings.data.length === 0) {
        this.subLoading = false;
        this.mdListings = listings.data;
        this.mdListingTotal = 0;
        this.mdListingCount = 0;
        this.mdListingCurrentPage = 1;
        this.mdListingPages = [];

        $('#propertyList').prepend($('<h2>').addClass('no-result').html('No result found!'));

        this.isListingSearching = false;
      }
    });

    this.myDesktopService.myDesktopCustomParams
    .takeUntil(this.destroy$)
    .subscribe((dataCustomParams: any) => {
      if (typeof dataCustomParams !== 'undefined' && dataCustomParams.length > 0) {
        if (this.activeInsertListing) {
          this.mdCustomParams = dataCustomParams;
          const _params = dataCustomParams.slice();
          _params.forEach((param) => {
            const _key = Object.keys(param)[0];
            const _values = param[_key];
            const _index = this.selectedListings.findIndex((sListings) => sListings.id === +_key);

            if (_index !== -1) {
              this.selectedListings[_index]['inspections'] = null;
              if (typeof _values.inspections !== 'undefined') {
                this.selectedListings[_index]['inspections'] = _values.inspections;
              }
              this.selectedListings[_index]['sales'] = null;
              if (typeof _values.sales !== 'undefined') {
                this.selectedListings[_index]['sales'] = _values.sales;
              }
            }
          });

          this.activateInsertListing = true;
          this.loadingInsertListing = false;
          this.subLoading = false;

          if (this.selectedListingIDs.length === 0) {
            this.activateInsertListing = false;
          }

          this.selectAllCount += 1;

          this.progressService.progress(this.selectAllCount / this.selectedListings.length);

          if (this.selectedListings.length === this.selectAllCount) {
            this.selectAllCount = 0;
            this.activeInsertListing = false;
            this.onSelectListings(true);
          }
        }
      }
    });

    this.mdListingsForm = new FormGroup({
      suburb: new FormControl(''),
      classification: new FormControl('residential'),
      propertytype: new FormControl(''),
      precincts: new FormControl(''),
      customRegions: new FormControl(''),
      listingtype: new FormControl(''),
      status: new FormControl('listing'),
      substatus: new FormControl(''),
      available: new FormControl(''),
      officeid: new FormControl(''),
      agentid: new FormControl(''),
      pricefrom: new FormControl(''),
      priceto: new FormControl(''),
      bedroom: new FormControl(''),
      bedfrom: new FormControl(''),
      bedto: new FormControl(''),
      bathfrom: new FormControl(''),
      bathto: new FormControl(''),
      carfrom: new FormControl(''),
      carto: new FormControl(''),
      local_search: new FormControl('0'),
      client_id: new FormControl(''),
      orderby: new FormControl('modifydateDesc'),
      multioffice: new FormControl(false),
      includeinternal: new FormControl(this.publicCheck),
      showsaleprice: new FormControl(false),
      internalmarketing: new FormControl(false),
      franchiselistings: new FormControl(false),
      basedon: new FormControl(''),
      extrafilters: new FormControl(''),
      openhomedays: new FormControl(''),
      soldfilter: new FormControl(''),
      sortdate: new FormControl(''),
      vaultsuburb: new FormControl(''),
      branches: new FormControl(''),
      periodtype: new FormControl(this.periodFilterType),
      period: new FormControl('any'),
      method: new FormControl(''),
      landareafrom: new FormControl(''),
      landareato: new FormControl(''),
      floorareafrom: new FormControl(''),
      floorareato: new FormControl(''),
      openhomebyappointment: new FormControl(''),
      property_id: new FormControl(''),
    });

    this.vaultService.onVaultGetListing
    .takeUntil(this.destroy$)
    .subscribe((response: any) => {
      if (typeof response !== 'undefined' && typeof response.status !== 'undefined') {
        if (this.activeInsertListing) {
          this.activateInsertListing = true;
          this.loadingInsertListing = false;
          this.subLoading = false;

          if (this.selectedListingIDs.length === 0) {
            this.activateInsertListing = false;
          }

          if (typeof response.data !== 'undefined' && typeof response.data.id !== 'undefined') {
            this.selectedListings = this.selectedListings.filter((listing, index) => {
              if (response.data.id === listing.id) {
                listing.url = response.data.url;
                listing.inspections = response.data.inspections;

                if (response.data.virtualtour !== '') {
                  listing.virtualtour = response.data.virtualtour;
                }

                if (response.data.offers_url !== '') {
                  listing.offers_url = response.data.offers_url;
                }

                if (response.data.saleprice !== undefined) {
                  if (response.data.saleprice === 'hidden') {
                    listing.saleprice = null;
                  } else {
                    listing.saleprice = response.data.saleprice;
                  }
                }

                if (response.data.salepricecurrent !== undefined) {
                  listing.salepricecurrent = response.data.salepricecurrent;
                }

                if (response.data.lifeId !== undefined) {
                  listing.lifeId = response.data.lifeId;
                }

                if (response.data.leaselifeId !== undefined) {
                  listing.leasedlifeId = response.data.leaselifeId;
                }

                if (response.data.saledate !== undefined) {
                  listing.saledate = response.data.saledate;
                }

                if (response.data.unconditional_date !== undefined) {
                  listing.unconditional_date = response.data.unconditional_date;
                }

                if (typeof response.data.customfields !== 'undefined') {
                  listing.customfields = response.data.customfields;
                }

                if (typeof response.data.owners !== 'undefined' && response.data.owners) {
                  listing.owners = response.data.owners;
                }

                if (typeof response.data.rooms !== 'undefined' && response.data.rooms) {
                  listing.rooms = response.data.rooms;
                }

                if (typeof response.data.highlights !== 'undefined' && response.data.highlights) {
                  listing.highlights = response.data.highlights;
                }

                if (typeof response.data.map_image !== 'undefined' && response.data.map_image) {
                  listing.map_image = response.data.map_image;
                }

                if (typeof response.data.custom_features !== 'undefined' && response.data.custom_features) {
                  listing.customfeatures = response.data.custom_features;
                }

                if (typeof response.data.files !== 'undefined' && response.data.files) {
                  listing.files = response.data.files;
                }

                if (typeof response.data.offers !== 'undefined' && response.data.offers) {
                  listing.offers = response.data.offers;
                }

                if (typeof response.data.advertising !== 'undefined' && response.data.advertising) {
                  listing.advertising = response.data.advertising;
                }

                if (typeof response.data.solicitor !== 'undefined' && response.data.solicitor) {
                  listing.solicitor = response.data.solicitor;
                }

                if (typeof response.data.offer_conditions !== 'undefined' && response.data.offer_conditions) {
                  listing.offer_conditions = response.data.offer_conditions;
                }

                if (typeof response.data.tenanted !== 'undefined' && response.data.tenanted) {
                  listing.tenanted = response.data.tenanted;
                }

                if (typeof response.data.tenancystop !== 'undefined' && response.data.tenancystop) {
                  listing.tenancystop = response.data.tenancystop;
                }

                if (typeof response.data.propertyConfig !== 'undefined' && response.data.propertyConfig) {
                  listing.propertyConfig = response.data.propertyConfig;
                }

                if (typeof response.data.leasehold !== 'undefined' && response.data.leasehold) {
                  listing.leasehold = response.data.leasehold;
                }

                if (typeof response.data.shared_ownership !== 'undefined' && response.data.shared_ownership) {
                  listing.shared_ownership = response.data.shared_ownership;
                }

                if (typeof response.data.geolocation !== 'undefined' && response.data.geolocation) {
                  listing.geolocation = response.data.geolocation;
                }

                if (typeof response.data.tenants !== 'undefined' && response.data.tenants) {
                  listing.tenants = response.data.tenants;
                }

                if (typeof response.data.hazards !== 'undefined' && response.data.hazards) {
                  listing.hazards = response.data.hazards;
                }

                if (typeof response.data.appraisal_min !== 'undefined' && response.data.appraisal_min) {
                  listing.appraisal_min = response.data.appraisal_min;
                }

                if (typeof response.data.appraisal_max !== 'undefined' && response.data.appraisal_max) {
                  listing.appraisal_max = response.data.appraisal_max;
                }

                return listing;
              }

              return listing;
            });
          }

          this.selectAllCount += 1;

          this.progressService.progress(this.selectAllCount / this.selectedListings.length);

          if (this.selectedListings.length === this.selectAllCount) {
            this.selectAllCount = 0;
            this.activeInsertListing = false;
            this.onSelectListings(true);
          }
        }
      }
    });

    this.domainService.onGetDomainListing
    .takeUntil(this.destroy$)
    .subscribe((response: any) => {
      if (typeof response !== 'undefined' && typeof response.status !== 'undefined') {
        if (this.activeInsertListing) {
          this.activateInsertListing = true;
          this.loadingInsertListing = false;
          this.subLoading = false;

          if (this.selectedListingIDs.length === 0) {
            this.activateInsertListing = false;
          }

          if (typeof response.data !== 'undefined' && typeof response.data.id !== 'undefined') {
            this.selectedListings = this.selectedListings.filter((listing, index) => {
              if (response.data.id === String(listing.id)) {
                listing.agent1 = listing.agent_primary = response.data.agents[0];
                listing.agent2 = response.data.agents[1];
                listing.description = response.data.description;
                listing.inspections = response.data.inspections;
                return listing;
              }

              return listing;
            });
          }

          this.selectAllCount += 1;

          this.progressService.progress(this.selectAllCount / this.selectedListings.length);

          if (this.selectedListings.length === this.selectAllCount) {
            this.selectAllCount = 0;
            this.activeInsertListing = false;
            this.onSelectListings(true);
          }
        }
      }
    });

    this.idashboardService.onGetListing
    .takeUntil(this.destroy$)
    .subscribe((response: any) => {
      if (typeof response !== 'undefined' && typeof response.status !== 'undefined') {
        if (this.activeInsertListing) {
          this.activateInsertListing = true;
          this.loadingInsertListing = false;
          this.subLoading = false;

          if (this.selectedListingIDs.length === 0) {
            this.activateInsertListing = false;
          }

          if (typeof response.data !== 'undefined' && typeof response.data.id !== 'undefined') {
            const listingIndex = this.selectedListings.findIndex((selectedListing) => {
              return String(selectedListing.id) === String(response.data.id);
            });
            this.selectedListings[listingIndex] = { ...this.selectedListings[listingIndex], ...response.data };
          }

          this.selectAllCount += 1;

          this.progressService.progress(this.selectAllCount / this.selectedListings.length);

          if (this.selectedListings.length === this.selectAllCount) {
            this.selectAllCount = 0;
            this.activeInsertListing = false;
            this.onSelectListings(true);
          }
        }
      }
    });

    this.pricefinderService.onGetSingleProperty
    .takeUntil(this.destroy$)
    .subscribe((response: any) => {
      if (typeof response !== 'undefined' && typeof response.status !== 'undefined') {
        if (this.activeInsertListing) {
          this.activateInsertListing = true;
          this.loadingInsertListing = false;
          this.subLoading = false;

          if (this.selectedListingIDs.length === 0) {
            this.activateInsertListing = false;
          }

          if (typeof response.data !== 'undefined' && typeof response.data.id !== 'undefined') {
            const listingIndex = this.selectedListings.findIndex((selectedListing) => {
              return String(selectedListing.id) === String(response.data.id);
            });
            this.selectedListings[listingIndex] = { ...this.selectedListings[listingIndex], ...response.data };
          }

          this.selectAllCount += 1;

          this.progressService.progress(this.selectAllCount / this.selectedListings.length);

          if (this.selectedListings.length === this.selectAllCount) {
            this.selectAllCount = 0;
            this.activeInsertListing = false;
            this.onSelectListings(true);
          }
        }
      }
    });

    this.vaultService.onGetSingleProperty
    .takeUntil(this.destroy$)
    .subscribe((response: any) => {
      if (response) {
        this.subLoading = false;
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.selectedListings = [response.data];
          this.searchProperties = [];
          this.searchPropertyLabel = '';
          this.onSelectListings(true);
        } else {
          swal({
            title: 'Error',
            icon: 'error'
          });
        }
      }
    });

    this.vaultService.onVaultGetBranches
    .takeUntil(this.destroy$)
    .subscribe((response: any) => {
      if (response) {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.branches = response.data;
          if (Array.isArray(this.branches)) {
            this.s2BranchesData = <any>this.branches.map((branch) => {
              return {
                id: branch.id,
                text: branch.name,
              };
            });
          }
        }
      }
    });

    this.vaultService.onVaultGetPropertyTypes
    .takeUntil(this.destroy$)
    .subscribe((response: any) => {
      if (response) {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.propertyTypes = response.data;
          this.s2TypeData = <any>this.propertyTypes.map((propertytype) => {
            return {
              id: propertytype.id,
              text: propertytype.name,
            };
          });
        }
      }
    });
    this.vaultService.onVaultGetPrecincts
    .takeUntil(this.destroy$)
    .subscribe((response: any) => {
      if (response) {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.precincts = response.data;
          if (Array.isArray(this.precincts)) {
            this.s2PrecinctsData = <any>this.precincts.map((precincts) => {
              return {
                id: precincts.id,
                text: precincts.name,
              };
            });
          }
        }
      }
    });
    this.vaultService.onVaultGetAccountRegions
    .takeUntil(this.destroy$)
    .subscribe((response: any) => {
      if (response) {
        if (typeof response !== 'undefined' && response.status === 'success') {
          this.customRegions = response.data;
          if (Array.isArray(this.customRegions)) {
            this.s2AccountRegionsData = <any>this.customRegions.map((regions) => {
              return {
                id: regions.id,
                text: regions.name,
              };
            });
          }
        }
      }
    });
    this.propertySearchService.onSetCheckers
    .takeUntil(this.destroy$)
    .subscribe((response: any) => {
      if (response) {
        if (response.checkCustomFields) {
          this.checkCustomFields = 1;
        } else {
          this.checkCustomFields = 0;
        }

        if (response.checkInspections) {
          this.checkInspections = 1;
        } else {
          this.checkInspections = 0;
        }

        if (response.checkMapImage) {
          this.checkMapImage = 1;
        } else {
          this.checkMapImage = 0;
        }

        if (response.checkOwners) {
          this.checkOwners = 1;
        } else {
          this.checkOwners = 0;
        }

        if (response.checkTenants) {
          this.checkTenants = 1;
        } else {
          this.checkTenants = 0;
        }

        if (response.checkRooms) {
          this.checkRooms = 1;
        } else {
          this.checkRooms = 0;
        }

        if (response.checkSalePrice) {
          this.checkSalePrice = 1;
        } else {
          this.checkSalePrice = 0;
        }

        if (response.checkFilingCabinet) {
          this.checkFilingCabinet = 1;
        } else {
          this.checkFilingCabinet = 0;
        }

        if (response.checkFeatures) {
          this.checkFeatures = 1;
        } else {
          this.checkFeatures = 0;
        }

        if (response.checkPropertyOffers) {
          this.checkPropertyOffers = 1;
        } else {
          this.checkPropertyOffers = 0;
        }

        if (response.checkPropertyAdvertising) {
          this.checkPropertyAdvertising = 1;
        } else {
          this.checkPropertyAdvertising = 0;
        }

        if (response.checkPropertySolicitor) {
          this.checkPropertySolicitor = 1;
        } else {
          this.checkPropertySolicitor = 0;
        }

        if (response.checkPropertyOfferConditions) {
          this.checkPropertyOfferConditions = 1;
        } else {
          this.checkPropertyOfferConditions = 0;
        }
      }
    });

    this.priceFinderSearchInput
    .takeUntil(this.destroy$)
    .debounceTime(500)
    .subscribe(
      (query: string) => {
        if (query) {
          this.priceFinderAddressSearchLoading = true;
          this.pricefinderService.httpSearchProperties({
            query
          });
        }
      }
    );

    this.priceFinderSearchSuburbInput
    .takeUntil(this.destroy$)
    .debounceTime(500)
    .subscribe(
      (query: string) => {
        if (query) {
          this.priceFinderAddressSearchLoading = true;
          this.searchSuburbs(query);
        }
      }
    );

    this.pricefinderService.onGetProperties
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.priceFinderAddressSearchLoading = false;

            if (response.status === 'success') {
              this.priceFinderProperties = response.data.map(property => {
                return {
                  value: property.property.id,
                  label: property.display
                };
              });
            }
          }
        }
      }
    );

    this.pricefinderService.onGetPropertyListings
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            if (response.status === 'success') {
              if (this.s2SearchValue === 'listed-in-market' || 
                  this.s2SearchValue === 'sold-in-market' ||
                  this.s2SearchValue === 'rentals-in-market' ||
                  this.s2SearchValue === 'leased-in-market') {
                this.displayPrice = true;
              } else {
                this.displayPrice = false;
              }
              this.subLoading = false;
              this.mdListings = response.data;
            }
          }
        }
      }
    );

    this.propertySearchService.onOpenPropSearch
    .takeUntil(this.destroy$)
    .subscribe(
      (response: boolean) => {
        if (response) {
          if (this.propertySearchService.proInsertType === 'property') {
            this.priceFinderEnabled = false;
            this.priceFinderSearch = false;

            if (this.s2SearchValue !== 'listed-in-agency') {
              this.showDesignlyPreviousPage = false;
              this.showDesignlyNextPage = false;
              this.vaultTotalPages = 1;

              this.mdListings = [];
              this.selectedListings = [];
              this.selectedListingIDs = [];
            }
            this.s2SearchValue = 'listed-in-agency';
          } else {
            if (
                (this.authUser.client.pricefinder_username && this.authUser.client.pricefinder_password) 
                || 
                (this.authUser.client.group.pricefinder_username)
            ) {
              this.priceFinderEnabled = true;
            } else {
              this.s2SearchValue = 'listed-in-agency';
              this.priceFinderEnabled = false;
              this.priceFinderSearch = false;
            }
          }

          this.changeDetector.detectChanges();
        }
      }
    );

    this.priceFinderSearchForm = new FormGroup({
      radius: new FormControl('500', Validators.required),
      minimum_beds: new FormControl('0', Validators.required),
      minimum_baths: new FormControl('0', Validators.required),
      minimum_car_parks: new FormControl('0', Validators.required),
      property_type: new FormControl(''),
      listed_date_from: new FormControl('today-3m'),
      listed_price_min: new FormControl(''),
      listed_price_max: new FormControl('')
    });

    this.searchPropertyInput
    .pipe(takeUntil(this.destroy$), debounceTime(500))
    .subscribe(
      (term: string) => {
        if (term) {
          this.searchPropertyLoading = true;
          this.searchPropertyLabel = 'displayAddress';
          this.vaultService.httpSearchAddress(term);
        }
      }
    );

    this.vaultService.onSearchAddress
    .takeUntil(this.destroy$)
    .subscribe(
      (response: any) => {
        if (response) {
          if (typeof response.status !== 'undefined') {
            this.searchPropertyLoading = false;
            if (response.status === 'success') {
              this.searchProperties = response.data;
            }
            if (response.status === 'error') {
              swal({
                title: 'VaultAPI Issue',
                text: 'Try again later',
                type: 'error'
              });
            }
          }
        }
      }
    );

    this.listingAgentsUpdate = [];
  }

  ngOnChanges() {
    const printableCategories = [4, 5, 8, 10];

    if (!this.isUK && (this.client && this.client.group_id === 25)) {
      if (typeof this.template !== 'undefined' && this.template && printableCategories.includes(this.template.category.ID)) {
        this.s2StatusValue = 'sold';
      }
    }

    const emailCategories = [1, 2, 3, 7, 11, 13];

    if (typeof this.template !== 'undefined' && this.template && emailCategories.includes(this.template.category.ID)) {
      this.publicCheck = true;
      this.mdListingsForm.patchValue({
        includeinternal: true,
      });
    }

  }

  listingSearchChange(page = 1, property) {
    if (this.mdListingsForm.get('franchiselistings').value) {
      if (typeof this.s2VaultSuburbValue !== 'undefined' && !this.s2VaultSuburbValue.length) {
        swal('Suburb is required.', '', 'error');
        return false;
      }
    }
    if (this.isListingSearching) {
      return true;
    }
    this.isListingSearching = true;

    this.mdListingsForm.patchValue({
      property_id: ''
    });

    if (property) {
      if (property.id !== null && property.id !== 'undefined') {
        let propertyStatus = '';
        let listingType = '';

        if (property.leaseLifeId !== null) {
          propertyStatus = property.leaseLife.status;
          listingType = 'rent';
        }

        if (property.saleLifeId !== null) {
          propertyStatus = property.saleLife.status;
          listingType = 'sale';
        }

        this.mdListingsForm.patchValue({
          property_id: property.id,
          classification: property.class.internalName,
          status: propertyStatus,
          listingtype: listingType,
        });
        $('#propertyAddress').modal('hide');
      }
    }

    this.selectedListings = [];

    this.subLoading = true;
    const formValues = this.mdListingsForm.value;

    this.mdListingCount = 0;
    this.mdListingTotal = 0;
    this.mdListingPages = [];
    this.mdListingCurrentPage = 1;

    if (page > 0) {
      formValues['page'] = page;
      this.mdListingCurrentPage = page;
    }

    if (this.isMultipleOffice && formValues['officeid']) {
      const officeData = this.authUser.multiple.slice(0);
      const officeIndex = officeData.findIndex((sOffice) => sOffice.groupid === +formValues['officeid']);

      if (officeIndex >= 0 && typeof officeData[officeIndex] !== 'undefined') {
        formValues['office_token'] = officeData[officeIndex].token;
      }
    }

    if (typeof this.template !== 'undefined') {
      formValues['template_type'] = this.template.category.slug;
    }

    formValues['pagesize'] = this.s2postPerPageValue;

    this.selectedListingIDs = [];
    this.listingSearchValues = formValues;

    this.myDesktopService.httpGetMDListings(formValues, this.authUser.provider);
  }

  selectProperty(listing, elRef: HTMLElement) {
    const isIndex = this.selectedListings.findIndex((sListing) => sListing.id === listing.id);
    const indexOfListingID = this.selectedListingIDs.indexOf(listing.id);

    if (this.selectedListings.length >= this.maxLimit && isIndex === -1) {
      swal('Exceed Max Limit Error', `Only ${this.maxLimit} property/ies can be added`, 'error');
      return false;
    }

    if ((typeof this.template !== 'undefined' && (this.template.category_id === this.templateCategoryId.BROCHURE || this.template.category_id === this.templateCategoryId.DL_CARD)) ||
    this.propertySearchService.proInsertType === 'property' || this.propertySearchService.propertyInsertSource === 'button') {
      this.element_listing_limit = 1;

      this.selectedListingIDs.splice(indexOfListingID, 1);
      this.selectedListings.splice(isIndex, 1);
      elRef.classList.remove('selected');
      $(elRef).find( 'input' ).prop('checked', false);
    }

    if (this.isReplace && this.selectedListings.length >= 1) {
      if (isIndex === -1) {
        swal('Listing limit of 1 reached.');
        return false;
      }
    }

    if (typeof this.template !== 'undefined' && this.template.category_id !== 1 && this.template.category_id !== 5 &&
      this.element_listing_limit !== 0 && this.selectedListings.length >= this.element_listing_limit) {
      if (isIndex === -1) {
        swal('Listing limit of ' + this.element_listing_limit + ' reached.');
        return false;
      }
    }

    if (isIndex === -1) {
      this.selectedListingIDs.push(listing.id);
      this.selectedListings.push(listing);
      elRef.classList.add('selected');
      $(elRef).find('input').prop('checked', true);
    } else {
      this.selectedListingIDs.splice(indexOfListingID, 1);

      this.selectedListings.splice(isIndex, 1);
      elRef.classList.remove('selected');
      $(elRef).find('input').prop('checked', false);
    }
  }

  getCustomParams(property_id: number, sync_data = 0) {
    this.myDesktopService.httpGetMDListingByCustomParams({
      ID: property_id,
      sync_data,
    });
  }

  onSelectListings(bypassSelect = false) {
    if (this.selectedListings.length === 0) {
      return false;
    }

    if (!bypassSelect) {
      this.activeInsertListing = true;
      this.progressService.initProgress({
        type: 'listing',
      });

      this.propertySearchService.setCheckers({
        checkFilingCabinet: 1,
        checkInspections: 1,
        checkCustomFields: 1,
        checkOwners: 1,
        checkTenants: 1,
        checkSalePrice: 1,
        checkPropertySolicitor: 1,
        checkRooms: this.checkRooms,
        checkFeatures: this.checkFeatures
      });

      this.propertySearchService.onOpenPropSearch.next(true);

      if (this.priceFinderSearchedPropertyId && this.priceFinderSearch) {
        this.selectedListings.forEach((element) => {
          this.pricefinderService.httpSingleProperty(element.id, this.listingSearchValues);
        });
      } else {
        if (this.authUser.provider === 'mydesktop') {
          this.selectedListings.forEach((element) => {
            this.getCustomParams(element.id);
          });
        }

        if (this.authUser.provider === 'vaultre') {
            if (this.checkOwners) {
              this.listingSearchValues['checkOwners'] = 1;
            } else {
              this.listingSearchValues['checkOwners'] = 0;
            }

            if (this.checkTenants) {
              this.listingSearchValues['checkTenants'] = 1;
            } else {
              this.listingSearchValues['checkTenants'] = 0;
            }

            if (this.checkSalePrice) {
              this.listingSearchValues['checkSalePrice'] = 1;
            } else {
              this.listingSearchValues['checkSalePrice'] = 0;
            }

            if (this.checkInspections) {
              this.listingSearchValues['checkInspections'] = 1;
            } else {
              this.listingSearchValues['checkInspections'] = 0;
            }

            if (this.checkCustomFields) {
              this.listingSearchValues['checkCustomFields'] = 1;
            } else {
              this.listingSearchValues['checkCustomFields'] = 0;
            }

            if (this.checkRooms) {
              this.listingSearchValues['checkRooms'] = 1;
            } else {
              this.listingSearchValues['checkRooms'] = 0;
            }

            if (this.checkMapImage) {
              this.listingSearchValues['checkMapImage'] = 1;
            } else {
              this.listingSearchValues['checkMapImage'] = 0;
            }

            if (this.checkFilingCabinet) {
              this.listingSearchValues['checkFilingCabinet'] = 1;
            } else {
              this.listingSearchValues['checkFilingCabinet'] = 0;
            }

            if (this.checkFeatures) {
              this.listingSearchValues['checkFeatures'] = 1;
            } else {
              this.listingSearchValues['checkFeatures'] = 0;
            }

            if (this.checkPropertyOffers) {
              this.listingSearchValues['checkPropertyOffers'] = 1;
            } else {
              this.listingSearchValues['checkPropertyOffers'] = 0;
            }

            if (this.checkPropertyAdvertising) {
              this.listingSearchValues['checkPropertyAdvertising'] = 1;
            } else {
              this.listingSearchValues['checkPropertyAdvertising'] = 0;
            }

            if (this.checkPropertySolicitor) {
              this.listingSearchValues['checkPropertySolicitor'] = 1;
            } else {
              this.listingSearchValues['checkPropertySolicitor'] = 0;
            }

            if (this.checkPropertyOfferConditions) {
              this.listingSearchValues['checkPropertyOfferConditions'] = 1;
            } else {
              this.listingSearchValues['checkPropertyOfferConditions'] = 0;
            }

            let callDelay = 1000;
            this.selectedListings.forEach((element) => {
              setTimeout(() => {
                if (element.url) {
                  this.listingSearchValues['url'] = element.url;
                } else {
                  this.listingSearchValues['url'] = "";
                }
                this.vaultService.httpGetListing(element.id, this.listingSearchValues);
              }, callDelay);

              callDelay += 1000;
            });
        }

        if (this.authUser.provider === 'domain') {
          this.selectedListings.forEach((element) => {
            this.domainService.httpGetListing(element.id);
          });
        }

        if (this.authUser.provider === 'idashboard') {
          this.selectedListings.forEach((element) => {
            this.idashboardService.httpGetListing(element.id);
          });
        }
      }

      return false;
    }

    this.selectedListings.map(listing => {
      listing.query_params = this.listingSearchValues;
    });

    this.propertySearchService.s2StatusValue = this.s2StatusValue;
    this.propertySearchService.insertListings(this.selectedListings);
    this.selectedListings = [];
    this.selectedListingIDs = [];
  }

  replace(text: string, search: string, replace: string) {
    return text.replace(search, replace);
  }

  clearListingResult() {
    this.s2SuburbValue = '';
    this.s2ClassificationValue = '';
    this.s2TypeValue = '';
    this.s2OfficeIdValue = '';
    this.s2AgentIdValue = '';
    this.s2BranchesValue = '';
    this.s2PriceFromValue = '';
    this.s2BedroomValue = '';
    this.s2BedFromValue = '';
    this.s2BedToValue = '';
    this.s2BathFromValue = '';
    this.s2BathToValue = '';
    this.s2CarFromValue = '';
    this.s2CarToValue = '';

    this.mdListings = [];
    this.mdListingTotal = 0;
    this.mdListingCount = 0;
    this.mdListingCurrentPage = 1;
    this.mdListingPages = [];
    this.landareaFromValue = '';
    this.landareaToValue = '';
    this.floorareaFromValue = '';
    this.floorareaToValue = '';
  }

  fillProposalPropertyAddress() {  
    if (!this.isProposalPropertyAddressFilled && this.proposalProperty) {
      this.priceFinderAddressSearchLoading = true;

      let query = '';

      switch (this.s2SearchByValue) {
        case "search-by-address":
          query = this.proposalProperty.unit_number ? 'Unit ' + this.proposalProperty.unit_number + ' ' : '';
          query += `${this.proposalProperty.street_number} ${this.proposalProperty.street} ${this.proposalProperty.suburb}`
          break;
        case "search-by-suburb":
          query =  `${this.proposalProperty.suburb}, ${this.proposalProperty.state} ${this.proposalProperty.postcode}`;
          break;
      }
      
      setTimeout(() => {        
        this.priceFinderSearchedPropertyLabel = query;
      }, 100);

      this.propertySearchAutoFills.splice(this.propertySearchAutoFills.indexOf(this.s2SearchByValue), 1);

      const callback = () => {
        if (this.priceFinderProperties.length || this.priceFinderSuburbs.length) {       
          
          if (this.s2SearchByValue === 'search-by-address') {
            this.onPriceFinderSelectProperty({
              value: this.priceFinderProperties[0].value,
              label: this.priceFinderProperties[0].label
            })
          } 
          if (this.s2SearchByValue === 'search-by-suburb') {
            this.onPriceFinderSelectProperty({
              value: this.priceFinderSuburbs[0].value,
              label: this.priceFinderSuburbs[0].label
            })
          } 
          
        }
      }
      

      if (this.s2SearchByValue === 'search-by-address') {
        this.searchAddresses(query, callback);
      }

      if (this.s2SearchByValue === 'search-by-suburb') {
        this.searchSuburbs(query, callback);
      }

       
    }  
  }

  s2SearchByValueChanged(value: string) {
    this.s2SearchByValue = value;

    this.priceFinderSearchedPropertyLabel = null;
    this.priceFinderSearchedPropertyId = null;
    this.priceFinderProperties = [];
    this.priceFinderSuburbs = [];   

    this.fillProposalPropertyAddress();
    
  }

  s2ValueChanged(field: string, value: any = '') {
    
    if (!this.priceFinderAddressSearchLoading &&
      ['listed-in-market', 'sold-in-market', 'rentals-in-market', 'leased-in-market'].includes(value)) {  
      this.fillProposalPropertyAddress();
    }


    switch (field) {
      case 'classification':
        this.s2StatusDisabled = false;
        this.s2MethodDisabled = false;
        this.s2OrderByDisabled = false;
        this.s2ClassificationValue = value.trim();
        this.s2SelectingCategory = true;
        this.currentVaultPage = 1;

        if (this.sharedService.authService.getApp() === 'Designly') {
          let s2StatusData = [];

          this.s2SubStatusValue = '';
          this.s2SubStatusDisabled = true;
          this.s2AvailableDisabled = false;
          switch (this.s2ClassificationValue) {
            case 'residential':

            if (this.isUK) {
              s2StatusData = [
                { id: 'all', text: 'All Types' },
                { id: 'listing', text: 'Listing' },
                { id: 'rental', text: 'Management/Listing' },
                { id: 'auction', text: 'Auction' },
                { id: 'sold', text: 'Sold' },
                { id: 'leased', text: 'Leased' },
                { id: 'listingOrConditional', text: 'Listing / Conditional' },
                { id: 'conditional', text: 'Under Offer' },
                { id: 'prospect', text: 'Prospect/Not currently Listed' },
                { id: 'appraisal', text: 'Valuation' },
              ];
            } else {
              s2StatusData = [
                { id: 'all', text: 'All Types' },
                { id: 'listing', text: 'Listing' },
                { id: 'rental', text: 'Lease' },
                { id: 'auction', text: 'Auction' },
                { id: 'sold', text: 'Sold' },
                { id: 'leased', text: 'Leased' },
                { id: 'listingOrConditional', text: 'Listing / Conditional' },
                { id: 'conditional', text: 'Under Offer / Conditional' },
                { id: 'prospect', text: 'Prospect' },
                { id: 'appraisal', text: 'Appraisal' },
              ];
            }

              break;
            case 'commercial':
              if (this.isUK) {
                s2StatusData = [
                  { id: 'all', text: 'All Types' },
                  { id: 'listing', text: 'Listing' },
                  { id: 'lease', text: 'Management/Listing' },
                  { id: 'auction', text: 'Auction' },
                  { id: 'salelease', text: 'For Sale & Lease' },
                  { id: 'sold', text: 'Sold' },
                  { id: 'leased', text: 'Leased' },
                  { id: 'listingOrConditional', text: 'Listing / Conditional' },
                  { id: 'conditional', text: 'Under Offer' },
                  { id: 'prospect', text: 'Prospect/Not currently Listed' },
                  { id: 'appraisal', text: 'Valuation' },
                ];
              } else {
                s2StatusData = [
                  { id: 'all', text: 'All Types' },
                  { id: 'listing', text: 'Listing' },
                  { id: 'lease', text: 'Lease' },
                  { id: 'auction', text: 'Auction' },
                  { id: 'salelease', text: 'For Sale & Lease' },
                  { id: 'sold', text: 'Sold' },
                  { id: 'leased', text: 'Leased' },
                  { id: 'listingOrConditional', text: 'Listing / Conditional' },
                  { id: 'conditional', text: 'Under Offer / Conditional' },
                  { id: 'prospect', text: 'Prospect' },
                  { id: 'appraisal', text: 'Appraisal' },
                ];
              }

              this.s2ExtraFiltersValue = '';
              break;
            case 'business':
              if (this.isUK) {
                s2StatusData = [
                  { id: 'all', text: 'All Types' },
                  { id: 'listing', text: 'Listing' },
                  { id: 'sold', text: 'Sold' },
                  { id: 'leased', text: 'Leased' },
                  { id: 'listingOrConditional', text: 'Listing / Conditional' },
                  { id: 'conditional', text: 'Under Offer' },
                  { id: 'prospect', text: 'Prospect/Not currently Listed' },
                  { id: 'appraisal', text: 'Valuation' },
                ];
              } else {
                s2StatusData = [
                  { id: 'all', text: 'All Types' },
                  { id: 'listing', text: 'Listing' },
                  { id: 'sold', text: 'Sold' },
                  { id: 'leased', text: 'Leased' },
                  { id: 'listingOrConditional', text: 'Listing / Conditional' },
                  { id: 'conditional', text: 'Under Offer / Conditional' },
                  { id: 'prospect', text: 'Prospect' },
                  { id: 'appraisal', text: 'Appraisal' },
                ];
              }

              this.s2ExtraFiltersValue = '';
              break;
            case 'rural':
              if (this.isUK) {
                s2StatusData = [
                  { id: 'all', text: 'All Types' },
                  { id: 'listing', text: 'Listing' },
                  { id: 'auction', text: 'Auction' },
                  { id: 'sold', text: 'Sold' },
                  { id: 'leased', text: 'Leased' },
                  { id: 'listingOrConditional', text: 'Listing / Conditional' },
                  { id: 'conditional', text: 'Under Offer' },
                  { id: 'prospect', text: 'Prospect/Not currently Listed' },
                  { id: 'appraisal', text: 'Valuation' },
                ];
              } else {
                s2StatusData = [
                  { id: 'all', text: 'All Types' },
                  { id: 'listing', text: 'Listing' },
                  { id: 'auction', text: 'Auction' },
                  { id: 'sold', text: 'Sold' },
                  { id: 'leased', text: 'Leased' },
                  { id: 'listingOrConditional', text: 'Listing / Conditional' },
                  { id: 'conditional', text: 'Under Offer / Conditional' },
                  { id: 'prospect', text: 'Prospect' },
                  { id: 'appraisal', text: 'Appraisal' },
                ];
              }

              this.s2ExtraFiltersValue = '';
              break;
            case 'land':
              if (this.isUK) {
                s2StatusData = [
                  { id: 'all', text: 'All Types' },
                  { id: 'listing', text: 'Listing' },
                  { id: 'sold', text: 'Sold' },
                  { id: 'prospect', text: 'Prospect/Not currently Listed' },
                  { id: 'listingOrConditional', text: 'Listing / Conditional' },
                  { id: 'conditional', text: 'Under Offer' },
                  { id: 'appraisal', text: 'Valuation' },
                ];
              } else {
                s2StatusData = [
                  { id: 'all', text: 'All Types' },
                  { id: 'listing', text: 'Listing' },
                  { id: 'sold', text: 'Sold' },
                  { id: 'prospect', text: 'Prospect' },
                  { id: 'listingOrConditional', text: 'Listing / Conditional' },
                  { id: 'conditional', text: 'Under Offer / Conditional' },
                  { id: 'appraisal', text: 'Appraisal' },
                ];
              }

              this.s2ExtraFiltersValue = '';
              break;
            case 'holidayRental':
              if (this.isUK) {
                s2StatusData = [
                  { id: 'all', text: 'All Types' },
                  { id: 'lease', text: 'Management/Listing' },
                  { id: 'leased', text: 'Leased' },
                  { id: 'prospect', text: 'Prospect/Not currently Listed' },
                  { id: 'appraisal', text: 'Valuation' },
                  { id: 'management', text: 'Management' },
                  { id: 'conditional', text: 'Under Offer' },
                ];
              } else {
                s2StatusData = [
                  { id: 'all', text: 'All Types' },
                  { id: 'lease', text: 'Lease' },
                  { id: 'leased', text: 'Leased' },
                  { id: 'prospect', text: 'Prospect' },
                  { id: 'appraisal', text: 'Appraisal' },
                  { id: 'management', text: 'Management' },
                  { id: 'conditional', text: 'Under Offer / Conditional' },
                ];
              }

              this.s2ExtraFiltersValue = '';
              break;
            case 'livestock':
                if (this.isUK) {
                  s2StatusData = [
                    { id: 'all', text: 'All Types' },
                    { id: 'listing', text: 'Listing' },
                    { id: 'lease', text: 'Management/Listing' },
                    { id: 'sold', text: 'Sold' },
                    { id: 'prospect', text: 'Prospect/Not currently Listed' },
                    { id: 'appraisal', text: 'Valuation' },
                    { id: 'conditional', text: 'Under Offer' },
                  ];
                } else {
                  s2StatusData = [
                    { id: 'all', text: 'All Types' },
                    { id: 'listing', text: 'Listing' },
                    { id: 'lease', text: 'Lease' },
                    { id: 'sold', text: 'Sold' },
                    { id: 'prospect', text: 'Prospect' },
                    { id: 'appraisal', text: 'Appraisal' },
                    { id: 'conditional', text: 'Under Offer / Conditional' },
                  ];
                }
  
                this.s2ExtraFiltersValue = '';
                break;
            case 'clearingSales':
              if (this.isUK) {
                s2StatusData = [
                  { id: 'all', text: 'All Types' },
                  { id: 'listing', text: 'Listing' },
                  { id: 'lease', text: 'Management/Listing' },
                  { id: 'sold', text: 'Sold' },
                  { id: 'prospect', text: 'Prospect/Not currently Listed' },
                  { id: 'appraisal', text: 'Valuation' },
                  { id: 'conditional', text: 'Under Offer' },
                ];
              } else {
                s2StatusData = [
                  { id: 'all', text: 'All Types' },
                  { id: 'listing', text: 'Listing' },
                  { id: 'lease', text: 'Lease' },
                  { id: 'sold', text: 'Sold' },
                  { id: 'prospect', text: 'Prospect' },
                  { id: 'appraisal', text: 'Appraisal' },
                  { id: 'conditional', text: 'Under Offer / Conditional' },
                ];
              }

              this.s2ExtraFiltersValue = '';
              break;
            default:
              if (this.isUK) {
                s2StatusData = [
                  { id: 'all', text: 'All Types' },
                  { id: 'listing', text: 'Listing' },
                  { id: 'auction', text: 'Auction' },
                  { id: 'sold', text: 'Sold' },
                  { id: 'leased', text: 'Leased' },
                  { id: 'prospect', text: 'Prospect/Not currently Listed' },
                  { id: 'conditional', text: 'Under Offer' },
                ];
              } else {
                s2StatusData = [
                  { id: 'all', text: 'All Types' },
                  { id: 'listing', text: 'Listing' },
                  { id: 'auction', text: 'Auction' },
                  { id: 'sold', text: 'Sold' },
                  { id: 'leased', text: 'Leased' },
                  { id: 'prospect', text: 'Prospect' },
                  { id: 'conditional', text: 'Under Offer / Conditional' },
                ];
              }

              this.s2ExtraFiltersValue = '';
              break;
          }

          if (this.authUser.provider === 'domain') {
            s2StatusData = [
              { id: '', text: 'Select a Type' },
              { id: 'Sale', text: 'Sales' },
              { id: 'Rent', text: 'Rental' },
              { id: 'Share', text: 'Share' },
              { id: 'Sold', text: 'Sold' },
              { id: 'NewHomes', text: 'New Homes' },
            ];
          }

          if (this.authUser.provider === 'idashboard') {
            s2StatusData = [
              { id: '', text: 'Select a Status' },
              { id: 'any', text: 'Any' },
              { id: 'appraisal', text: 'Appraisal' },
              { id: 'listing', text: 'Listing' },
              { id: 'application', text: 'Application' },
              { id: 'contract', text: 'Contract' },
              { id: 'sold', text: 'Sold' },
              { id: 'settled', text: 'Settled' },
              { id: 'leased', text: 'Leased' },
              { id: 'historical', text: 'Historical' },
              { id: 'withdrawn', text: 'Withdrawn' },
              { id: 'rejected', text: 'Rejected' },
              { id: 'lost', text: 'Lost' },
            ];
          }

          if (this.authUser.provider === 'vaultre') {
            s2StatusData.forEach((element, index) => {
              if (element.id === 'all' || element.id === 'pricechange' || element.id === 'salelease') {
                s2StatusData.splice(index, 1);
              }
            });
            this.s2StatusData = s2StatusData;
          } else {
            this.s2StatusData = s2StatusData;
          }

          this.s2SubStatusValue = '';
          this.s2SoldFilterValue = '';
        }
        break;
      case 'status':
        const statusValueTrimmed = value.trim();

        if (this.s2StatusValue !== statusValueTrimmed) {
          this.s2VaultSuburbValue = undefined;
        }

        this.s2StatusValue = statusValueTrimmed;

        if (this.sharedService.authService.getApp() === 'MyDesign3') {
          this.s2SelectingCategory = false;

          if (this.s2StatusValue === 'sold') {
            this.s2BasedOnDisabled = false;
            this.s2OrderByDisabled = false;
            this.s2BasedOnValue = 'unconditional';
          } else {
            this.s2BasedOnDisabled = true;
            this.mdListingsForm.patchValue({
              basedon: '',
            });
            this.s2BasedOnValue = '';
          }
        }

        if (this.sharedService.authService.getApp() === 'Designly' && this.sharedService.appConfig.SKIN !== 'idashboard') {
          this.s2SubStatusValue = 'management';
          this.s2SubStatusDisabled = true;
          this.s2AvailableDisabled = true;

          if (!this.s2SelectingCategory) {
            this.s2StatusValue = statusValueTrimmed;
          }

          if (this.s2StatusValue !== 'listing' && this.s2StatusValue !== 'rental') {
            this.s2ExtraFiltersValue = '';
          }

          if (this.s2StatusValue === 'pricechange' || this.s2StatusValue === 'appraisal' ||
          this.s2StatusValue === 'listing' || this.s2StatusValue === 'rental' ||
          this.s2StatusValue === 'conditional' || this.s2StatusValue === 'auction') {
            this.filterPeriodType = 'Listing Date';
          } else {
            this.filterPeriodType = 'n/a';
          }

          if (this.s2StatusValue === 'pricechange') {
            this.filterPeriodType = 'Price Modify Date';
          }

          if (this.s2StatusValue === 'auction') {
            this.filterPeriodType = 'Auction Date';
          }

          if (this.s2StatusValue === 'sold') {
            this.s2BasedOnDisabled = false;
            this.s2OrderByDisabled = false;
            this.s2BasedOnValue = 'settled,unconditional';
          } else {
            this.s2BasedOnDisabled = true;
            this.mdListingsForm.patchValue({
              basedon: '',
            });
            this.s2BasedOnValue = '';
          }

          switch (this.s2StatusValue) {
            case 'rental':
              if (this.s2ClassificationValue === 'residential') {
                this.s2SubStatusDisabled = false;
                this.s2AvailableDisabled = false;
              }
              break;
          }
        }

        this.propertySearchService.setS2Value({
          type: 's2StatusValue',
          value: this.s2StatusValue
        });

        if(this.s2StatusValue === 'sold' || this.s2StatusValue === 'unconditional' || (this.s2ClassificationValue === 'commercial' && this.s2StatusValue === 'leased') ) {
          this.disableLandBuildingFilter();
        }else {
          this.landBuildingAreaDisabled = false;
        }
        this.s2SoldFilterValue = '';
        break;
      case 'extrafilters':
        this.s2ExtraFiltersValue = value.trim();

        if (this.s2ExtraFiltersValue === 'openhomes') {
          this.s2OrderByDisabled = true;
          this.s2OpenHomeDaysDisabled = false;
          this.disableLandBuildingFilter();
        } else {
          this.s2OrderByDisabled = false;
          this.landBuildingAreaDisabled = false;
          this.s2OpenHomeDaysDisabled = true;

          this.s2OrderByData = <any>[
            { id: 'modifydateDesc', text: 'Sort by: Newest Modified' },
            { id: 'modifydateAsc', text: 'Sort by: Oldest Modified' },
            { id: 'inserteddateDesc', text: 'Sort by: Newest Inserted' },
            { id: 'inserteddateAsc', text: 'Sort by: Oldest Inserted' },
            { id: 'suburbAsc', text: 'Sort by: Suburb (A-Z)' },
            { id: 'suburbDesc', text: 'Sort by: Suburb (Z-A)' },
            { id: 'priceAsc', text: 'Sort by: Price (Low to High)' },
            { id: 'priceDesc', text: 'Sort by: Price (High to Low)' },
            { id: 'landareaAsc', text: 'Sort by: Land (Low to High)' },
            { id: 'landareaDesc', text: 'Sort by: Land (High to Low)' },
            { id: 'buildingareaAsc', text: 'Sort by: Building (Low to High)' },
            { id: 'buildingareaDesc', text: 'Sort by: Building (High to Low)' },
          ];
        }
        break;
      case 'substatus':
        this.s2SubStatusValue = value.trim();
        if (this.s2SubStatusValue === 'all') {
          this.s2SubStatusValue = '';
        }
        break;
      case 'openhomedays':
        this.s2OpenHomeDaysValue = value.trim();
        if (this.s2OpenHomeDaysValue === '0') {
          this.s2OpenHomeDaysValue = '';
        }
        break;
      case 'available':
        this.s2AvailableValue = value.trim();
        break;
      case 'basedon':
        this.s2BasedOnValue = value.trim();

        if (this.s2BasedOnValue !== '') {
          if (this.s2BasedOnValue === 'settled') {
            if (this.authUser.provider === 'vaultre') {
              this.s2OrderByData = <any>[
                { id: 'modifydateDesc', text: 'Sort by: Newest Modified' },
                { id: 'modifydateAsc', text: 'Sort by: Oldest Modified' },
                { id: 'inserteddateDesc', text: 'Sort by: Newest Inserted' },
                { id: 'inserteddateAsc', text: 'Sort by: Oldest Inserted' },
                { id: 'suburbAsc', text: 'Sort by: Suburb (A-Z)' },
                { id: 'suburbDesc', text: 'Sort by: Suburb (Z-A)' },
                { id: 'priceAsc', text: 'Sort by: Search Price (Low to High)' },
                { id: 'priceDesc', text: 'Sort by: Search Price (High to Low)' },
                { id: 'settlementAsc', text: 'Sort by: Settlement (A-Z)' },
                { id: 'settlementDesc', text: 'Sort by: Settlement (Z-A)' },
                { id: 'salepriceAsc', text: 'Sort by: Sale Price (Low to High)' },
                { id: 'salepriceDesc', text: 'Sort by: Sale Price (High to Low)' },
                { id: 'landareaAsc', text: 'Sort by: Land (Low to High)' },
                { id: 'landareaDesc', text: 'Sort by: Land (High to Low)' },
                { id: 'buildingareaAsc', text: 'Sort by: Building (Low to High)' },
                { id: 'buildingareaDesc', text: 'Sort by: Building (High to Low)' },
              ];
            }
          } else if (this.s2BasedOnValue === 'unconditional') {
            if (this.authUser.provider === 'vaultre') {
              this.s2OrderByData = <any>[
                { id: 'modifydateDesc', text: 'Sort by: Newest Modified' },
                { id: 'modifydateAsc', text: 'Sort by: Oldest Modified' },
                { id: 'inserteddateDesc', text: 'Sort by: Newest Inserted' },
                { id: 'inserteddateAsc', text: 'Sort by: Oldest Inserted' },
                { id: 'suburbAsc', text: 'Sort by: Suburb (A-Z)' },
                { id: 'suburbDesc', text: 'Sort by: Suburb (Z-A)' },
                { id: 'priceAsc', text: 'Sort by: Search Price (Low to High)' },
                { id: 'priceDesc', text: 'Sort by: Search Price (High to Low)' },
                { id: 'unconditionalAsc', text: 'Sort by: Unconditional (A-Z)' },
                { id: 'unconditionalDesc', text: 'Sort by: Unconditional (Z-A)' },
                { id: 'salepriceAsc', text: 'Sort by: Sale Price (Low to High)' },
                { id: 'salepriceDesc', text: 'Sort by: Sale Price (High to Low)' },
                { id: 'landareaAsc', text: 'Sort by: Land (Low to High)' },
                { id: 'landareaDesc', text: 'Sort by: Land (High to Low)' },
                { id: 'buildingareaAsc', text: 'Sort by: Building (Low to High)' },
                { id: 'buildingareaDesc', text: 'Sort by: Building (High to Low)' },
              ];
            }
          } else {
            if (this.authUser.provider === 'vaultre') {
              this.s2OrderByData = <any>[
                { id: 'modifydateDesc', text: 'Sort by: Newest Modified' },
                { id: 'modifydateAsc', text: 'Sort by: Oldest Modified' },
                { id: 'inserteddateDesc', text: 'Sort by: Newest Inserted' },
                { id: 'inserteddateAsc', text: 'Sort by: Oldest Inserted' },
                { id: 'suburbAsc', text: 'Sort by: Suburb (A-Z)' },
                { id: 'suburbDesc', text: 'Sort by: Suburb (Z-A)' },
                { id: 'priceAsc', text: 'Sort by: Search Price (Low to High)' },
                { id: 'priceDesc', text: 'Sort by: Search Price (High to Low)' },
                { id: 'unconditionalAsc', text: 'Sort by: Unconditional (A-Z)' },
                { id: 'unconditionalDesc', text: 'Sort by: Unconditional (Z-A)' },
                { id: 'settlementAsc', text: 'Sort by: Settlement (A-Z)' },
                { id: 'settlementDesc', text: 'Sort by: Settlement (Z-A)' },
                { id: 'salepriceAsc', text: 'Sort by: Sale Price (Low to High)' },
                { id: 'salepriceDesc', text: 'Sort by: Sale Price (High to Low)' },
                { id: 'landareaAsc', text: 'Sort by: Land (Low to High)' },
                { id: 'landareaDesc', text: 'Sort by: Land (High to Low)' },
                { id: 'buildingareaAsc', text: 'Sort by: Building (Low to High)' },
                { id: 'buildingareaDesc', text: 'Sort by: Building (High to Low)' },
              ];
            }
          }
        }
        break;
      case 'suburb':
        if (Array.isArray(value)) {
          this.s2SuburbValue = value;
        } else {
          if (this.s2SuburbValue === value) {
            value = '';
          }
          this.s2SuburbValue = value.trim();
        }
        break;
      case 'officeid':
        this.s2OfficeIdValue = value.trim();
        break;
      case 'agentid':
        this.s2AgentIdValue = value.trim();
        break;
      case 'pricefrom':
        this.s2PriceFromValue = value.trim();
        break;
      case 'priceto':
        this.s2PriceToValue = value.trim();
        break;
      case 'propertytype':
        this.s2TypeValue = value.trim();
        break;
      case 'precincts':
        this.s2PrecinctsValue = value.trim();
        break;        
      case 'customRegions':
        this.s2AccountRegionsValue = value.trim();
        break;                   
      case 'bedroom':
        this.s2BedroomValue = value.trim();
        break;
      case 'bedfrom':
        this.s2BedFromValue = value.trim();
        break;
      case 'bedto':
        this.s2BedToValue = value.trim();
        break;
      case 'bathfrom':
        this.s2BathFromValue = value.trim();
        break;
      case 'bathto':
        this.s2BathToValue = value.trim();
        break;
      case 'carfrom':
        this.s2CarFromValue = value.trim();
        break;
      case 'carto':
        this.s2CarToValue = value.trim();
        break;
      case 'orderby':
        this.s2OrderByValue = value.trim();
        break;
      case 'soldfilter':
        this.s2SoldFilterValue = value.trim();
        this.selectedSortDate = '';

        $('#sortDatePicker').val('');
        break;
      case 'vaultsuburb':
        if (Array.isArray(value)) {
          this.s2VaultSuburbValue = value;
        }
        break;
      case 'vaultdistrictsuburb':
        this.s2VaultSuburbValue = [];
        if (Array.isArray(value.data)) {
          this.s2VaultSuburbValue = value.data[0].suburb;
        }
        break;
      case 'branches':
        this.s2BranchesValue = value;
        break;
      case 'period':
        this.s2PeriodValue = value;
        break;
      case 'method':
        this.s2MethodValue = value;
        break;
      case 'perpage':
        this.s2postPerPageValue = value;
        break;
      case 'searchcategory':
        this.s2SearchValue = value;
        break;
      case 'listedpricemin':
        this.s2ListedPriceMinValue = '';
        if (value !== '0') {
          this.s2ListedPriceMinValue = value;
        }
        break;
      case 'listedpricemax':
        this.s2ListedPriceMaxValue = '';
        if (value !== '0') {
          this.s2ListedPriceMaxValue = value;
        }
      break;
    }

    if (field === 'searchcategory') {
      if (this.s2SearchValue === 'listed-in-market') {
        this.priceFinderSearch = true;
      } else {
        this.priceFinderSearch = false;
      }

      this.changeDetector.detectChanges();
      return;
    }

    this.mdListingsForm.patchValue({
      classification: this.s2ClassificationValue,
      propertytype: this.s2TypeValue,
      precincts: this.s2PrecinctsValue,
      customRegions: this.s2AccountRegionsValue,
      status: this.s2StatusValue,
      substatus: this.s2SubStatusValue,
      suburb: this.s2SuburbValue,
      officeid: this.s2OfficeIdValue,
      agentid: this.s2AgentIdValue,
      pricefrom: this.s2PriceFromValue,
      priceto: this.s2PriceToValue,
      bedroom: this.s2BedroomValue,
      bedfrom: this.s2BedFromValue,
      bedto: this.s2BedToValue,
      bathfrom: this.s2BathFromValue,
      bathto: this.s2BathToValue,
      carfrom: this.s2CarFromValue,
      carto: this.s2CarToValue,
      local_search: this.client.is_local_search,
      client_id: this.client.ID,
      orderby: this.s2OrderByValue,
      available: this.s2AvailableValue,
      basedon: this.s2BasedOnValue,
      extrafilters: this.s2ExtraFiltersValue,
      openhomedays: this.s2OpenHomeDaysValue,
      soldfilter: this.s2SoldFilterValue,
      sortdate: this.selectedSortDate,
      vaultsuburb: this.s2VaultSuburbValue ? this.s2VaultSuburbValue : '',
      branches: this.s2BranchesValue,
      periodtype: this.periodFilterType,
      period: this.s2PeriodValue,
      method: this.s2MethodValue,
      landareafrom: this.landareaFromValue ? this.landareaFromValue : '',
      landareato: this.landareaToValue ? this.landareaToValue : '',
      floorareafrom: this.floorareaFromValue ? this.floorareaFromValue : '',
      floorareato: this.floorareaToValue ? this.floorareaToValue : '',
      property_id: '',
    });

    if (field !== 'classification' && this.s2SelectingCategory) {
      this.s2SelectingCategory = false;
    }

    this.selectedListingIDs = [];
    this.selectedListings = [];
  }

  reorderImages(listing) {
    this._tmp_order_id = listing.id;
    this.listingImages = Array.from(listing.images);
    this.listingFloorplans = Array.from(listing.floorplans);

    $('#mdReorderImage').modal('show');
  }

  saveImageOrder() {
    const listingIndex = this.mdListings.findIndex(listing => listing.id === this._tmp_order_id);

    if (listingIndex !== -1) {
      this.mdListings[listingIndex].images = this.listingImages;
      this.mdListings[listingIndex].floorplans = this.listingFloorplans;

      $('#mdReorderImage').modal('hide');
      swal({
        position: 'top-end',
        type: 'success',
        title: 'Order saved',
        showConfirmButton: false,
        timer: 1500,
      }).catch(swal.noop);
    }
  }

  listListingAgents(listing) {
    this.subLoading = true;

    this._tmp_agent_listing_id = listing.id;

    jQuery('.agent-name').find('input').prop('checked', false);

    this.selectedlistingAgents = [];
    this.listingAgents = [];

    const agent1 = listing.agent1;
    const agent2 = listing.agent2;

    if (typeof this.templateAgent1 !== 'undefined') {
      if (typeof this.templateAgent1[this._tmp_agent_listing_id] !== 'undefined') {
        this.selectedlistingAgents.push(String(this.templateAgent1[this._tmp_agent_listing_id].agent_id));
        jQuery('.agent-name').find('input[value="' + this.templateAgent1[this._tmp_agent_listing_id].agent_id + '"]').prop('checked', true);
      } else {
        if ( agent1 !== null ) {
          this.selectedlistingAgents.push(String(agent1.agent_id));
          jQuery('.agent-name').find('input[value="' + agent1.agent_id + '"]').prop('checked', true);
        }
      }
    } else {
      if ( agent1 !== null ) {
        this.selectedlistingAgents.push(String(agent1.agent_id));
        jQuery('.agent-name').find('input[value="' + agent1.agent_id + '"]').prop('checked', true);
      }
    }

    if (typeof this.templateAgent2 !== 'undefined') {
      if (typeof this.templateAgent2[this._tmp_agent_listing_id] !== 'undefined') {
        this.selectedlistingAgents.push(String(this.templateAgent2[this._tmp_agent_listing_id].agent_id));
        jQuery('.agent-name').find('input[value="' + this.templateAgent2[this._tmp_agent_listing_id].agent_id + '"]').prop('checked', true);
      } else {
        if ( agent2 !== null ) {
          this.selectedlistingAgents.push(String(agent2.agent_id));
          jQuery('.agent-name').find('input[value="' + agent2.agent_id + '"]').prop('checked', true);
        }
      }
    } else {
      if ( agent2 !== null ) {
        this.selectedlistingAgents.push(String(agent2.agent_id));
        jQuery('.agent-name').find('input[value="' + agent2.agent_id + '"]').prop('checked', true);
      }
    }

    this.listingAgents = this.clientAgents;
    $('#mdListingAgents').modal('show');

    setTimeout(() => {
      this.subLoading = false;
    }, 1000);
  }

  selectAgent(id) {
    let selectAgents = [];

    $('.agents').each(function() {
      if ($(this).is(':checked')) {
        selectAgents.push($(this).val());
      } else {
        selectAgents = jQuery.grep(selectAgents, function(value) {
          return value != $(this).val();
        });
      }
    });

    if (selectAgents.length > 2) {
      $('#agent' + id).prop('checked', false);

      selectAgents = jQuery.grep(selectAgents, function(value) {
        return value != id;
      });

      swal({
        position: 'top-end',
        type: 'error',
        title: 'Maximum of two agents',
        showConfirmButton: false,
        timer: 1500
      }).catch(swal.noop);
    }

    this.selectedlistingAgents = selectAgents;
  }

  clearListingAgents() {
    jQuery('.agent-name').find('input[type="checkbox"]').prop('checked', false);
    this.selectedlistingAgents = [];
  }

  saveListingAgents() {
    const listingIndex = this.mdListings.findIndex(listing => listing.id === this._tmp_agent_listing_id);

    if (listingIndex !== -1) {
      const agents = this.selectedlistingAgents;
      const agent1 = this.clientAgents.find(item => item.agent_id === agents[0]);

      if (agent1 !== -1) {
        this.mdListings[listingIndex].agent1 = agent1;
      }

      if (agents.length > 1) {
        const agent2 = this.clientAgents.find(item => item.agent_id === agents[1]);

        if (agent2 !== -1) {
          this.mdListings[listingIndex].agent2 = agent2;
        }
      }

      $('#mdListingAgents').modal('hide');
      swal({
        position: 'top-end',
        type: 'success',
        title: 'Listing agents saved',
        showConfirmButton: false,
        timer: 1500
      }).catch(swal.noop);
    }
  }

  onCheckMulti() {
    const isMulti = $('#multi-office').is(':checked');
    this.mdListingsForm.patchValue({
      multioffice: isMulti,
    });
  }
  // Published properties
  onCheckInternal() {
    const includeInternalMarketing = $('#internal-marketing').is(':checked');
    // WB-2021: only one checkbox can be toggled between  and Internal marketing properties (behave like radio button)
    this.publishedProperties = $('#internal-marketing').is(':checked');
    const includeInternalMarketingOnly = $('#internal-marketing-only').is(':checked');
    if (includeInternalMarketing) {
      if (includeInternalMarketingOnly) {
        $('#internal-marketing-only').prop('checked', false);
      }
    }
    
    this.mdListingsForm.patchValue({
      includeinternal: includeInternalMarketing,
      internalmarketing: includeInternalMarketingOnly
    });
  }

  // Internal marketing properties
  onCheckMarketing() {
    const includeInternalMarketingOnly = $('#internal-marketing-only').is(':checked');
    // WB-2021: only one checkbox can be toggled between  and Internal marketing properties (behave like radio button)
    const includeInternalMarketing = $('#internal-marketing').is(':checked');
    if (includeInternalMarketingOnly) {
      if (includeInternalMarketing) {
        $('#internal-marketing').prop('checked', false);
      }
    }

    this.mdListingsForm.patchValue({
      internalmarketing: includeInternalMarketingOnly,
      includeinternal: includeInternalMarketing
    });
  }

  onCheckFranchiseListings() {
    const includeFranchiseListings = $('#franchise-listing').is(':checked');
    this.mdListingsForm.patchValue({
      franchiselistings: includeFranchiseListings,
    })
  }

  onCheckShowSalePrice() {
    const showSalePrice = $('#show-saleprice').is(':checked');
    this.mdListingsForm.patchValue({
      showsaleprice: showSalePrice,
    });

    this.listingSearchChange(0, false);
  }

  onCheckOpenHome() {
    const showByAppointment = $('#openhome-by-appointment').is(':checked');
    this.mdListingsForm.patchValue({
      openhomebyappointment: showByAppointment,
    });
  }

  onPaginate(type) {
    const formValues = this.mdListingsForm.value;
    if (type === 'prev') {
      this.currentVaultPage -= 1;
      formValues['paginate_link'] = this.designlyPreviousLink;
    }

    if (type === 'next') {
      this.currentVaultPage += 1;
      formValues['paginate_link'] = this.designlyNextLink;
    }

    if (type === 'all') {
      this.currentVaultPage = 1;
      formValues['pagesize'] = 500;
      formValues['paginate_link'] = '';
    }

    if (this.authUser.provider === 'idashboard') {
      formValues['page'] = this.currentVaultPage;
    }

    if (typeof this.template !== 'undefined') {
      formValues['template_type'] = this.template.category.slug;
    }

    this.myDesktopService.httpGetMDListings(formValues, this.authUser.provider);

    this.subLoading = true;
  }

  checkIfSelected(listingId: number) {
    if (this.selectedListingIDs.includes(listingId)) {
      return true;
    }

    return false;
  }

  selectAll() {
    this.selectAllCount = 0;
    this.mdListings.forEach((element) => {
      if (typeof element.id !== 'undefined' && element.id && !this.selectedListingIDs.includes(element.id)) {
        this.selectedListingIDs.push(element.id);
        this.selectedListings.push(element);
      }

      this.selectAllTriggered = true;
    });
  }

  deselectAll() {
    this.selectAllCount = 0;
    this.selectedListings = [];
    this.selectedListingIDs = [];
  }

  changePeriodSelection(value: string) {
    this.filterPeriodType = value;

    if (this.s2PeriodValue !== '' && this.s2PeriodValue !== 'any') {
      this.mdListingsForm.patchValue({
        periodtype: this.filterPeriodType,
      });
      this.listingSearchChange(0, false);
    }
  }

  changeAreaSelection(value: string) {
    this.filterByAreaValue = value;
  }

  initTooltip() {
    setTimeout(() => {
      let timeout;
      $('[data-tooltip]').on({
        'mouseenter': function () {
          timeout = setTimeout(() => {
              $('[data-tooltip]').removeClass('hovered');
              $(this).addClass('hovered');
            }, 350);
        },
        'mouseleave' : function () {
          clearTimeout(timeout);
          $(this).removeClass('hovered');
        }
      });
    }, 300);
  }

  onDragged(item: any, list: any[], effect: DropEffect) {
    if (effect === 'move') {
      const index = list.indexOf(item);
      list.splice(index, 1);
    }
  }

  onDrop(event: DndDropEvent, list?: any[]) {
    if (list && (event.dropEffect === 'copy' || event.dropEffect === 'move')) {
      let index = event.index;

      if (typeof index === 'undefined') {
        index = list.length;
      }

      list.splice(index, 0, event.data);
    }
  }

  closeModal(id: string) {
    $(`#${id}`).modal('hide');
  }

  onPriceFinderSelectProperty(event) {
    this.priceFinderSearchedPropertyId = event.value;
    this.priceFinderSearchedPropertyLabel = event.label;
  }

  onPriceFinderSearch() {
    const priceFinderSearchFormValues = this.priceFinderSearchForm.value;

    if (this.s2SearchValue === 'sold-in-market') {
      priceFinderSearchFormValues['status'] = 'sales';
    } else if (this.s2SearchValue === 'leased-in-market') {
      priceFinderSearchFormValues['status'] = 'lease';
    } else if (this.s2SearchValue === 'rentals-in-market') {
      priceFinderSearchFormValues['status'] = 'rental';
    } else {
      priceFinderSearchFormValues['status'] = 'listings';
    }

    priceFinderSearchFormValues['listed_price_min'] = this.s2ListedPriceMinValue;
    priceFinderSearchFormValues['listed_price_max'] = this.s2ListedPriceMaxValue;

    if (this.s2SearchByValue === 'search-by-suburb') {
      priceFinderSearchFormValues['searchType'] = 'suburb';
    }

    if (this.priceFinderSearchForm.valid) {
      this.showDesignlyPreviousPage = false;
      this.showDesignlyNextPage = false;
      this.vaultTotalPages = 1;
      this.priceFinderSearch = true;

      this.listingSearchValues = priceFinderSearchFormValues;
      this.pricefinderService.httpPropertyListings(this.priceFinderSearchedPropertyId, priceFinderSearchFormValues);
      this.subLoading = true;
    }
  }

  numberOnly(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  disableLandBuildingFilter(){
    this.landareaFromValue = '';
    this.landareaToValue = '';
    this.floorareaFromValue = '';
    this.floorareaToValue = '';
    this.landBuildingAreaDisabled = true;
  }

  onSelectSingleListing(property) {
    if (typeof property !== 'undefined') {
      this.subLoading = true;
      this.vaultService.httpGetSingleProperty(property.id, {
        normalize: true
      });
      $('#propertyAddress').modal('hide');
    }
  }

  ngAfterViewInit() {
    const $this = this;

    setTimeout(() => {
      $('#sortDatePicker')
      .datepicker({
        autoHide: true,
        format: 'yyyy-mm-dd',
      })
      .on('pick.datepicker', function (e) {
        $this.selectedSortDate = moment(e.date).format('YYYY-MM-DD');

        $this.s2ValueChanged('sortdate', $this.selectedSortDate);
      });
    }, 1000);

    this.mScrollbarService.initScrollbar('#prop-filter-container', { axis: 'y', theme: 'minimal-dark', scrollInertia: 600 });
  }

  searchSuburbs(query, callback?) {
    this.pricefinderService.searchSuburbs({
      query
    }).subscribe((response:any) => {
      if (response) {
        if (typeof response.status !== 'undefined') {
          this.priceFinderAddressSearchLoading = false;

          if (response.status === 'success') {
            this.priceFinderSuburbs = response.data.map(suburb => {
              return {
                value: suburb.suburb.id,
                label: suburb.display
              };
            });
            
            if (callback)
            callback();
          }
        }
      }
    });
  }

  searchAddresses(query, callback?) {
    this.pricefinderService.searchProperties({
      query
    }).subscribe((response:any) => {
      
      this.priceFinderAddressSearchLoading = false;

      if (response.status === 'success') {
        this.priceFinderProperties = response.data.map(property => {
          return {
            value: property.property.id,
            label: property.display
          };
        });

        if (callback)
          callback();
      }
    })
  }

  transformDisplayPrice(price: string): string {
    if (!!price && !price.startsWith('$')) {
      if (/^\d/.test(price)) {
        return `$${price}`;
      }
    }
    
    return price;
  }

  onBack() {
    this.back.emit();
  }

  get isProposalPropertyAddressFilled() {
    return !this.propertySearchAutoFills.includes(this.s2SearchByValue)
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.mScrollbarService.destroy('#prop-filter-container');
  }


}
