import {HttpParams,  HttpClient,   HttpRequest} from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';
import { APP_CONFIG } from '../app.config';
import { MyDesktopService } from './mydesktop.service';
@Injectable()
export class VaultService {
  vaultAuthLogin = new Subject<any>();

  onVaultSendTemplate = new Subject();
  onVaultSyncAgent = new Subject();
  onVaultGetListing = new Subject();
  onLoginAs = new Subject();
  onVaultGetListings = new Subject();
  onVaultGetMailoutData = new Subject();
  onVaultGetBranches = new Subject();
  onVaultGetPropertyTypes = new Subject();
  onVaultGetPrecincts = new Subject();
  onVaultGetAccountRegions = new Subject();
  onVaultGetAccountBranches = new Subject();

  // New Subscriptions
  onSearchAddress = new Subject();
  onGetFiles = new Subject();
  onGetFilesFromInsertProperty = new Subject();
  onSearchContacts = new Subject();
  onGetSingleProperty = new Subject();
  onStoreFiles = new Subject();
  onGetSinglePropertyInvestment = new Subject();
  onGetPropertyOwners = new Subject();
  onPushToFileCabinate = new Subject();

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    private httpClient: HttpClient,
    private myDesktopService: MyDesktopService
  ) {}

  httpLogin(data: any) {
    const req = new HttpRequest(
      'POST',
      `${this.appConfig.API_ENDPOINT}/vault/login`,
      data
    );

    return this.httpClient.request<any>(req)
    .map(
      (response: any) => {
      if (response.body) {
        if ( typeof response.body.status !== 'undefined') {
          return response.body;
        }
      }

      return [];
      }
    )
    .subscribe(
      (response: any) => {
        this.vaultAuthLogin.next(response);
      },
      (response: any) => {
        this.vaultAuthLogin.next(response.error);
      }
    );
  }

  httpSendTemplate(data: any) {
      const req = new HttpRequest(
          'POST',
          `${this.appConfig.API_ENDPOINT}/vault/sendtemplate`,
          data
      );

      return this.httpClient.request<any>(req)
      .map(
          (response: any) => {
          if (response.body) {
              if ( typeof response.body.status !== 'undefined') {
                  return response.body;
              }
          }

          return [];
          }
      )
      .subscribe(
          (response: any) => {
              this.onVaultSendTemplate.next(response);
          },
          (response: any) => {
              this.onVaultSendTemplate.next(response.error);
          }
      );
  }

  httpSyncAgents(id: number) {
      const req = new HttpRequest(
        'GET',
        `${this.appConfig.API_ENDPOINT}/vault/sync/agents/${id}`,
      );

      return this.httpClient.request(
        req
      )
      .map(
          (response: any) => {
            if (typeof response.body !== 'undefined' && response.body != null) {
                return response.body;
            }

            return [];
          }
      )
      .subscribe(
        (response: any) => {
          this.onVaultSyncAgent.next(response);
        },
        (response: any) => {
          this.onVaultSyncAgent.next(response.error);
        }
      );
  }

  httpGetListings(params: any = []) {
      let httpParams = new HttpParams();

      for (const p of Object.keys(params)) {
        httpParams = httpParams.append(p, params[p]);
      }

      const req = new HttpRequest(
        'GET',
        `${this.appConfig.API_ENDPOINT}/vault/listings`,
        {
          params: httpParams
        }
      );

      return this.httpClient.request(
        req
      )
      .map(
          (response: any) => {
            if (typeof response.body !== 'undefined' && response.body != null) {
                return response.body;
            }

            return [];
          }
      )
      .subscribe(
        (response: any) => {
          this.onVaultGetListings.next(response);
        },
        (response: any) => {
          this.onVaultGetListings.next(response.error);
        }
      );
  }

  getListing(id: number, params: any) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      `${this.appConfig.API_ENDPOINT}/vault/listing/${id}`,
      {
        params: httpParams
      }
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    );
}

  httpGetListing(id: number, params: any) {
      let httpParams = new HttpParams();

      for (const p of Object.keys(params)) {
        httpParams = httpParams.append(p, params[p]);
      }

      const req = new HttpRequest(
        'GET',
        `${this.appConfig.API_ENDPOINT}/vault/listing/${id}`,
        {
          params: httpParams
        }
      );

      return this.httpClient.request<any>(req)
      .map(
          (response: any) => {
            if (typeof response.body !== 'undefined' && response.body != null) {
                return response.body;
            }

            return [];
          }
      )
      .subscribe(
        (response: any) => {
          this.onVaultGetListing.next(response);
        },
        (response: any) => {
          this.onVaultGetListing.next(response.error);
        }
      );
  }

  httpLoginAs(data: any) {
      const req = new HttpRequest(
          'POST',
          `${this.appConfig.API_ENDPOINT}/vault/login-as`,
          data
      );

      return this.httpClient.request<any>(req)
      .map(
          (response: any) => {
          if (response.body) {
              if ( typeof response.body.status !== 'undefined') {
                  return response.body;
              }
          }

          return [];
          }
      )
      .subscribe(
          (response: any) => {
              this.onLoginAs.next(response);
          },
          (response: any) => {
              this.onLoginAs.next(response.error);
          }
      );
  }

  httpPaginate(data: any) {
      const req = new HttpRequest(
          'POST',
          `${this.appConfig.API_ENDPOINT}/vault/paginate`,
          data
      );

      return this.httpClient.request<any>(req)
      .map(
          (response: any) => {
          if (response.body) {
              if ( typeof response.body.status !== 'undefined') {
                  return response.body;
              }
          }

          return [];
          }
      )
      .subscribe(
          (response: any) => {
              this.myDesktopService.setMyDesktops(response);
          },
          (response: any) => {
              this.myDesktopService.myDesktopApiError.next(response);
          }
      );
  }

  httpGeMailOutData(id: string, params: any) {
    let httpParams = new HttpParams();

    for (const p of Object.keys(params)) {
      httpParams = httpParams.append(p, params[p]);
    }

    const req = new HttpRequest(
      'GET',
      `${this.appConfig.API_ENDPOINT}/vault/mailoutdata/${id}`,
      {
        params: httpParams
      }
    );

    return this.httpClient.request<any>(req)
    .map(
        (response: any) => {
          if (typeof response.body !== 'undefined' && response.body != null) {
              return response.body;
          }

          return [];
        }
    )
    .subscribe(
      (response: any) => {
        this.onVaultGetMailoutData.next(response);
      },
      (response: any) => {
        this.onVaultGetMailoutData.next(response.error);
      }
    );
  }

  httpGetBranches() {
    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/vault/branches`)
    .subscribe(
      (response: any) => {
        this.onVaultGetBranches.next(response);
      },
      (response: any) => {
        this.onVaultGetBranches.next(response.error);
      }
    );
  }

  searchAddress(term: string) {
    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/vault/address?term=${term}`)
  }

  httpSearchAddress(term: string) {
    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/vault/address?term=${term}`)
    .subscribe(
      (response: any) => {
        this.onSearchAddress.next(response);
      },
      (response: any) => {
        this.onSearchAddress.next(response.error);
      }
    );
  }

  httpGetFiles(params = {}) {
    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/vault/files`, {
      params
    })
    .subscribe(
      (response: any) => {
        this.onGetFiles.next(response);
      },
      (response: any) => {
        this.onGetFiles.next(response.error);
      }
    );
  }

  httpGetFilesFromProperty(params = {}) {
    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/vault/files`, {
      params
    })
    .subscribe(
      (response: any) => {
        this.onGetFilesFromInsertProperty.next(response);
      },
      (response: any) => {
        this.onGetFilesFromInsertProperty.next(response.error);
      }
    );
  }

  searchContacts(term: string) {
    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/vault/contacts?term=${term}`);
  }

  httpSearchContacts(term: string) {
    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/vault/contacts?term=${term}`)
    .subscribe(
      (response: any) => {
        this.onSearchContacts.next(response);
      },
      (response: any) => {
        this.onSearchContacts.next(response.error);
      }
    );
  }

  httpGetPropertyTypes() {
    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/vault/propertytypes`)
    .subscribe(
      (response: any) => {
        this.onVaultGetPropertyTypes.next(response);
      },
      (response: any) => {
        this.onVaultGetPropertyTypes.next(response.error);
      }
    );
  }

  httpGetPrecincts() {
    return this.httpClient.get(`${this.appConfig.NEW_API_ENDPOINT}/vault/precincts`)
    .subscribe(
      (response: any) => {
        this.onVaultGetPrecincts.next(response);
      },
      (response: any) => {
        this.onVaultGetPrecincts.next(response.error);
      }
    );
  }

  httpGetAccountRegions() {
    return this.httpClient.get(`${this.appConfig.NEW_API_ENDPOINT}/vault/account-regions`)
    .subscribe(
      (response: any) => {
        this.onVaultGetAccountRegions.next(response);
      },
      (response: any) => {
        this.onVaultGetAccountRegions.next(response.error);
      }
    );
  }

  httpGetAccountBranches() {
    return this.httpClient.get(`${this.appConfig.NEW_API_ENDPOINT}/vault/account-branches`)
    .subscribe(
      (response: any) => {
        this.onVaultGetAccountBranches.next(response);
      },
      (response: any) => {
        this.onVaultGetAccountBranches.next(response.error);
      }
    );
  }

  singleProperty(id: number, params: any = {}) {
    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/vault/single/property/${id}`, {
      params
    })
  }

  httpGetSingleProperty(id: number, params: any = {}) {
    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/vault/single/property/${id}`, {
      params
    })
    .subscribe(
      (response: any) => {
        if (typeof params.isInvestment !== 'undefined') {
          this.onGetSinglePropertyInvestment.next(response);
        } else {
          this.onGetSingleProperty.next(response);
        }
      },
      (response: any) => {
        this.onGetSingleProperty.next(response.error);
      }
    );
  }

  httpStoreFiles(data: any) {
    return this.httpClient.post(`${this.appConfig.API_ENDPOINT}/vault/storefiles`, data)
    .subscribe(
      (response: any) => {
        this.onStoreFiles.next(response);
      },
      (response: any) => {
        this.onStoreFiles.next(response.error);
      }
    );
  }

  getOwners(params: any) {
    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/vault/property/owners`, {
      params
    })
  }

  httpGetOwners(params: any) {
    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/vault/property/owners`, {
      params
    }).subscribe(
      (response: any) => {
        this.onGetPropertyOwners.next(response);
      },
      (response: any) => {
        this.onGetPropertyOwners.next(response.error);
      }
    );
  }

  getSuburbs(params: any) {
    return this.httpClient.get(`${this.appConfig.API_ENDPOINT}/vault/suburbs`, {
      params
    })
  }
  httpGetFilingCabinetFolders() {
    return this.httpClient.get(`${this.appConfig.NEW_API_ENDPOINT}/vault/filingcabinet/folders`);
  }

  httpPushFileToCabinetFolders(data: any) {
    const req = this.httpClient.post(
        `${this.appConfig.NEW_API_ENDPOINT}/vault/filingcabinet/upload`,
        data
    ).subscribe(
      (response: any) => {
          this.onPushToFileCabinate.next(response);
      },
      (response: any) => {
          this.onPushToFileCabinate.next(response.error);
      }
    );
  }
}
