import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PropertySearchService {
  onInsertListings = new Subject();
  onSetCheckers = new Subject();
  onS2Changed = new Subject();
  onOpenPropSearch = new Subject<boolean>();

  proInsertType = '';
  s2StatusValue = '';
  propertyInsertSource: any = null;

  constructor() { }

  insertListings(listings: any) {
    this.onInsertListings.next(listings);
  }

  setCheckers(checkers: any) {
    this.onSetCheckers.next(checkers);
  }

  setS2Value(data: any) {
    this.onS2Changed.next(data);
  }
}
